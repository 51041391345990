<template>
    
    <div
        class="c-below-topbar e-hide-scrollbar e-hide-scrollbar--x"
    >
        <div class="e-hide-scrollbar__inner">
            <ul class="c-below-topbar__list">
                <li class="c-below-topbar__item">
                    <router-link
                        active-class="c-below-topbar__link--active"
                        class="c-below-topbar__link"
                        :to="{ name: 'app.dashboards.traffic.orders' }"
                    >
                        Bestellingen
                    </router-link>
                </li>
                <li class="c-below-topbar__item">
                     <router-link
                        active-class="c-below-topbar__link--active"
                        class="c-below-topbar__link"
                        :to="{ name: 'app.dashboards.traffic.maatwerk-runs' }"
                    >
                        Maatwerk runs
                    </router-link>
                </li>
                <li class="c-below-topbar__item">
                     <router-link
                        active-class="c-below-topbar__link--active"
                        class="c-below-topbar__link"
                        :to="{ name: 'app.dashboards.traffic.finance' }"
                    >
                        Administratie
                    </router-link>
                </li>
                <li class="c-below-topbar__item">
                     <router-link
                        active-class="c-below-topbar__link--active"
                        class="c-below-topbar__link"
                        :to="{ name: 'app.dashboards.traffic.stock' }"
                    >
                        Voorraad
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive } from "vue";

onMounted(() => {

});
</script>