<template>
    <article>
        <div class="c-page-content">
            <div class="c-page-content__left e-load-items-animation">
                <div class="c-delivery">
                    <!-- @include('frontend.partials.order.tabs.billing.invoice') -->

                    <div class="e-note" v-if="state.order.is_splitted">
                        <SvgInfo />
                        <div
                            class="e-note__text"
                            v-if="state.order.origin_reference"
                        >
                            <p>
                                Deze bestelling is een splitsing. Ga naar de
                                hoofdbestelling

                                <router-link
                                    :to="{
                                        name: 'app.order.order',
                                        params: {
                                            hash: state.order.origin_hash,
                                        },
                                    }"
                                    class="c-order__order-link"
                                    >{{ state.order.origin_reference }}
                                </router-link>
                                om de facturering te beheren.
                            </p>
                        </div>
                        <div
                            class="e-note__text"
                            v-if="!state.order.origin_reference"
                        >
                            <p>
                                Deze bestelling bestaat uit meerdere
                                splitsingen. Alle details uit die bestellingen
                                worden in deze hoofdbestelling verzameld onder 1
                                factuur.<br />
                                Wanneer je de Facturatiestatus aanpast, wordt
                                deze ook toegepast op de onderliggende
                                splitsingen.
                            </p>
                        </div>
                    </div>

                    <section
                        class="c-page-content__section e-module-style"
                        :class="{
                            'is-hidden':
                                state.order.is_splitted &&
                                state.order.origin_reference,
                        }"
                    >
                        <div
                            class="e-section-heading e-section-heading--no-border"
                        >
                            <div class="e-section-heading__left">
                                <h2 class="e-section-heading__name">Factuur</h2>
                            </div>
                            <div class="e-section-heading__right"></div>
                        </div>
                        <div class="c-table-default mt-10">
                            <div class="c-table-default__inner-wrap">
                                <div class="c-table-default__table">
                                    <div class="c-table-default__head">
                                        <div class="c-table-default__tr">
                                            <div class="c-table-default__td">
                                                Product
                                            </div>
                                            <div class="c-table-default__td">
                                                Prijs
                                            </div>
                                            <div class="c-table-default__td">
                                                Aantal
                                            </div>
                                            <div class="c-table-default__td">
                                                Totaal
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-table-default__body">
                                        <invoice-row
                                            v-for="(row, index) in state.invoice
                                                .data"
                                            :key="index"
                                            :item="row"
                                            :category="true"
                                        >
                                        </invoice-row>
                                    </div>
                                    <div
                                        class="c-table-default__footer"
                                        v-if="state.invoice.totals"
                                    >
                                        <div
                                            class="c-table-default__tr"
                                            style="display: none"
                                        >
                                            <div
                                                class="c-table-default__td"
                                            ></div>
                                            <div class="c-table-default__td">
                                                Subtotaal
                                            </div>
                                            <div
                                                class="c-table-default__td"
                                            ></div>
                                            <div class="c-table-default__td">
                                                {{
                                                    state.invoice.totals[
                                                        "subtotaal"
                                                    ]
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="c-table-default__tr"
                                            style="display: none"
                                        >
                                            <div
                                                class="c-table-default__td"
                                            ></div>
                                            <div class="c-table-default__td">
                                                BTW
                                            </div>
                                            <div
                                                class="c-table-default__td"
                                            ></div>
                                            <div class="c-table-default__td">
                                                {{
                                                    state.invoice.totals["btw"]
                                                }}
                                            </div>
                                        </div>
                                        <div class="c-table-default__tr">
                                            <div
                                                class="c-table-default__td"
                                            ></div>
                                            <div
                                                class="c-table-default__td c-table-default__td--bold"
                                            >
                                                Totaal
                                            </div>
                                            <div
                                                class="c-table-default__td"
                                            ></div>
                                            <div
                                                class="c-table-default__td c-table-default__td--bold"
                                            >
                                                {{
                                                    state.invoice.totals[
                                                        "subtotaal"
                                                    ]
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="c-page-content__section e-module-style">
                        <div class="e-section-heading">
                            <div class="e-section-heading__left">
                                <div class="e-section-heading__icon">
                                    <SvgPencil />
                                </div>
                                <h2 class="e-section-heading__name">
                                    Notities gericht aan
                                    <span>Administratie</span>
                                </h2>
                            </div>
                        </div>

                        <div
                            class="c-chat"
                            v-if="
                                order.nested['messages'] &&
                                order.nested['messages'].data.length > 0
                            "
                        >
                            <div
                                class="e-hide-scrollbar e-hide-scrollbar--top-shadow"
                            >
                                <div
                                    class="c-chat__scroll-area e-hide-scrollbar__inner"
                                    ref="container"
                                >
                                    <div
                                        class="c-chat__messages"
                                        v-if="order.nested['messages']"
                                    >
                                        <div
                                            class="c-chat__message"
                                            v-for="(message, index) in order
                                                .nested['messages'].data"
                                            :key="index"
                                            v-show="
                                                message.type == 'Notitie' &&
                                                message.receiver ==
                                                    'Administratie'
                                            "
                                            :class="{
                                                'c-chat__message--client':
                                                    message.sender.role ==
                                                    'contact',
                                                'c-chat__message--note':
                                                    message.type == 'Notitie',
                                            }"
                                        >
                                            <div
                                                class="c-chat__message-inner-wrap"
                                            >
                                                <div
                                                    class="c-chat__message-head"
                                                >
                                                    <div
                                                        class="c-chat__message-name"
                                                    >
                                                        <img
                                                            class="c-chat__message-user-image"
                                                            v-if="
                                                                message.sender
                                                                    .avatar
                                                            "
                                                            :src="
                                                                message.sender
                                                                    .avatar ??
                                                                '/svg/user-image.svg'
                                                            "
                                                        />
                                                        <SvgUserImage
                                                            class="c-chat__message-user-image"
                                                            v-if="
                                                                !message.sender
                                                                    .avatar
                                                            "
                                                        ></SvgUserImage>

                                                        {{
                                                            message.sender.name
                                                        }}

                                                        <!-- <span
                                                            class="c-chat__message-source"
                                                            v-if="
                                                                message.type ==
                                                                    'Notitie' &&
                                                                !message.receiver
                                                            "
                                                        >
                                                            Notitie
                                                        </span>
                                                        <span
                                                            class="c-chat__message-source"
                                                            v-if="
                                                                message.type ==
                                                                    'Notitie' &&
                                                                message.receiver
                                                            "
                                                        >
                                                            Notitie voor
                                                            {{
                                                                message.receiver
                                                            }}
                                                        </span> -->
                                                    </div>
                                                    <div
                                                        class="c-chat__message-date"
                                                    >
                                                        {{ message.created_at }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="c-chat__message-text"
                                                >
                                                    <p
                                                        v-html="message.message"
                                                    ></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div class="c-page-content__sidebar e-load-items-animation">
                <!-- @include('frontend.partials.order.tabs.billing.data') -->

                <div
                    class="c-page-content__sidebar-section e-module-style"
                    :class="{
                        'is-hidden':
                            state.order.is_splitted &&
                            state.order.origin_reference,
                    }"
                >
                    <h2
                        class="c-page-content__sidebar-section-title"
                        style="position: relative"
                    >
                        Facturatiestatus
                        <div
                            v-if="state.invoice_status != 'Niet gefactureerd'"
                            style="
                                position: absolute;
                                right: 0;
                                top: 0;
                                scale: 0.8;
                                margin-top: -4px;
                            "
                        >
                            <a
                                class="e-button e-button--red e-button--green-hover"
                                @click="resetInvoiceStatus()"
                            >
                                <SvgRefresh
                                    style="margin-left: 6px"
                                ></SvgRefresh>
                            </a>
                        </div>
                    </h2>
                    <div class="e-form__select-wrap">
                        <select
                            class="e-form__select"
                            v-model="state.invoice_status"
                        >
                            <option
                                v-for="(option, value) in enums.options[
                                    'order-invoice-status'
                                ]"
                                v-bind:value="option"
                                :key="value"
                                :disabled="
                                    (state.invoice.exact &&
                                        option == 'Niet gefactureerd') ||
                                    (state.invoice.exact &&
                                        option ==
                                            'Staat klaar voor facturatie') ||
                                    (state.invoice.exact &&
                                        !option.includes('actureerd')) ||
                                    state.invoice.exact == 'busy'
                                "
                            >
                                {{ option }}
                            </option>
                        </select>
                    </div>
                    <div class="e-form__field-wrap mt-10">
                        <button
                            class="e-button e-button--full-width e-button--green"
                            v-if="state.invoice.exact != 'busy'"
                            @click="switchBillingStatus()"
                        >
                            Opslaan
                        </button>

                        <button
                            class="e-button e-button--full-width e-button--green disabled"
                            v-if="state.invoice.exact == 'busy'"
                        >
                            Wordt naar Exact gestuurd&hellip;
                        </button>
                    </div>
                </div>

                <div
                    class="c-page-content__sidebar-section e-module-style"
                    v-if="state.invoice.totals"
                    :class="{
                        'is-hidden':
                            state.order.is_splitted &&
                            state.order.origin_reference,
                    }"
                >
                    <h2 class="c-page-content__sidebar-section-title">
                        Statistieken
                    </h2>
                    <ul class="c-data-list c-data-list--table-style">
                        <!-- <li class="c-data-list__item">
                            <p class="c-data-list__subject">Omzet</p>
                            <p class="c-data-list__data">
                                {{ state.invoice.totals["subtotaal"] }}
                            </p>
                        </li> -->
                        <li class="c-data-list__item">
                            <p class="c-data-list__subject">Omzet</p>
                            <p class="c-data-list__data">
                                {{ state.invoice.totals["subtotaal"] }}
                            </p>
                        </li>
                    </ul>
                </div>

                <div
                    class="c-page-content__sidebar-section e-module-style temp"
                    v-if="state.invoice.totals"
                    :class="{
                        'is-hidden':
                            state.order.is_splitted &&
                            state.order.origin_reference,
                    }"
                >
                    <h2 class="c-page-content__sidebar-section-title">
                        Indicatieve berekeningen
                    </h2>
                    <ul class="c-data-list c-data-list--table-style">
                        <li class="c-data-list__item">
                            <p class="c-data-list__subject">Subtotaal</p>
                            <p
                                class="c-data-list__data"
                                onclick="alert('Tijdelijke weergave van deze berekening. Dit toont de eerder ooit berekende waardes.')"
                            >
                                {{ state.invoice.totals["subtotaal"] }}
                                <small>&nbsp; indicatief ℹ️</small>
                            </p>
                        </li>

                        <li class="c-data-list__item">
                            <p class="c-data-list__subject">BTW</p>
                            <p
                                class="c-data-list__data"
                                onclick="alert('Laat weten of dit een BTW bedrag is waar jullie wat mee kunnen, want die is in het verleden ooit berekend en met een reden weggehaald, maar wellicht dat je deze nu terug wilt')"
                            >
                                {{ state.invoice.totals["btw"] }}
                                <small>&nbsp; indicatief ℹ️</small>
                            </p>
                        </li>

                        <li class="c-data-list__item">
                            <p class="c-data-list__subject">Totaal</p>
                            <p
                                class="c-data-list__data"
                                onclick="alert('Tijdelijke weergave van deze berekening. Dit toont de eerder ooit berekende waardes.')"
                            >
                                {{ state.invoice.totals["totaal"] }}
                                <small>&nbsp; indicatief ℹ️</small>
                            </p>
                        </li>

                        <!-- 
                        <li class="c-data-list__item">
                            <p class="c-data-list__subject">Inkoopkosten</p>
                            <p class="c-data-list__data">- € 1467</p>
                        </li>
                        <li class="c-data-list__item">
                            <p class="c-data-list__subject">Productiekosten</p>
                            <p class="c-data-list__data">- € 1300</p>
                        </li>
                        <li class="c-data-list__item">
                            <p class="c-data-list__subject">Winst</p>
                            <p class="c-data-list__data">€ 3400</p>
                        </li>
                         -->
                    </ul>
                </div>
            </div>
        </div>
    </article>
</template>
<style scoped>
.temp {
    opacity: 0.2 !important;
}
.temp:hover {
    opacity: 0 !important;
}
</style>
<script setup>
import { useEnumStore } from "@/store/enum";
import { useOrderStore } from "@/store/order";
import SvgInfo from "@/svg/info.svg";
import SvgPencil from "@/svg/pencil.svg";
import SvgRefresh from "@/svg/refresh.svg";
import SvgUserImage from "@/svg/user-image.svg";
import Swal from "sweetalert2";
import { inject, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";

const Modals = inject("Modals");
const order = useOrderStore();
const enums = useEnumStore();

enums.fill("user-roles");
const route = useRoute();

const state = reactive({
    invoice_status: null,
    invoice: {
        exact: null,
        data: [],
        totals: null,
    },
    form: null,
    order: {
        status: {
            label: null,
        },
        contact: {
            address: {},
        },
        followers: {},
    },
});

onMounted(() => {
    Eventing.listen("fillOrder", (data) => {
        state.order = data.data;
        state.invoice_status = data.data.invoice_status.label;
    });

    Eventing.listen("fillInvoice", (data) => {
        console.warn("fillInvoice");
        console.info(data);
        state.invoice = data;
    });

    loadItems();

    fillNestedOrder();
});

function loadItems() {
    order.show(route.params.hash);
    order.invoice(route.params.hash);

    enums.fill("order-invoice-status");
}

function switchBillingStatus() {
    if (
        state.order.status.label == "Concept" ||
        state.order.status.label == "XXXX-In ontvangst"
    ) {
        Eventing.fire(
            "toasting",
            "Facturatiestatus kan pas worden aangepast wanneer order een status heeft",
            // "Facturatiestatus kan pas worden aangepast wanneer order minimaal de KIP-status heeft",
            "error"
        );
    } else {
        try {
            state.invoice.data.forEach((row) => {
                if (row.items) {
                    row.items.forEach((row) => {
                        if (row.btw == null) {
                            var warning =
                                'De regel met als waarde "' +
                                row.product.name +
                                '" bevat geen BTW. Dit kan voor problemen gaan zorgen zodra Exact hier een factuur van gaat maken. Controleer de regel(s) waarbij je een 🚨 icoon ziet staan.';

                            // Eventing.fire("toasting", warning, "warning");
                            // if (
                            //     confirm(
                            //         'De regel met als waarde "' +
                            //             row.product.name +
                            //             '" bevat geen BTW. Weet je zeker dat je door wilt gaan? Dit kan nanmelijk voor problemen gaan zorgen zodra Exact hier een factuur van gaat maken. Controleer de regel(s) waarbij je een 🚨 icoon ziet staan.'
                            //     )
                            // ) {
                            //     //
                            // } else {
                            //     window.location.reload();
                            // }
                        }
                    });
                }
            });
        } catch (error) {}

        order.switchBillingState(state.order.hash, state.invoice_status);
        if (state.invoice_status == "Factuur naar Exact sturen") {
            state.invoice.exact = "busy";
        }
    }
}

function resetInvoiceStatus() {
    Swal.fire({
        title: "Facturatiestatus reset",
        // text: "Zeker weten dat je de facturatie status wilt herstellen naar de beginwaarde? En realiseer je je dat daarmee ook eventuele koppelingen richting Exact hiermee worden gereset? Om dit te bevestigen vul je hieronder het bestelnummer in om dit te bevestigen.",
        text: "Zeker weten dat je de facturatie status wilt herstellen naar de beginwaarde? En realiseer je je dat daarmee ook eventuele koppelingen richting Exact hiermee worden gereset?",
        confirmButtonText: "Reset",
        input: "textarea",
        inputPlaceholder: "Notitie voor reden van wijziging",
        showCloseButton: true,
        icon: "error",
    }).then(async (result) => {
        if (result.isConfirmed) {
            // if (result.value != state.order.reference) {
            //     return alert(
            //         "Je opgegeven waarde komt niet overeen met het nummer van de bestelling"
            //     );
            // }
            if (confirm("Weet je het zeker?")) {
                let reset = await order.resetBillingState(
                    state.order.hash,
                    "reset",
                    result.value
                );
                if (reset) {
                    if (!reset.toString().includes("409")) {
                        alert(
                            'Facturatie status is hersteld naar "Niet gefactureerd"'
                        );
                        window.location.reload();
                    }
                }
            }
        }
    });
}

async function fillNestedOrder() {
    let result = await order.fillNested(route.params.hash, "messages");
    if (result) {
        state.form = {
            message: null,
            type: "Notitie",
            via: "Order",
            receiver: [],
        };
    }
}
</script>
