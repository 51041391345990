<template>
    <section>
        <modal-production-is-ready
            v-if="state.modal == 'production-is-ready'"
        />
    </section>
</template>
<script setup>
import { onMounted, reactive, ref, inject } from "vue";
const Modals = inject("Modals");

const state = reactive({ modal: null });

onMounted(() => {
    Eventing.listen("modal", (screen) => openModal(screen));
    Eventing.listen("closeModal", () => closeModal());
});

function openModal(screen) {
    state.modal = screen;
    Modals.open();
}

function closeModal() {
    state.modal = null;
    Modals.closing();
}
</script>
