<template>
    <article>
        <div class="c-page-content">
            <div class="c-page-content__left">
                <div
                    class="c-delivery e-load-items-animation"
                    v-if="state.order.boxes && state.order.boxes.length > 0"
                >
                    <!-- @include('frontend.partials.order.tabs.delivery.type') -->

                    <section class="c-page-content__section e-module-style">
                        <div class="e-section-heading">
                            <div class="e-section-heading__left">
                                <h2 class="e-section-heading__name">
                                    Type bezorging
                                </h2>
                            </div>
                            <div class="e-section-heading__right">
                                <label class="e-form__checkbox-wrap">
                                    <input
                                        class="e-form__checkbox"
                                        type="checkbox"
                                        v-model="
                                            state.order.has_varying_deliveries
                                        "
                                        @change="switchVaryingDelivery()"
                                        value="true"
                                    />
                                    <span class="e-form__checkbox-label"></span>
                                    <span class="e-form__checkbox-text"
                                        >Verschilt per samenstelling</span
                                    >
                                </label>
                            </div>
                        </div>

                        <!-- IF NOT Verschilt per samenstelling -->

                        <div
                            class="e-form__field-wrap e-form__field-wrap--radios"
                            v-if="!state.order.has_varying_deliveries"
                        >
                            <div
                                class="e-form__multiple-radios"
                                v-for="(box, index) in state.order.boxes"
                                :key="box.id"
                                v-show="index == 0"
                            >
                                <label
                                    class="e-form__radio-wrap"
                                    v-for="(option, value) in enums.options[
                                        'box-shipping-methods'
                                    ]"
                                    :key="value"
                                >
                                    <input
                                        class="e-form__radio"
                                        type="radio"
                                        x-name="'delivery-type' + box.id"
                                        v-model="box.shipping_method"
                                        :value="option"
                                        @change="
                                            changeDeliverySetting(
                                                'shipping_method',
                                                box.shipping_method,
                                                box.hash
                                            )
                                        "
                                    />
                                    <span class="e-form__radio-label"></span>
                                    <span class="e-form__radio-text">{{
                                        option
                                    }}</span>
                                </label>
                            </div>
                        </div>

                        <!-- IF Verschilt per samenstelling -->

                        <div
                            class="c-page-content__sub-sections"
                            v-if="state.order.has_varying_deliveries"
                        >
                            <div
                                class="c-page-content__sub-section"
                                v-for="box in state.order.boxes"
                                :key="box.id"
                            >
                                <h2 class="c-page-content__sub-section-title">
                                    <SvgGift /> {{ box.name }}
                                </h2>
                                <div
                                    class="e-form__field-wrap e-form__field-wrap--radios mt-10"
                                >
                                    <div class="e-form__multiple-radios">
                                        <label
                                            class="e-form__radio-wrap"
                                            v-for="(option, value) in enums
                                                .options[
                                                'box-shipping-methods'
                                            ]"
                                            :key="value"
                                        >
                                            <input
                                                class="e-form__radio"
                                                type="radio"
                                                x-name="'delivery-type' + box.id"
                                                v-model="box.shipping_method"
                                                :value="option"
                                                @change="
                                                    changeDeliverySetting(
                                                        'shipping_method',
                                                        box.shipping_method,
                                                        box.hash
                                                    )
                                                "
                                            />
                                            <span
                                                class="e-form__radio-label"
                                            ></span>
                                            <span class="e-form__radio-text">{{
                                                option
                                            }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <!-- @include('frontend.partials.order.tabs.delivery.address') -->
                    <Transition name="fade">
                        <delivery-addresses
                            v-if="!state.loading"
                            :order="state.order"
                        ></delivery-addresses>
                    </Transition>

                    <!-- @include('frontend.partials.order.tabs.delivery.date') -->
                    <Transition name="fade">
                        <section class="c-page-content__section e-module-style">
                            <div class="e-section-heading">
                                <div class="e-section-heading__left">
                                    <h2 class="e-section-heading__name">
                                        Leverdatum
                                    </h2>
                                </div>
                            </div>

                            <!-- IF NOT verschilt per samenstelling -->
                            <div class="e-form">
                                <div
                                    class="c-page-content__section"
                                    v-if="!state.order.has_varying_deliveries"
                                >
                                    <div
                                        v-for="(box, index) in state.order
                                            .boxes"
                                        :key="box.id"
                                        v-show="index == 0"
                                    >
                                        <div
                                            class="e-note mt-10"
                                            v-if="
                                                box.delivery_options &&
                                                Object.values(
                                                    box.delivery_options
                                                ).length > 0 &&
                                                !box.delivery_date
                                            "
                                        >
                                            <SvgInfo />
                                            <div class="e-note__text">
                                                <p>
                                                    Selecteer de gewenste
                                                    afleverdatum uit
                                                    onderstaande opties:
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            class="e-form__field-wrap e-form__field-wrap--radios mt-10"
                                            v-if="
                                                box.delivery_options &&
                                                Object.values(
                                                    box.delivery_options
                                                ).length > 0 &&
                                                !box.delivery_date
                                            "
                                        >
                                            <div
                                                class="e-form__multiple-radios delivery_options"
                                            >
                                                <label
                                                    class="e-form__radio-wrap"
                                                    v-for="(
                                                        option, value
                                                    ) in box.delivery_options"
                                                    :key="value"
                                                >
                                                    <input
                                                        class="e-form__radio"
                                                        type="radio"
                                                        :value="option"
                                                        v-model="
                                                            box.delivery_selected_option
                                                        "
                                                        @change="
                                                            changeDeliverySetting(
                                                                'delivery_date',
                                                                box.delivery_selected_option,
                                                                box.hash
                                                            )
                                                        "
                                                    />
                                                    <span
                                                        class="e-form__radio-label"
                                                    ></span>
                                                    <span
                                                        class="e-form__radio-text"
                                                        >{{
                                                            box
                                                                .delivery_options_formatted[
                                                                value
                                                            ]
                                                        }}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>

                                        <div
                                            class="e-form__field-wrap mt-10"
                                            v-show="
                                                box.delivery_date ||
                                                Object.values(
                                                    box.delivery_options
                                                ).length == 0
                                            "
                                        >
                                            <span
                                                class="e-form__label e-form__label--nowrap"
                                                >Definitieve datum
                                                <span v-if="box.delivery_week"
                                                    >| WK
                                                    {{
                                                        box.delivery_week.split(
                                                            "_"
                                                        )[0]
                                                    }}</span
                                                >
                                            </span>
                                            <input
                                                type="date"
                                                class="e-form__input"
                                                v-model="box.delivery_date"
                                            />
                                            <button
                                                class="e-button e-button--grey-dark"
                                                @click="
                                                    changeDeliverySetting(
                                                        'delivery_date',
                                                        box.delivery_date,
                                                        box.hash
                                                    )
                                                "
                                            >
                                                Opslaan
                                            </button>

                                            <button
                                                v-show="box.delivery_date"
                                                class="e-section-heading__icon e-section-heading__icon--delete"
                                                @click="
                                                    changeDeliverySetting(
                                                        'delivery_date',
                                                        null,
                                                        box.hash
                                                    )
                                                "
                                            >
                                                <SvgDelete />
                                            </button>
                                        </div>

                                        <div
                                            class="e-form__field-wrap mt-10"
                                            x-show="
                                                box.delivery_date ||
                                                Object.values(
                                                    box.delivery_options
                                                ).length == 0
                                            "
                                            v-if="
                                                state.order.category &&
                                                state.order.category ==
                                                    'Traditioneel pakket'
                                            "
                                        >
                                            <span
                                                class="e-form__label e-form__label--nowrap"
                                                >Richting partner
                                                <span
                                                    v-if="
                                                        box.delivery_week_partner
                                                    "
                                                    >| WK
                                                    {{
                                                        box.delivery_week_partner.split(
                                                            "_"
                                                        )[0]
                                                    }}</span
                                                >
                                            </span>
                                            <input
                                                type="date"
                                                class="e-form__input"
                                                v-model="
                                                    box.delivery_date_partner
                                                "
                                            />
                                            <button
                                                class="e-button e-button--grey-dark"
                                                @click="
                                                    changeDeliverySetting(
                                                        'delivery_date_partner',
                                                        box.delivery_date_partner,
                                                        box.hash
                                                    )
                                                "
                                            >
                                                Opslaan
                                            </button>

                                            <button
                                                v-show="box.delivery_date"
                                                class="e-section-heading__icon e-section-heading__icon--delete"
                                                @click="
                                                    changeDeliverySetting(
                                                        'delivery_date',
                                                        null,
                                                        box.hash
                                                    )
                                                "
                                            >
                                                <SvgDelete />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <!-- IF Verschilt per samenstelling -->
                                <div
                                    class="c-page-content__sub-sections"
                                    v-if="state.order.has_varying_deliveries"
                                >
                                    <div
                                        class="c-page-content__sub-section"
                                        v-for="box in state.order.boxes"
                                        :key="box.id"
                                    >
                                        <h2
                                            class="c-page-content__sub-section-title"
                                        >
                                            <SvgGift /> {{ box.name }}
                                        </h2>

                                        <div
                                            class="e-note mt-10"
                                            v-if="
                                                box.delivery_options &&
                                                Object.values(
                                                    box.delivery_options
                                                ).length > 0 &&
                                                !box.delivery_date
                                            "
                                        >
                                            <SvgInfo />
                                            <div class="e-note__text">
                                                <p>
                                                    Selecteer de gewenste
                                                    afleverdatum uit
                                                    onderstaande opties:
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            class="e-form__field-wrap e-form__field-wrap--radios mt-10"
                                            v-if="
                                                box.delivery_options &&
                                                Object.values(
                                                    box.delivery_options
                                                ).length > 0 &&
                                                !box.delivery_date
                                            "
                                        >
                                            <div
                                                class="e-form__multiple-radios delivery_options"
                                            >
                                                <label
                                                    class="e-form__radio-wrap"
                                                    v-for="(
                                                        option, value
                                                    ) in box.delivery_options"
                                                    :key="value"
                                                >
                                                    <input
                                                        class="e-form__radio"
                                                        type="radio"
                                                        :value="option"
                                                        v-model="
                                                            box.delivery_selected_option
                                                        "
                                                        @change="
                                                            changeDeliverySetting(
                                                                'delivery_date',
                                                                box.delivery_selected_option,
                                                                box.hash
                                                            )
                                                        "
                                                    />
                                                    <span
                                                        class="e-form__radio-label"
                                                    ></span>
                                                    <span
                                                        class="e-form__radio-text"
                                                        >{{
                                                            box
                                                                .delivery_options_formatted[
                                                                value
                                                            ]
                                                        }}</span
                                                    >
                                                </label>
                                            </div>
                                        </div>

                                        <div
                                            class="e-form__field-wrap mt-10"
                                            v-show="
                                                box.delivery_date ||
                                                Object.values(
                                                    box.delivery_options
                                                ).length == 0
                                            "
                                        >
                                            <span
                                                class="e-form__label e-form__label--nowrap"
                                                >Definitieve datum
                                                <span v-if="box.delivery_week"
                                                    >| WK
                                                    {{
                                                        box.delivery_week.split(
                                                            "_"
                                                        )[0]
                                                    }}</span
                                                ></span
                                            >
                                            <input
                                                type="date"
                                                class="e-form__input"
                                                v-model="box.delivery_date"
                                            />
                                            <button
                                                class="e-button e-button--grey-dark"
                                                @click="
                                                    changeDeliverySetting(
                                                        'delivery_date',
                                                        box.delivery_date,
                                                        box.hash
                                                    )
                                                "
                                            >
                                                Opslaan
                                            </button>

                                            <button
                                                v-show="box.delivery_date"
                                                class="e-section-heading__icon e-section-heading__icon--delete"
                                                @click="
                                                    changeDeliverySetting(
                                                        'delivery_date',
                                                        null,
                                                        box.hash
                                                    )
                                                "
                                            >
                                                <SvgDelete />
                                            </button>
                                        </div>

                                        <div
                                            class="e-form__field-wrap mt-10"
                                            x-show="
                                                box.delivery_date ||
                                                Object.values(
                                                    box.delivery_options
                                                ).length == 0
                                            "
                                            v-if="
                                                state.order.category &&
                                                state.order.category ==
                                                    'Traditioneel pakket'
                                            "
                                        >
                                            <span
                                                class="e-form__label e-form__label--nowrap"
                                                >Richting partner
                                                <span
                                                    v-if="
                                                        box.delivery_week_partner
                                                    "
                                                    >| WK
                                                    {{
                                                        box.delivery_week_partner.split(
                                                            "_"
                                                        )[0]
                                                    }}</span
                                                ></span
                                            >
                                            <input
                                                type="date"
                                                class="e-form__input"
                                                v-model="
                                                    box.delivery_date_partner
                                                "
                                            />
                                            <button
                                                class="e-button e-button--grey-dark"
                                                @click="
                                                    changeDeliverySetting(
                                                        'delivery_date_partner',
                                                        box.delivery_date_partner,
                                                        box.hash
                                                    )
                                                "
                                            >
                                                Opslaan
                                            </button>

                                            <button
                                                v-show="box.delivery_date"
                                                class="e-section-heading__icon e-section-heading__icon--delete"
                                                @click="
                                                    changeDeliverySetting(
                                                        'delivery_date',
                                                        null,
                                                        box.hash
                                                    )
                                                "
                                            >
                                                <SvgDelete />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Transition>
                    <!-- @include('frontend.partials.order.tabs.delivery.company') -->
                    <section class="c-page-content__section e-module-style">
                        <div class="e-section-heading">
                            <div class="e-section-heading__left">
                                <h2 class="e-section-heading__name">
                                    Transporteur
                                </h2>
                            </div>
                        </div>

                        <!-- IF NOT Verschilt per samenstelling -->

                        <div
                            class="e-form__field-wrap e-form__field-wrap--radios e-form__field-wrap--radios-list"
                            v-if="!state.order.has_varying_deliveries"
                        >
                            <div
                                class="e-form__multiple-radios"
                                v-for="(box, index) in state.order.boxes"
                                :key="box.id"
                                v-show="index == 0"
                            >
                                <label
                                    class="e-form__radio-wrap"
                                    :class="{
                                        disabled:
                                            box.shipping_method == 'Digitaal',
                                    }"
                                    v-for="(option, value) in enums.options[
                                        'box-transporters'
                                    ]"
                                    :key="value"
                                >
                                    <input
                                        class="e-form__radio"
                                        type="radio"
                                        :name="'delivery-type' + box.id"
                                        v-model="box.shipping_transporter"
                                        :disabled="
                                            box.shipping_method == 'Digitaal'
                                        "
                                        :value="option"
                                        @change="
                                            changeDeliverySetting(
                                                'shipping_transporter',
                                                box.shipping_transporter,
                                                box.hash
                                            )
                                        "
                                    />
                                    <span class="e-form__radio-label"></span>
                                    <span class="e-form__radio-text">{{
                                        option
                                    }}</span>
                                </label>
                            </div>
                        </div>

                        <!-- IF Verschilt per samenstelling -->

                        <div
                            class="c-page-content__sub-sections"
                            v-if="state.order.has_varying_deliveries"
                        >
                            <div
                                class="c-page-content__sub-section"
                                v-for="box in state.order.boxes"
                                :key="box.id"
                                v-show="box.shipping_method != null"
                            >
                                <h2 class="c-page-content__sub-section-title">
                                    <SvgGift /> {{ box.name }}
                                </h2>
                                <div
                                    class="e-form__field-wrap e-form__field-wrap--radios e-form__field-wrap--radios-list mt-10"
                                >
                                    <div class="e-form__multiple-radios">
                                        <label
                                            class="e-form__radio-wrap"
                                            :class="{
                                                disabled:
                                                    box.shipping_method ==
                                                    'Digitaal',
                                            }"
                                            v-for="(option, value) in enums
                                                .options['box-transporters']"
                                            :key="value"
                                        >
                                            <input
                                                class="e-form__radio"
                                                type="radio"
                                                :name="'delivery-type' + box.id"
                                                v-model="
                                                    box.shipping_transporter
                                                "
                                                :disabled="
                                                    box.shipping_method ==
                                                    'Digitaal'
                                                "
                                                :value="option"
                                                @change="
                                                    changeDeliverySetting(
                                                        'shipping_transporter',
                                                        box.shipping_transporter,
                                                        box.hash
                                                    )
                                                "
                                            />
                                            <span
                                                class="e-form__radio-label"
                                            ></span>
                                            <span class="e-form__radio-text">{{
                                                option
                                            }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <!-- @include('frontend.partials.order.tabs.delivery.note') -->
                    <section class="c-page-content__section e-module-style">
                        <div class="e-section-heading">
                            <div class="e-section-heading__left">
                                <div class="e-section-heading__icon">
                                    <SvgPencil />
                                </div>
                                <h2 class="e-section-heading__name">
                                    Opmerking bezorging
                                </h2>
                            </div>
                        </div>

                        <!-- IF NOT Verschilt per samenstelling -->
                        <div v-if="!state.order.has_varying_deliveries">
                            <div
                                v-for="(box, index) in state.order.boxes"
                                :key="box.id"
                                v-show="index == 0"
                            >
                                <resize-textarea
                                    ref="myTextarea"
                                    :min-height="50"
                                    v-model="box.shipping_remarks"
                                    style="padding: 1.5rem"
                                    class="e-form__textarea vue-textarea-auto-height"
                                    placeholder="Opmerking..."
                                    maxlength="250"
                                />
                                <div class="c-page-content__section-button">
                                    <button
                                        class="e-button e-button--green mt-10"
                                        @click="
                                            changeDeliverySetting(
                                                'shipping_remarks',
                                                box.shipping_remarks,
                                                box.hash
                                            )
                                        "
                                    >
                                        Opmerking opslaan
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- IF Verschilt per samenstelling -->

                        <div
                            class="c-page-content__sub-sections"
                            v-if="state.order.has_varying_deliveries"
                        >
                            <div
                                class="c-page-content__sub-section"
                                v-for="box in state.order.boxes"
                                :key="box.id"
                            >
                                <h2 class="c-page-content__sub-section-title">
                                    <SvgGift /> {{ box.name }}
                                </h2>
                                <textarea
                                    class="e-form__textarea mt-10"
                                    placeholder="Opmerking..."
                                    v-model="box.shipping_remarks"
                                    maxlength="250"
                                ></textarea>

                                <div class="c-page-content__section-button">
                                    <button
                                        class="e-button e-button--green mt-10"
                                        @click="
                                            changeDeliverySetting(
                                                'shipping_remarks',
                                                box.shipping_remarks,
                                                box.hash
                                            )
                                        "
                                    >
                                        Opmerking opslaan
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div class="c-page-content__sidebar e-load-items-animation">
                <!-- @include('frontend.partials.order.tabs.delivery.data') -->

                <div
                    class="c-page-content__sidebar-section e-module-style"
                    v-if="state.delivery"
                >
                    <h2 class="c-page-content__sidebar-section-title">
                        Bezorging data
                    </h2>
                    <ul class="c-data-list">
                        <li
                            class="c-data-list__item"
                            v-if="state.delivery.weight"
                        >
                            <p class="c-data-list__subject">Totaal gewicht</p>
                            <p class="c-data-list__data">
                                {{ state.delivery.weight }}
                            </p>
                        </li>
                        <li class="c-data-list__item">
                            <p class="c-data-list__subject">Aantal colli</p>
                            <p class="c-data-list__data">
                                {{ state.delivery.colli }}
                            </p>
                        </li>
                        <li class="c-data-list__item">
                            <p class="c-data-list__subject">Aantal kratten</p>
                            <p class="c-data-list__data">
                                {{ state.delivery.crates }}
                            </p>
                        </li>
                        <li
                            class="c-data-list__item"
                            v-if="
                                state.delivery.shipment_costs &&
                                state.delivery.shipment_costs != '€ 0,00'
                            "
                        >
                            <p class="c-data-list__subject">Verzendkosten</p>
                            <p class="c-data-list__data">
                                {{ state.delivery.shipment_costs }}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </article>
</template>

<script setup>
import SvgDelete from "@/svg/delete.svg";
import SvgGift from "@/svg/gift.svg";
import SvgInfo from "@/svg/info.svg";

import { useBoxStore } from "@/store/box";
import { useEnumStore } from "@/store/enum";
import { useOrderStore } from "@/store/order";
import SvgPencil from "@/svg/pencil.svg";
import Swal from "sweetalert2";
import { inject, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";

const Modals = inject("Modals");
const order = useOrderStore();
const box = useBoxStore();

const route = useRoute();
const enums = useEnumStore();

const state = reactive({
    invoice_status: null,
    loading: true,
    delivery: null,
    invoice: {
        data: [],
        totals: null,
    },
    order: {
        hash: null,
        has_varying_deliveries: false,
        contact: {
            address: {},
        },
        followers: {},
        boxes: null,
    },
});

onMounted(() => {
    Eventing.listen("fillDelivery", (data) => {
        state.order = data.data;
        state.delivery = data.delivery;
        state.loading = false;
        if (state.order.boxes && state.order.boxes.length == 0) {
            Eventing.fire(
                "toasting",
                "Er zijn nog geen samenstellingen bekend",
                "info"
            );
        }
    });
    Eventing.listen("reloadDelivery", () => {
        order.delivery(route.params.hash);
    });
    Eventing.listen("fillOrder", (data) => {
        state.order = data.data;
        state.loading = false;
    });
    loadItems();
});

function loadItems() {
    order.delivery(route.params.hash);
    order.invoice(route.params.hash);

    enums.fill("box-shipping-methods");
    enums.fill("box-transporters");
}

async function changeDeliverySetting(field, _value, hash) {
    state.loading = true;

    if (field == "shipping_method" && _value == "Huis-aan-huis") {
        const { value: check } = await Swal.fire({
            title: "Bevat deze bestellingen ook bezorgingen naar het buitenland?",
            input: "select",
            icon: "question",
            inputOptions: {
                NEE: "Nee, enkel HaH naar Nederland",
                JA: "Ja, die zijn aanwezig in deze bestelling",
                JA_DONE: "Ja, en deze bestelling is om die reden al gesplitst",
            },
            cancelButtonText: "Annuleren",
            inputPlaceholder: "",
            showCancelButton: true,
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    if (value === "JA") {
                        resolve(
                            "Je dient deze bestelling te gaan splitsen om door te gaan"
                        );
                    } else {
                        resolve();
                    }
                });
            },
        });
        if (check) {
            if (_value == null) {
                if (confirm("Zeker weten?")) {
                    return box.updateBoxDelivery(route.params.hash, {
                        field: field,
                        value: _value,
                        box: hash,
                    });
                }
                return;
            }
            Swal.fire({
                text:
                    'Zeker weten dat je "' +
                    _value +
                    '" wilt kiezen' +
                    translateField(field),
                confirmButtonText: "Ja",
                showCloseButton: true,
                icon: "info",
            }).then((result) => {
                if (result.isConfirmed) {
                    box.updateBoxDelivery(route.params.hash, {
                        field: field,
                        value: _value,
                        box: hash,
                    });
                }
            });
        } else {
            window;
        }
    } else {
        if (_value == null) {
            if (confirm("Zeker weten?")) {
                return box.updateBoxDelivery(route.params.hash, {
                    field: field,
                    value: _value,
                    box: hash,
                });
            }
            return;
        }
        Swal.fire({
            text:
                'Zeker weten dat je "' +
                _value +
                '" wilt kiezen' +
                translateField(field),
            confirmButtonText: "Ja",
            showCloseButton: true,
            icon: "info",
        }).then((result) => {
            if (result.isConfirmed) {
                box.updateBoxDelivery(route.params.hash, {
                    field: field,
                    value: _value,
                    box: hash,
                });
            }
        });
    }
}

function translateField(field) {
    switch (field) {
        case "shipping_method":
            return " voor type bezorging?";
        case "delivery_date":
            return " als definitieve leverdatum?";
        case "shipping_transporter":
            return " als transporteur?";
        case "shipping_remarks":
            return " als opmerking voor bezorging?";
        default:
            return "?";
    }
}

function switchVaryingDelivery() {
    state.loading = true;
    order.switchVaryingDelivery(
        state.order.hash,
        state.order.has_varying_deliveries
    );
}
</script>
