<template>
    <article>
        <app-order-header :users="users" />
        <app-order-navigation />

        <router-view name="tab"></router-view>
    </article>
</template>

<script>
export default {
    mounted() {
        if ("app.order" == this.$route.name) {
            this.$router.push({
                name: "app.order.order",
            });
        }
        Echo.join("orders." + this.$route.params.hash)
            .listen(".new-chat", (event) => {
                Eventing.fire("newChat", event.chat);
            })
            .listen(".new-log", (event) => {
                console.info(event.log);
                Eventing.fire("reloadActivities");
            })
            .listen(".new-address", (event) => {
                console.info(event.address);
                Eventing.fire(
                    "addAddress." + event.address.box.hash,
                    event.address
                );
            })
            .here((users) => {
                this.users = users;
            })
            .joining((user) => {
                this.users.push(user);
                Eventing.fire("addUser", user);
            })
            .leaving((user) => {
                this.users = this.users.filter((u) => u.id != user.id);
            });
    },
    data() {
        return {
            users: [],
        };
    },
};
</script>
