<template>
    <aside class="l-sidebar">
        <div class="l-sidebar__head">
            <router-link :to="{ name: 'start' }" class="l-sidebar__logo">
                <div class="l-sidebar__logo-big">
                    <SvgFeelingz />
                </div>
                <div class="l-sidebar__logo-small">
                    <SvgFeelingzSmall />
                </div>
            </router-link>
            <!-- 
            <router-link
                :to="{ name: 'app.notifications' }"
                class="c-notifications"
            >
                <div class="c-notifications__icon">
                    <SvgNotifications />
                    <p
                        class="c-notifications__counter"
                        v-if="
                            state.notifications &&
                            state.notifications.unread > 0
                        "
                    >
                        {{ state.notifications.unread }}
                    </p>
                </div>
            </router-link> -->
        </div>
        <div class="l-sidebar__content" v-if="user.role() == 'Shopmanager'">
            <nav class="l-sidebar__nav">
                <ul class="c-menu">
                    <li
                        class="c-menu__item c-menu__item--iconx18"
                        :class="{
                            'c-menu__item--current':
                                $route.name.includes('app.order'),
                        }"
                    >
                        <router-link
                            :to="{ name: 'app.orders' }"
                            class="c-menu__item-link"
                        >
                            <SvgOrders />
                            <span class="c-menu__item-name">
                                Bestellingen
                            </span>
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="l-sidebar__content" v-if="user.role() !== 'Shopmanager'">
            <nav class="l-sidebar__nav">
                <ul class="c-menu">
                    <li
                        class="c-menu__item e-tooltip e-tooltip--right"
                        :class="{
                            'c-menu__item--current':
                                $route.name.includes('app.dashboards'),
                        }"
                        data-tooltip="Dashboard"
                    >
                        <router-link
                            :to="{ name: 'app.dashboards.traffic.orders' }"
                            class="c-menu__item-link"
                        >
                            <SvgDashboard />
                            <span class="c-menu__item-name"> Dashboard </span>
                        </router-link>
                    </li>
                    <li
                        class="c-menu__item e-tooltip e-tooltip--right"
                        :class="{
                            'c-menu__item--current':
                                $route.name.includes('app.order'),
                        }"
                        data-tooltip="Bestellingen"
                    >
                        <router-link
                            :to="{ name: 'app.orders' }"
                            class="c-menu__item-link"
                        >
                            <SvgOrders />
                            <span class="c-menu__item-name">
                                Bestellingen
                            </span>
                        </router-link>
                    </li>
                    <li
                        class="c-menu__item e-tooltip e-tooltip--right"
                        :class="{
                            'c-menu__item--current':
                                $route.name.includes('app.stock'),
                        }"
                        data-tooltip="Voorraad"
                    >
                        <router-link
                            :to="{ name: 'app.stock' }"
                            class="c-menu__item-link"
                        >
                            <SvgStock />
                            <span class="c-menu__item-name"> Voorraad </span>
                            <span
                                class="e-alert-stamp sidebar"
                                v-if="state.stock_alert"
                                >!</span
                            >
                        </router-link>
                    </li>

                    <li
                        class="c-menu__item e-tooltip e-tooltip--right"
                        v-if="user.role() == 'Beheerder'"
                        data-tooltip="Productie"
                    >
                        <router-link
                            :to="{ name: 'production.scan' }"
                            class="c-menu__item-link"
                            target="_blank"
                        >
                            <SvgProduction />
                            <span class="c-menu__item-name"> Productie </span>
                        </router-link>
                    </li>
                    <li
                        class="c-menu__item c-menu__item--iconx18 e-tooltip e-tooltip--right"
                        :class="{
                            'c-menu__item--current':
                                $route.name.includes('app.contacts'),
                        }"
                        data-tooltip="Klanten"
                    >
                        <router-link
                            :to="{ name: 'app.contacts' }"
                            class="c-menu__item-link"
                        >
                            <SvgCustomer />
                            <span class="c-menu__item-name"> Klanten </span>
                        </router-link>
                    </li>
                    <!-- <li class="c-menu__item">
                        <p class="c-menu__item-text" href="">
                            <SvgGraph />
                            Rapportages
                        </p>
                    </li> -->
                </ul>
                <h4 class="l-sidebar__nav-title">Beheer</h4>
                <ul class="c-menu">
                    <li
                        class="c-menu__item e-tooltip e-tooltip--right"
                        :class="{
                            'c-menu__item--current':
                                $route.name.includes('app.products'),
                        }"
                        data-tooltip="Assortiment"
                    >
                        <router-link
                            :to="{ name: 'app.products' }"
                            class="c-menu__item-link"
                        >
                            <SvgProducts />
                            <span class="c-menu__item-name"> Assortiment </span>
                        </router-link>
                    </li>
                    <li
                        class="c-menu__item e-tooltip e-tooltip--right"
                        :class="{
                            'c-menu__item--current':
                                $route.name.includes('app.delivery-areas'),
                        }"
                        data-tooltip="Bezorging"
                    >
                        <router-link
                            :to="{ name: 'app.delivery-areas' }"
                            class="c-menu__item-link"
                        >
                            <SvgDelivery />
                            <span class="c-menu__item-name"> Bezorging </span>
                        </router-link>
                    </li>
                    <li
                        class="c-menu__item e-tooltip e-tooltip--right"
                        :class="{
                            'c-menu__item--current':
                                $route.name.includes('app.emails'),
                        }"
                        data-tooltip="E-mails"
                    >
                        <router-link
                            :to="{ name: 'app.emails' }"
                            class="c-menu__item-link"
                        >
                            <SvgMail />
                            <span class="c-menu__item-name"> E-mails </span>
                        </router-link>
                    </li>
                    <li
                        class="c-menu__item c-menu__item--iconx18 e-tooltip e-tooltip--right"
                        :class="{
                            'c-menu__item--current':
                                $route.name.includes('app.users'),
                        }"
                        data-tooltip="Gebruikers"
                    >
                        <router-link
                            :to="{ name: 'app.users' }"
                            class="c-menu__item-link"
                        >
                            <SvgUser />
                            <span class="c-menu__item-name"> Gebruikers </span>
                        </router-link>
                    </li>
                </ul>
            </nav>
            <Transition name="fade">
                <div v-if="!state.is_loading" class="e-preloader"></div>
            </Transition>
        </div>

        <div class="l-sidebar__nav l-sidebar__user">
            <div class="l-sidebar__user-left">
                <div class="e-user-avatar" v-if="state.auth.avatar">
                    <img
                        class="e-user-avatar__image"
                        :src="state.auth.avatar"
                    />
                </div>
                <span class="c-menu__item-name">
                    {{ state.auth.name }}
                </span>
            </div>
            <SvgArrowDown />
            <ul class="e-popover e-popover--bottom">
                <li class="e-popover__item">
                    <router-link
                        :to="{ name: 'app.notifications' }"
                        class="e-popover__link"
                    >
                        Notificaties
                        <!-- <p
                            class="c-notifications__counter"
                            v-if="
                                state.notifications &&
                                state.notifications.unread > 0
                            "
                        >
                            {{ state.notifications.unread }}
                        </p> -->
                    </router-link>
                </li>

                <li class="e-popover__item">
                    <router-link
                        :to="{ name: 'app.profile-settings' }"
                        class="e-popover__link"
                    >
                        Profiel instellingen
                    </router-link>
                </li>
                <li class="e-popover__item">
                    <a class="e-popover__link" @click="user.logout()">
                        Uitloggen
                    </a>
                </li>
            </ul>
        </div>
    </aside>
</template>

<script setup>
import SvgArrowDown from "@/svg/arrow-down.svg";
import SvgCustomer from "@/svg/customer.svg";
import SvgDashboard from "@/svg/dashboard.svg";
import SvgDelivery from "@/svg/delivery.svg";
import SvgFeelingzSmall from "@/svg/feelingz-small.svg";
import SvgFeelingz from "@/svg/feelingz.svg";
import SvgMail from "@/svg/mail.svg";
import SvgOrders from "@/svg/orders.svg";
import SvgProduction from "@/svg/production.svg";
import SvgProducts from "@/svg/products.svg";
import SvgStock from "@/svg/stock.svg";
import SvgUser from "@/svg/user.svg";

import { useAuthStore } from "@/store/auth";
import { inject, onMounted, reactive } from "vue";

const Toast = inject("Toast");

const user = useAuthStore();

const state = reactive({
    is_loading: true,
    auth: {
        name: user.name(),
        avatar: user.avatar(),
    },
    notifications: {
        data: [],
        unread: 0,
    },
    stock_alert: false,
});

onMounted(() => {
    Eventing.listen("userChanged", (_user) => {
        let _auth = user.auth();
        _auth.user = _user;

        user.authenticate(_auth);
        state.auth.name = user.name();
        state.auth.avatar = user.avatar();
        if (_user.profile_photo) {
            state.auth.avatar = _user.profile_photo;
        }
    });
    Eventing.listen("setNotifications", (data) => {
        state.notifications = data;
    });
    Eventing.listen("fetchNotifications", () => {
        user.notifications();
    });
    Eventing.listen("setStockAlert", (data) => {
        state.stock_alert = data;
    });

    Eventing.listen("loader", (data) => {
        state.is_loading = data == "yes" ? false : true;
    });
});
state.notifications = user.notifications();
</script>
