import { defineStore } from "pinia";

export const useToolbarStore = defineStore({
    id: "toolbars",
    state: () => ({
        toolbar: {},
    }),
    actions: {
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
        email() {
            let self = this;
            this.$patch({
                toolbar: {
                    mailtags: {
                        title: "menu",
                        icon: "v-md-icon-tip",
                        menus: [
                            {
                                text: "Klantnaam",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{Klantnaam}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Bedrijfsnaam",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{Bedrijfsnaam}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Klant achternaam",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{KlantAchternaam}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Klant voornaam",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{KlantVoornaam}",
                                        };
                                    });
                                },
                            },

                            {
                                text: "LoginLink",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{LoginLink}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Order status",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{OrderStatus}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Order factuur status",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{OrderInvoiceStatus}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Bestelling",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{Bestelling}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Bestelling extra opties",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{BestellingExtraOpties}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Bestelling opmerking",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{BestellingOpmerking}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Bestelnummer",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{Bestelnummer}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Leverdatum",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{Leverdatum}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Type bezorging",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{TypeBezorging}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Transporteur",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{Transporteur}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Bezorging opmerking",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{BezorgingOpmerking}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Deadline voor aanleveringen",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{AanleverDeadline}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Aan te leveren bestanden (aantal)",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{NogAanTeLeveren}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Al aangeleverde bestanden (aantal)",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{Aangeleverd}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Aan te leveren bestanden (tabel)",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{NogAanTeLeverenTabel}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Chat: tekst van nieuw bericht",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{NieuwBericht}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Chat: afzender nieuwe bericht",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{BerichtAfzender}",
                                        };
                                    });
                                },
                            },
                            {
                                text: "Chat: button",
                                action(editor) {
                                    editor.insert(function () {
                                        return {
                                            text: "{ButtonNaarChat}",
                                        };
                                    });
                                },
                            },
                        ],
                    },
                    handleUploadImage(event, insertImage, files) {
                        try {
                            let file = files[0];
                            if (
                                file &&
                                file["type"].split("/")[0] === "image"
                            ) {
                                self.getBase64(file).then((data) =>
                                    insertImage({
                                        url: data,
                                        desc: "",
                                        // width: 'auto',
                                        // height: 'auto',
                                    })
                                );
                            }
                        } catch (error) {}
                    },
                },
            });
        },
    },
});
