import { defineStore } from "pinia";
import { useAuthClientStore } from "@/store/authClient";

export const useEnumStore = defineStore({
    id: "enums",
    state: () => ({
        options: {},
    }),
    actions: {
        auth() {
            const user = useAuthClientStore();
            return { headers: { Authorization: "Bearer " + user.token() } };
        },
        async fill(_enum) {
            //
            let url = "/api/clients/enums/" + _enum;
            try {
                let result = await axios.get(url, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    var enumList = {
                        [_enum]: result.data,
                    };
                    this.$patch({
                        options: enumList,
                    });
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        fail(error) {
            console.error(error);
            Eventing.fire("error", error.response);
        },
    },
});
