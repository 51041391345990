<template>
    <div class="c-side-overlay__header" v-if="props.order">
        <div class="c-side-overlay__header-content">
            <div class="c-side-overlay__header-title-wrap">
                <h2 class="c-side-overlay__title">
                    {{ props.order.contact.company }}
                </h2>
                <p
                    class="c-side-overlay__below-title"
                    v-if="props.order.reference"
                >
                    <router-link
                        :to="{
                            name: 'app.order',
                            params: { hash: props.order.hash },
                        }"
                        class="c-side-overlay__link"
                        >{{ props.order.reference }}
                    </router-link>

                    <span
                        v-if="
                            order.origin_reference &&
                            (props.order.is_duplicate ||
                                props.order.is_splitted)
                        "
                        class="c-side-overlay__below-title-col c-side-overlay__link--small"
                    >
                        <span v-if="props.order.is_duplicate">
                            Duplicatie van

                            <router-link
                                :to="{
                                    name: 'app.order.order',
                                    params: {
                                        hash: props.order.origin_hash,
                                    },
                                }"
                                class="c-side-overlay__link"
                                >{{ props.order.origin_reference }}
                            </router-link>
                        </span>
                        <span
                            v-if="
                                props.order.is_splitted &&
                                props.order.origin_reference
                            "
                        >
                            <span v-if="props.order.is_duplicate">&bull; </span
                            >Splitsing van

                            <router-link
                                :to="{
                                    name: 'app.order.order',
                                    params: {
                                        hash: props.order.origin_hash,
                                    },
                                }"
                                class="c-side-overlay__link"
                                >{{ props.order.origin_reference }}
                            </router-link>
                        </span>
                        <span
                            v-if="
                                props.order.is_splitted &&
                                !props.order.origin_reference
                            "
                        >
                            &bull; Bevat splitsing(en)
                        </span>
                    </span>
                </p>
            </div>
            <div class="c-side-overlay__header-icons">
                <!-- If spoed-->
                <div
                    class="e-label-speed e-tooltip"
                    v-if="props.order.is_urgent"
                    data-tooltip="Spoed"
                >
                    <SvgSpeed />
                </div>

                <!-- If maatwerk -->
                <div
                    class="c-side-overlay__header-icon e-icon--maatwerk"
                    v-if="props.order.is_maatwerk"
                >
                    <SvgMaatwerk />
                </div>

                <!-- If mailing  -->
                <div
                    class="c-side-overlay__header-icon e-icon--mailing"
                    v-if="props.order.is_mailing"
                >
                    <SvgMail />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import SvgMaatwerk from "@/svg/maatwerk.svg";
import SvgMail from "@/svg/mail.svg";
import SvgSpeed from "@/svg/speed.svg";
const props = defineProps({
    order: Object,
});
</script>
