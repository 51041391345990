<template>
    <div v-if="props.missing.length > 0" class="e-button--full-width mb-10">
        <a class="e-button e-button--full-width e-button--grey-dark assembler-btn"> Klik hier om compleet te maken </a>
    </div>
</template>

<script setup>
const props = defineProps({
    missing: {
        type: Array,
        default: [],
    },
});
</script>
