<template>
    <div
        class="c-table-item c-table-item--is-category"
        v-if="checkIsNew(product, isNewCategory)"
    >
        <div class="c-table-item__col c-table-item__col--category-name">
            {{ product.category.name }}
        </div>
        <div
            class="c-table-item__col c-table-item__col--chart c-table-item__col--stock"
        ></div>
        <div class="c-table-item__col c-table-item__col--article-nr"></div>
    </div>
    <div
        class="c-table-item c-table-item--product-row js-open-side-overlay"
        :class="{
            'c-table-item--out-of-stock': product.out_of_stock,

            'c-table-item--future-out-of-stock':
                product.future_stock_reduction > 0 &&
                product.future_stock_reduction >=
                    product.stock + product.future_stock,
            ' c-table-item--is-inside-category': product.category,
        }"
        @click="toggleStock(product)"
    >
        <div class="c-table-item__col c-table-item__col--product-name">
            {{ product.name }}
            <span
                class="e-alert-stamp e-tooltip"
                data-tooltip="Voorraad is op!"
                v-if="product.out_of_stock"
                >!</span
            >
        </div>

        <div
            class="c-table-item__col c-table-item__col--chart c-table-item__col--stock"
        >
            <div
                class="e-chart e-chart--inside-table-item e-chart--bars-rounded"
                v-if="state.graph"
            >
                <chart-bar-single
                    :series="state.graph.series"
                    :colors="state.graph.colors"
                    :yaxisMax="state.graph.yaxisMax"
                    :tooltipYFormatter="state.graph.tooltipYFormatter"
                />
            </div>
        </div>

        <div class="c-table-item__col c-table-item__col--article-nr">
            {{ product.article_nr }}
        </div>
    </div>
</template>

<script setup>
import { useStockStore } from "@/store/stock";
import { onMounted, reactive } from "vue";

const props = defineProps({
    product: Object,
    isNewCategory: Boolean,
    showCategoryProducts: Array,
    maxStockValue: Number, //Max-width of the single bar chart
});

function checkIsNew(product, isNewCategory) {
    if (props.showCategoryProducts.indexOf(product.slug) !== -1) {
        return true;
    }
    return isNewCategory;
}

const state = reactive({
    form: {
        stock: 0,
    },
    graph: null,
    future: {
        future_stock: 0,
        future_stock_date: null,
        future_stock_date_nl: null,
    },
    future_date: new Date().toISOString().split("T")[0],
});

onMounted(() => {
    state.form.stock = props.product.stock;
    fillGraph(props.product);
    Eventing.fire("toggleSideOverlay");
});

const stock = useStockStore();

async function toggleStock(_stock) {
    if (state.stock && state.stock.slug == _stock.slug) {
        state.stock = null;

        if (document.body.classList.contains("body--side-overlay-is-open")) {
            document.body.classList.remove("body--side-overlay-is-open");
        }
    } else {
        Eventing.fire("clearSideOverlay");
        let result = await stock.show(_stock.slug);
        if (result) {
            state.stock = result;
            document.body.classList.add("body--side-overlay-is-open");
            Eventing.fire("fillSideOverlay", result);
        }
    }
}

function fillGraph(product) {
    console.info("maxStockValue", props.maxStockValue);
    state.graph = {
        series: [
            {
                name: "Totaal",
                data: [product.stock + product.future_stock - product.future_stock_reduction],
            }
        ],

        colors: [
            function ({ value, seriesIndex, w }) {
                if (value < product.stock_alert_at / 2 || value < 0) {
                    return "#ec3737"; //Alert stock color
                } else if (value < product.stock_alert_at) {
                    return "#ff911d"; //Low stock color
                } else {
                    return "#54cb8f"; //Enough stock color
                }
            },
            "#bde8d1",
        ], //'In aanlevering' color

        yaxisMax: product.stock + product.future_stock,

        //Tooltip info
        tooltipYFormatter: function (
            val,
            { series, seriesIndex, dataPointIndex, w }
        ) {
            var name = w.globals.seriesNames[seriesIndex];
            var color = w.globals.colors[seriesIndex];
            var date = product.future_stock_date_nl;
            var val = w.globals.series[seriesIndex][dataPointIndex];

            return val +'<div class="v-apexcharts-tooltip__data-table">' +
                '<div class="v-apexcharts-tooltip__row">' +
                        '<div class="v-apexcharts-tooltip__col">' +
                                '<span>Voorraad:</span>' +
                        '</div>' +
                        '<div class="v-apexcharts-tooltip__col">' +
                            '<span>' +
                                product.stock + 
                            '</span>' +
                        '</div>' +
                '</div>' +
                '<div class="v-apexcharts-tooltip__row">' +
                        '<div class="v-apexcharts-tooltip__col">' +
                                '<span>In aanlevering:</span>' +
                        '</div>' +
                        '<div class="v-apexcharts-tooltip__col">' +
                            '<span>' +
                                product.future_stock + 
                            '</span>' +
                        '</div>' +
                '</div>' +
                '<div class="v-apexcharts-tooltip__row">' +
                        '<div class="v-apexcharts-tooltip__col">' +
                                '<span>Gereserveerd:</span>' +
                        '</div>' +
                        '<div class="v-apexcharts-tooltip__col">' +
                            '<span> - ' +
                                product.future_stock_reduction + 
                            '</span>' +
                        '</div>' +
                '</div>' +
            '</div>';
        }
    };
}
</script>
