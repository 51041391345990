<template>
    <article>
        <div class="c-page-content">
            <div
                class="c-page-content__container c-page-content__container--spacing-small"
            >
                <!-- @include('frontend.partials.stock.products') -->
                <article>
                    <div class="c-table">
                        <div class="c-table__head js-sticky-element">
                            <div
                                class="c-table-item c-table-item--head js-overflow-arrow"
                            >
                                <div
                                    class="c-table-item__col c-table-item__col--product-name c-table-item__col--sortable"
                                    :class="{
                                        'c-table-item__col--sorted':
                                            state.filter == 'name',
                                        'c-table-item__col--sorted-desc':
                                            state.orderBy == 'desc' &&
                                            state.filter == 'name',
                                    }"
                                    @click="setFilter('name')"
                                >
                                    Naam
                                </div>
                                <div
                                    class="c-table-item__col c-table-item__col--chart c-table-item__col--stock c-table-item__col--sortable"
                                    :class="{
                                        'c-table-item__col--sorted':
                                            state.filter == 'current_stock',
                                        'c-table-item__col--sorted-desc':
                                            state.orderBy == 'desc' &&
                                            state.filter == 'current_stock',
                                    }"
                                    @click="setFilter('current_stock')"
                                >
                                    Voorraad
                                </div>
                                <div
                                    class="c-table-item__col c-table-item__col--article-nr c-table-item__col--sortable"
                                    :class="{
                                        'c-table-item__col--sorted':
                                            state.filter == 'article_nr',
                                        'c-table-item__col--sorted-desc':
                                            state.orderBy == 'desc' &&
                                            state.filter == 'article_nr',
                                    }"
                                    @click="setFilter('article_nr')"
                                >
                                    Nummer
                                </div>
                            </div>
                        </div>
                        <div class="c-table__body" v-if="state.stock">
                            <app-stock-products
                                :product="product"
                                v-for="product in stock.products.data"
                                :key="product.id"
                                :is-new-category="
                                    currently(product.category, product.slug)
                                "
                                :show-category-products="state.shownCategories"
                                :max-stock-value="getMaxStockValue()"
                            />
                        </div>
                    </div>
                    <pagination
                        :items="stock.products"
                        v-show="!state.search"
                        :order="state.orderFilter"
                    />
                </article>
            </div>
        </div>
    </article>

    <app-dashboards-side-overlay-traffic-stock />
</template>

<script setup>
import { useStockStore } from "@/store/stock";
import { inject, onMounted, onUpdated, reactive } from "vue";

const stock = useStockStore();
const Modals = inject("Modals");

const state = reactive({
    url: "/api/stocks?q",
    orderBy: "asc",
    filter: "name",
    orderFilter: "",
    search: null,
    stock: true,
    debounce: 0,
    search: null,
    currentCategories: [],
    shownCategories: [],
});

onUpdated(() => {
    Eventing.fire("stickyElement");
});

onMounted(() => {
    Eventing.listen("paginate", (url) => {
        state.url = url;
        stock.fill(url);
    });
    setTimeout(() => {
        Eventing.fire("loadStockActivities");
    }, 1000);

    Eventing.fire("stickyElement");

    Eventing.fire("addOverflowArrows");

    Eventing.listen("reloadStock", async () => {
        state.stock = false;
        let fill = await stock.fill();
        if (fill) {
            state.stock = true;
        }
    });
});

stock.fill();

let currentCategories = [];
function currentlyOld(item) {
    if (item && item.slug) {
        if (currentCategories.includes(item.slug)) {
            return false;
        } else {
            currentCategories.push(item.slug);
            return true;
        }
    }
    return false;
}

function currently(item, product_id) {
    if (item && item.slug) {
        if (state.currentCategories.indexOf(item.slug) !== -1) {
        } else {
            state.shownCategories.push(product_id);
        }

        if (state.currentCategories.includes(item.slug)) {
            return false;
        } else {
            state.currentCategories.push(item.slug);
            return true;
        }
    }
    return false;
}

function setFilter(filter) {
    let orderBy =
        state.orderBy == "asc"
            ? (state.orderBy = "desc")
            : (state.orderBy = "asc");
    state.filter = filter;
    state.orderFilter = "&order=" + state.filter + "&by=" + orderBy;

    stock.fill(state.url + "&order=" + state.filter + "&by=" + orderBy);
}

function menu(menu) {
    Eventing.fire("toggleMenu", menu);
}

function getMaxStockValue() {
    //Returns the highest stock value of all products
    //The value is used to set the max-width of the single bar chart

    var allValues = [];
    var maxValue = 0;

    stock.products.data.forEach((product) => {
        const totalValue = product.stock + product.future_stock;

        allValues.push(totalValue);
    });

    allValues.forEach((value) => {
        if (value > maxValue) {
            maxValue = value;
        }
    });

    return maxValue;
}
</script>
