<template>
    <aside class="c-client-sidebar">
        <div
            class="c-client-sidebar__menu-button e-menu-button e-menu-button--dark"
            @click="menu('client')"
        >
            <div class="e-menu-button__bars-wrap">
                <div class="e-menu-button__bars"></div>
            </div>
        </div>

        <div class="c-client-sidebar__inner">
            <router-link
                :to="{ name: 'client.app' }"
                class="c-client-sidebar__logo"
            >
                <SvgKerstcadeaukiezen
                    v-if="
                        state.order &&
                        state.order.source &&
                        state.order.source.includes('Kerst')
                    "
                />
                <SvgFeelingz
                    v-if="
                        state.order &&
                        state.order.source &&
                        !state.order.source.includes('Kerst')
                    "
                />
            </router-link>

            <div class="c-client-sidebar__desc">
                <h1>Hallo {{ state.auth.name }},</h1>
                <p>
                    Welkom in je persoonlijke omgeving. Maak hier jouw
                    bestelling
                    <span>{{ state.order.reference }}</span> compleet.
                </p>
            </div>

            <div
                class="c-client-sidebar__note"
                v-if="state.order.deadline && state.order.completed_at == null"
            >
                <div class="e-notes">
                    <div class="e-note">
                        <SvgInfo />
                        <div class="e-note__text">
                            <p>
                                Uw bestelling bevat producten waar bestanden
                                voor aangeleverd moeten worden. U heeft tot
                                <b>{{ state.order.deadline_pretty }}</b> om de
                                bestanden aan te leveren
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <nav class="c-client-sidebar__nav">
                <ul class="c-client-sidebar__list" @click="menu('client')">
                    <!-- Add class c-client-sidebar__item--completed if step is completed -->
                    <li
                        class="c-client-sidebar__item"
                        :class="{
                            ' c-client-sidebar__item--completed':
                                !state.order.incomplete,
                        }"
                    >
                        <router-link
                            active-class="c-client-sidebar__item-link--current"
                            class="c-client-sidebar__item-link"
                            :to="{ name: 'client.order' }"
                            @click="scrollToPageContent"
                        >
                            <div class="c-client-sidebar__item-number">
                                1
                                <div class="c-client-sidebar__item-check">
                                    <SvgCheck />
                                    <SvgCross />
                                </div>
                            </div>

                            Bestelling

                            <!-- <span
                                class="c-client-sidebar__item-alert"
                                v-if="state.order.incomplete"
                            >
                                <SvgInfo />
                            </span> -->
                        </router-link>
                    </li>
                    <li
                        class="c-client-sidebar__item"
                        :class="{
                            ' c-client-sidebar__item--completed':
                                state.order.checks.done ==
                                state.order.checks.total,
                        }"
                    >
                        <router-link
                            active-class="c-client-sidebar__item-link--current"
                            class="c-client-sidebar__item-link"
                            :to="{ name: 'client.files' }"
                            @click="scrollToPageContent"
                        >
                            <div class="c-client-sidebar__item-number">
                                2
                                <div class="c-client-sidebar__item-check">
                                    <SvgCheck />
                                    <SvgCross />
                                </div>
                            </div>
                            Bestanden

                            <span
                                class="c-client-sidebar__item-alert"
                                v-if="
                                    state.order.checks.done <
                                    state.order.checks.total
                                "
                            >
                                <SvgInfo />
                            </span>

                            <span
                                v-if="state.order.checks.total > 0"
                                :class="{
                                    alert:
                                        state.order.checks.done <
                                        state.order.checks.total,
                                }"
                            >
                                {{ state.order.checks.done }}
                                /
                                {{ state.order.checks.total }}
                            </span>
                        </router-link>
                    </li>
                    <li
                        class="c-client-sidebar__item"
                        :class="{
                            ' c-client-sidebar__item--completed':
                                state.order.delivery_completed,
                        }"
                    >
                        <router-link
                            active-class="c-client-sidebar__item-link--current"
                            class="c-client-sidebar__item-link"
                            :to="{
                                name: 'client.delivery',
                                query: { t: state.random },
                            }"
                            @click="
                                scrollToPageContent();
                                setRandom();
                            "
                            replace
                        >
                            <div class="c-client-sidebar__item-number">
                                3
                                <div class="c-client-sidebar__item-check">
                                    <SvgCheck />
                                    <SvgCross />
                                </div>
                            </div>

                            Bezorging

                            <span
                                class="c-client-sidebar__item-alert"
                                v-if="!state.order.delivery_completed"
                            >
                                <SvgInfo />
                            </span>
                        </router-link>
                    </li>

                    <!--
                    <li class="c-client-sidebar__item">
                        <router-link
                            active-class="c-client-sidebar__item-link--current"
                            class="c-client-sidebar__item-link"
                            :to="{ name: 'client.contact' }"
                            @click="scrollToPageContent"
                        >
                            Contact
                            <div
                                class="e-chat-counter"
                                v-if="state.unread_messages > 0"
                            >
                                <SvgChat />
                                <p class="e-chat-counter__count">
                                    {{ state.unread_messages }}
                                </p>
                            </div>
                        </router-link>
                    </li>-->
                </ul>
            </nav>

            <div class="c-client-sidebar__bottom">
                <a
                    href="#/klant/communicatie"
                    class="c-client-sidebar__contact-button"
                    @click="scrollToPageContent"
                >
                    <div class="e-chat-counter">
                        <SvgChat />
                        <p
                            class="e-chat-counter__count"
                            v-if="state.unread_messages > 0"
                        >
                            {{ state.unread_messages }}
                        </p>
                    </div>
                    <span>Vragen?</span>
                </a>

                <a
                    @click="logOff()"
                    class="e-button e-button--transparent e-button--full-width"
                >
                    Uitloggen
                </a>
            </div>
        </div>
    </aside>
    <div class="c-client-sidebar__bg" @click="menu('client')"></div>
</template>

<style scoped>
.invisible {
    visibility: hidden;
}
</style>

<script setup>
import SvgChat from "@/svg/chat.svg";
import SvgCheck from "@/svg/check.svg";
import SvgCross from "@/svg/cross.svg";
import SvgFeelingz from "@/svg/feelingz.svg";
import SvgInfo from "@/svg/info.svg";
import SvgKerstcadeaukiezen from "@/svg/kerstcadeaukiezen.svg";

import { onMounted, reactive } from "vue";

import { useOrderStore } from "@/store/client/order";

import { useAuthClientStore } from "@/store/authClient";
const user = useAuthClientStore();
const order = useOrderStore();

// const user = useAuthClientStore();

const state = reactive({
    unread_messages: 0,
    order: {
        checks: {
            done: 0,
            total: 0,
            todo: 0,
            perc: 0,
        },
    },
    access: "f",
    auth: {
        name: user.name(),
    },
    order: {
        source: null,
        contact: {
            address: {},
        },
        followers: {},
        checks: {
            done: 0,
            total: 0,
            todo: 0,
            perc: 0,
        },
    },
});

onMounted(() => {
    Eventing.listen("fillOrder", (data) => {
        state.order = data.data;
    });

    Eventing.listen("reloadOrder", () => {
        order.show(user.order());
    });

    order.show(user.order());

    Eventing.listen("fillOrder", (data) => {
        state.order = data.data;
        try {
            if (state.order.source && state.order.source.includes("Kerst")) {
                document.title = state.order.source;
            }
        } catch (error) {}
    });
});

function setRandom() {
    state.access = Math.random();
    Eventing.fire("reloadDeliveryPage");
}

function scrollToPageContent() {
    Eventing.fire("scrollContentIntoView");
}

function menu(menu) {
    Eventing.fire("toggleMenu", menu);
}
function logOff() {
    user.logout();
}
</script>
