<template>
    <article v-if="state.order">
        <div class="c-production-content">
            <div class="c-production-content__page-info">
                <div class="c-production-content__page-info-col">
                    <h1 class="c-production-content__company">
                        {{ state.address.postcode }} - Order
                        <span>{{ state.order.reference }}</span>
                    </h1>
                    <button-state
                        :label="state.order.status.label"
                        :buttonclass="state.order.status.class"
                    />
                    <p
                        class="c-production-content__desc"
                        v-if="state.order.status.label && state.box.signed"
                    >
                        Getekend op {{ state.box.signed.at }} door
                        {{ state.box.signed.by }}
                    </p>
                </div>
            </div>

            <div class="c-production-content__delivery-data">
                <h2 class="c-production-content__title">Levering</h2>
                <ul
                    class="c-data-list c-data-list--table-style c-data-list--no-ellipsis"
                >
                    <li class="c-data-list__item">
                        <p class="c-data-list__subject">Samenstelling</p>
                        <p class="c-data-list__data">{{ state.box.name }}</p>
                    </li>
                    <li class="c-data-list__item">
                        <p class="c-data-list__subject">Aantal colli</p>
                        <p class="c-data-list__data">{{ state.box.pieces }}</p>
                    </li>
                    <li
                        class="c-data-list__item"
                        v-if="
                            state.box.signe &&
                            state.box.shipping_transporter == 'ELS'
                        "
                    >
                        <p class="c-data-list__subject">
                            Getekend voor ontvangst
                        </p>
                        <p class="c-data-list__data">
                            {{ state.box.signed["by"] }}
                        </p>
                    </li>
                    <li
                        class="c-data-list__item"
                        v-if="
                            state.box.signed &&
                            state.box.shipping_transporter == 'ELS'
                        "
                    >
                        <p class="c-data-list__subject">
                            Getekend voor ontvangst
                        </p>
                        <p class="c-data-list__data">
                            {{ state.box.signed["at"] }}
                        </p>
                    </li>

                    <li
                        class="c-data-list__item"
                        v-if="
                            state.address.signed_at &&
                            state.box.shipping_method == 'Huis-aan-huis'
                        "
                    >
                        <p class="c-data-list__subject">
                            Getekend voor ontvangst
                        </p>
                        <p class="c-data-list__data">
                            {{ state.address.signed_at }}
                        </p>
                    </li>
                </ul>
            </div>
        </div>

        <div
            class="c-production-footer"
            v-if="
                state.box.shipping_method != 'Digitaal' &&
                state.order.status.label.includes('Transport')
            "
        >
            <a
                class="e-button e-button--green e-button--full-width"
                @click="
                    Modals.show('transport-received', state.address.sign_url)
                "
                >Tekenen voor ontvangst</a
            >
        </div>
    </article>
    <article v-if="!state.order">
        <div class="c-production-footer">
            <a class="e-button e-button--green e-button--full-width"
                >Order kan niet worden geladen</a
            >
        </div>
    </article>
    <canvas id="my-canvas"></canvas>
</template>

<script setup>
import { onMounted, reactive, inject } from "vue";
import { useRoute } from "vue-router";
import ConfettiGenerator from "confetti-js";

const Modals = inject("Modals");

const route = useRoute();

const state = reactive({
    box: null,
    order: null,
    address: null,
});

onMounted(() => {
    Eventing.listen("isSigned", () => {
        setTimeout(() => {
            validateOrder();

            var confetti = new ConfettiGenerator({ target: "my-canvas" });
            confetti.render();
        }, 1000);
    });

    validateOrder();
});

function validateOrder() {
    axios
        .get(
            "/api/transport/qr/" +
                route.params.order +
                "/" +
                route.params.box +
                "/" +
                route.params.address +
                "?signature=" +
                route.params.signature +
                "&data=show"
        )
        .then((response) => {
            console.warn(response.data.data);
            state.order = response.data.data.order;
            state.box = response.data.data.box;
            state.address = response.data.data.address;
        })
        .catch((error) => {
            alert("error");
        });
}
</script>
