<template>
    <div class="c-page-content">
        <div class="c-page-content__container c-page-content__container--spacing-small">
            <div class="c-table js-overflow-arrow" v-if="totals">
                <div class="c-table__head js-sticky-element">
                    <div class="c-table-item c-table-item--head">
                        <div
                            class="c-table-item__col c-table-item__col--total-transport"
                            v-for="(header, index) in totals.headers"
                            :key="index"
                            v-show="header != 'Hash'"
                        >
                            {{ header }}
                        </div>
                    </div>
                </div>
                <section class="c-table__body">
                    <div
                        class="c-table-item"
                        v-for="(total, totalindex) in totals.totals"
                        :key="totalindex"
                    >
                        <div
                            class="c-table-item__col c-table-item__col--total-transport"
                            v-for="(header, headerindex) in totals.headers"
                            v-show="header != 'Hash'"
                            :key="headerindex"
                        >
                            <router-link
                                v-if="header == 'Order'"
                                class="order-link"
                                target="_blank"
                                :to="{
                                    name: 'app.order',
                                    params: { hash: total['Hash'] },
                                }"
                            >
                                {{ total[header] }}
                            </router-link>

                            <button-state
                                v-if="header == 'Status'"
                                :label="total[header].label"
                                :buttonclass="total[header].class"
                            />

                            <span
                                v-if="header != 'Order' && header != 'Status'"
                                v-html="total[header]"
                            ></span>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
 
</template>

<script setup>
import { onMounted, onUpdated, reactive, ref, inject } from "vue";
import Vapor from "laravel-vapor";
import { useAuthStore } from "@/store/auth";
import { useUploadStore } from "@/store/upload";

const props = defineProps({
    totals: Object,
});

onUpdated(() => {
    Eventing.fire("stickyElement");
    Eventing.fire("addOverflowArrows");
});

</script>

<style scoped>
.c-table-item__col {
    width: 10%;
}
.order-link {
    color: #78ceab;
}
</style>
