<template>
    <div class="c-side-overlay" v-if="state.order">
        <app-dashboards-side-overlay-header :order="state.order" />

        <div class="c-side-overlay__content">
            <div class="c-side-overlay__content-section">
                <ul class="c-data-list c-data-list--stripped">
                    <li class="c-data-list__item">
                        <p class="c-data-list__subject">Shop aangemaakt</p>
                        <p
                            class="c-data-list__data c-data-list__data--unchecked cursor-pointer"
                            v-show="!state.order.has_shop"
                            @click="toggleShop()"
                        >
                            <SvgCross />
                        </p>
                        <p
                            class="c-data-list__data cursor-pointer"
                            @click="toggleShop()"
                            v-show="state.order.has_shop"
                        >
                            <SvgCheck />
                        </p>
                    </li>
                    <li class="c-data-list__item">
                        <p class="c-data-list__subject">Bank aangemaakt</p>
                        <p
                            class="c-data-list__data c-data-list__data--unchecked cursor-pointer"
                            v-show="!state.order.has_bank"
                            @click="toggleBank()"
                        >
                            <SvgCross />
                        </p>
                        <p
                            class="c-data-list__data cursor-pointer"
                            @click="toggleBank()"
                            v-show="state.order.has_bank"
                        >
                            <SvgCheck />
                        </p>
                    </li>
                    <li class="c-data-list__item" v-if="state.order.has_bank">
                        <p class="c-data-list__subject">Opgewaardeerd</p>
                        <p
                            class="c-data-list__data c-data-list__data--unchecked cursor-pointer"
                            v-show="!state.order.has_collected"
                            @click="toggleCollected()"
                        >
                            <SvgCross />
                        </p>
                        <p
                            class="c-data-list__data cursor-pointer"
                            v-show="state.order.has_collected"
                            @click="toggleCollected()"
                        >
                            <SvgCheck />
                        </p>
                    </li>
                    <!-- <li
                        class="c-data-list__item"
                        v-for="box in state.cart.data"
                        :key="box.id"
                    >
                        <p class="c-data-list__subject">
                            Leverdatum {{ box.name }}
                        </p>
                        <p class="c-data-list__data">
                            {{ box.delivery_date_formatted }}
                        </p>
                    </li> -->
                </ul>
            </div>

            <div
                class="c-side-overlay__content-section"
                v-if="
                    state.order.notes &&
                    Object.values(state.order.notes).length > 0
                "
            >
                <h3 class="c-side-overlay__content-section-title">
                    Notities voor de Shop manager
                </h3>
                <div class="c-chat__messages">
                    <div
                        class="c-chat__message c-chat__message--note"
                        v-for="note in state.order.notes"
                        :key="note.id"
                    >
                        <div class="c-chat__message-inner-wrap">
                            <div class="c-chat__message-head">
                                <div
                                    class="c-chat__message-name"
                                    v-if="note.sender"
                                >
                                    <img
                                        class="c-chat__message-user-image"
                                        :src="note.sender.avatar"
                                    />

                                    {{ note.sender.name }}

                                    <span class="c-chat__message-source">
                                        {{ note.sender.role }}
                                    </span>
                                </div>
                                <div class="c-chat__message-date">
                                    {{ note.created_at }}
                                </div>
                            </div>
                            <div class="c-chat__message-text">
                                <p v-html="note.message"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="c-side-overlay__content-section"
                v-if="state.cart && state.cart.data"
            >
                <div
                    class="c-side-overlay__content-section-table"
                    v-for="(box, subindex) in state.cart.data"
                    :key="box.id + subindex"
                    v-show="box.baskets.length > 0"
                >
                    <h3 class="c-side-overlay__content-section-title">
                        <SvgGift />
                        {{ box.name }}
                    </h3>
                    <!-- @W: Styling -->
                    <span>{{ box.delivery_date_formatted }}</span>
                    <!-- / @W: Styling -->
                    <div class="c-table-default">
                        <div class="c-table-default__inner-wrap">
                            <div class="c-table-default__table">
                                <div class="c-table-default__head">
                                    <div class="c-table-default__tr">
                                        <div class="c-table-default__td">
                                            Product
                                        </div>
                                        <div class="c-table-default__td"></div>
                                    </div>
                                </div>
                                <div class="c-table-default__body">
                                    <div
                                        class="c-table-default__tr"
                                        v-for="(
                                            basket, subindex
                                        ) in box.baskets"
                                        :key="basket.id + subindex"
                                        :class="{
                                            'not-for-shopmanager':
                                                !basket.product
                                                    ?.for_shopmanager,
                                        }"
                                    >
                                        <div class="c-table-default__td">
                                            <span v-if="basket.product">{{
                                                basket.product.name
                                            }}</span>
                                        </div>
                                        <div class="c-table-default__td">
                                            {{ basket.amount }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="c-side-overlay__footer">
            <div class="c-side-overlay__footer-content">
                <button
                    class="e-button e-button--full-width e-button--big"
                    @click="state.order = null"
                >
                    Sluiten
                </button>
                <router-link
                    :to="{
                        name: 'app.order',
                        params: { hash: state.order.hash },
                    }"
                    class="e-button e-button--icon-right e-button--big"
                    >Naar bestelling <SvgArrowRight />
                </router-link>
            </div>
        </div>
    </div>
</template>
<style scoped>
.cursor-pointer {
    cursor: pointer;
}
.not-for-shopmanager {
    opacity: 0.6;
}
.not-for-shopmanager .c-table-default__td {
    font-weight: 400 !important;
}
</style>
<script setup>
import { useDashboardShopmanagerStore } from "@/store/dashboard/shopmanager";
import { useOrderStore } from "@/store/order";
import SvgArrowRight from "@/svg/arrow-right.svg";
import SvgCheck from "@/svg/check.svg";
import SvgCross from "@/svg/cross.svg";
import SvgGift from "@/svg/gift.svg";
import { onMounted, reactive } from "vue";

const order = useOrderStore();

const state = reactive({
    order: null,
    cart: {
        data: [],
    },
    isChanged: false,
});

const storemanager = useDashboardShopmanagerStore();

onMounted(() => {
    Eventing.listen("clearSideOverlay", () => {
        state.order = null;
        state.cart = {
            data: [],
        };
    });

    Eventing.listen("fillHeader", (data) => {
        state.order = data.data;
    });

    Eventing.listen("fillSideOverlay", async (data) => {
        state.order = data.data;
        state.cart = {
            data: [],
        };
        let cart = await storemanager.cart(data.data.hash);
        if (cart) {
            state.cart = cart;
        }
    });
});

async function toggleShop() {
    await order.shop(state.order.hash);
}

async function toggleBank() {
    await order.bank(state.order.hash);
}

async function toggleCollected() {
    await order.collected(state.order.hash);
}
</script>
