<template>
    <article>
        <div
            v-if="!state.activities || state.loading"
            class="e-preloader"
        ></div>
        <div class="c-timeline">
            <div class="c-timeline__messages">
                <div
                    class="c-timeline__message"
                    v-for="(activity, index) in state.activities"
                    :key="index"
                >
                    <div class="c-timeline__message-content">
                        <div class="c-timeline__message-text">
                            <p
                                class="c-timeline__message-text"
                                v-html="activity.text"
                            ></p>
                        </div>
                    </div>
                    <div class="c-timeline__message-options">
                        <p class="c-timeline__message-date">
                            {{ activity.timestamp }} {{ activity.causer }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </article>
</template>

<script setup>
import { onMounted, ref, reactive, inject } from "vue";
import { useProductStore } from "@/store/product";

const product = useProductStore();
// product.activities();

const state = reactive({
    activities: null,
    loading: false,
});

onMounted(() => {
    Echo.channel("stock").listen(".new-log", (event) => {
        console.info(event.log);
        // state.loading = true;
        try {
            state.activities.unshift(event.log);
        } catch (error) {}
        let msg = event.log;
        setTimeout(() => {
            // setActivities();
        }, 2000);
    });
    setTimeout(() => {
        setActivities();
    }, 300);
    Eventing.listen("loadStockActivities", () => {
        setActivities();
    });
});

async function setActivities() {
    await product.activities();
    state.activities = product.activityList;
    state.loading = false;
}
</script>
