<template>
    <section>
        <div
            v-if="missing.length > 0"
            class="c-order-item__row c-order-item__row--note"
        >
            <p>
                <b>Let op!</b> Je moet de volgende producten nog toevoegen om de
                samenstelling compleet te maken:
            </p>
            <ul>
                <li v-for="(missing, index) in missing" :key="index">
                    Categorie: {{ missing }}
                </li>
            </ul>
            <p v-if="showIfGeschenkverpakking()">
                <!-- 
                Bezoek
                <a
                    href="https://www.feelingz.nl/geschenkverpakking"
                    target="_blank"
                    >feelingz.nl/geschenkverpakking</a
                >
                voor meer informatie over geschenkverpakkingen 
                -->
            </p>
        </div>
    </section>
</template>

<script setup>
const props = defineProps({
    missing: Array,
});

function showIfGeschenkverpakking() {
    let mayShow = false;
    props.missing.forEach((item) => {
        if (item == "Geschenkverpakking") {
            mayShow = true;
        }
    });
    return mayShow;
}
</script>
