<template>
    <section class="c-page-content__section e-module-style">
        <div class="e-section-heading">
            <div class="e-section-heading__left">
                <div class="e-section-heading__icon">
                    <SvgPlus />
                </div>
                <h2 class="e-section-heading__name">Extra opties</h2>
            </div>
            <div class="c-order-item__col c-order-item__col--half">
                <div class="c-order-item__data c-order-item__data--head">
                    <div class="c-order-item__data-amount">Aantal</div>
                    <div class="c-order-item__data-price-pp">P/st.</div>
                    <div class="c-order-item__data-total">Totaal</div>
                    <div class="c-order-item__data-vat">BTW</div>
                    <div class="c-order-item__data-delete"></div>
                </div>
            </div>
        </div>
        <div class="c-order__extra-options-items">
            <div
                v-for="(addition, index) in additions"
                :key="addition.id + index"
                class="c-order__extra-options-row c-order-item__row c-order-item__row--is-draggable"
            >
                <div class="c-order-item__col">
                    <div class="c-order-item__name-wrap">
                        <span class="c-order-item__name">
                            {{ addition.name }}
                        </span>
                        <span
                            class="c-order-item__sub-name"
                            v-if="addition.attachments.length"
                        >
                            {{ addition.attachments.length }} bijlage(s)
                        </span>
                    </div>
                </div>
                <div class="c-order-item__col c-order-item__col--half">
                    <div class="c-order-item__data">
                        <div class="c-order-item__data-amount">
                            {{ addition.amount }}
                        </div>
                        <div class="c-order-item__data-price-pp">
                            {{
                                new Intl.NumberFormat("nl-NL", {
                                    style: "currency",
                                    currency: "EUR",
                                }).format(addition.price)
                            }}
                        </div>
                        <div class="c-order-item__data-total">
                            {{
                                new Intl.NumberFormat("nl-NL", {
                                    style: "currency",
                                    currency: "EUR",
                                }).format(addition.total)
                            }}
                        </div>
                        <div class="c-order-item__data-vat">
                            <span v-if="addition.vat != null">
                                {{ addition.vat }}%
                            </span>
                            <span
                                v-if="addition.vat == null"
                                onclick='
                                    alert(
                                        "🚨 LET OP: ZORGT VOOR PROBLEMEN MET EXACT\n\nDeze extra optie mist de BTW-categorie waar die in moet vallen. Dit komt vermoedelijk doordat dit product via PerfectView is geïmporeerd. Corrigeer het BTW-bedrag door deze Extra optie handmatig opnieuw toe te voegen. Controleer verder aan de zijkant van de bestelling wat er mis is gegaan bij het importeren van de regel vanuit PerfectView waardoor deze tussen de Extra opties is beland.\n\nEen artikelcode meegeven is zeer wenselijk, want op basis daarvan wordt een correct product uit het assortiment van de database toegevoegd. Pas als een product uit het assortiment wordt toegevoegd, kan er ook geautomatiseerde controle plaatsvinden naar eventuele aan te leveren bestanden."
                                    )
                                '
                            >
                                ❓
                            </span>
                        </div>
                        <button
                            class="c-order-item__delete c-order-item__data-delete"
                            @click="deleteAddition(addition)"
                        >
                            <SvgDelete />
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="c-order__extra-options-contents"
            v-if="state.showForm"
            @keydown="addition.errors.errors = {}"
        >
            <section class="c-order__extra-option-section">
                <div
                    class="c-order__extra-option-wrap e-form__field-wrap"
                    :class="{
                        'e-form__field-wrap--invalid':
                            addition.errors.message &&
                            addition.errors.errors.name,
                    }"
                    :invalid-message="addition.errors.errors.name"
                >
                    <input
                        class="c-order__extra-option-name e-form__input e-form__input--small"
                        type="text"
                        v-model="state.form.name"
                        required
                        placeholder="Omschrijving (Bijv. Maatwerk website)"
                    />
                </div>
                <div class="c-order__extra-option-wrap e-form__field-wrap">
                    <label class="e-form__checkbox-wrap">
                        <input
                            class="e-form__checkbox"
                            type="checkbox"
                            v-model="state.form.has_attachments"
                        />
                        <span class="e-form__checkbox-label"></span>
                        <span class="e-form__checkbox-text"
                            >Heeft bestanden nodig voor productie</span
                        >
                    </label>
                </div>
            </section>

            <section
                class="c-order__extra-option-section"
                v-if="state.form.has_attachments"
            >
                <div class="c-order__file-names-form e-form">
                    <ul class="e-form__files-list">
                        <li
                            class="e-form__files-item"
                            v-for="(name, index) in state.form.attachments"
                            :key="index"
                        >
                            {{ name }}
                            <div class="e-form__files-item-delete">
                                <SvgDelete @click="removeAttachment(index)" />
                            </div>
                        </li>
                    </ul>

                    <div
                        class="e-form__field-wrap"
                        :class="{
                            'e-form__field-wrap--invalid':
                                addition.errors.message &&
                                addition.errors.errors.attachments,
                        }"
                        :invalid-message="addition.errors.errors.attachments"
                    >
                        <input
                            type="text"
                            class="e-form__input"
                            v-model="state.new_attachment"
                            placeholder="Voer naamgeving voor benodigde bestand in"
                        />
                        <div
                            class="e-button e-button--big e-button--grey"
                            @click="addAttachment()"
                        >
                            Toevoegen
                        </div>
                    </div>
                </div>
            </section>

            <section class="c-order__extra-option-section">
                <div
                    class="c-order__extra-option-wrap e-form__field-wrap"
                    :class="{
                        'e-form__field-wrap--invalid':
                            addition.errors.message &&
                            addition.errors.errors.amount,
                    }"
                    :invalid-message="addition.errors.errors.amount"
                >
                    <label class="e-form__label">Aantal</label>
                    <input
                        class="e-form__input e-form__input--small"
                        type="number"
                        placeholder="1"
                        min="0"
                        steps="1"
                        v-model="amount"
                        required
                        :disabled="
                            orderstatus &&
                            !orderstatus.includes('In ontvangst') &&
                            !orderstatus.includes('Concept')
                        "
                    />
                </div>

                <div
                    class="c-order__extra-option-wrap e-form__field-wrap"
                    :class="{
                        'e-form__field-wrap--invalid':
                            addition.errors.message &&
                            addition.errors.errors.price,
                    }"
                    :invalid-message="addition.errors.errors.price"
                >
                    <label class="e-form__label">Prijs p/st. €</label>
                    <input
                        class="e-form__input e-form__input--small"
                        type="number"
                        placeholder="+/- 0"
                        min="0"
                        steps="0.01"
                        required
                        :disabled="
                            orderstatus &&
                            !orderstatus.includes('In ontvangst') &&
                            !orderstatus.includes('Concept')
                        "
                        v-model="price"
                    />
                </div>

                <div
                    class="c-order__extra-option-wrap e-form__field-wrap"
                    :class="{
                        'e-form__field-wrap--invalid':
                            addition.errors.message &&
                            addition.errors.errors.total,
                    }"
                    :invalid-message="addition.errors.errors.total"
                >
                    <label class="e-form__label">Totaalprijs €</label>
                    <input
                        class="e-form__input e-form__input--small"
                        type="number"
                        placeholder="+/- 0"
                        min="-1000"
                        steps="0.01"
                        required
                        :disabled="
                            (orderstatus &&
                                !orderstatus.includes('In ontvangst') &&
                                !orderstatus.includes('Concept')) ||
                            state.form.price ||
                            true
                        "
                        v-model="state.form.total"
                    />
                </div>

                <div class="e-button e-button--green" @click="createAddition()">
                    Opslaan
                </div>
            </section>
        </div>
        <button
            class="e-button e-button--full-width"
            @click="state.showForm = !state.showForm"
        >
            <SvgPlus />
            <span v-if="!state.showForm">Extra optie toevoegen</span>
            <span v-if="state.showForm">Annuleren</span>
        </button>
    </section>
</template>
<script setup>
import { useAdditionStore } from "@/store/addition";
import { useBoxStore } from "@/store/box";
import SvgDelete from "@/svg/delete.svg";
import SvgPlus from "@/svg/plus.svg";
import Swal from "sweetalert2";
import { onMounted, reactive, ref, watch } from "vue";

const props = defineProps({
    additions: Object,
    order: String,
    box: {
        type: String,
        default: null,
    },
    orderstatus: String,
});

const state = reactive({
    showForm: false,
    form: {
        amount: 1,
        price: 0,
        total: 0,
        name: null,
        has_attachments: false,
        attachments: [],
        box_id: null,
    },
    new_attachment: null,
});

onMounted(() => {
    state.form.box_id = props?.box;
});

const amount = ref(1);
const price = ref(null);

watch(amount, async (value, oldQuestion) => {
    if (value) {
        state.form.amount = value;
    } else {
        state.form.amount = 1;
    }
    if (value && price.value) {
        state.form.total = (parseInt(price.value) * value).toFixed(2);
    }
});

watch(price, async (value, oldQuestion) => {
    let _amount = 1;
    if (amount.value) {
        _amount = amount.value;
    }
    if (value && _amount) {
        state.form.price = value;
        state.form.total = (parseInt(_amount) * value).toFixed(2);
    }
});

const box = useBoxStore();
const addition = useAdditionStore();

function updateAdditionAmount(_addition) {
    addition.update(props.order, _addition.id, { total: _addition.total });
}

async function createAddition() {
    let store = await addition.store(props.order, state.form);
    if (store) {
        state.form = {
            amount: 1,
            price: 0,
            total: 0,
            name: null,
            has_attachments: false,
            attachments: [],
            box_id: props.box,
        };
        amount.value = 1;
        price.value = 0;
    }
}

function addAttachment() {
    if (state.new_attachment) {
        state.form.attachments.push(state.new_attachment);
        state.new_attachment = null;
    }
}
function removeAttachment(index) {
    state.form.attachments.splice(index, 1);
}

function deleteAddition(item) {
    setTimeout(() => {
        Modals.close();
    }, 100);
    Swal.fire({
        text: 'Zeker weten dat je "' + item.name + '" wilt verwijderen?',
        confirmButtonText: "Wissen",
        showCloseButton: true,
        icon: "warning",
    }).then((result) => {
        if (result.isConfirmed) {
            addition.destroy(props.order, item.id);
        }
    });
}
</script>
