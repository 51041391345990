<template>

   <apexchart 
        width="100%" 
        height="100%" 
        :series="props.series"
        :options='chart.options'
    >
    </apexchart>

</template>

<script setup>
import { inject, onMounted, reactive } from "vue";

const props = defineProps({
    series: Array,
    labels: Array,
    colors: {
        type: Array,
        default: ['#78ceab', '#5fb090', '#499175'],
    },
    legend: {
        type: Boolean,
        default: true,
    },
    module_theme: "", //'green' for green styling
});

//Green module style
var themeOptions = {
    stroke: {
    },
    legend: {
        labels: {},
    },
    dataLabels: {
        formatter: function (val, opts) {
                return opts.w.config.series[opts.seriesIndex]
            },
    },
    fill: {
        type: 'gradient',
        gradient: {
              shade: 'lighten',
              type: "horizontal",
              shadeIntensity: 0,
              gradientToColors: undefined,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 0.8,
              stops: [10, 100],
              colorStops: []
          },
    },
};

if(props.module_theme == 'green') {

    themeOptions = {
        stroke: {
            width: 1,
            colors: '#fff',
        },
        legend: {
            labels: {
                  colors: '#fff',
              },
          },
        dataLabels: {
            background: {
                borderWidth: 1,
            },
            style: {
                fontSize: '13px',
            },
            dropShadow: {
                enabled: false,
            },
            formatter: function (val, opts) {
                return opts.w.config.series[opts.seriesIndex] + ' producten';
            },
        },
        fill: {
            type: 'gradient',
            opacity: 1,
            gradient: {
                  shade: 'lighten',
                  type: "horizontal",
                  shadeIntensity: 1,
                  gradientToColors: ['#fff', '#fff', '#fff'],
                  inverseColors: false,
                  opacityFrom: 0.2,
                  opacityTo: 0.5,
                  stops: [0, 10],
                  colorStops: []
              },
        },
    }
}


const chart = reactive({
      options: {
        chart: {
          type: 'donut',
        },
        colors: props.colors,
        plotOptions: {
            pie: {
                expandOnClick: false,
                donut: {
                    size: '60%',
                    //background: '#b5dfce',
                    labels: {
                        show: false,
                    },
                },
            },
        },
        legend: {
            show: props.legend,
            position: 'right',
            labels: themeOptions.legend.labels,
        },
        dataLabels: themeOptions.dataLabels,
        fill: themeOptions.fill,
        grid: {
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
        },
        stroke: themeOptions.stroke,
        labels: props.labels,
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (val, opts) {
                    return val + ' producten';
                },
            },
        },
        responsive: [{
          breakpoint: 960,
          options: {
            legend: {
              position: 'bottom'
            }
          }
        }],
      },
});

</script>