<template>
    <article>
        <div class="e-modal e-modal--w600" modal-id="received">
            <div class="e-modal__wrap">
                <div class="e-modal__modal">
                    <div class="e-modal__close js-close-modal">
                        <SvgCross @click="Modals.close()" />
                    </div>

                    <div class="e-modal__section">
                        <div class="e-modal__section-title">
                            <h2 class="e-modal__title">
                                In ontvangst genomen door
                            </h2>
                        </div>
                    </div>

                    <div class="e-modal__section">
                        <div class="e-form">
                            <vueSignature
                                class="signature"
                                ref="signature"
                                :w="'100%'"
                                :h="'300px'"
                            ></vueSignature>

                            <div
                                class="e-form__field-wrap e-form__field-wrap--align-center"
                            >
                                <input
                                    class="e-form__input e-form__input--align-center"
                                    placeholder="Naam ontvanger"
                                    ref="receiver"
                                />
                            </div>
                            <a
                                @click="acceptPackage()"
                                class="e-button e-button--green e-button--full-width"
                                >Afronden</a
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="e-modal__bg js-close-modal"
                @click="Modals.close()"
            ></div>
        </div>
    </article>
</template>

<script setup>
import vueSignature from "vue3-signature";

import SvgCross from "@/svg/cross.svg";
import { onMounted, ref, inject } from "vue";
import { useRoute } from "vue-router";

const props = defineProps({
    path: {
        type: String,
    },
});

const Modals = inject("Modals");

const signature = ref(null);
const receiver = ref(null);
const route = useRoute();

function acceptPackage() {
    console.info(signature);
    if (signature.value.isEmpty()) {
        alert("Handtekening mist");
    } else if (!receiver.value.value) {
        alert("Naam mist");
    } else {
        axios
            .post(props.path, {
                by: receiver.value.value,
                signature: signature.value.save(),
            })
            .then((response) => {
                Modals.close();

                Eventing.fire("isSigned");
            })
            .catch((error) => {
                if (confirm("Daar ging wat mis.. Probeer het opnieuw")) {
                    window.location.reload();
                }
            });
    }
}
// let file = uploadFile.value.files[0];
</script>

<style scoped>
.signature {
    border-radius: 0.6rem;
    border: 0.1rem solid #e0e0e0;
    padding: 0 1.5rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
    margin-top: -20px;
}
</style>
