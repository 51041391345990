<template>
    <article>
        <app-dashboards-traffic-navigation />

        <app-dashboards-finance-content />
    </article>

    <app-dashboards-side-overlay-traffic-finance />
</template>

<script setup></script>
