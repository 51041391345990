import "./bootstrap";

import "./edit-toggle.js";
import "./modals.js";
import "./order-item.js";
import "./toggle-class.js";

import { createApp, markRaw } from "vue";
import router from "./router";

// Eventings
import TinyEmitter from "tiny-emitter";

import Swal from "sweetalert2";
const Toast = Swal.mixin({
    toast: true,
    position: "bottom-right",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    showClass: {
        popup: "animated fadeInUp",
        icon: "animated heartBeat delay-1s",
    },
    hideClass: {
        popup: "animated fadeOutUp faster",
    },
    customClass: {
        container: "c-sweet-toast",
        // container: "c-sweet-alert",
    },
    didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

window.Eventing = new (class {
    constructor() {
        this.emitter = new TinyEmitter();
    }
    fire(event, data = null, subdata = null) {
        this.emitter.emit(event, data, subdata);
    }
    listen(event, callback) {
        this.emitter.on(event, callback);
    }
})();

import VueMarkdownEditor from "@kangc/v-md-editor";
import enUS from "@kangc/v-md-editor/lib/lang/en-US";
import "@kangc/v-md-editor/lib/style/base-editor.css";
import githubTheme from "@kangc/v-md-editor/lib/theme/github.js";
import "@kangc/v-md-editor/lib/theme/style/github.css";
VueMarkdownEditor.lang.use("en-US", enUS);
VueMarkdownEditor.use(githubTheme, {
    extend(md) {},
});

const Modals = new (class {
    constructor() {}
    show(screen, path) {
        Eventing.fire("modal", screen, path);
    }
    open() {
        setTimeout(() => {
            try {
                let eModal = document.getElementsByClassName("e-modal");
                eModal[0].classList.add("e-modal--open");
                document.body.classList.add("body--no-scroll");
            } catch (error) {}
        }, 100);
    }
    closing() {
        setTimeout(() => {
            document.body.classList.remove("body--no-scroll");
        }, 100);
    }
    close() {
        Eventing.fire("closeModal");
    }
})();

window.Modals = Modals;

import { createPinia } from "pinia";

import App from "./screens/App.vue";

// Components
import MaatwerkTile from "@/components/App/Order/MaatwerkTile.vue";
import OrderItem from "@/components/App/Order/OrderItem.vue";
import OrderTile from "@/components/App/Order/OrderTile.vue";

import AppDashboardsFinanceContent from "@/components/App/Dashboards/Finance/Content.vue";
import AppDashboardsTrafficNavigation from "@/components/App/Dashboards/Traffic/Navigation.vue";

import AppDashboardsSideOverlayDesign from "@/components/App/Dashboards/SideOverlay/Design.vue";
import AppDashboardsSideOverlayFinance from "@/components/App/Dashboards/SideOverlay/Finance.vue";
import AppDashboardsSideOverlayHeader from "@/components/App/Dashboards/SideOverlay/Header.vue";
import AppDashboardsSideOverlayShopManager from "@/components/App/Dashboards/SideOverlay/ShopManager.vue";
import AppDashboardsSideOverlayTrafficFinance from "@/components/App/Dashboards/SideOverlay/Traffic/Finance.vue";
import AppDashboardsSideOverlayTrafficMaatwerkRuns from "@/components/App/Dashboards/SideOverlay/Traffic/MaatwerkRuns.vue";
import AppDashboardsSideOverlayTrafficStock from "@/components/App/Dashboards/SideOverlay/Traffic/Stock.vue";

import AppDashboardsSideOverlayTrafficOrder from "@/components/App/Dashboards/SideOverlay/Traffic/Order.vue";

import ChartArea from "@/components/App/Dashboards/Charts/Area.vue";
import ChartAreaSimple from "@/components/App/Dashboards/Charts/AreaSimple.vue";
import ChartBarSingle from "@/components/App/Dashboards/Charts/BarSingle.vue";
import ChartBars from "@/components/App/Dashboards/Charts/Bars.vue";
import ChartDonut from "@/components/App/Dashboards/Charts/Donut.vue";
import ChartRadar from "@/components/App/Dashboards/Charts/Radar.vue";
import ChartRangeBar from "@/components/App/Dashboards/Charts/RangeBar.vue";

import ProductionProductStats from "@/components/App/Dashboards/Production/ProductStats.vue";
import ProductionProductsPicker from "@/components/App/Dashboards/Production/ProductsPicker.vue";

import AppOrderHeader from "@/components/App/Order/Header.vue";
import AppOrderNavigation from "@/components/App/Order/Navigation.vue";
import AppOrderTimeline from "@/components/App/Order/Timeline.vue";

import AppStockContent from "@/components/App/Stock/Content.vue";
import AppStockProducts from "@/components/App/Stock/Products.vue";
import AppStockTimeline from "@/components/App/Stock/Timeline.vue";

import ClientHeader from "@/components/Client/Header.vue";
import ClientNavigation from "@/components/Client/Navigation.vue";

import ProductionDeliveryData from "@/components/Production/DeliveryData.vue";
import ProductionOrderDetail from "@/components/Production/OrderDetail.vue";
import ProductionOrderProduction from "@/components/Production/OrderDetailProduction.vue";

import ButtonState from "@/components/ButtonState.vue";
import Pagination from "@/components/Pagination.vue";
import Product from "@/components/Product.vue";
import ProductOrCategory from "@/components/ProductOrCategory.vue";
import ValidationErrors from "@/components/ValidationErrors.vue";

import FileUploader from "@/components/FileUploader.vue";
import Notifications from "@/components/Notifications.vue";

import FinanceTotals from "@/components/Totals/Finance.vue";
import ProductionTotals from "@/components/Totals/Production.vue";
import TransportTotals from "@/components/Totals/Transport.vue";

import ClientFileUploader from "@/components/ClientFileUploader.vue";

import PickerProduct from "@/components/ProductPicker/Product.vue";
import PickerProductOrCategory from "@/components/ProductPicker/ProductOrCategory.vue";

import ClientPickerProduct from "@/components/ProductPicker/Clients/Product.vue";
import ClientPickerProductOrCategory from "@/components/ProductPicker/Clients/ProductOrCategory.vue";

import BasketItem from "@/components/App/Order/BasketItem.vue";
import DeliveryAddresses from "@/components/App/Order/DeliveryAddresses.vue";
import OrderAdditions from "@/components/App/Order/OrderAdditions.vue";

import InvoiceRow from "@/components/App/Order/InvoiceRow.vue";
import InvoiceRowSmall from "@/components/App/Order/InvoiceRowSmall.vue";

import OrdersCategoryFilter from "@/components/App/Order/CategoryFilter.vue";
import ProductsCategoryFilter from "@/components/App/Product/CategoryFilter.vue";

import ClientBasketItem from "@/components/Client/ClientBasketItem.vue";

// Modals
import ModalAppContactCreate from "@/modals/App/Contact/Create.vue";
import ModalAppContactUpdate from "@/modals/App/Contact/Update.vue";
import ModalAppEmailCreate from "@/modals/App/Email/Create.vue";
import ModalAppEmailtUpdate from "@/modals/App/Email/Update.vue";
import ModalAppOrderChangeStatus from "@/modals/App/Order/ChangeStatus.vue";
import ModalAppOrderFilter from "@/modals/App/Order/Filter.vue";
import ModalAppOrderProducts from "@/modals/App/Order/Products.vue";
import ModalAppProductCategoryCreate from "@/modals/App/Product/Category/Create.vue";
import ModalAppProductCategoryUpdate from "@/modals/App/Product/Category/Update.vue";
import ModalAppProductCreate from "@/modals/App/Product/Create.vue";
import ModalAppProductUpdate from "@/modals/App/Product/Update.vue";
import ModalAppUserCreate from "@/modals/App/User/Create.vue";
import ModalAppUserUpdate from "@/modals/App/User/Update.vue";

import ModalAppMaatwerkRuns from "@/modals/App/Maatwerk/Runs.vue";

import ModalDashboardProductionProducts from "@/modals/Dashboard/Production/Products.vue";

import ModalAppDeliveryAreaCreate from "@/modals/App/DeliveryArea/Create.vue";
import ModalAppDeliveryAreaUpdate from "@/modals/App/DeliveryArea/Update.vue";

import ModalProductionIsReady from "@/modals/Production/IsReady.vue";

import ModalTransportOnTransport from "@/modals/Transport/OnTransport.vue";
import ModalTransportReceived from "@/modals/Transport/Received.vue";

import ModalClientProducts from "@/modals/Client/Products.vue";

import ResizeTextarea from "resize-textarea-vue3";

import VueApexCharts from "vue3-apexcharts";

import AddressesTable from "@/components/App/Order/AddressesTable.vue";

import AddressesTableClient from "@/components/Client/Order/AddressesTable.vue";

const pinia = createPinia();

pinia.use(({ store }) => {
    store.$router = markRaw(router);
});

const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(VueMarkdownEditor);
app.use(ResizeTextarea);
app.use(VueApexCharts);

app.provide("Modals", Modals);
app.provide("Toast", Toast);

app.component(
    "app-dashboards-traffic-navigation",
    AppDashboardsTrafficNavigation
);
app.component("app-dashboards-finance-content", AppDashboardsFinanceContent);

app.component(
    "app-dashboards-side-overlay-header",
    AppDashboardsSideOverlayHeader
);
app.component(
    "app-dashboards-side-overlay-traffic-maatwerk-runs",
    AppDashboardsSideOverlayTrafficMaatwerkRuns
);
app.component(
    "app-dashboards-side-overlay-traffic-finance",
    AppDashboardsSideOverlayTrafficFinance
);
app.component(
    "app-dashboards-side-overlay-traffic-stock",
    AppDashboardsSideOverlayTrafficStock
);
app.component(
    "app-dashboards-side-overlay-traffic-order",
    AppDashboardsSideOverlayTrafficOrder
);
app.component(
    "app-dashboards-side-overlay-design",
    AppDashboardsSideOverlayDesign
);
app.component(
    "app-dashboards-side-overlay-shop-manager",
    AppDashboardsSideOverlayShopManager
);
app.component(
    "app-dashboards-side-overlay-finance",
    AppDashboardsSideOverlayFinance
);

app.component("order-tile", OrderTile);
app.component("order-item", OrderItem);
app.component("maatwerk-tile", MaatwerkTile);

app.component("chart-range-bar", ChartRangeBar);
app.component("chart-area", ChartArea);
app.component("chart-area-simple", ChartAreaSimple);
app.component("chart-bars", ChartBars);
app.component("chart-bar-single", ChartBarSingle);
app.component("chart-radar", ChartRadar);
app.component("chart-donut", ChartDonut);

app.component("app-order-header", AppOrderHeader);
app.component("app-order-navigation", AppOrderNavigation);
app.component("app-order-timeline", AppOrderTimeline);

app.component("app-stock-content", AppStockContent);
app.component("app-stock-timeline", AppStockTimeline);
app.component("app-stock-products", AppStockProducts);

app.component("client-header", ClientHeader);
app.component("client-navigation", ClientNavigation);

app.component("production-order-detail", ProductionOrderDetail);
app.component("production-order-detail-production", ProductionOrderProduction);
app.component("production-order-delivery", ProductionDeliveryData);

app.component("button-state", ButtonState);
app.component("pagination", Pagination);
app.component("product-row", Product);
app.component("products-row", ProductOrCategory);
app.component("validation-errors", ValidationErrors);

app.component("invoice-row", InvoiceRow);
app.component("invoice-row-small", InvoiceRowSmall);

app.component("product-adding-row", PickerProduct);
app.component("products-adding-row", PickerProductOrCategory);

app.component("client-product-adding-row", ClientPickerProduct);
app.component("client-products-adding-row", ClientPickerProductOrCategory);

app.component("basket-item", BasketItem);
app.component("order-additions", OrderAdditions);
app.component("delivery-addresses", DeliveryAddresses);

app.component("address-table", AddressesTable);
app.component("address-table-client", AddressesTableClient);

app.component("client-basket-item", ClientBasketItem);

app.component("file-uploader", FileUploader);
app.component("notification-list", Notifications);

app.component("client-file-uploader", ClientFileUploader);

app.component("production-totals", ProductionTotals);
app.component("transport-totals", TransportTotals);
app.component("finance-totals", FinanceTotals);

app.component("orders-category-filter", OrdersCategoryFilter);
app.component("products-category-filter", ProductsCategoryFilter);

app.component("production-product-stats", ProductionProductStats);
app.component("production-products-picker", ProductionProductsPicker);

// Modals
app.component("modal-app-contact-create", ModalAppContactCreate);
app.component("modal-app-contact-update", ModalAppContactUpdate);
app.component("modal-app-email-create", ModalAppEmailCreate);
app.component("modal-app-email-update", ModalAppEmailtUpdate);
app.component("modal-app-order-change-status", ModalAppOrderChangeStatus);
app.component("modal-app-order-filter", ModalAppOrderFilter);
app.component("modal-app-order-products", ModalAppOrderProducts);
app.component("modal-app-product-create", ModalAppProductCreate);
app.component("modal-app-product-update", ModalAppProductUpdate);
app.component(
    "modal-app-product-category-create",
    ModalAppProductCategoryCreate
);
app.component(
    "modal-app-product-category-update",
    ModalAppProductCategoryUpdate
);
app.component("modal-app-user-create", ModalAppUserCreate);
app.component("modal-app-user-update", ModalAppUserUpdate);

app.component("modal-app-maatwerk-runs", ModalAppMaatwerkRuns);

app.component("modal-app-delivery-area-create", ModalAppDeliveryAreaCreate);
app.component("modal-app-delivery-area-update", ModalAppDeliveryAreaUpdate);

app.component("modal-production-is-ready", ModalProductionIsReady);
app.component("modal-transport-on-transport", ModalTransportOnTransport);
app.component("modal-transport-received", ModalTransportReceived);
app.component("modal-client-products", ModalClientProducts);

app.component(
    "modal-dashboard-production-products",
    ModalDashboardProductionProducts
);

app.mount("#feelingz");

import Echo from "laravel-echo";

import Pusher from "pusher-js";
window.Pusher = Pusher;

// // window.Echo = new Echo({
// //     broadcaster: 'pusher',
// //     key: import.meta.env.VITE_PUSHER_APP_KEY,
// //     wsHost: import.meta.env.VITE_PUSHER_HOST ?? `ws-${import.meta.env.VITE_PUSHER_CLUSTER}.pusher.com`,
// //     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
// //     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
// //     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
// //     enabledTransports: ['ws', 'wss'],
// // });
import { useAuthStore } from "@/store/auth";
import { useAuthClientStore } from "@/store/authClient";
const auth = useAuthStore();

const client = useAuthClientStore();

let token = auth.token();
if (typeof token === "undefined") {
    token = client.token();
}

//Default ApexCharts settings

Apex = {
    colors: ["#78ceab", "#5fb090", "#499175"],
    chart: {
        width: "100%",
        height: "100%",
        fontFamily: "Heebo",
        toolbar: {
            show: false,
        },
        selection: {
            enabled: false,
        },
        zoom: {
            enabled: false,
        },
    },
    stroke: {
        curve: "smooth",
    },
    plotOptions: {
        radar: {
            size: 75,
            polygons: {
                strokeColors: "#e9e9e9",
                fill: {
                    colors: ["#f8f8f8", "#fff"],
                },
            },
        },
        pie: {
            expandOnClick: false,
            donut: {
                labels: {
                    total: {
                        fontSize: "13px",
                    },
                },
            },
        },
        area: {
            fillTo: "end",
        },
    },
    states: {
        normal: {
            filter: {
                type: "none",
            },
        },
        hover: {
            filter: {
                type: "none",
            },
        },
        active: {
            allowMultipleDataPointsSelection: false,
            filter: {
                type: "none",
            },
        },
    },
    grid: {
        xaxis: {
            lines: {
                show: false,
            },
        },
        yaxis: {
            lines: {
                show: true,
            },
        },
    },
    dataLabels: {
        style: {
            fontSize: "11px",
            fontWeight: "bold",
            colors: undefined,
        },
        background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 4,
            borderWidth: 2,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: "#000",
                opacity: 0.45,
            },
        },
    },
    legend: {
        fontSize: "13px",
        fontWeight: 400,
        markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 2,
            offsetX: -8,
            offsetY: 1,
        },
    },
    xaxis: {
        labels: {
            show: true,
            style: {
                colors: [
                    "#231f20",
                    "#231f20",
                    "#231f20",
                    "#231f20",
                    "#231f20",
                    "#231f20",
                    "#231f20",
                    "#231f20",
                ],
                fontSize: "13px",
                fontFamily: "Heebo, sans-serif",
                fontWeight: 400,
            },
        },
        tooltip: {
            enabled: false,
        },
    },
    tooltip: {
        enabled: true,
        style: {
            fontSize: "13px",
            fontFamily: "Heebo, Arial",
        },
    },
};

window.Echo = new Echo({
    broadcaster: "pusher",
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    encrypted: true,
    key: import.meta.env.VITE_PUSHER_APP_KEY,

    auth: {
        headers: {
            Authorization: "Bearer " + token,
        },
    },

    // wsHost:
    //     import.meta.env.VITE_PUSHER_HOST ??
    //     `ws-${import.meta.env.VITE_PUSHER_CLUSTER}.pusher.com`,
    // wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
    // wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
    // forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? "https") === "https",
    // enabledTransports: ["ws", "wss"],

    authorizer: (channel, options) => {
        return {
            authorize: (socketId, callback) => {
                axios
                    .post(
                        "/api/broadcasting/auth",
                        {
                            socket_id: socketId,
                            channel_name: channel.name,
                        },
                        { headers: { Authorization: "Bearer " + token } }
                    )
                    .then((response) => {
                        callback(false, response.data);
                    })
                    .catch((error) => {
                        callback(true, error);
                    });
            },
        };
    },
});

window.Echo.channel("feelingz").listen(".deployment", (event) => {
    setTimeout(() => {
        window.location.reload(true);
    }, 10000);
});
