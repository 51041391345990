<template>
    <product-row
        :product="product"
        :showcategory="showcategory"
        :is-new-category="checkIsNew(product, isNewCategory)"
        v-if="showcategory"
    ></product-row>

    <product-row :product="product" v-if="!showcategory"></product-row>
</template>
<script setup>
import SvgDelete from "@/svg/delete.svg";
import { inject } from "vue";

const props = defineProps({
    product: Object,
    showcategory: Boolean,
    isNewCategory: Boolean,
    showCategoryProducts: Array,
});

function checkIsNew(product, isNewCategory) {
    if (props.showCategoryProducts.indexOf(product.slug) !== -1) {
        return true;
    }
    return isNewCategory;
}

const Modals = inject("Modals");

function deleteProduct(item) {
    Eventing.fire("deletingProduct", item);
}

function deleteProductCategory(item) {
    Eventing.fire("deletingProductCategory", item);
}
</script>
