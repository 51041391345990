<template>
    <div class="c-side-overlay" v-if="state.order">
        <app-dashboards-side-overlay-header :order="state.order" />

        <div class="c-side-overlay__content">
            <div
                class="c-side-overlay__content-section"
                v-if="
                    state.order.notes &&
                    Object.values(state.order.notes).length > 0
                "
            >
                <h3 class="c-side-overlay__content-section-title">
                    Notities voor de Traffic
                </h3>
                <div class="c-chat__messages">
                    <div
                        class="c-chat__message c-chat__message--note"
                        v-for="note in state.order.notes"
                        :key="note.id"
                    >
                        <div class="c-chat__message-inner-wrap">
                            <div class="c-chat__message-head">
                                <div
                                    class="c-chat__message-name"
                                    v-if="note.sender"
                                >
                                    <img
                                        class="c-chat__message-user-image"
                                        :src="note.sender.avatar"
                                    />

                                    {{ note.sender.name }}

                                    <span class="c-chat__message-source">
                                        {{ note.sender.role }}
                                    </span>
                                </div>
                                <div class="c-chat__message-date">
                                    {{ note.created_at }}
                                </div>
                            </div>
                            <div class="c-chat__message-text">
                                <p v-html="note.message"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="c-side-overlay__content-section"
                v-if="state.cart && state.cart.data"
            >
                <div
                    class="c-side-overlay__content-section-table"
                    v-for="(box, subindex) in state.cart.data"
                    :key="box.id + subindex"
                    v-show="box.baskets.length > 0"
                >
                    <h3 class="c-side-overlay__content-section-title">
                        <SvgGift />
                        {{ box.name }}
                        &nbsp;
                    </h3>

                    <span
                        v-if="box.delivery_date_formatted"
                        class="e-label e-label--medium e-label--green e-tooltip"
                        data-tooltip="Leverdatum"
                        >&nbsp;{{ box.delivery_date_formatted }}&nbsp;</span
                    >
                    <div class="c-table-default">
                        <div class="c-table-default__inner-wrap">
                            <div class="c-table-default__table">
                                <div class="c-table-default__head">
                                    <div class="c-table-default__tr">
                                        <div class="c-table-default__td">
                                            Product
                                        </div>
                                        <div class="c-table-default__td"></div>
                                    </div>
                                </div>
                                <div class="c-table-default__body">
                                    <div
                                        class="c-table-default__tr"
                                        v-for="(
                                            basket, subindex
                                        ) in box.baskets"
                                        :key="basket.id + subindex"
                                    >
                                        <div class="c-table-default__td">
                                            <span v-if="basket.product">{{
                                                basket.product.name
                                            }}</span>
                                        </div>
                                        <div class="c-table-default__td">
                                            {{ basket.amount }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="c-side-overlay__footer">
            <div class="c-side-overlay__footer-content">
                <a
                    class="e-button e-button--full-width e-button--big"
                    @click="close()"
                >
                    Sluiten
                </a>
                <router-link
                    :to="{
                        name: 'app.order',
                        params: { hash: state.order.hash },
                    }"
                    class="e-button e-button--full-width e-button--big e-button--green"
                    >Naar bestelling <SvgArrowRight />
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useDashboardTrafficStore } from "@/store/dashboard/traffic";
import SvgArrowRight from "@/svg/arrow-right.svg";
import SvgGift from "@/svg/gift.svg";
import { onMounted, reactive } from "vue";

const state = reactive({
    order: null,
    cart: {
        data: [],
    },
    isChanged: false,
});

const traffic = useDashboardTrafficStore();

onMounted(() => {
    Eventing.listen("clearSideOverlay", () => {
        state.order = null;
        state.cart = {
            data: [],
        };
    });

    Eventing.listen("fillSideOverlay", async (data) => {
        state.order = data.data;
        state.cart = {
            data: [],
        };
        let cart = await traffic.cart(data.data.hash);
        if (cart) {
            state.cart = cart;
        }
    });
});

function close() {
    Eventing.fire("clearSideOverlay");
    state.order = null;
}
</script>
