<template>
    <product-adding-row
        :product="product"
        :box="box"
        :showcategory="showcategory"
        :is-new-category="isNewCategory"
        :start-amount="state.amount"
        v-if="showcategory && state.amount"
    ></product-adding-row>
    <product-adding-row
        :product="product"
        :box="box"
        :start-amount="state.amount"
        v-if="!showcategory && state.amount"
    ></product-adding-row>
</template>
<script setup>
import { useBoxStore } from "@/store/box";
import { inject, onMounted, reactive } from "vue";

const props = defineProps({
    product: Object,
    showcategory: Boolean,
    isNewCategory: Boolean,
    box: String,
});

const state = reactive({
    amount: null,
});

const _box = useBoxStore();

onMounted(() => {
    setTimeout(() => {
        startAmountSetter();
    }, 100);
});

async function startAmountSetter() {
    try {
        const waarde = getLocalStorageWithExpiry("startAmount" + props.box);
        if (waarde) {
            state.amount = waarde;
        } else {
            console.warn("else");
            try {
                let startAmount = await _box.getStartAmountBasket(props.box);
                if (startAmount) {
                    if (startAmount == 0) {
                        startAmount = 1;
                    }
                    setLocalStorageWithExpiry(
                        "startAmount" + props.box,
                        startAmount,
                        5
                    );

                    state.amount = startAmount;
                } else {
                    state.amount = 1;
                }
            } catch (error) {
                state.amount = 1;
            }
        }
    } catch (error) {
        state.amount = 1;
    }
}

function setLocalStorageWithExpiry(key, value, minutes) {
    const now = new Date();
    const expiryTime = now.getTime() + minutes * 60 * 1000; // minuten omzetten naar milliseconden
    const item = {
        value: value,
        expiry: expiryTime,
    };
    localStorage.setItem(key, JSON.stringify(item));
}

function getLocalStorageWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // Controleer of de tijd verstreken is
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}

const Modals = inject("Modals");

function deleteProduct(item) {
    Eventing.fire("deletingProduct", item);
}

function deleteProductCategory(item) {
    Eventing.fire("deletingProductCategory", item);
}
</script>
