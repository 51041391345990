<template>
    <client-product-adding-row
        :product="product"
        :box="box"
        :showcategory="showcategory"
        :is-new-category="isNewCategory"
        v-if="showcategory"
    ></client-product-adding-row>
    <client-product-adding-row
        :product="product"
        :box="box"
        v-if="!showcategory"
    ></client-product-adding-row>
</template>
<script setup>
import SvgDelete from "@/svg/delete.svg";
import { inject } from "vue";
import { useBoxStore } from "@/store/client/box";

const props = defineProps({
    product: Object,
    showcategory: Boolean,
    isNewCategory: Boolean,
    box: String,
});

const _box = useBoxStore();

_box.getStartAmountBasket(props.box);

const Modals = inject("Modals");

function deleteProduct(item) {
    Eventing.fire("deletingProduct", item);
}

function deleteProductCategory(item) {
    Eventing.fire("deletingProductCategory", item);
}
</script>
