<template>
    <article>
        <div class="c-production-content" v-if="state.loaded">
            <div class="c-production-content__page-info">
                <div class="c-production-content__page-info-col">
                    <h1 class="c-production-content__company">
                        Order <span>{{ state.order.reference }}</span>
                    </h1>

                    <button-state
                        :label="state.order.status.label"
                        :buttonclass="state.order.status.class"
                    />
                    <p
                        class="c-production-content__desc"
                        v-if="state.order.in_production"
                    >
                        Naar "{{ state.order.in_production.status }}" gezet door
                        {{ state.order.in_production.by }} om
                        <span>{{ state.order.in_production.at }}</span>
                    </p>
                </div>

                <div class="c-production-content__page-info-col">
                    <a
                        class="e-button e-button--green e-button--white-hover e-button--full-width"
                        @click="printStickers(state.order.box_options)"
                        >Stickers printen</a
                    >
                    <a
                        class="e-button e-button--green e-button--white-hover e-button--full-width mt-5"
                        @click="printCrates()"
                        >Krat labels printen</a
                    >
                </div>
            </div>

            <nav
                class="c-page-menu"
                style="position: unset !important"
                v-if="state.tab"
            >
                <ul class="c-page-menu__list">
                    <li class="c-page-menu__item">
                        <a
                            :class="{
                                'c-page-menu__item-link--current':
                                    state.tab == 'info',
                            }"
                            @click="state.tab = 'info'"
                            class="c-page-menu__item-link"
                        >
                            Details
                        </a>
                    </li>

                    <li class="c-page-menu__item">
                        <a
                            :class="{
                                'c-page-menu__item-link--current':
                                    state.tab == 'notes',
                            }"
                            @click="state.tab = 'notes'"
                            class="c-page-menu__item-link"
                        >
                            Notities
                            <span>{{ state.order.remarks.length }}</span>
                        </a>
                    </li>

                    <li
                        class="c-page-menu__item"
                        v-for="(box, index) in state.order.boxes"
                        :key="index"
                    >
                        <a
                            :class="{
                                'c-page-menu__item-link--current':
                                    state.tab == box.hash,
                            }"
                            @click="state.tab = box.hash"
                            class="c-page-menu__item-link"
                        >
                            {{ box.name }}

                            <span>{{ picked(box.baskets) }}</span>
                        </a>
                    </li>

                    <li
                        class="c-page-menu__item"
                        v-if="state.order.additions.length"
                    >
                        <a
                            :class="{
                                'c-page-menu__item-link--current':
                                    state.tab == 'additions',
                            }"
                            @click="state.tab = 'additions'"
                            class="c-page-menu__item-link"
                        >
                            Extra opties
                            <span>{{ picked(state.order.additions) }}</span>
                        </a>
                    </li>
                </ul>
            </nav>

            <div class="c-production-content__section">
                <!-- <h2 class="c-production-content__title">
                    Order: {{ state.order.reference }}
                </h2> -->

                <section
                    v-for="(box, index) in state.order.boxes"
                    :key="box.id + index"
                    v-show="state.tab == box.hash"
                >
                    <div class="c-order__package">
                        <div class="e-section-heading">
                            <div class="e-section-heading__left">
                                <div class="e-section-heading__icon">
                                    <SvgGift />
                                </div>
                                <div class="e-edit-toggle">
                                    <div class="e-edit-toggle__output-wrap">
                                        <h2 class="e-section-heading__name">
                                            {{ box.name }}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="c-order-item js-drag-handle js-draggable"
                            v-for="(basket, subindex) in box.baskets"
                            :key="basket.id + subindex"
                        >
                            <production-order-detail-production
                                :order="state.order.hash"
                                :basket="basket"
                                :box="box.hash"
                                :product="basket.product"
                                :in-production="
                                    state.order.status.label == 'In productie'
                                        ? true
                                        : false
                                "
                                :first-of-category="
                                    basket.product &&
                                    basket.first_of_categories.includes(
                                        basket.product.article_nr
                                    )
                                "
                            />
                        </div>
                    </div>
                </section>

                <article
                    class="c-order__package"
                    v-show="state.tab == 'additions'"
                >
                    <div class="e-section-heading">
                        <div class="e-section-heading__left">
                            <div class="e-section-heading__icon">
                                <SvgPlus />
                            </div>
                            <div class="e-edit-toggle">
                                <div class="e-edit-toggle__output-wrap">
                                    <h2 class="e-section-heading__name">
                                        Extra opties
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="c-order-item js-drag-handle js-draggable"
                        v-for="(addition, subindex) in state.order.additions"
                        :key="addition.id + subindex"
                    >
                        <section v-if="addition.product">
                            <div class="c-order-item__row">
                                <div
                                    class="c-order-item__col c-order-item__col--align-left"
                                >
                                    <label
                                        class="c-order-item__checkbox e-form__checkbox-wrap"
                                        v-if="
                                            state.order.status.label ==
                                            'In productie'
                                        "
                                    >
                                        <input
                                            class="e-form__checkbox"
                                            type="checkbox"
                                            v-model="addition.is_picked"
                                            @click="
                                                togglePicker(
                                                    addition,
                                                    addition.is_picked
                                                )
                                            "
                                        />
                                        <span
                                            class="e-form__checkbox-label"
                                        ></span>
                                    </label>
                                    <p class="c-order-item__name">
                                        {{ addition.product.name }}
                                    </p>
                                </div>
                                <div class="c-order-item__col">
                                    <div class="c-order-item__amount"></div>
                                </div>
                            </div>
                        </section>
                    </div>
                </article>

                <article class="c-order__package" v-show="state.tab == 'notes'">
                    <article>
                        <div class="e-section-heading">
                            <div class="e-section-heading__left">
                                <div class="e-section-heading__icon">
                                    <SvgPencil />
                                </div>
                                <h2 class="e-section-heading__name">
                                    Notities &amp; opmerkingen
                                </h2>
                            </div>
                        </div>

                        <div class="c-production-content__delivery-data">
                            <ul
                                class="c-data-list c-data-list--table-style c-data-list--no-ellipsis"
                            >
                                <li
                                    class="c-data-list__item"
                                    v-for="(remark, what) in state.order
                                        .remarks"
                                    :key="what"
                                >
                                    <p class="c-data-list__subject">
                                        {{ what }}
                                    </p>
                                    <p
                                        class="c-data-list__data"
                                        v-html="remark"
                                    ></p>
                                </li>
                            </ul>
                        </div>
                    </article>
                </article>

                <article class="c-order__package" v-show="state.tab == 'info'">
                    <article>
                        <div class="e-section-heading">
                            <div class="e-section-heading__left">
                                <div class="e-section-heading__icon">
                                    <SvgInfo />
                                </div>
                                <h2 class="e-section-heading__name">Details</h2>
                            </div>
                        </div>

                        <div class="c-production-content__delivery-data">
                            <ul
                                class="c-data-list c-data-list--table-style c-data-list--no-ellipsis"
                            >
                                <li
                                    class="c-data-list__item"
                                    v-for="(info, what) in state.order.info"
                                    :key="what"
                                >
                                    <p
                                        class="c-data-list__subject"
                                        v-html="what.split('_')[0]"
                                    ></p>
                                    <p
                                        class="c-data-list__data"
                                        v-html="info"
                                    ></p>
                                </li>
                            </ul>
                        </div>
                    </article>
                </article>

                <!-- <production-order-delivery /> -->
            </div>
        </div>

        <section v-if="state.order.status">
            <div
                class="c-production-footer"
                v-if="state.order.status.label == 'Kan in productie'"
            >
                <a
                    class="e-button e-button--green e-button--full-width"
                    @click="toggleStatus('In productie')"
                    >Plaats bestelling "In Productie"</a
                >
            </div>

            <div
                class="c-production-footer"
                v-if="state.order.status.label == 'In productie'"
            >
                <a
                    class="e-button e-button--green e-button--full-width"
                    @click="
                        // toggleStatus(
                        //     state.order.info['Colli'] == 'Onbekend'
                        //         ? 'Colli'
                        //         : 'Klaar voor transport'
                        // )
                        toggleStatus('Colli')
                    "
                    >Plaats bestelling "Klaar voor transport"</a
                >
            </div>

            <div
                class="c-production-footer"
                v-if="state.order.status.label == 'Klaar voor transport'"
            >
                <a
                    class="e-button e-button--green e-button--full-width"
                    @click="toggleStatus('Transport')"
                    >Plaats bestelling "Op Transport"</a
                >
            </div>
        </section>
    </article>
</template>

<script setup>
import SvgGift from "@/svg/gift.svg";
import SvgPlus from "@/svg/plus.svg";
import SvgPencil from "@/svg/pencil.svg";
import SvgInfo from "@/svg/info.svg";

import Swal from "sweetalert2";
import { onMounted, ref, reactive, inject, computed } from "vue";
import { useBoxStore } from "@/store/box";
import { useAdditionStore } from "@/store/addition";
import { useOrderStore } from "@/store/production/order";
import { useRoute } from "vue-router";

const Modals = inject("Modals");

const route = useRoute();
const order = useOrderStore();

order.show(useRoute().params.hash);

const state = reactive({
    loaded: false,
    tab: null,
    order: {
        contact: {
            address: {},
        },
        status: null,
        followers: {},
        additions: {},
        remarks: {},
        has_varying_deliveries: false,
    },
    currentCategories: [],
    shownCategories: [],
});

onMounted(() => {
    state.order = {
        contact: {
            address: {},
        },
    };
    state.currentCategories = [];
    state.shownCategories = [];

    Eventing.listen("fillOrder", (data) => {
        state.order = data.data;
        state.loaded = true;
    });

    Eventing.listen("collieKnown", () => {
        return toggleStatus("Klaar voor transport");
    });
    if (route.params.box_hash) {
        state.tab = route.params.box_hash;
    } else {
        state.tab = "notes";
    }
});

async function toggleStatus(status) {
    let i = 0;
    state.order.additions.forEach((item) => {
        if (!item.is_picked) {
            i++;
        }
    });

    state.order.boxes.forEach((box) => {
        box.baskets.forEach((item) => {
            if (!item.is_picked) {
            }
        });
    });

    let e = 0;
    state.order.boxes.forEach((box) => {
        if (box.shipping_transporter == "ELS" && box.delivery_date) {
            e++;
        }
    });

    if (e > 0 && !state.order.has_varying_deliveries) {
        e = 1;
    }

    if (i > 0) {
        if (state.order.status.label != "Kan in productie") {
            Eventing.fire(
                "toasting",
                "Er zijn " +
                    i +
                    " onderdelen nog niet afgevinkt van de Pakbon. Doe dit eerst voor er een volgende status kan worden geactvieerd.",
                "error"
            );
            return;
        }
    }

    if (status == "Transport") {
        // return Eventing.fire(
        //     "toasting",
        //     "Op Transport zetten is nog niet mogelijk. Overleggen wanneer en hoe dit moet gebeuren.",
        //     "error"
        // );

        if (e > 0) {
            await Swal.fire({
                text:
                    "Zeker weten dat je deze Order ook " +
                    e +
                    " keer naar ELS wilt sturen?",
                confirmButtonText: "Ja",
                cancelButtonText: "Nee, annuleer",
                showCancelButton: true,
                showCloseButton: true,
                icon: "warning",
            }).then(async (result) => {
                if (!result.isConfirmed) {
                    window.location.reload();
                }
            });
        }
    }

    if (status == "Colli") {
        await order.setColli(route.params.hash);
        return;
    }

    Swal.fire({
        text:
            'Zeker weten dat de status van order "' +
            state.order.reference +
            '" wilt wijzigen naar "' +
            status +
            '"?',
        confirmButtonText: "Akkoord",
        showCloseButton: true,
        icon: "warning",
    }).then(async (result) => {
        if (result.isConfirmed) {
            await order.updateStatus(route.params.hash, {
                status: status,
            });
            if (status == "Transport" && e > 0) {
                Eventing.fire(
                    "toasting",
                    "Er wordt een melding gestuurd naar ELS voor " +
                        e +
                        " bezorging(en)",
                    "info"
                );
            }
        }
    });
}

function printStickers(box_options) {
    order.printStickers(route.params.hash, box_options);
}

function printCrates() {
    order.printCrates(route.params.hash);
}

function picked(list) {
    let i = 0;
    list.forEach((item) => {
        if (item.is_picked) {
            i++;
        }
    });
    return i + "/" + list.length;
}

async function togglePicker(db, is_picked) {
    await order.update(route.params.hash, {
        model: "addition",
        id: db.id,
        is_picked: !is_picked,
    });
}
</script>
