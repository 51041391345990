<template>
    <section class="c-production-header">
        <div class="c-production-header__left">
            <router-link
                :to="{ name: 'production.scan' }"
                class="c-production-header__logo"
            >
                <SvgFeelingz />
            </router-link>
        </div>
        <div class="c-production-header__right">
            <div class="c-production-header__user">
                <p class="c-production-header__user-name">{{ user.name() }}</p>

                <a
                    @click="user.logout()"
                    class="c-production-header__user-log-out"
                >
                    Uitloggen
                </a>
            </div>
        </div>
    </section>
</template>

<script setup>
import SvgFeelingz from "@/svg/feelingz.svg";
import SvgNotifications from "@/svg/notifications.svg";
import { onMounted, ref, reactive, inject } from "vue";
import { useAuthStore } from "@/store/auth";
const user = useAuthStore();
</script>
