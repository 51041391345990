import Sortable from 'sortablejs';

const dragAreas = document.querySelectorAll('.js-drag-area');

//Create sortable
dragAreas.forEach(area => {
    var sortable = new Sortable(area, {
        group: 'shared',
        handle: ".js-drag-handle",
        direction: 'vertical',
        animation: 150,
        draggable: '.js-draggable',
    });
});