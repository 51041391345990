<template>
    <article>
        <div
            class="e-modal e-modal--w600 e-modal--align-start"
            modal-id="add-user"
        >
            <div class="e-modal__wrap">
                <div class="e-modal__modal">
                    <div class="e-modal__close js-close-modal">
                        <SvgCross @click="Modals.close()" />
                    </div>

                    <div class="e-modal__section">
                        <div class="e-modal__section-title">
                            <h2 class="e-modal__title">
                                Bezorging <span>toevoegen</span>
                            </h2>
                        </div>
                    </div>

                    <!-- <validation-errors :errors="deliveryarea.errors" /> -->
                    <form
                        method="POST"
                        @submit.prevent="onSubmit"
                        @keydown="deliveryarea.errors.errors = {}"
                        novalidate
                    >
                        <div class="e-modal__section">
                            <div class="e-form">
                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            deliveryarea.errors.message &&
                                            deliveryarea.errors.errors
                                                .postcode_from,
                                    }"
                                    :invalid-message="
                                        deliveryarea.errors.errors.postcode_from
                                    "
                                >
                                    <label class="e-form__label"
                                        >Postcode van</label
                                    >
                                    <input
                                        class="e-form__input"
                                        type="text"
                                        v-model="state.form.postcode_from"
                                    />
                                </div>

                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            deliveryarea.errors.message &&
                                            deliveryarea.errors.errors
                                                .postcode_to,
                                    }"
                                    :invalid-message="
                                        deliveryarea.errors.errors.postcode_to
                                    "
                                >
                                    <label class="e-form__label"
                                        >Postcode tot</label
                                    >
                                    <input
                                        class="e-form__input"
                                        type="text"
                                        v-model="state.form.postcode_to"
                                    />
                                </div>

                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            deliveryarea.errors.message &&
                                            deliveryarea.errors.errors.date,
                                    }"
                                    :invalid-message="
                                        deliveryarea.errors.errors.date
                                    "
                                >
                                    <label class="e-form__label">Datum</label>
                                    <input
                                        class="e-form__input"
                                        type="date"
                                        v-model="state.form.date"
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            class="e-modal__section e-modal__section--center-text"
                        >
                            <button
                                class="e-button e-button--full-width e-button--green js-close-modal"
                            >
                                Toevoegen
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div
                class="e-modal__bg js-close-modal"
                @click="Modals.close()"
            ></div>
        </div>
    </article>
</template>

<script setup>
import SvgArrowLeft from "@/svg/arrow-left.svg";
import SvgCheck from "@/svg/check.svg";

import SvgCross from "@/svg/cross.svg";
import { onMounted, ref, inject, reactive } from "vue";

import { useDeliveryAreaStore } from "@/store/delivery-areas";
import Swal from "sweetalert2";

const deliveryarea = useDeliveryAreaStore();

const Modals = inject("Modals");

const state = reactive({
    form: {},
});

onMounted(() => {
    deliveryarea.errors.errors = { message: null, errors: [] };
});

function onSubmit() {
    deliveryarea.store(state.form);
}
</script>
