<template>
    <article>
        <div class="c-page-content">
            <div class="c-page-content__left e-load-items-animation">
                <!-- @include('frontend.partials.order.tabs.contact.chat') -->

                <section class="c-page-content__section e-module-style">
                    <div class="e-section-heading">
                        <div class="e-section-heading__left">
                            <h2
                                class="e-section-heading__name"
                                v-if="personsInChat"
                            >
                                Communicatie tussen
                                {{ personsInChat }}
                            </h2>
                            <h2
                                class="e-section-heading__name"
                                v-if="!personsInChat"
                            >
                                Communicatie
                            </h2>
                        </div>
                    </div>
                    <div class="c-chat">
                        <div
                            class="e-hide-scrollbar e-hide-scrollbar--top-shadow"
                        >
                            <div
                                class="c-chat__scroll-area e-hide-scrollbar__inner"
                                ref="container"
                            >
                                <div
                                    class="c-chat__messages"
                                    v-if="order.nested['messages']"
                                >
                                    <div
                                        class="c-chat__message"
                                        v-for="(message, index) in order.nested[
                                            'messages'
                                        ].data"
                                        :key="index"
                                        :class="{
                                            'c-chat__message--client':
                                                message.sender.role ==
                                                'contact',
                                            'c-chat__message--note':
                                                message.type == 'Notitie',
                                        }"
                                    >
                                        <div class="c-chat__message-inner-wrap">
                                            <div class="c-chat__message-head">
                                                <div
                                                    class="c-chat__message-name"
                                                >
                                                    <img
                                                        class="c-chat__message-user-image"
                                                        v-if="
                                                            message.sender
                                                                .avatar
                                                        "
                                                        :src="
                                                            message.sender
                                                                .avatar ??
                                                            '/svg/user-image.svg'
                                                        "
                                                    />
                                                    <SvgUserImage
                                                        class="c-chat__message-user-image"
                                                        v-if="
                                                            !message.sender
                                                                .avatar
                                                        "
                                                    ></SvgUserImage>

                                                    {{ message.sender.name }}

                                                    <span
                                                        class="c-chat__message-source"
                                                        v-if="
                                                            message.type ==
                                                            'Chat'
                                                        "
                                                    >
                                                        via
                                                        {{ message.type }}</span
                                                    >
                                                    <span
                                                        class="c-chat__message-source"
                                                        v-if="
                                                            message.type ==
                                                                'Notitie' &&
                                                            !message.receiver
                                                        "
                                                    >
                                                        Notitie
                                                    </span>
                                                    <span
                                                        class="c-chat__message-source"
                                                        v-if="
                                                            message.type ==
                                                                'Notitie' &&
                                                            message.receiver
                                                        "
                                                    >
                                                        Notitie voor
                                                        {{ message.receiver }}
                                                    </span>
                                                </div>
                                                <div
                                                    class="c-chat__message-date"
                                                >
                                                    {{ message.created_at }}
                                                </div>
                                            </div>
                                            <div class="c-chat__message-text">
                                                <p v-html="message.message"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="c-chat__send-message">
                            <form
                                method="POST"
                                @submit.prevent="onSubmit"
                                @keydown="order.errors.errors = {}"
                                novalidate
                                v-if="state.form"
                            >
                                <div
                                    class="e-form__field-wrap e-form__field-wrap--checkboxes"
                                >
                                    <div class="e-form__multiple-radios">
                                        <label class="e-form__checkbox-wrap">
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                name="message-type"
                                                v-model="state.form.is_note"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Notitie (intern)</span
                                            >
                                        </label>
                                    </div>

                                    <div
                                        class="e-form__multiple-radios"
                                        v-if="state.form.is_note"
                                    >
                                        <div
                                            class="e-form__checkbox-wrap e-form__checkbox-wrap--text-only"
                                        >
                                            <p class="e-form__checkbox-text">
                                                Alleen voor:
                                            </p>
                                        </div>

                                        <label
                                            class="e-form__checkbox-wrap"
                                            v-for="(option, value) in enums
                                                .options['user-roles']"
                                            :key="value"
                                        >
                                            <input
                                                class="e-form__checkbox"
                                                :value="option"
                                                type="checkbox"
                                                name="message-type"
                                                v-model="state.form.receiver"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span
                                                class="e-form__checkbox-text"
                                                >{{ option }}</span
                                            >
                                        </label>
                                    </div>
                                </div>

                                <div
                                    class="e-form__field-wrap"
                                    no-class="{
                                        'e-form__field-wrap--invalid':
                                            Object.keys(order.errors.message)
                                                .length == 0,
                                    }"
                                >
                                    <textarea
                                        class="c-chat__textarea e-form__textarea"
                                        placeholder="Typ een bericht..."
                                        v-model="state.form.message"
                                        autocomplete="off"
                                    ></textarea>
                                </div>

                                <div class="e-form__field-wrap mt-10">
                                    <button
                                        class="e-button e-button--green e-button--full-width"
                                        type="submit"
                                    >
                                        Versturen
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
            <div class="c-page-content__sidebar e-load-items-animation">
                <!-- @include('frontend.partials.order.tabs.contact.data') -->

                <div
                    class="c-page-content__sidebar-section e-module-style"
                    v-if="state.order.webhook"
                >
                    <h2 class="c-page-content__sidebar-section-title">
                        Import info
                    </h2>
                    <ul class="c-data-list c-data-list--table-style">
                        <li
                            v-for="(value, key) in state.order.webhook"
                            :key="value"
                            class="c-data-list__item e-tooltip"
                            :data-tooltip="value"
                        >
                            <p class="c-data-list__subject">{{ key }}</p>
                            <p class="c-data-list__data">
                                {{ value }}
                            </p>
                        </li>
                    </ul>
                </div>

                <div class="c-page-content__sidebar-section e-module-style">
                    <h2 class="c-page-content__sidebar-section-title">
                        Trafficmanager
                    </h2>
                    <p class="c-page-content__sidebar-section-desc">
                        E-mails die verstuurd worden vanuit het systeem bevatten
                        de handtekening van deze trafficmanager.
                    </p>

                    <div class="e-form__select-wrap">
                        <select
                            class="e-form__select"
                            v-model="state.order.user.id"
                            required
                        >
                            <option
                                v-for="(option, value) in options.options[
                                    'user-trafficmanagers'
                                ]"
                                v-bind:value="value"
                                :key="value"
                            >
                                {{ option }}
                            </option>
                        </select>
                    </div>
                    <div class="e-form__field-wrap mt-10">
                        <button
                            class="e-button e-button--full-width e-button--green"
                            @click="updateTrafficmanager()"
                        >
                            Opslaan
                        </button>
                    </div>
                </div>

                <div class="c-page-content__sidebar-section e-module-style">
                    <h2 class="c-page-content__sidebar-section-title">
                        E-mailcommunicatie
                    </h2>
                    <p class="c-page-content__sidebar-section-desc">
                        Handmatig versturen van een voorgedefiniëerde e-mail.
                    </p>

                    <div class="e-form__select-wrap">
                        <select
                            class="e-form__select"
                            v-model="state.manual_email"
                        >
                            <option
                                v-for="(option, value) in options.options[
                                    'email-list'
                                ]"
                                v-bind:value="value"
                                :key="value"
                            >
                                Onderwerp: {{ option }}
                            </option>
                        </select>
                    </div>
                    <div class="e-form__field-wrap mt-10">
                        <button
                            class="e-button e-button--full-width e-button--green"
                            @click="manualSendMail()"
                        >
                            Verstuur
                        </button>
                    </div>
                </div>

                <div
                    class="c-page-content__sidebar-section e-module-style"
                    v-if="state.order.sold_by"
                >
                    <h2 class="c-page-content__sidebar-section-title">
                        Feelingz sales medewerker
                    </h2>
                    <ul class="c-data-list c-data-list--table-style">
                        <li
                            class="c-data-list__item e-tooltip"
                            :data-tooltip="state.order.sold_by"
                        >
                            <p class="c-data-list__subject">Naam</p>
                            <p class="c-data-list__data">
                                {{ state.order.sold_by }}
                            </p>
                        </li>
                    </ul>
                </div>

                <div
                    class="c-page-content__sidebar-section e-module-style"
                    v-if="state.order.contact"
                >
                    <h2 class="c-page-content__sidebar-section-title">
                        Contactpersoon
                    </h2>
                    <ul class="c-data-list c-data-list--table-style">
                        <li
                            class="c-data-list__item e-tooltip"
                            :data-tooltip="state.order.contact.contactperson"
                        >
                            <p class="c-data-list__subject">Naam</p>
                            <p class="c-data-list__data">
                                {{ state.order.contact.contactperson }}
                            </p>
                        </li>
                        <li
                            class="c-data-list__item e-tooltip"
                            :data-tooltip="state.order.contact.email"
                        >
                            <p class="c-data-list__subject">E-mail</p>
                            <p class="c-data-list__data">
                                {{ state.order.contact.email }}
                            </p>
                        </li>
                        <li
                            class="c-data-list__item e-tooltip"
                            :data-tooltip="state.order.contact.phone"
                        >
                            <p class="c-data-list__subject">Tel.</p>
                            <p class="c-data-list__data">
                                {{ state.order.contact.phone }}
                            </p>
                        </li>
                    </ul>
                </div>

                <div class="c-page-content__sidebar-section e-module-style">
                    <h2 class="c-page-content__sidebar-section-title">
                        Betaalgegevens
                    </h2>
                    <ul class="c-data-list c-data-list--table-style">
                        <li
                            class="c-data-list__item e-tooltip"
                            :data-tooltip="state.order.contact.invoice_email"
                        >
                            <p class="c-data-list__subject">E-mail</p>
                            <p class="c-data-list__data">
                                {{ state.order.contact.invoice_email }}
                            </p>
                        </li>
                        <li
                            class="c-data-list__item e-tooltip"
                            :data-tooltip="state.order.contact.btw_id"
                        >
                            <p class="c-data-list__subject">BTW-id</p>
                            <p class="c-data-list__data">
                                {{ state.order.contact.btw_id }}
                            </p>
                        </li>
                    </ul>
                </div>

                <div class="c-page-content__sidebar-section e-module-style">
                    <h2 class="c-page-content__sidebar-section-title">
                        Bedrijfsgegevens
                    </h2>
                    <ul class="c-data-list c-data-list--table-style">
                        <li
                            class="c-data-list__item e-tooltip"
                            :data-tooltip="state.order.contact.company"
                        >
                            <p class="c-data-list__subject">Bedrijfsnaam</p>
                            <p class="c-data-list__data">
                                {{ state.order.contact.company }}
                            </p>
                        </li>
                        <li
                            class="c-data-list__item e-tooltip"
                            :data-tooltip="state.order.contact.address.street"
                        >
                            <p class="c-data-list__subject">Adres</p>
                            <p class="c-data-list__data">
                                {{ state.order.contact.address.street }}
                            </p>
                        </li>
                        <li
                            class="c-data-list__item e-tooltip"
                            :data-tooltip="state.order.contact.address.postcode"
                        >
                            <p class="c-data-list__subject">Postcode</p>
                            <p class="c-data-list__data">
                                {{ state.order.contact.address.postcode }}
                            </p>
                        </li>
                        <li
                            class="c-data-list__item e-tooltip"
                            :data-tooltip="state.order.contact.address.city"
                        >
                            <p class="c-data-list__subject">Plaats</p>
                            <p class="c-data-list__data">
                                {{ state.order.contact.address.city }}
                            </p>
                        </li>
                        <li
                            class="c-data-list__item e-tooltip"
                            :data-tooltip="state.order.contact.kvk"
                        >
                            <p class="c-data-list__subject">KVK</p>
                            <p class="c-data-list__data">
                                {{ state.order.contact.kvk }}
                            </p>
                        </li>
                        <li
                            class="c-data-list__item e-tooltip"
                            :data-tooltip="state.order.contact.code"
                        >
                            <p class="c-data-list__subject">Exact</p>
                            <p class="c-data-list__data">
                                {{ state.order.contact.code }}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </article>
</template>

<script setup>
import { useOptionStore } from "@/store/option";
import { useOrderStore } from "@/store/order";
import { computed, inject, nextTick, onMounted, reactive, ref } from "vue";

import { useEnumStore } from "@/store/enum";
import SvgUserImage from "@/svg/user-image.svg";
import { useRoute } from "vue-router";

const Modals = inject("Modals");
const order = useOrderStore();

const route = useRoute();

const options = useOptionStore();
const enums = useEnumStore();

const chatbox = ref(null);

const state = reactive({
    order: {
        contact: {
            address: {},
        },
        followers: {},
        user: {
            id: null,
        },
        source: null,
    },
    form: {
        message: null,
        type: "Chat",
        is_note: false,
        receiver: [],
    },
    manual_email: null,
});

const personsInChat = computed(() => {
    const persons = [];
    if (order.nested["messages"]) {
        order.nested["messages"].data.map(function (msg, key) {
            persons.push(msg.sender.name);
        });
    }
    return [...new Set(persons)].join(", ");
});

onMounted(async () => {
    await nextTick();

    Eventing.listen("contactIsUpdated", () => {
        order.show(route.params.hash);
    });

    Eventing.listen("fillOrder", (data) => {
        state.order = data.data;
    });
    Eventing.listen("fillNested", (data) => {
        //
    });
    Eventing.listen("storedNested", (data) => {
        order.fillNested(route.params.hash, "messages");
    });

    Eventing.listen("newChat", (data) => {
        order.nested["messages"].data.push(data);
    });

    loadItems();
});

function loadItems() {
    options.fill("user-trafficmanagers");
    options.fill("email-list");

    enums.fill("user-roles");

    order.show(route.params.hash);

    order.fillNested(route.params.hash, "messages");
}

function onSubmit() {
    if (state.form.is_note) {
        state.form.type = "Notitie";
    }
    order.storeNested(route.params.hash, "messages", state.form);
    state.form.message = null;
    state.form.receiver = [];
}

function updateTrafficmanager() {
    order.switchTrafficmanager(state.order.hash, state.order.user.id);
}

function updateSource() {
    order.switchSource(state.order.hash, state.order.source);
}

function manualSendMail() {
    Eventing.fire(
        "toasting",
        "E-mail wordt verstuurd als deze aan de condities voldoet"
    );
    order.sendManualEmail(state.order.hash, state.manual_email);
    setTimeout(() => {
        state.manual_email = null;
    }, 3000);
}
</script>
