import { useAuthStore } from "@/store/auth";
import { defineStore } from "pinia";

export const useUploadStore = defineStore({
    id: "uploads",
    state: () => ({
        errors: {
            message: null,
            errors: [],
        },
    }),
    actions: {
        api() {
            return "/api/upload/";
        },
        auth() {
            const user = useAuthStore();
            return { headers: { Authorization: "Bearer " + user.token() } };
        },
        async store(what, form) {
            try {
                let result = await axios.put(
                    this.api() + what,
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    // this.added(result);
                    return result.status;
                }
            } catch (error) {
                this.fail(error);
                return "error";
            }
        },
        fail(error) {
            console.error(error);
            this.$patch({
                errors: error.response.data,
            });
            Eventing.fire("error", error.response);
        },
    },
});
