<template>
    <div
        class="c-order-tile list-group-item"
        v-if="props.order"
        @click="checkIfMovable()"
        :class="{
            'not-movalbe': props.order.status_traffic == 'Kan in productie',
        }"
    >
        <div class="c-order-tile__icons">
            <div
                class="c-order-tile__icon e-icon--speed e-tooltip e-tooltip--left"
                data-tooltip="Spoed"
                v-if="props.order.is_urgent"
            >
                <SvgSpeed />
            </div>
            <div
                class="c-order-tile__icon e-icon--maatwerk e-tooltip e-tooltip--left"
                data-tooltip="Maatwerk"
                v-if="props.order.is_maatwerk"
            >
                <SvgMaatwerk />
            </div>
            <div
                class="c-order-tile__icon e-icon--mailing e-tooltip e-tooltip--left"
                data-tooltip="Heeft mailing product"
                v-if="props.order.is_mailing"
            >
                <SvgMail />
            </div>
        </div>
        <div class="c-order-tile__heading">
            <h2 class="c-order-tile__name">
                {{ props.order.contact.company }}
            </h2>

            <router-link
                :to="{
                    name: 'app.order',
                    params: { hash: props.order.hash },
                }"
                class="c-order-tile__number c-order-tile__number--link"
                >{{ props.order.reference }}
            </router-link>
            {{ props.order.status }}
        </div>
        <div class="c-order-tile__footer">
            <p class="c-order-tile__data" v-if="props.order.has_shop">
                <span class="c-order-tile__data-subject"
                    ><SvgCheck />Shop aangemaakt</span
                >
            </p>
            <p class="c-order-tile__data" v-if="props.order.has_bank">
                <span class="c-order-tile__data-subject"
                    ><SvgCheck />Bank aangemaakt</span
                >
            </p>
            <p
                class="c-order-tile__data"
                v-if="props.order.has_bank && props.order.has_collected"
            >
                <span class="c-order-tile__data-subject"
                    ><SvgCheck />Opgewaardeerd</span
                >
            </p>
            <p class="c-order-tile__data" v-if="props.order.amount">
                <span class="c-order-tile__data-subject">Aantal</span>
                <span class="c-order-tile__data-output">{{
                    props.order.amount
                }}</span>
            </p>
            <p
                class="c-order-tile__data e-tooltip e-tooltip--bottom"
                v-if="props.order.runs"
                :data-tooltip="props.order.runs.tooltip"
            >
                <span class="c-order-tile__data-subject">Runs</span>
                <span class="c-order-tile__data-output">{{
                    props.order.runs.label
                }}</span>
            </p>

            <p
                class="c-order-tile__data"
                v-if="
                    props.order.delivered_attachments &&
                    props.order.delivered_attachments?.total > 0
                "
            >
                <span class="c-order-tile__data-subject">Aangeleverd</span>
                <span class="c-order-tile__data-output"
                    >{{ props.order.delivered_attachments.completed }}/{{
                        props.order.delivered_attachments.total
                    }}</span
                >
            </p>

            <p
                class="c-order-tile__data"
                no-v-if="
                    (props.order.delivery_completed == true ||
                        props.order.delivery_completed == false) &&
                    props.order.delivery_completed != null
                "
            >
                <span class="c-order-tile__data-subject"
                    >Bezorging compleet</span
                >
                <span class="c-order-tile__data-output">{{
                    props.order.delivery_completed ? "Ja" : "Nee"
                }}</span>
            </p>

            <p class="c-order-tile__data" v-if="props.order.deliver_deadline">
                <span class="c-order-tile__data-subject"
                    >Aanlever deadline:</span
                >

                <span
                    class="c-order-tile__data-output"
                    :class="{
                        'c-order-tile__data-output--alert':
                            props.order.deliver_deadline_expired,
                    }"
                    >{{ props.order.deliver_deadline }}</span
                >
            </p>

            <p class="c-order-tile__data" v-if="props.order.delivery_date">
                <span class="c-order-tile__data-subject">Leverdatum</span>
                <span class="c-order-tile__data-output">{{
                    props.order.delivery_date
                }}</span>
            </p>

            <p
                class="c-order-tile__data e-tooltip e-tooltip--bottom"
                v-if="props.order.trafficmanager_name"
                :data-tooltip="props.order.trafficmanager_name"

            >
                <span class="c-order-tile__data-subject"
                    >Trafficmanager:</span
                >
                <span
                    class="c-order-tile__data-output">
                    <div 
                        class="c-followers"
                    >
                        <div
                            
                            class="c-followers__user"
                        >
                            <img class="c-followers__user-image" :src="props.order.trafficmanager_photo"/> 

                            <div
                                class="c-followers__user-status"
                            ></div>
                        </div>
                    </div>
                </span>
            </p>
        </div>
    </div>
</template>
<style scoped>
/* @W: KIP mag niet meer worden gesleept vanaf hier, dat moet je via de Order doen */
.not-movalbe:hover {
    cursor: not-allowed;
    background-color: rgba(255, 99, 71, 0.084);
}
</style>
<script setup>
import SvgCheck from "@/svg/check.svg";
import SvgMaatwerk from "@/svg/maatwerk.svg";
import SvgMail from "@/svg/mail.svg";
import SvgSpeed from "@/svg/speed.svg";
import { onMounted } from "vue";

const props = defineProps({
    order: Object,
});

onMounted(() => {
    Eventing.fire("toggleSideOverlay");
});

function checkIfMovable() {
    if (
        props.order.traffic_status == "Kan in productie" ||
        props.order.status == "Kan in productie"
    ) {
        Eventing.fire("error", "KIP kan niet worden teruggesleept");
    }
}
</script>
