<template>
    <article>
        <div class="c-production-scan">
            <StreamBarcodeReader
                v-if="state.openScanner"
                @decode="onDecode"
                @loaded="onLoaded"
            ></StreamBarcodeReader>

            <div
                class="c-production-scan__inner"
                @click="state.openScanner = true"
                v-if="!state.openScanner"
            >
                <div class="c-production-scan__qr-code">
                    <div class="c-production-scan__animation">
                        <SvgQr />
                    </div>
                </div>
                <a class="e-button e-button--grey-dark">Scan QR Code</a>
            </div>
        </div>

        <div class="c-production-footer">
            <router-link
                class="e-button e-button--green e-button--full-width"
                :to="{ name: 'production.overview' }"
            >
                Overzicht
            </router-link>
        </div>
    </article>
</template>

<script setup>
import SvgQr from "@/svg/qr.svg";
import { onMounted, ref, inject, reactive } from "vue";
import { StreamBarcodeReader } from "vue-barcode-reader";
import { useOrderStore } from "@/store/production/order";

const state = reactive({
    openScanner: false,
});
const order = useOrderStore();

function onDecode(result) {
    if (result) {
        try {
            order.handleQR(result);
        } catch (error) {
            console.warn(error);
            Eventing.fire("toasting", "QR-code error", "error");
        }
        setTimeout(() => {
            state.openScanner = false;
        }, 2000);
    }
}

function onLoaded() {
    //
}
</script>
