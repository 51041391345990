<template>

    <apexchart 
        width="100%" 
        height="100%" 
        :series="props.series"
        :options="chart.options"
    >
    </apexchart>
        
</template>

<script setup>
import { inject, onMounted, reactive } from "vue";

const props = defineProps({
    series: Array,
    colors: Array,
    yaxisMax: Number,
    tooltipYFormatter: '',
});

const chart = reactive({
    options: {
        colors: props.colors,
        chart: {
            type: 'bar',
            stacked: true,
            sparkline: {
              enabled: true,
            },
            animations: {
                enabled: false,
            },
            background: '#f0f0f0',
        },
        stroke: {
            show: true,
            width: 0,
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '100%',
            },
        },
        dataLabels: {
            enabled: true,
            offsetX: 0,
            offsetY: -2,
            background: {
                borderWidth: 0,
            },
        },
        yaxis: {
            max: props.yaxisMax,
        },
        tooltip: {
            shared: true,
            intersect: false,
            x: {
                show: false
            },
            y: {
                formatter: props.tooltipYFormatter,
            },
        },
    },

});

</script>