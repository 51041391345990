<template>
    <div class="c-order-tile js-open-side-overlay" v-if="props.order">
        <div class="c-order-tile__icons">
            <div
                class="c-order-tile__icon e-icon--speed e-tooltip e-tooltip--left"
                data-tooltip="Spoed"
                v-if="props.order.is_urgent"
            >
                <SvgSpeed />
            </div>
            <div
                class="c-order-tile__icon e-icon--maatwerk e-tooltip e-tooltip--left"
                data-tooltip="Maatwerk"
                v-if="props.order.is_maatwerk"
            >
                <SvgMaatwerk />
            </div>
            <div
                class="c-order-tile__icon e-icon--mailing e-tooltip e-tooltip--left"
                data-tooltip="Heeft mailing product"
                v-if="props.order.is_mailing"
            >
                <SvgMail />
            </div>

            <div
                class="c-order-tile__icon c-order-tile__icon--button c-order-tile__icon--button-edit e-tooltip e-tooltip--left"
                data-tooltip="Bewerken"
                v-if="!state.editModus"
                @click="doEdit()"
            >
                <SvgEdit />
            </div>
            <div
                class="c-order-tile__icon c-order-tile__icon--button c-order-tile__icon--button-save"
                v-if="state.editModus"
                @click="saveBasket()"
            >
                <SvgCheck />
            </div>
        </div>
        <div class="c-order-tile__heading">
            <p class="c-order-tile__subject e-label e-label--product">
                {{ props.order.product }}
            </p>
            <h2 class="c-order-tile__name">
                {{ props.order.contact.company }}
            </h2>
            <p class="c-order-tile__number">{{ props.order.reference }}</p>

            <p
                class="c-order-tile__number is-new-added dark"
                style="color: black"
            >
                {{ props.order.box }}
            </p>
            <!-- @W: Deze hierboven heb ik toegevoegd. Het kan namelijk zo zijn dat je Product X bij 1 Bestelling in 3 Samenstellingen hebt. Alleen kunnen die alle 3 verschillen. Dus je kunt ook per Samenstelling dan van dat Product langer wachten op de ene input dan de ander. Daarom mogelijkheid nu om echt op Product niveau per Samenstelling te slepen. Moet je alleen wel ergens de Samenstelling naam zichtbaar maken. -->
        </div>
        <div class="c-order-tile__footer">
            <div v-if="!state.editModus">
                <p class="c-order-tile__data">
                    <span class="c-order-tile__data-subject">Aantal:</span>
                    <span class="c-order-tile__data-output"
                        >{{ props.order.amount }}
                    </span>
                </p>

                <p
                    class="c-order-tile__data e-tooltip e-tooltip--bottom"
                    v-if="props.order.runs"
                    :data-tooltip="props.order.runs.tooltip"
                >
                    <span class="c-order-tile__data-subject">Runs:</span>
                    <span class="c-order-tile__data-output">{{
                        props.order.runs.label
                    }}</span>
                </p>

                <!-- 
                @W: indien runs onbekend, kun je er eventueel wat mee
                -->
                <p
                    class="c-order-tile__data e-tooltip e-tooltip--bottom"
                    v-if="!props.order.runs"
                >
                    <span class="c-order-tile__data-subject">Runs:</span>
                    <span
                        class="c-order-tile__data-output"
                        style="color: tomato"
                        >onbekend</span
                    >
                </p>
            </div>
            <div class="c-order-tile__footer-inner-wrap" v-if="state.editModus">
                <div class="c-order-tile__data mt-20">
                    <span class="c-order-tile__data-subject">Drukkerij:</span>
                    <div class="e-form__select-wrap">
                        <select
                            class="e-form__select e-form__select--small"
                            placeholder="Run"
                            v-model="state.form.run_drukkerij"
                        >
                            <option value="">Onbekend</option>
                            <option
                                class="e-form__input-list-item"
                                v-for="run in props.runs[0].items"
                                :key="run.id"
                                :value="run.id"
                            >
                                {{ run.name }} ({{ run.pretty_date }})
                            </option>
                        </select>
                    </div>
                </div>

                <div class="c-order-tile__data">
                    <span class="c-order-tile__data-subject">Magazijn:</span>
                    <div class="e-form__select-wrap">
                        <select
                            class="e-form__select e-form__select--small"
                            placeholder="Run"
                            v-model="state.form.run_magazijn"
                        >
                            <option value="">Onbekend</option>
                            <option
                                class="e-form__input-list-item"
                                v-for="run in props.runs[1].items"
                                :key="run.id"
                                :value="run.id"
                            >
                                {{ run.name }} ({{ run.pretty_date }})
                            </option>
                        </select>
                    </div>
                </div>
                <!-- 
                <div class="c-order-tile__data">
                    <button
                        class="e-button e-button--small mb-5 e-button--align-right"
                        @click="Modals.show('manage-runs')"
                    >
                        Beheer runs
                    </button>
                </div> 
                -->
            </div>
        </div>
    </div>
</template>

<style scoped>
.date-picker {
    border: none;
}
</style>

<script setup>
import { useOrderStore } from "@/store/order";
import SvgCheck from "@/svg/check.svg";
import SvgEdit from "@/svg/edit.svg";
import SvgMaatwerk from "@/svg/maatwerk.svg";
import SvgMail from "@/svg/mail.svg";
import SvgSpeed from "@/svg/speed.svg";
import { onMounted, reactive } from "vue";

const order = useOrderStore();

const props = defineProps({
    order: Object,
    runs: Object,
    order_hash: String,
});

const state = reactive({
    editModus: false,
    delivery_date: null,
    form: {
        run_drukkerij: "",
        run_magazijn: "",
    },
});

onMounted(() => {
    if (props.item) {
        state.delivery_date = props.item.delivery_date;
    }
    Eventing.fire("toggleSideOverlay");

    if (props.order.run_drukkerij) {
        state.form.run_drukkerij = props.order.run_drukkerij.id;
    }
    if (props.order.run_magazijn) {
        state.form.run_magazijn = props.order.run_magazijn.id;
    }
});

function doEdit() {
    state.editModus = true;
}

async function saveBasket() {
    let data = await order.deliveryDateMaatwerk(
        props.order.order_hash,
        props.order.id,
        state.form
    );
    if (data) {
        Eventing.fire("reloadItems");
        state.editModus = false;
    }
}
</script>
