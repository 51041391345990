<template>
    <article>
        <div class="c-page-content">
            <div
                class="c-page-content__container c-page-content__container--spacing c-page-content__container--no-pt c-page-content__container--no-pb"
            >
                <div class="c-status-columns" v-if="state.results">
                    <div class="c-status-columns__columns">
                        <div
                            class="c-status-columns__column"
                            v-for="(result, value) in state.results"
                            :key="value"
                        >
                            <div class="c-status-columns__column-heading">
                                <h2>
                                    {{ result.status }}
                                    <span
                                        class="c-status-columns__column-count e-label e-label--medium e-label--green"
                                        >{{ result.items.length }}</span
                                    >
                                </h2>
                            </div>

                            <div class="c-status-columns__items">
                                <draggable
                                    class="list-group drop-area"
                                    :list="result.items"
                                    group="products"
                                    :sort="false"
                                    @end="saveSortings"
                                    itemKey="name"
                                    filter=".c-order-tile__icon--button, .e-button, .date-picker, input"
                                    preventOnFilter="false"
                                >
                                    <template #item="{ element, index }">
                                        <div class="list-group-item">
                                            <maatwerk-tile
                                                noclick="toggleOrder(element)"
                                                :index="index"
                                                :test="element.id.toString()"
                                                :item="element"
                                                :order="element"
                                                :status="result.status"
                                                :order_hash="state.order_hash"
                                                :runs="state.runs"
                                            ></maatwerk-tile>
                                        </div>
                                    </template>
                                </draggable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </article>
</template>

<script setup>
import { useDashboardDesignStore } from "@/store/dashboard/design";
import { useDashboardRunsStore } from "@/store/dashboard/runs";
import { useOrderStore } from "@/store/order";
import { inject, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
import draggable from "vuedraggable";
const Modals = inject("Modals");
const order = useOrderStore();

const design = useDashboardDesignStore();
const runs = useDashboardRunsStore();

// https://sortablejs.github.io/vue.draggable.next/#/two-lists

const state = reactive({
    maatwerk: null,
    order_hash: null,
    results: null,
    url: "/api/dashboards/design/orders?q",
    order: null,
    runs: null,
});

function log(evt) {
    window.console.log(evt);
}

async function loadRuns() {
    let fill = await runs.fill();
    if (fill) {
        state.runs = fill;
    }
}
loadRuns();

async function loadingResults() {
    Eventing.fire("clearSideOverlay");
    state.url = "/api/dashboards/design/orders?q&single=" + state.order_hash;
    state.order = null;
    let results = await design.fill(state.url, state.filter);
    if (results) {
        state.results = results;
    }
}

async function saveSortings() {
    let data = await design.saveStatus({
        statusses: state.results,
    });
    if (data) {
        loadingResults();
    }
}
onMounted(() => {
    state.order_hash = useRoute().params.hash;

    loadingResults();

    Eventing.listen("reloadItems", () => {
        loadingResults();
    });
});
</script>
