<template>
    <div class="e-modal e-modal--w600" modal-id="order-is-ready">
        <div class="e-modal__wrap">
            <div class="e-modal__modal">
                <div class="e-modal__close js-close-modal">
                    <SvgCross @click="Modals.close()" />
                </div>

                <div class="e-modal__section">
                    <div class="e-modal__section-title">
                        <h2 class="e-modal__title">
                            Aantal <span>kratten</span>
                        </h2>
                    </div>
                </div>

                <div class="e-modal__section">
                    <div class="e-form">
                        <div
                            class="e-form__field-wrap e-form__field-wrap--align-center"
                        >
                            <input
                                class="e-form__input e-form__input--align-center"
                                type="number"
                                placeholder=""
                            />
                        </div>
                        <a
                            href="/frontend/productie/staat-klaar"
                            class="e-button e-button--green e-button--full-width"
                            >Staat klaar <small>Datum onbekend</small></a
                        >
                        <a
                            href="/frontend/productie/staat-klaar"
                            class="e-button e-button--grey-dark e-button--full-width mt-5"
                            >Staat klaar voor transport
                            <small>Datum bekend</small></a
                        >
                    </div>
                </div>
            </div>
        </div>

        <div class="e-modal__bg js-close-modal" @click="Modals.close()"></div>
    </div>
</template>

<script setup>
import { onMounted, ref, inject } from "vue";
const Modals = inject("Modals");
</script>
