<template>

    <apexchart 
        width="100%" 
        height="100%" 
        :series="JSON.parse(JSON.stringify(props.series))"
        :options='rangeChart.options'
    >
    </apexchart><!-- DE JSON IS EEN BUG FIX IN APEXCHARTS ICM TYPE RANGEBAR -->

</template>

<script setup>
import { inject, onMounted, reactive } from "vue";

const props = defineProps({
    series: Array,
    colors: {
      type: Array,
      default: ['#f88737', '#3dbce8', '#e570e4', '#e1c95d', '#e7415d'],
    },
    module_theme: "", //'green' for green styling
});

//Green module style
var themeOptions = {
    grid: {},
    stroke: {
      width: 1,
    },
    fill: {},
    bar: {
        barHeight: '100%',
    },
    legend: {
        labels: {},
        markers: {},
        fontWeight: {},
    },
    xaxis: {
        labels: {
            style: {},
        },
        axisBorder: {},
        axisTicks: {},
    },
};

if(props.module_theme == 'green') {

    themeOptions = {
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },   
            yaxis: {
                lines: {
                    show: false,
                }
            },
            borderColor: '#fff',
            row: {
                colors: ['rgba(255,255,255,1)'],
                opacity: 0.5,
            },  
            column: {
                colors: ['red'],
                opacity: 0.5
            },
        },
        bar: {
            barHeight: '70%',
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        fill: {},
        legend: {
            labels: {
                colors: ['#fff', '#fff', '#fff', '#fff'],
            },
            markers: {
                strokeWidth: 1,
                strokeColor: '#fff',
                offsetY: -1
            },
            fontWeight: 600,
        },
        xaxis: {
            labels: {
                style: {
                    fontSize: '13px',
                    colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
                    fontWeight: 500,
                },
            },
            axisBorder: {
                show: false,
                color: '#fff',
            },
            axisTicks: {
              show: true,
              color: '#fff',
            },
        },
    }
}

const rangeChart = reactive({
      options: {
        grid: themeOptions.grid,
        colors: props.colors,
        fill: themeOptions.fill,
        chart: {
          type: 'rangeBar',
          offsetY: -30,
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
            horizontal: true,
            columnWidth: '100%',
            barHeight: themeOptions.bar.barHeight,
          }
        },
        stroke: themeOptions.stroke,
        yaxis: {
            show: false,
        },
        xaxis: {
            type: 'datetime',
            labels: {
                style: themeOptions.xaxis.labels.style,
            },
            axisBorder: themeOptions.xaxis.axisBorder,
            axisTicks: themeOptions.xaxis.axisTicks,
        },
        legend: {
            position: 'top',
            offsetY: 15,
            labels: themeOptions.legend.labels,
            markers: themeOptions.legend.markers,
            fontWeight: themeOptions.legend.fontWeight,
            itemMargin: {
                  horizontal:10,
                  vertical: 10,
              },
        },
        tooltip: {
          custom: function({series, seriesIndex, dataPointIndex, w}) {
            var color = w.globals.colors[seriesIndex];
            var label = w.globals.labels[dataPointIndex];
            var name = w.globals.seriesNames[seriesIndex];
            var val = w.globals.series[seriesIndex][dataPointIndex];
            var date = new Date(val).toLocaleDateString('nl-NL', { weekday:"long", year:"numeric", month:"numeric", day:"numeric"});

            return '<div class="v-apexcharts-tooltip__wrap">' +
                        '<div class="v-apexcharts-tooltip__row">' +
                                '<div class="v-apexcharts-tooltip__col">' +
                                    '<span class="v-apexcharts-tooltip__marker" style="background-color: ' + color + ';"></span>' + 
                                '</div>' +
                                '<div class="v-apexcharts-tooltip__col">' +
                                    '<div class="v-apexcharts-tooltip__row">' +
                                        '<span class="v-apexcharts-tooltip__subject" style="color: ' + color + ';">' +
                                            name +
                                        '</span>' +
                                        '<span class="v-apexcharts-tooltip__subject"> | ' +
                                            label +
                                        '</span>' +
                                    '</div>' +
                                    '<div class="v-apexcharts-tooltip__row">' +
                                        '<span class="v-apexcharts-tooltip__output">' + 
                                            date + 
                                        '</span>' +
                                    '</div>' +
                                '</div>' +
                        '</div>' +
                    '</div>'
          }
        }
      },     
});

</script>