<template>
    <article>
        <div class="e-modal e-modal--align-start" modal-id="add-products">
            <div class="e-modal__wrap">
                <div class="e-modal__modal">
                    <div class="e-modal__close js-close-modal">
                        <SvgCross @click="Modals.close()" />
                    </div>

                    <div class="e-modal__section e-modal__section--green-style">
                        <div class="e-modal__section-title">
                            <h2 class="e-modal__title">
                                Producten toevoegen aan
                                <span>"{{ state.box.name }}"</span>
                            </h2>
                        </div>
                    </div>

                    <div
                        class="e-modal__section e-modal__section--green-style WEGGEHAALD-e-modal__section--spacing-bottom"
                    >
                        <!-- <div class="e-search-input">
                            <label
                                class="e-search-input__search-label"
                                for="table-search"
                            >
                                <SvgSearch />
                            </label>
                            <input
                                class="e-search-input__search-input e-form__input"
                                id="table-search"
                                type="search"
                                @input="debounceSearch"
                                autocomplete="false"
                                spellcheck="false"
                                placeholder="Zoeken door producten..."
                            />
                        </div> -->
                    </div>

                    <div
                        class="e-modal__section e-modal__section--no-spacing-sides e-modal__section--no-spacing-top"
                        v-if="state.isLoaded"
                    >
                        <div
                            v-if="
                                product.products.data &&
                                product.products.data.length == 0
                            "
                            class="e-note e-note--error"
                            style="display: block; margin: 20px 40px -10px"
                        >
                            <p>
                                <b
                                    >Er zijn geen beschikbare producten meer toe
                                    te voegen voor deze samenstelling.</b
                                ><br />Wil je toch wat extra's toevoegen? Voeg
                                dan een nieuwe Samenstelling toe of stuur ons
                                een bericht via de chat.
                            </p>
                        </div>

                        <div
                            class="c-table c-table--product-selection js-overflow-arrow"
                            v-if="
                                product.products.data &&
                                product.products.data.length > 0
                            "
                        >
                            <div class="c-table__head">
                                <div class="c-table-item c-table-item--head">
                                    <div class="c-table-item__col c-table-item__col--product-name">Product</div>
                                    <div class="c-table-item__col c-table-item__col--article-nr">Artikelnr.</div>
                                    <div class="c-table-item__col c-table-item__col--price">Prijs</div>
                                    <div class="c-table-item__col c-table-item__col--amount">Aantal</div>
                                    <div class="c-table-item__col c-table-item__col--button-big"></div>
                                </div>
                            </div>
                            <div class="c-table__body">
                                <client-products-adding-row
                                    v-for="product in product.products.data"
                                    :key="product.id"
                                    :product="product"
                                    :box="state.box.hash"
                                    :showcategory="
                                        Boolean(product.category)
                                    "
                                    :is-new-category="
                                        currently(product.category)
                                    "
                                ></client-products-adding-row>
                            </div>
                        </div>
                    </div>

                    <!-- <pagination
                        :items="product.products"
                        v-show="!state.search"
                        class="e-modal__pagination"
                    /> -->

                    <div class="e-modal__section e-modal__section--center-text">
                        <button
                            class="e-button e-button--full-width e-button--green js-close-modal"
                            @click="Modals.close()"
                        >
                            Sluiten
                        </button>
                    </div>
                </div>
            </div>

            <div
                class="e-modal__bg js-close-modal"
                @click="Modals.close()"
            ></div>
        </div>
    </article>
</template>

<script setup>
import { useProductStore } from "@/store/client/product";
import SvgArrowLeft from "@/svg/arrow-left.svg";
import SvgCheck from "@/svg/check.svg";
import SvgCross from "@/svg/cross.svg";
import SvgSearch from "@/svg/search.svg";

import { onUpdated, onMounted, reactive, inject } from "vue";
const Modals = inject("Modals");

const product = useProductStore();

const props = defineProps({
    path: {
        type: Object,
    },
});

console.warn(props);

const state = reactive({
    url: "/api/clients/products?q&box=" + props.path.hash + "&",
    search: null,
    debounce: 0,
    box: {},
    isLoaded: false,
});

let currentCategories = [];

onUpdated(() => {
    Eventing.fire("addOverflowArrows");
});

onMounted(() => {
    state.box = props.path;
    Eventing.listen("paginate", (url) => {
        state.url = url;
        product.fill(url);
    });

    Eventing.listen("isLoaded", () => {
        state.isLoaded = true;
    });
    currentCategories = [];
    product.fill("/api/clients/products", props.path.hash);
});

function debounceSearch(event) {
    clearTimeout(state.debounce);
    state.debounce = setTimeout(() => {
        state.search = event.target.value;
        if (state.search) {
            state.isLoaded = false;
            currentCategories = [];
            product.search(state.search);
        } else {
            product.fill();
        }
    }, 600);
}

// let currentCategories = [];
function currently(item) {
    if (item && item.slug) {
        if (currentCategories.includes(item.slug)) {
            return false;
        } else {
            currentCategories.push(item.slug);
            return true;
        }
    }
    return false;
}
</script>
