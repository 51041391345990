<template>
    <section class="c-client-header">
        <div class="c-client-header__content">
            <div class="c-client-header__container">
                <div class="c-client-header__text">
                    <h1 class="c-client-header__title">Bestelling #{{ state.order.reference }}</h1>
                </div>
            </div>
            <div class="e-hide-scrollbar e-hide-scrollbar--x">
                <div class="e-hide-scrollbar__inner">
                    <div
                        class="c-client-steps"
                        v-if="state.order && state.order.status"
                    >
                        <div
                            class="c-client-steps__step"
                            :class="{
                                'c-client-steps__step--current':
                                    state.order.status.label.includes(
                                        'In ontvangst'
                                    ),
                            }"
                        >
                            <div class="c-client-steps__icon">
                                <SvgReceived />
                            </div>
                            <div class="c-client-steps__check">
                                <SvgCheck />
                            </div>
                            <p class="c-client-steps__label">In ontvangst</p>
                        </div>
                        <div
                            class="c-client-steps__step"
                            :class="{
                                'c-client-steps__step--current':
                                    state.order.status.label.includes(
                                        'Kan in productie'
                                    ),
                            }"
                        >
                            <div class="c-client-steps__icon">
                                <SvgReadyForProduction />
                            </div>
                            <div class="c-client-steps__check">
                                <SvgCheck />
                            </div>
                            <p class="c-client-steps__label">
                                Kan in productie
                            </p>
                        </div>
                        <div
                            class="c-client-steps__step"
                            :class="{
                                'c-client-steps__step--current':
                                    state.order.status.label.includes(
                                        'In productie'
                                    ),
                            }"
                        >
                            <div class="c-client-steps__icon">
                                <SvgProduction />
                            </div>
                            <div class="c-client-steps__check">
                                <SvgCheck />
                            </div>
                            <p class="c-client-steps__label">In productie</p>
                        </div>
                        <div
                            class="c-client-steps__step"
                            :class="{
                                'c-client-steps__step--current':
                                    state.order.status.label.includes(
                                        'Klaar voor transport'
                                    ),
                            }"
                        >
                            <div class="c-client-steps__icon">
                                <SvgReadyForTransport />
                            </div>
                            <div class="c-client-steps__check">
                                <SvgCheck />
                            </div>
                            <p class="c-client-steps__label">
                                Klaar voor transport
                            </p>
                        </div>
                        <div
                            class="c-client-steps__step"
                            :class="{
                                'c-client-steps__step--current':
                                    state.order.status.label.includes(
                                        'Transport'
                                    ),
                            }"
                        >
                            <div class="c-client-steps__icon">
                                <SvgTransport />
                            </div>
                            <div class="c-client-steps__check">
                                <SvgCheck />
                            </div>
                            <p class="c-client-steps__label">Transport</p>
                        </div>
                        <div
                            class="c-client-steps__step"
                            :class="{
                                'c-client-steps__step--current':
                                    state.order.status.label.includes(
                                        'Afgeleverd'
                                    ),
                            }"
                        >
                            <div class="c-client-steps__icon">
                                <SvgDelivered />
                            </div>
                            <div class="c-client-steps__check">
                                <SvgCheck />
                            </div>
                            <p class="c-client-steps__label">Afgeleverd</p>
                        </div>
                    </div>
                    <div class="c-client-steps__bar"></div>
                </div>
            </div>
            <div class="c-client-header__container">
                <div
                    class="c-client-header__status-text"
                    v-if="state.order.status"
                >
                    <h2>
                        <span>Status - </span> {{ state.order.status.label }}.
                    </h2>

                    <p v-if="state.order.status.label.includes('In ontvangst')">
                        Maak de <b>bestelling compleet</b>, voeg de benodigde bestanden toe en kies jouw gewenste <b>verzendmethode</b>. Zodra deze stappen zijn afgerond zullen we de bestelling klaarzetten voor producie. Heb je vragen of help nodig? Onze <a
                            href="#/klant/communicatie"
                            @click="scrollToPageContent"
                            >trafficmanagers</a
                        > staan voor je klaar!
                    </p>

                    <p
                        v-if="
                            state.order.status.label.includes(
                                'Kan in productie'
                            )
                        "
                    >
                        Je kan de bestelling niet meer wijzigen. Wil je toch nog
                        een wijziging doorvoeren of heb je vragen over je
                        bestelling? Onze
                        <a
                            href="#/klant/communicatie"
                            @click="scrollToPageContent"
                            >trafficmanagers</a
                        >
                        staan voor je klaar!
                    </p>

                    <p v-if="state.order.status.label.includes('In productie')">
                        Je bestelling is in productie. Wil je een bijbestelling
                        plaatsen of heb je andere vragen over je bestelling?
                        Onze
                        <a
                            href="#/klant/communicatie"
                            @click="scrollToPageContent"
                            >trafficmanagers</a
                        >
                        staan voor je klaar!
                    </p>

                    <p
                        v-if="
                            state.order.status.label.includes(
                                'Klaar voor transport'
                            )
                        "
                    >
                        Je bestelling staat klaar voor transport. Wil je een
                        bijbestelling plaatsen of heb je andere vragen over je
                        bestelling? Onze
                        <a
                            href="#/klant/communicatie"
                            @click="scrollToPageContent"
                            >trafficmanagers</a
                        >
                        staan voor je klaar!
                    </p>

                    <p v-if="state.order.status.label.includes('Transport')">
                        Je bestelling is onderweg! Wil je een bijbestelling
                        plaatsen of heb je andere vragen over je bestelling?
                        Onze
                        <a
                            href="#/klant/communicatie"
                            @click="scrollToPageContent"
                            >trafficmanagers</a
                        >
                        staan voor je klaar!
                    </p>

                    <p v-if="state.order.status.label.includes('Afgeleverd')">
                        Woohoo, je bestelling is afgeleverd! We zijn benieuwd
                        naar je
                        <a
                            href="https://www.kiyoh.com/reviews/1062259/Feelingz"
                            target="_blank"
                            >ervaring</a
                        >.<br />
                        Wil je een bijbestelling plaatsen? Neem dan contact op
                        met onze
                        <a
                            href="#/klant/communicatie"
                            @click="scrollToPageContent"
                            >trafficmanagers</a
                        >
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import SvgChat from "@/svg/chat.svg";
import SvgCheck from "@/svg/check.svg";
import SvgFeelingz from "@/svg/feelingz.svg";
import SvgDelivered from "@/svg/illustration-delivered.svg";
import SvgProduction from "@/svg/illustration-production.svg";
import SvgReadyForProduction from "@/svg/illustration-ready-for-production.svg";
import SvgReadyForTransport from "@/svg/illustration-ready-for-transport.svg";
import SvgReceived from "@/svg/illustration-received.svg";
import SvgTransport from "@/svg/illustration-transport.svg";
import SvgKerstcadeaukiezen from "@/svg/kerstcadeaukiezen.svg";

import { useAuthClientStore } from "@/store/authClient";
import { useOrderStore } from "@/store/client/order";
import { onMounted, reactive } from "vue";

const user = useAuthClientStore();
const order = useOrderStore();


const state = reactive({
    auth: {
        name: user.name(),
    },
    order: {
        source: null,
        contact: {
            address: {},
        },
        followers: {},
        checks: {
            done: 0,
            total: 0,
            todo: 0,
            perc: 0,
        },
    },
});

onMounted(() => {

    order.show(user.order());

    
    Eventing.listen("fillOrder", (data) => {
        state.order = data.data;
        try {
            if (state.order.source && state.order.source.includes("Kerst")) {
                document.title = state.order.source;
            }
        } catch (error) {}
    });
});

function scrollToPageContent() {
    Eventing.fire("scrollContentIntoView");
}
</script>
