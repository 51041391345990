<template>
    <article>
        <div class="c-topbar">
            <div class="c-topbar__left">
                <div
                    class="c-topbar__left-button e-menu-button js-toggle-mobile-menu"
                    @click="menu('mobile')"
                >
                    <div class="e-menu-button__bars-wrap">
                        <div class="e-menu-button__bars"></div>
                    </div>
                </div>
                <div class="c-topbar__search">
                    <label class="c-topbar__search-label" for="table-search">
                        <SvgSearch />
                    </label>
                    <input
                        class="c-topbar__search-input"
                        id="table-search"
                        type="search"
                        @input="debounceSearch"
                        autocomplete="false"
                        spellcheck="false"
                        placeholder="Zoeken door contacten..."
                    />
                </div>
            </div>
            <div
                class="c-topbar__right-button e-menu-button js-toggle-topbar-menu"
                @click="menu('topbar')"
            >
                <div class="e-menu-button__bars-wrap">
                    <div class="e-menu-button__bars"></div>
                </div>
            </div>
            <div class="c-topbar__right">
                <a
                    class="e-button e-button--grey-dark"
                    @click="Modals.show('contact-create')"
                >
                    <SvgPlus />
                    Contact
                </a>
            </div>
        </div>

        <div class="c-page-content">
            <div class="c-page-content__container c-page-content__container--spacing-small">

                <div class="c-table js-overflow-arrow">
                    <div class="c-table__head js-sticky-element">
                        <div class="c-table-item c-table-item--head">
                            <div class="c-table-item__col c-table-item__col--icon">
                                <div class="c-table-item__icon">
                                    <SvgSort />
                                </div>
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--company c-table-item__col--sortable"
                                :class="{
                                    'c-table-item__col--sorted':
                                        state.filter == 'company',
                                    'c-table-item__col--sorted-desc':
                                        state.orderBy == 'desc' &&
                                        state.filter == 'company',
                                }"
                                @click="setFilter('company')"
                            >
                                Bedrijfsnaam
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--contact-person c-table-item__col--sortable"
                                :class="{
                                    'c-table-item__col--sorted':
                                        state.filter == 'contactperson',
                                    'c-table-item__col--sorted-desc':
                                        state.orderBy == 'desc' &&
                                        state.filter == 'contactperson',
                                }"
                                @click="setFilter('contactperson')"
                            >
                                Contactpersoon
                            </div>
                            <div class="c-table-item__col c-table-item__col--email">E-mail</div>
                            <div class="c-table-item__col c-table-item__col--phone">Telefoon</div>
                            <div
                                class="c-table-item__col c-table-item__col--orders-count c-table-item__col--sortable"
                                :class="{
                                    'c-table-item__col--sorted':
                                        state.filter == 'orders_count',
                                    'c-table-item__col--sorted-desc':
                                        state.orderBy == 'desc' &&
                                        state.filter == 'orders_count',
                                }"
                                @click="setFilter('orders_count')"
                            >
                                <span class="e-tooltip" data-tooltip="Aantal bestellingen">Aantal</span>
                            </div>
                            <div class="c-table-item__col c-table-item__col--button"></div>
                        </div>
                    </div>
                    <div class="c-table__body">
                        <div
                            class="c-table-item c-table-item--product-row"
                            open-modal-id="add-contact"
                            v-for="contact in contact.contacts.data"
                            :key="contact.id"
                            @click="Modals.show('contact-update', contact.slug)"
                        >
                            <div class="c-table-item__col c-table-item__col--icon">
                            </div>
                            <div class="c-table-item__col c-table-item__col--company">
                                {{ contact.company }}
                            </div>
                            <div class="c-table-item__col c-table-item__col--contact-person">
                                {{ contact.contactperson }}
                            </div>
                            <div class="c-table-item__col c-table-item__col--email">
                                {{ contact.email }}
                            </div>
                            <div class="c-table-item__col c-table-item__col--phone">
                                {{ contact.phone }}
                            </div>
                            <div class="c-table-item__col c-table-item__col--orders-count">
                                {{ contact.orders_count }}
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--button c-table-item__col--align-right"
                                @click="deleteContact(contact)"
                            >
                                <button class="e-button e-button--icon-only">
                                    <SvgDelete />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <pagination
                    :items="contact.contacts"
                    :order="state.orderFilter"
                    v-show="!state.search"
                />
            </div>
        </div>
            
    </article>
</template>

<script setup>
import SvgDelete from "@/svg/delete.svg";
import SvgPlus from "@/svg/plus.svg";
import SvgSearch from "@/svg/search.svg";
import SvgSort from "@/svg/sort.svg";

import { onMounted, onUpdated, reactive, ref, inject } from "vue";
import { useContactStore } from "@/store/contact";
import Swal from "sweetalert2";

const contact = useContactStore();
const Modals = inject("Modals");

const state = reactive({
    url: "/api/contacts?q",
    orderBy: "asc",
    filter: "company",
    orderFilter: "",
    search: null,
    debounce: 0,
});

onUpdated(() => {
    Eventing.fire("stickyElement");
});

onMounted(() => {
    Eventing.listen("paginate", (url) => {
        state.url = url;
        contact.fill(url);
    });
    Eventing.fire("addOverflowArrows");
});

contact.fill();

function deleteContact(item) {
    setTimeout(() => {
        Modals.close();
    }, 100);
    Swal.fire({
        text:
            'Zeker weten dat je "' +
            item.company +
            '" wilt verwijderen inclusief de gehele orderhistorie van deze klant?',
        confirmButtonText: "Wissen",
        showCloseButton: true,
        icon: "warning",
    }).then((result) => {
        if (result.isConfirmed) {
            contact.destroy(item.slug);
        }
    });
}

function debounceSearch(event) {
    clearTimeout(state.debounce);
    state.debounce = setTimeout(() => {
        state.search = event.target.value;
        if (state.search) {
            contact.search(state.search);
        } else {
            contact.fill();
        }
    }, 600);
}

function setFilter(filter) {
    let orderBy =
        state.orderBy == "asc"
            ? (state.orderBy = "desc")
            : (state.orderBy = "asc");
    state.filter = filter;
    state.orderFilter = "&order=" + state.filter + "&by=" + orderBy;

    contact.fill(state.url + "&order=" + state.filter + "&by=" + orderBy);
}

function menu(menu) {
    Eventing.fire("toggleMenu", menu);
}
</script>
