<template>
    <article v-if="items.meta">
        <ul class="c-pagination" v-if="items.meta">
            <li
                class="c-pagination__item"
                v-for="(item, index) in items.meta.links"
                :key="index"
                v-show="items.meta.from"
            >
                <a
                    class="c-pagination__link"
                    style="cursor: pointer"
                    v-if="items.meta.from != items.meta.last_page"
                    :class="{
                        'c-pagination__link--current': item.active,
                        'not-clickable': !item.url,
                    }"
                    @click="paginate(item.url + order)"
                    >{{ item.label }}</a
                >
            </li>
            <li class="c-pagination__note" v-show="items.meta.from">
                resultaten {{ items.meta.from }} t/m {{ items.meta.to }} van
                {{ items.meta.total }} in totaal
            </li>
            <li class="c-pagination__note" v-show="!items.meta.from">
                geen resultaten
            </li>
        </ul>
    </article>
</template>
<script setup>
const props = defineProps({
    items: Object,
    order: String,
    extraQuery: String,
});

function paginate(url) {
    try {
        if (props.extraQuery) {
            url += props.extraQuery;
        }
    } catch (error) {}
    url = url.replace("undefined", "");
    Eventing.fire("paginate", url.replace("undefined", ""));
}
</script>
