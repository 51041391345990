<template>
    <article>
        <div
            class="e-modal e-modal--w600 e-modal--align-start"
            modal-id="add-contact"
        >
            <div class="e-modal__wrap">
                <div class="e-modal__modal e-form">
                    <div class="e-modal__close js-close-modal">
                        <SvgCross @click="Modals.close()" />
                    </div>

                    <div class="e-modal__section">
                        <div class="e-modal__section-title">
                            <h2 class="e-modal__title">
                                Contact <span>bewerken</span>
                            </h2>
                        </div>
                    </div>

                    <div class="e-modal__section e-modal__section--bordered">
                        <div class="e-modal__section-subtitle">
                            <h2 class="e-modal__title">
                                Bestellingen
                                <span
                                    v-if="
                                        state.form.orders &&
                                        Object.entries(state.form.orders)
                                            .length >= 0
                                    "
                                >
                                    ({{
                                        Object.entries(state.form.orders)
                                            .length
                                    }})
                                </span>
                            </h2>
                            <a
                                class="e-button e-button--grey"
                                @click="newOrder()"
                                >Bestelling toevoegen</a
                            >
                        </div>
                    </div>
                    <div
                        class="c-table c-table--inside-update-contact-modal"
                        v-if="
                            state.form.orders &&
                            Object.entries(state.form.orders).length > 0
                        "
                    >
                        <div class="c-table__head">
                            <div class="c-table-item c-table-item--head">
                                <div
                                    class="c-table-item__col c-table-item__col--article-nr"
                                >
                                    Nummer
                                </div>
                                <div
                                    class="c-table-item__col c-table-item__col--date"
                                >
                                    Datum
                                </div>
                                <div
                                    class="c-table-item__col c-table-item__col--status"
                                >
                                    Status
                                </div>
                            </div>
                        </div>
                        <div class="c-table__body">
                            <router-link
                                class="c-table-item c-table-item--product-row"
                                v-for="(order, index) in state.form.orders"
                                :key="index"
                                :to="{
                                    name: 'app.order',
                                    params: { hash: order.hash },
                                }"
                                @click="Modals.close()"
                            >
                                <div
                                    class="c-table-item__col c-table-item__col--article-nr"
                                >
                                    {{ order.reference }}
                                </div>
                                <div
                                    class="c-table-item__col c-table-item__col--date"
                                >
                                    {{ order.created_at }}
                                </div>
                                <div
                                    class="c-table-item__col c-table-item__col--status"
                                >
                                    {{ order.status }}
                                </div>
                            </router-link>
                        </div>
                    </div>

                    <!-- <validation-errors :errors="contact.errors" /> -->
                    <form
                        method="POST"
                        @submit.prevent="onSubmit"
                        @keydown="contact.errors.errors = {}"
                        novalidate
                    >
                        <div class="e-modal__section">
                            <div class="e-modal__section-subtitle">
                                <h2 class="e-modal__title">Bedrijfsgegevens</h2>
                            </div>
                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.company,
                                }"
                                :invalid-message="contact.errors.errors.company"
                            >
                                <label class="e-form__label"
                                    >Bedrijfsnaam</label
                                >
                                <input
                                    class="e-form__input"
                                    type="text"
                                    v-model="state.form.company"
                                />
                            </div>

                            <div
                                class="e-note perfectview-search"
                                v-if="state.form.search"
                                @click="fillPerfectViewAddress()"
                            >
                                <div class="e-note__text">
                                    <p>
                                        Adresgegevens aanvullen?<br /><b
                                            >{{ state.form.search["address"] }},
                                            {{ state.form.search["city"] }}</b
                                        >
                                    </p>
                                </div>
                            </div>

                            <!-- <mapbox-address-autofill
                                access-token="pk.eyJ1IjoiY29kaW5nd29ya3MiLCJhIjoiY2xwMThhOWowMGtmMTJpbmxpeWUyZWgwcyJ9.H_skFd5kKbpjeeDwzX0dPw"
                                :options="{ country: 'nl' }"
                            > -->
                            <div
                                v-if="state.form.address"
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors['address.street'],
                                }"
                                :invalid-message="
                                    contact.errors.errors['address.street']
                                "
                            >
                                <label class="e-form__label">Adres</label>
                                <input
                                    class="e-form__input"
                                    type="text"
                                    name="address"
                                    autocomplete="shipping address-line1"
                                    v-model="state.form.address.street"
                                />
                            </div>

                            <div
                                v-if="state.form.address"
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors[
                                            'address.postcode'
                                        ],
                                }"
                                :invalid-message="
                                    contact.errors.errors['address.postcode']
                                "
                            >
                                <label class="e-form__label">Postcode</label>
                                <input
                                    class="e-form__input"
                                    type="text"
                                    name="postal-code"
                                    autocomplete="shipping postal-code"
                                    v-model="state.form.address.postcode"
                                />
                            </div>
                            <div
                                v-if="state.form.address"
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors['address.city'],
                                }"
                                :invalid-message="
                                    contact.errors.errors['address.city']
                                "
                            >
                                <label class="e-form__label">Plaats</label>
                                <input
                                    class="e-form__input"
                                    type="text"
                                    name="city"
                                    autocomplete="shipping address-level2"
                                    v-model="state.form.address.city"
                                />
                            </div>
                            <!-- </mapbox-address-autofill> -->
                        </div>
                        <div
                            class="e-modal__section e-modal__section--bordered"
                        >
                            <div class="e-modal__section-subtitle">
                                <h2 class="e-modal__title">Contactpersoon</h2>
                            </div>
                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.contactperson,
                                }"
                                :invalid-message="
                                    contact.errors.errors.contactperson
                                "
                            >
                                <label class="e-form__label">Naam</label>
                                <input
                                    class="e-form__input"
                                    type="text"
                                    v-model="state.form.contactperson"
                                />
                            </div>

                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.lastname,
                                }"
                                :invalid-message="
                                    contact.errors.errors.lastname
                                "
                            >
                                <label class="e-form__label">Achternaam</label>
                                <input
                                    class="e-form__input"
                                    type="text"
                                    v-model="state.form.lastname"
                                />
                            </div>

                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.jobtitle,
                                }"
                                :invalid-message="
                                    contact.errors.errors.jobtitle
                                "
                            >
                                <label class="e-form__label">Functie</label>
                                <input
                                    class="e-form__input"
                                    type="text"
                                    v-model="state.form.jobtitle"
                                />
                            </div>
                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.email,
                                }"
                                :invalid-message="contact.errors.errors.email"
                            >
                                <label class="e-form__label">E-mailadres</label>
                                <input
                                    class="e-form__input"
                                    type="email"
                                    v-model="state.form.email"
                                />
                            </div>
                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.mobile,
                                }"
                                :invalid-message="contact.errors.errors.mobile"
                            >
                                <label class="e-form__label">Mobiel nr.</label>
                                <input
                                    class="e-form__input"
                                    type="tel"
                                    v-model="state.form.mobile"
                                />
                            </div>
                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.phone,
                                }"
                                :invalid-message="contact.errors.errors.phone"
                            >
                                <label class="e-form__label"
                                    >Telefoon nr.</label
                                >
                                <input
                                    class="e-form__input"
                                    type="tel"
                                    v-model="state.form.phone"
                                />
                            </div>
                        </div>
                        <div
                            class="e-modal__section e-modal__section--bordered"
                        >
                            <div class="e-modal__section-subtitle">
                                <h2 class="e-modal__title">Overig</h2>
                            </div>

                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.code,
                                }"
                                :invalid-message="contact.errors.errors.code"
                                v-show="!state.form.sync_exact"
                            >
                                <label class="e-form__label"
                                    >Exact relatienr.</label
                                >
                                <input
                                    class="e-form__input"
                                    v-model="state.form.code"
                                    :disabled="
                                        state.form.exact_guid && state.form.code
                                    "
                                    :readonly="
                                        state.form.exact_guid && state.form.code
                                    "
                                    type="number"
                                />
                            </div>

                            <div
                                class="e-form__field-wrap"
                                v-if="
                                    !state.form.exact_guid || !state.form.code
                                "
                            >
                                <label class="e-form__label">&nbsp;</label>
                                <label class="e-form__checkbox-wrap">
                                    <input
                                        class="e-form__checkbox"
                                        type="checkbox"
                                        v-model="state.form.sync_exact"
                                    />
                                    <span class="e-form__checkbox-text"
                                        >Synchroniseer naar Exact&nbsp;</span
                                    >
                                    <span class="e-form__checkbox-label"></span>
                                </label>
                            </div>

                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.kvk,
                                }"
                                :invalid-message="contact.errors.errors.kvk"
                            >
                                <a
                                    v-if="state.form.company && !state.form.kvk"
                                    :href="
                                        'https://www.kvk.nl/zoeken/?source=all&q=' +
                                        state.form.company.replace(' ', '%20') +
                                        '&start=0&site=kvk2014'
                                    "
                                    target="_blank"
                                    style="
                                        float: right;
                                        position: absolute;
                                        right: 55px;
                                        margin-top: 4px;
                                    "
                                >
                                    <SvgSearch />
                                </a>
                                <label class="e-form__label">KVK</label>

                                <input
                                    class="e-form__input"
                                    type="text"
                                    v-model="state.form.kvk"
                                />
                            </div>
                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.btw_id,
                                }"
                                :invalid-message="contact.errors.errors.btw_id"
                            >
                                <label class="e-form__label">BTW-id</label>
                                <input
                                    class="e-form__input"
                                    type="text"
                                    v-model="state.form.btw_id"
                                />
                            </div>
                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.invoice_email,
                                }"
                                :invalid-message="
                                    contact.errors.errors.invoice_email
                                "
                            >
                                <label class="e-form__label"
                                    >Factuur e-mail</label
                                >
                                <input
                                    class="e-form__input"
                                    type="email"
                                    v-model="state.form.invoice_email"
                                />
                            </div>
                        </div>

                        <div
                            class="e-modal__section e-modal__section--center-text"
                        >
                            <button
                                type="submit"
                                class="e-button e-button--full-width e-button--green js-close-modal"
                            >
                                Aanpassen
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div
                class="e-modal__bg js-close-modal"
                @click="Modals.close()"
            ></div>
        </div>
    </article>
</template>
<style scoped>
.perfectview-search {
    cursor: pointer;
    margin: 12px 0;
}
</style>
<script setup>
import { useContactStore } from "@/store/contact";
import { useEnumStore } from "@/store/enum";
import { useOrderStore } from "@/store/order";
import SvgCross from "@/svg/cross.svg";
import SvgSearch from "@/svg/search.svg";
import { inject, onMounted, reactive } from "vue";

import Swal from "sweetalert2";

const props = defineProps({
    path: {
        type: String,
    },
});

const Modals = inject("Modals");

const contact = useContactStore();
const order = useOrderStore();
const enums = useEnumStore();

enums.fill("order-source");

const state = reactive({
    form: {
        id: null,
        address: {
            street: null,
            postcode: null,
            city: null,
            postcode: null,
            streetname: null,
        },
    },
});

onMounted(() => {
    state.form = contact.show(props.path);

    Eventing.listen("fillForm", (data) => {
        state.form = data.data;
    });

    contact.errors.errors = { message: null, errors: [] };
});

function onSubmit() {
    contact.update(props.path, state.form);
}

function fillPerfectViewAddress() {
    state.form.address.street = state.form.search["address"];
    state.form.address.perfectview = true;
    state.form.address.streetname = state.form.search["street"];
    state.form.address["houseNumber"] = state.form.search["number"];
    state.form.address.postcode = state.form.search["postcode"];
    state.form.address.city = state.form.search["city"];
    state.form.kvk = state.form.search["kvk"];
    state.form.search = null;
}

console.info(enums.options["order-source"]);

function newOrder() {
    Swal.fire({
        text:
            'Zeker weten dat je een nieuwe bestelling voor "' +
            state.form.company +
            '" wilt aanmaken?',
        confirmButtonText: "Ja",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "Annuleren",
        icon: "info",
        input: "select",
        inputOptions: {
            Productiesysteem: "Bron: Productiesysteem",
            Feelingz: "Bron: Feelingz",
            Kerstcadeaukiezen: "Bron: Kerstcadeaukiezen",
        },
    }).then(async (result) => {
        if (result.isConfirmed) {
            await order.store({
                contact_id: state.form.id,
                source: result.value,
                returnHash: true,
            });
        }
    });
}
</script>
