<template>
    <!-- #F030 -->
    <div
        class="c-below-topbar e-hide-scrollbar e-hide-scrollbar--x"
        v-if="state.categories"
    >
        <div class="e-hide-scrollbar__inner" v-if="!state.asList">
            <ul class="c-below-topbar__list">
                <li class="c-below-topbar__item">
                    <router-link
                        :to="{
                            name: 'app.orders',
                        }"
                        @click="refreshItemsBasedOnCategory()"
                        class="c-below-topbar__link"
                        :class="{
                            'c-below-topbar__link--active':
                                ($route.query && !$route.query.category) ||
                                !$route.query,
                        }"
                    >
                        Alles
                    </router-link>
                </li>
                <li
                    class="c-below-topbar__item"
                    v-for="(item, index) in state.categories"
                    :key="index"
                >
                    <router-link
                        :to="{
                            name: 'app.orders',
                            query: { category: item.name },
                        }"
                        @click="refreshItemsBasedOnCategory()"
                        class="c-below-topbar__link"
                        :class="{
                            'c-below-topbar__link--active':
                                $route.query &&
                                $route.query.category &&
                                $route.query.category == item.name,
                            wip: item.total <= 0,
                        }"
                    >
                        {{ item.name }}

                        <span class="e-amount-label">{{ item.total }}</span>
                    </router-link>
                </li>
                <!-- 
                @W: Combi & Specials opgesomd
                <li class="c-below-topbar__item">
                    <a class="c-below-topbar__link">
                        Combi & Specials

                        <span class="e-amount-label">{{
                            state.combiAndSpecials
                        }}</span>
                    </a>
                </li> -->
            </ul>
        </div>

        <!-- @W: zie https://trello.com/c/lVD3Ahpu - misschien dat je de lijst onder elkaar kunt tonen? Voeg dan ergens een knop toe doe die dit doet: <button @click="state.asList=!state.asList"></button> -->
        <section v-if="state.asList">
            <ul>
                <li
                    class="c-below-topbar__item"
                    v-for="(item, index) in state.categories"
                    :key="index"
                >
                    <router-link
                        :to="{
                            name: 'app.orders',
                            query: { category: item.name },
                        }"
                        @click="refreshItemsBasedOnCategory()"
                        class="c-below-topbar__link"
                        :class="{
                            'c-below-topbar__link--active':
                                $route.query &&
                                $route.query.category &&
                                $route.query.category == item.name,
                            wip: item.total <= 0,
                        }"
                    >
                        {{ item.name }}

                        <span class="e-amount-label">{{ item.total }}</span>
                    </router-link>
                </li>
            </ul>
        </section>
    </div>
</template>
<style scoped>
.horizontal {
    fill: white !important;
}
.vertical {
    fill: white !important;
    transform: rotate(270deg);
}
</style>
<script setup>
import { useOrderStore } from "@/store/order";
import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
const order = useOrderStore();
const route = useRoute();
const state = reactive({
    categories: null,
    asList: false,
    combiAndSpecials: 0,
});

onMounted(() => {
    getTotalsAndCategories();

    if (route.query && route.query.category) {
        setTimeout(() => {
            Eventing.fire("refreshOrders");
        }, 500);
    }
});

function refreshItemsBasedOnCategory() {
    setTimeout(() => {
        Eventing.fire("refreshOrders");
    }, 500);
}

async function getTotalsAndCategories() {
    let categories = await order.totals("categories");
    state.categories = categories.data;

    var total = 0;
    state.categories.forEach((cat) => {
        if (cat.name == "Combi") {
            total = total + cat.total;
        }
        if (cat.name == "Specials") {
            total = total + cat.total;
        }
    });
    state.combiAndSpecials = total;
}
</script>
