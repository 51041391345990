<template>
    <div class="c-page-content">
        <div
            class="c-page-content__container c-page-content__container--spacing-small c-page-content__container--no-pb c-page-content__container--dont-move-if-side-overlay-is-open"
        >
            <div class="c-page-filter e-form--transparent">
                <div class="c-page-filter__inner-wrap">
                    <div class="c-page-filter__button js-toggle-page-filter">
                        <SvgFilter />
                    </div>
                    <div class="c-page-filter__options">
                        <div class="e-form__field-wrap">
                            <div class="e-form__label">
                                <p>Leverdatum</p>
                            </div>
                            <input
                                type="date"
                                v-show="state.delivery_date"
                                v-model="state.delivery_date"
                                @change="loadingResults()"
                                class="e-form__input e-form__input--small"
                            />
                            <span
                                class="e-form__input e-form__input--small"
                                v-show="!state.delivery_date"
                                @click="
                                    state.delivery_date = new Date()
                                        .toJSON()
                                        .slice(0, 10)
                                "
                                >Selecteer datum</span
                            >

                            <span
                                class="e-form__suffix"
                                v-show="state.delivery_date"
                                @click="
                                    state.delivery_date = '';
                                    loadingResults();
                                "
                                ><SvgCross />Reset</span
                            >
                        </div>
                        <div class="e-form__field-wrap">
                            <div class="e-form__label">
                                <p>Shop</p>
                            </div>
                            <div class="e-form__select-wrap">
                                <select
                                    class="e-form__select e-form__select--small"
                                    placeholder="Bron"
                                    v-model="state.shop"
                                    @change="loadingResults()"
                                >
                                    <option placeholder="Alles" value="">
                                        Alles
                                    </option>
                                    <option value="1">Ja</option>
                                    <option value="0">Nee</option>
                                </select>
                            </div>
                        </div>

                        <div class="e-form__field-wrap">
                            <div class="e-form__label">
                                <p>Bank</p>
                            </div>
                            <div class="e-form__select-wrap">
                                <select
                                    class="e-form__select e-form__select--small"
                                    placeholder="Bron"
                                    v-model="state.bank"
                                    @change="loadingResults()"
                                >
                                    <option placeholder="Alles" value="">
                                        Alles
                                    </option>
                                    <option value="1">Ja</option>
                                    <option value="0">Nee</option>
                                </select>
                            </div>
                        </div>

                        <div
                            class="e-form__field-wrap"
                            v-show="state.bank == '1'"
                        >
                            <div class="e-form__label">
                                <p>Opgewaardeerd</p>
                            </div>
                            <div class="e-form__select-wrap">
                                <select
                                    class="e-form__select e-form__select--small"
                                    placeholder="Bron"
                                    v-model="state.collected"
                                    @change="loadingResults()"
                                >
                                    <option placeholder="Alles" value="">
                                        Alles
                                    </option>
                                    <option value="1">Ja</option>
                                    <option value="0">Nee</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="c-status-columns" v-if="state.results">
                <div class="c-status-columns__columns">
                    <div
                        class="c-status-columns__column"
                        v-for="(result, value) in state.results"
                        :key="value"
                    >
                        <div class="c-status-columns__column-heading">
                            <h2>
                                {{ result.status }}
                                <span
                                    class="c-status-columns__column-count e-label e-label--medium e-label--green"
                                    >{{ result.items.length }}</span
                                >
                            </h2>
                        </div>

                        <div class="c-status-columns__items">
                            <draggable
                                class="list-group drop-area"
                                :list="result.items"
                                group="products"
                                :sort="false"
                                @end="saveSortings"
                                itemKey="name"
                                filter=".c-order-tile__icon--button, .e-button, .date-picker, input"
                                preventOnFilter="false"
                            >
                                <template #item="{ element, index }">
                                    <div class="list-group-item">
                                        <order-tile
                                            @click="toggleOrder(element)"
                                            :index="index"
                                            :test="element.id.toString()"
                                            :item="element"
                                            :order="element"
                                            :status="result.status"
                                        ></order-tile>
                                    </div>
                                </template>
                            </draggable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-dashboards-side-overlay-shop-manager />
</template>

<script setup>
import SvgCross from "@/svg/cross.svg";
import SvgFilter from "@/svg/filter.svg";
import { inject, onMounted, onUpdated, reactive } from "vue";

import { useDashboardShopmanagerStore } from "@/store/dashboard/shopmanager";

import draggable from "vuedraggable";

const Modals = inject("Modals");

function log(evt) {
    window.console.log(evt);
}

const shopmanager = useDashboardShopmanagerStore();

onUpdated(() => {
    Eventing.fire("stickyElement");
});

const state = reactive({
    url: "/api/dashboards/shopmanager/orders?q",
    orderBy: "desc",
    results: null,

    shop: "",
    bank: "",
    collected: "",

    delivery_date: "",

    order: null,
});

onMounted(() => {
    Eventing.listen("reloadItems", () => {
        if (document.body.classList.contains("body--side-overlay-is-open")) {
            document.body.classList.remove("body--side-overlay-is-open");
        }
        loadingResults();
    });
});

async function loadingResults() {
    Eventing.fire("clearSideOverlay");
    state.url = "/api/dashboards/shopmanager/orders?q";
    state.order = null;
    let results = await shopmanager.fill(
        state.url,
        state.shop,
        state.bank,
        state.collected,
        state.delivery_date
    );
    if (results) {
        state.results = results;
    }
}
loadingResults();

async function saveSortings() {
    let data = await shopmanager.saveStatus({
        statusses: state.results,
    });
    if (data) {
        loadingResults();
    }
}

async function toggleOrder(order) {
    if (state.order && state.order.hash == order.hash) {
        state.order = null;

        if (document.body.classList.contains("body--side-overlay-is-open")) {
            document.body.classList.remove("body--side-overlay-is-open");
        }
    } else {
        Eventing.fire("clearSideOverlay");
        let result = await shopmanager.show(order.hash);
        if (result) {
            state.order = result;
            document.body.classList.add("body--side-overlay-is-open");
            Eventing.fire("fillSideOverlay", result);
        }
    }
}
</script>
