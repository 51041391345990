/*
Html structure:

Add modal attriute to the modal:
<div class="e-modal" modal-id="{unique-modal-name}">

Add open-modal attribute to open on click
<a open-modal-id="{unique-modal-name}">Open modal</a>

*/

const modals = document.querySelectorAll("[open-modal-id]");

modals.forEach((trigger) => {
    trigger.addEventListener("click", function (event) {
        const modalName = trigger.getAttribute("open-modal-id");
        const modal = document.querySelector("[modal-id=" + modalName + "]");

        modal.classList.add("e-modal--open");
        document.body.classList.add("body--no-scroll");

        //Focus on first input
        const formInputs = modal.querySelector(".e-form__input");

        if (formInputs) {
            setTimeout(function () {
                //After the animation
                formInputs.focus();
            }, 100);
        }

        //Close modal
        const closeButtons = modal.querySelectorAll(".js-close-modal");

        closeButtons.forEach((button) => {
            button.addEventListener("click", function (event) {
                modal.classList.remove("e-modal--open");
                document.body.classList.remove("body--no-scroll");
            });
        });
    });
});
