<template>
    <article>
        <div class="c-client-header">
            <div class="c-client-header__content">
                <div class="c-client-header__container">
                    <div class="c-client-header__text">
                        <h1 class="c-client-header__title">Communicatie #{{ state.order.reference }}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-page-content c-page-content--has-page-menu">
            <div class="c-page-content__container c-page-content__container--spacing">
                <section class="c-page-content__section e-module-style">
                    <div class="e-section-heading">
                        <div class="e-section-heading__left">
                            <h2
                                class="e-section-heading__name"
                                v-if="personsInChat"
                            >
                                Communicatie tussen
                                {{ personsInChat }}
                            </h2>
                            <h2
                                class="e-section-heading__name"
                                v-if="!personsInChat"
                            >
                                Communicatie
                            </h2>
                        </div>
                    </div>
                    <div class="c-chat">
                        <div
                            class="e-hide-scrollbar e-hide-scrollbar--top-shadow"
                        >
                            <div
                                class="c-chat__scroll-area e-hide-scrollbar__inner"
                                ref="container"
                            >
                                <div
                                    class="c-chat__messages"
                                    v-if="order.nested['messages']"
                                >
                                    <div
                                        class="c-chat__message"
                                        v-for="(message, index) in order.nested[
                                            'messages'
                                        ].data"
                                        :key="index"
                                        :class="{
                                            'c-chat__message--client':
                                                message.sender.role ==
                                                'contact',
                                            'c-chat__message--note':
                                                message.type == 'Notitie',
                                        }"
                                    >
                                        <div class="c-chat__message-inner-wrap">
                                            <div class="c-chat__message-head">
                                                <div
                                                    class="c-chat__message-name"
                                                >
                                                    <img
                                                        class="c-chat__message-user-image"
                                                        v-if="
                                                            message.sender
                                                                .avatar
                                                        "
                                                        :src="
                                                            message.sender
                                                                .avatar ??
                                                            '/svg/user-image.svg'
                                                        "
                                                    />
                                                    <SvgUserImage
                                                        class="c-chat__message-user-image"
                                                        v-if="
                                                            !message.sender
                                                                .avatar
                                                        "
                                                    ></SvgUserImage>

                                                    {{ message.sender.name }}
                                                    <span
                                                        class="c-chat__message-source"
                                                        >via
                                                        {{ message.type }}</span
                                                    >
                                                </div>
                                                <div
                                                    class="c-chat__message-date"
                                                >
                                                    {{ message.created_at }}
                                                </div>
                                            </div>
                                            <div class="c-chat__message-text">
                                                <p v-html="message.message"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="c-chat__send-message">
                            <form
                                method="POST"
                                @submit.prevent="onSubmit"
                                @keydown="order.errors.errors = {}"
                                novalidate
                                v-if="state.form"
                            >
                                <div
                                    class="e-form__field-wrap"
                                    c-page-content__containerclass="{
                                        'e-form__field-wrap--invalid':
                                            Object.keys(order.errors.message)
                                                .length == 0,
                                    }"
                                >
                                    <textarea
                                        class="c-chat__textarea e-form__textarea"
                                        placeholder="Typ een bericht..."
                                        v-model="state.form.message"
                                        autocomplete="off"
                                    ></textarea>
                                </div>

                                <div class="e-form__field-wrap mt-10">
                                    <button
                                        class="e-button e-button--green e-button--full-width"
                                        type="submit"
                                    >
                                        Versturen
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </article>
</template>

<script setup>
import { useOrderStore } from "@/store/client/order";
import { computed, inject, nextTick, onMounted, reactive, ref } from "vue";

import { useAuthClientStore } from "@/store/authClient";
import SvgUserImage from "@/svg/user-image.svg";
import { useRoute } from "vue-router";

const Modals = inject("Modals");
const order = useOrderStore();

const route = useRoute();

const user = useAuthClientStore();

order.show(user.order());

order.fillNested(user.order(), "messages");

const chatbox = ref(null);

const state = reactive({
    order: {
        contact: {
            address: {},
        },
        followers: {},
        user: {
            id: null,
        },
    },
    form: {
        message: null,
        type: "Chat",
        is_note: false,
    },
});

const personsInChat = computed(() => {
    const persons = [];
    if (order.nested["messages"]) {
        order.nested["messages"].data.map(function (msg, key) {
            persons.push(msg.sender.name);
        });
    }
    return [...new Set(persons)].join(", ");
});

onMounted(async () => {
    await nextTick();

    Eventing.listen("fillOrder", (data) => {
        state.order = data.data;
    });
    Eventing.listen("fillNested", (data) => {
        //
    });
    Eventing.listen("storedNested", (data) => {
        order.fillNested(user.order(), "messages");
    });

    Eventing.listen("newChat", (data) => {
        order.nested["messages"].data.push(data);
    });
});

function onSubmit() {
    order.storeNested(user.order(), "messages", state.form);
    state.form.message = null;
}
</script>
