import _ from "lodash";
window._ = _;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from "axios";
window.axios = axios;

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.withCredentials = true;

window.axios.interceptors.response.use(function (response) {
    document.body.classList.remove(
        "body--topbar-menu-is-open",
        "body--mobile-menu-is-open"
    );
    Eventing.fire("loader", "yes");
    if (response && response.status) {
        setTimeout(() => {
            Eventing.fire("loader", "no");
        }, 50);
    }
    return response;
});

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from "laravel-echo";

// import Pusher from "pusher-js";
// window.Pusher = Pusher;

// // // window.Echo = new Echo({
// // //     broadcaster: 'pusher',
// // //     key: import.meta.env.VITE_PUSHER_APP_KEY,
// // //     wsHost: import.meta.env.VITE_PUSHER_HOST ?? `ws-${import.meta.env.VITE_PUSHER_CLUSTER}.pusher.com`,
// // //     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
// // //     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
// // //     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
// // //     enabledTransports: ['ws', 'wss'],
// // // });
// import { useAuthStore } from "@/store/auth";
// const auth = useAuthStore();

// window.Echo = new Echo({
//     broadcaster: "pusher",
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
//     encrypted: true,
//     key: import.meta.env.VITE_PUSHER_APP_KEY,

//     // wsHost:
//     //     import.meta.env.VITE_PUSHER_HOST ??
//     //     `ws-${import.meta.env.VITE_PUSHER_CLUSTER}.pusher.com`,
//     // wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     // wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     // forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? "https") === "https",
//     // enabledTransports: ["ws", "wss"],

//     authorizer: (channel, options) => {
//         return {
//             authorize: (socketId, callback) => {
//                 axios
//                     .post(
//                         "/api/broadcasting/auth",
//                         {
//                             socket_id: socketId,
//                             channel_name: channel.name,
//                         },
//                         { headers: { Authorization: "Bearer " + auth.token() } }
//                     )
//                     .then((response) => {
//                         callback(false, response.data);
//                     })
//                     .catch((error) => {
//                         callback(true, error);
//                     });
//             },
//         };
//     },
// });
