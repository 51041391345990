/*
    Click to show input field

    HTML STRUCTURE:

    <div class="e-edit-toggle">
        <div class="e-edit-toggle__output-wrap">
            <button class="e-edit-toggle__edit-icon js-edit-toggle">@svg('edit')</button>
        </div>
        <div class="e-edit-toggle__input-wrap">
            <button class="e-edit-toggle__save-icon js-edit-toggle">@svg('check')</button>
            <input type="text" class="c-order-item__amount-input e-form__input e-edit-toggle__input" />
        </div>
    </div>
*/

//Add event listeners
document.addEventListener("click", function (e) {
    if (e.target.classList.contains("js-edit-toggle")) {
        editToggle(e.target);
    }
});

//Edit item amount
function editToggle(item) {
    //Toggle field
    const parent = item.closest(".e-edit-toggle");
    const input = parent.querySelector(".e-edit-toggle__input");

    parent.classList.toggle("e-edit-toggle--show-input");
    // try {
    input.select();
    // } catch (error) {}
}
