<template>
    <ul class="e-popover" v-show="state.showPopover">
        <li
            class="e-popover__item"
            v-for="(item, index) in notifications"
            :key="index"
            v-show="item.data.message"
            :class="{ read: item.read_at, unread: !item.read_at }"
            @click="readNotification(item.id)"
        >
            <a
                class="e-popover__link"
                :href="item.data.url"
                v-html="item.data.message"
            >
            </a>
            <p class="e-popover__date-stamp">{{ item.created_at }}</p>
        </li>
        <li class="e-popover__item e-popover__heading">
            <a class="e-popover__heading-link" @click="readNotification('all')"
                >Markeer alles als gelezen</a
            >
        </li>
    </ul>
</template>
<script setup>
import { useAuthStore } from "@/store/auth";
import { onMounted, reactive } from "vue";
const user = useAuthStore();

const props = defineProps({
    notifications: {
        type: Object,
    },
});

const state = reactive({
    showPopover: true,
});

onMounted(() => {
    Eventing.listen("fetchNotifications", () => {
        setTimeout(() => {
            state.showPopover = true;
        }, 1000);
    });
});

function readNotification(which) {
    state.showPopover = false;
    user.notificationRead(which);
}
</script>
<style scoped>
.read {
    opacity: 0.9;
}
.unread {
    border-left: 3px solid #78ceab;
}
</style>
