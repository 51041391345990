import { defineStore } from "pinia";
import { useAuthClientStore } from "../authClient";

export const useProductStore = defineStore({
    id: "products",
    state: () => ({
        errors: {
            message: null,
            errors: [],
        },
        products: [],
        categories: [],
    }),
    actions: {
        api() {
            return "/api/clients/products/";
        },
        auth() {
            const user = useAuthClientStore();
            return {
                headers: {
                    Authorization: "Bearer " + user.token(),
                    Order: user.order(),
                },
            };
        },
        async giftboxes(basket) {
            try {
                let result = await axios.get(
                    "/api/clients/samenstellen/giftboxes/" + basket,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async assemble(basket, form) {
            try {
                let result = await axios.post(
                    "/api/clients/samenstellen/" + basket,
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async presents(cat) {
            try {
                let result = await axios.get(
                    "/api/clients/samenstellen/presents/" + cat,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async show(path) {
            try {
                let result = await axios.get(this.api() + path, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillForm", result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async fill(url, box) {
            if (typeof url === "undefined") {
                url = this.api();
            }
            try {
                let result = await axios.get(url + "?&box=" + box, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.success(result);
                    Eventing.fire("isLoaded");
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async search(search) {
            if (search) {
                let url = this.api() + "?search=" + search;
                try {
                    let result = await axios.get(url, this.auth());
                    if ([200, 201, 204].includes(result.status)) {
                        this.success(result);
                        Eventing.fire("isLoaded");
                    }
                } catch (error) {
                    this.fail(error);
                }
            }
        },
        async store(form) {
            try {
                let result = await axios.post(this.api(), form, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.added(result);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async update(path, form) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.updated(result);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async destroy(path) {
            try {
                let result = await axios.delete(this.api() + path, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.deleted();
                }
            } catch (error) {
                this.fail(error);
            }
        },
        deleted() {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Verwijderd", "warning");
            this.fill();
        },
        updated(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Aangepast: " + result.data.data.name);
            this.fill();
        },
        added(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Toegevoegd: " + result.data.data.name);
            this.fill();
        },
        success(result) {
            this.$patch({
                products: result.data,
            });
            this.checkResults(result.data);
        },
        fail(error) {
            console.error(error);
            this.$patch({
                errors: error.response.data,
            });
            Eventing.fire("error", error.response);
        },
        checkResults(result) {
            try {
                if (result.data && Object.keys(result.data).length <= 0) {
                    Eventing.fire(
                        "toasting",
                        "Geen resultaten gevonden",
                        "info"
                    );
                }
            } catch (error) {}
        },
    },
});
