<template>
    <apexchart
        width="100%"
        height="100%"
        :series="props.series"
        :options="chart.options"
    >
    </apexchart>
</template>

<script setup>
import { reactive } from "vue";

const props = defineProps({
    id: "", //ID om 'group' te laten werken
    series: Array,
    labels: Array,
    colors: {
        type: Array,
        default: ["#78ceab", "#5fb090", "#499175"],
    },
    module_theme: "", //'green' for green styling
});

//Green module style
var themeOptions = {
    stroke: {},
    fill: {},
    xaxis: {
        crosshairs: {},
    },
};

if (props.module_theme == "green") {
    themeOptions = {
        stroke: {
            width: 0,
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: "lighten",
                type: "vertical",
                shadeIntensity: 0,
                gradientToColors: ["#fff", "#fff"],
                inverseColors: true,
                opacityFrom: 0.8,
                opacityTo: 1,
                stops: [100, 100],
                colorStops: [],
            },
        },
        xaxis: {
            crosshairs: {
                stroke: {
                    color: "rgba(255,255,255,0.8)",
                    width: 1,
                },
            },
        },
    };
}
const chart = reactive({
    options: {
        colors: props.colors,
        fill: themeOptions.fill,
        stroke: themeOptions.stroke,

        chart: {
            type: "area",
            id: props.id,
            group: "invoices",
            sparkline: {
                enabled: true,
            },
            animations: {
                enabled: false,
            },
        },
        grid: {
            padding: {
                top: 10,
            },
            borderColor: "#fff",
        },

        xaxis: {
            type: "datetime",
            categories: props.labels,
            crosshairs: themeOptions.xaxis.crosshairs,
        },
        tooltip: {
            x: {
                format: "d-MM-yy",
                // format: "dddd d-MM-yy",
            },
            y: {
                formatter: function (val) {
                    return val;
                    // return val + ' <span class="e-suffix-text">(€12.321)</span>';
                },
            },
        },
    },
});
</script>
