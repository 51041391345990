<template>
    <article>
        <div class="e-modal e-modal--align-start" modal-id="filter-modal">
            <div class="e-modal-submit">
                <button
                    class="e-modal-submit__button e-button--full-width e-button e-button--green e-button--green--3d e-button--iconx16 js-close-modal"
                    @click="saveFilters()"
                >
                    <SvgFilter /> Filteren
                </button>
            </div>

            <div class="e-modal__wrap">
                <div class="e-modal__before-modal"></div>

                <div class="e-modal__modal">
                    <div class="e-modal__close js-close-modal">
                        <SvgCross @click="Modals.close()" />
                    </div>

                    <div class="e-modal__section">
                        <div class="e-modal__section-title">
                            <h2 class="e-modal__title">
                                Resultaten <span>filteren</span>
                            </h2>
                        </div>
                    </div>

                    <div class="e-modal__section e-modal__section--bordered">
                        <div class="e-form">
                            <div
                                class="e-form__columns e-form__columns--three-columns"
                            >
                                <div
                                    class="e-form__field-wrap e-form__field-wrap--checkboxes"
                                >
                                    <label
                                        class="e-form__label e-form__label--big"
                                        >Categorieën</label
                                    >
                                    <div
                                        class="e-form__multiple-checkboxes e-form__multiple-checkboxes--list"
                                    >
                                        <label
                                            class="e-form__checkbox-wrap"
                                            v-for="(option, value) in enums
                                                .options['order-categories']"
                                            :key="value"
                                        >
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.category"
                                                v-bind:value="option"
                                            />

                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text">
                                                {{ option }}</span
                                            >
                                        </label>
                                    </div>
                                </div>

                                <div
                                    class="e-form__field-wrap e-form__field-wrap--checkboxes"
                                >
                                    <label
                                        class="e-form__label e-form__label--big"
                                        >Status</label
                                    >
                                    <div
                                        class="e-form__multiple-checkboxes e-form__multiple-checkboxes--list"
                                    >
                                        <label
                                            class="e-form__checkbox-wrap"
                                            v-for="(option, value) in enums
                                                .options['order-status']"
                                            :key="value"
                                        >
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.status"
                                                v-bind:value="option"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text">
                                                {{ option }}</span
                                            >
                                        </label>
                                    </div>
                                </div>

                                <div
                                    class="e-form__field-wrap e-form__field-wrap--checkboxes"
                                >
                                    <label
                                        class="e-form__label e-form__label--big"
                                        >Facturatie</label
                                    >
                                    <div
                                        class="e-form__multiple-checkboxes e-form__multiple-checkboxes--list"
                                    >
                                        <label
                                            class="e-form__checkbox-wrap"
                                            v-for="(option, value) in enums
                                                .options[
                                                'order-invoice-status'
                                            ]"
                                            :key="value"
                                        >
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.invoice_status"
                                                v-bind:value="option"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text">
                                                {{ option }}</span
                                            >
                                        </label>
                                    </div>
                                </div>

                                <div
                                    class="e-form__field-wrap e-form__field-wrap--checkboxes"
                                >
                                    <label
                                        class="e-form__label e-form__label--big"
                                        >Type bezorging</label
                                    >
                                    <div
                                        class="e-form__multiple-checkboxes e-form__multiple-checkboxes--list"
                                    >
                                        <label
                                            class="e-form__checkbox-wrap"
                                            v-for="(option, value) in enums
                                                .options[
                                                'box-shipping-methods'
                                            ]"
                                            :key="value"
                                        >
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.shipping_method"
                                                v-bind:value="option"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text">
                                                {{ option }}</span
                                            >
                                        </label>
                                    </div>
                                </div>

                                <div
                                    class="e-form__field-wrap e-form__field-wrap--checkboxes"
                                >
                                    <label
                                        class="e-form__label e-form__label--big"
                                        >Transporteur</label
                                    >
                                    <div
                                        class="e-form__multiple-checkboxes e-form__multiple-checkboxes--list"
                                    >
                                        <label
                                            class="e-form__checkbox-wrap"
                                            v-for="(option, value) in enums
                                                .options['box-transporters']"
                                            :key="value"
                                        >
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="
                                                    state.shipping_transporter
                                                "
                                                v-bind:value="option"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text">
                                                {{ option }}</span
                                            >
                                        </label>
                                    </div>
                                </div>

                                <div
                                    class="e-form__field-wrap e-form__field-wrap--checkboxes"
                                >
                                    <label
                                        class="e-form__label e-form__label--big"
                                        >Leverdatum</label
                                    >
                                    <div
                                        class="e-form__multiple-radio e-form__multiple-radios--list"
                                    >
                                        <label class="e-form__radio-wrap">
                                            <input
                                                class="e-form__radio"
                                                type="radio"
                                                name="delivery_date"
                                                v-model="state.delivery_date"
                                                @click="
                                                    state.delivery_date ==
                                                    'unknown'
                                                        ? (state.delivery_date =
                                                              null)
                                                        : (state.delivery_date =
                                                              'unknown')
                                                "
                                                v-bind:value="'unknown'"
                                            />
                                            <span
                                                class="e-form__radio-label"
                                            ></span>
                                            <span class="e-form__radio-text"
                                                >Onbekend</span
                                            >
                                        </label>
                                        <label class="e-form__radio-wrap">
                                            <input
                                                class="e-form__radio"
                                                name="delivery_date"
                                                v-model="state.delivery_date"
                                                @click="
                                                    state.delivery_date ==
                                                    'known'
                                                        ? (state.delivery_date =
                                                              null)
                                                        : (state.delivery_date =
                                                              'known')
                                                "
                                                v-bind:value="'known'"
                                                type="radio"
                                            />
                                            <span
                                                class="e-form__radio-label"
                                            ></span>
                                            <span class="e-form__radio-text"
                                                >Bekend</span
                                            >
                                        </label>
                                    </div>
                                </div>

                                <div
                                    class="e-form__field-wrap e-form__field-wrap--checkboxes"
                                >
                                    <label
                                        class="e-form__label e-form__label--big"
                                        >Shop / Bank</label
                                    >
                                    <div
                                        class="e-form__multiple-checkboxes e-form__multiple-checkboxes--list"
                                    >
                                        <label class="e-form__checkbox-wrap">
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.without_shop"
                                                :checked="state.without_shop"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Zonder shop</span
                                            >
                                        </label>

                                        <label class="e-form__checkbox-wrap">
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.with_shop"
                                                :checked="state.with_shop"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Met shop</span
                                            >
                                        </label>

                                        <label class="e-form__checkbox-wrap">
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.without_bank"
                                                :checked="state.without_bank"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Zonder bank</span
                                            >
                                        </label>

                                        <label class="e-form__checkbox-wrap">
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.with_bank"
                                                :checked="state.with_bank"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Met bank</span
                                            >
                                        </label>

                                        <label
                                            class="e-form__checkbox-wrap"
                                            v-show="
                                                state.with_bank ||
                                                state.with_collected
                                            "
                                        >
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.with_collected"
                                                :checked="state.with_collected"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Opgewaardeerd</span
                                            >
                                        </label>
                                        <label
                                            class="e-form__checkbox-wrap"
                                            v-show="
                                                state.with_bank ||
                                                state.without_collected
                                            "
                                        >
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="
                                                    state.without_collected
                                                "
                                                :checked="
                                                    state.without_collected
                                                "
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Niet opgewaardeerd</span
                                            >
                                        </label>
                                    </div>
                                </div>

                                <div
                                    class="e-form__field-wrap e-form__field-wrap--checkboxes"
                                >
                                    <label
                                        class="e-form__label e-form__label--big"
                                        >Aangeleverd</label
                                    >
                                    <div
                                        class="e-form__multiple-radio e-form__multiple-radios--list"
                                    >
                                        <label class="e-form__radio-wrap">
                                            <input
                                                class="e-form__radio"
                                                type="radio"
                                                name="attachments"
                                                v-model="state.attachments"
                                                @click="
                                                    state.attachments ==
                                                    'incomplete'
                                                        ? (state.attachments =
                                                              null)
                                                        : (state.attachments =
                                                              'incomplete')
                                                "
                                                v-bind:value="'incomplete'"
                                            />
                                            <span
                                                class="e-form__radio-label"
                                            ></span>
                                            <span class="e-form__radio-text"
                                                >Onvolledig</span
                                            >
                                        </label>
                                        <label class="e-form__radio-wrap">
                                            <input
                                                class="e-form__radio"
                                                name="attachments"
                                                v-model="state.attachments"
                                                @click="
                                                    state.attachments ==
                                                    'complete'
                                                        ? (state.attachments =
                                                              null)
                                                        : (state.attachments =
                                                              'complete')
                                                "
                                                v-bind:value="'complete'"
                                                type="radio"
                                            />
                                            <span
                                                class="e-form__radio-label"
                                            ></span>
                                            <span class="e-form__radio-text"
                                                >Volledig</span
                                            >
                                        </label>
                                    </div>
                                </div>

                                <div
                                    class="e-form__field-wrap e-form__field-wrap--checkboxes"
                                >
                                    <label
                                        class="e-form__label e-form__label--big"
                                        >Bron</label
                                    >
                                    <div
                                        class="e-form__multiple-checkboxes e-form__multiple-checkboxes--list"
                                    >
                                        <label
                                            class="e-form__checkbox-wrap"
                                            v-for="(option, value) in enums
                                                .options['order-source']"
                                            :key="value"
                                        >
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.source"
                                                v-bind:value="option"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text">
                                                {{ option }}</span
                                            >
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="e-form mt-20">
                                <div
                                    class="e-form__field-wrap e-form__field-wrap--checkboxes"
                                >
                                    <label
                                        class="e-form__label e-form__label--big"
                                        >Overig</label
                                    >
                                    <div
                                        class="e-form__multiple-checkboxes e-form__multiple-checkboxes--list"
                                    >
                                        <label
                                            class="e-form__checkbox-wrap mt-10"
                                        >
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.only_new"
                                                :checked="state.only_new"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Toon alleen nieuwe
                                                bestellingen</span
                                            >
                                        </label>

                                        <label
                                            class="e-form__checkbox-wrap mt-10"
                                        >
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.show_datepicker"
                                                :checked="state.show_datepicker"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Zoek binnen periode voor
                                                levering</span
                                            >
                                        </label>

                                        <label
                                            class="e-form__checkbox-wrap mt-10"
                                        >
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.without_delivery"
                                                :checked="
                                                    state.without_delivery
                                                "
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Toon bestellingen zonder
                                                leverdatum</span
                                            >
                                        </label>

                                        <label class="e-form__checkbox-wrap">
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.only_following"
                                                :checked="state.only_following"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Toon alleen bestellingen die ik
                                                volg</span
                                            >
                                        </label>

                                        <label
                                            class="e-form__checkbox-wrap mt-10"
                                        >
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.only_season"
                                                :checked="state.only_season"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Toon bestellingen van alle
                                                seizoenen</span
                                            >
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--  ALLEEN ZICHTBAAR INDIEN MAATWERK CATEGORIE AANGEVINKT -->
                    <div
                        class="e-modal__section e-modal__section--bordered is-hidden"
                    >
                        <div class="e-form">
                            <div
                                class="e-form__field-wrap e-form__field-wrap--checkboxes"
                            >
                                <label class="e-form__label e-form__label--big"
                                    >Verwachte maatwerk leverdatum</label
                                >
                                <p class="e-form__desc">
                                    De verwachte leverdatum van het maatwerk in
                                    het magazijn.
                                </p>
                            </div>

                            <div
                                class="e-form__field-wrap e-form__field-wrap--checkboxes"
                            >
                                <DatePicker
                                    is-range
                                    v-model="state.date_range"
                                    show-weeknumbers
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        class="e-modal__section e-modal__section--bordered"
                        v-if="state.show_datepicker"
                    >
                        <div class="e-form">
                            <div
                                class="e-form__field-wrap e-form__field-wrap--checkboxes"
                            >
                                <label class="e-form__label e-form__label--big"
                                    >Periode voor levering</label
                                >
                                <p class="e-form__desc">
                                    Selecteer een periode voor de levering.
                                </p>
                            </div>

                            <div
                                class="e-form__field-wrap e-form__field-wrap--checkboxes"
                            >
                                <DatePicker
                                    is-range
                                    v-model="state.date_range"
                                    show-weeknumbers
                                />
                            </div>
                        </div>
                    </div>

                    <div class="e-modal__section e-modal__section--bordered">
                        <div class="e-form">
                            <div
                                class="e-form__columns e-form__columns--two-columns"
                            >
                                <div
                                    class="e-form__field-wrap e-form__field-wrap--checkboxes"
                                >
                                    <label
                                        class="e-form__label e-form__label--big"
                                        >Producten in bestelling</label
                                    >
                                    <Multiselect
                                        v-model="state.products"
                                        mode="tags"
                                        :close-on-select="false"
                                        :searchable="true"
                                        :create-option="false"
                                        no-results-text="Niets gevonden..."
                                        :options="options.options['products']"
                                        openDirection="top"
                                        placeholder="Filter op orders met deze producten"
                                    />
                                </div>

                                <div
                                    class="e-form__field-wrap e-form__field-wrap--checkboxes"
                                >
                                    <label
                                        class="e-form__label e-form__label--big"
                                        >Categorieën in bestelling</label
                                    >
                                    <Multiselect
                                        v-model="state.categories"
                                        mode="tags"
                                        :close-on-select="false"
                                        :searchable="true"
                                        no-results-text="Niets gevonden..."
                                        :create-option="false"
                                        :options="options.options['categories']"
                                        openDirection="top"
                                        placeholder="Filter op orders met producten uit deze categorieën"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="e-modal__bg js-close-modal"
                @click="Modals.close()"
            ></div>
        </div>
    </article>
</template>

<script setup>
import SvgCross from "@/svg/cross.svg";
import SvgFilter from "@/svg/filter.svg";

import { useEnumStore } from "@/store/enum";
import { useOrderStore } from "@/store/order";

import { useOptionStore } from "@/store/option";
import { useProductStore } from "@/store/product";

import Multiselect from "@vueform/multiselect";
import { inject, onMounted, reactive } from "vue";

import { DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";

const Modals = inject("Modals");

const order = useOrderStore();
const enums = useEnumStore();

const product = useProductStore();
// product.fillCategories();

const options = useOptionStore();
options.fill("products");
options.fill("categories");

const state = reactive({
    invoice_status: [],
    status: [],
    category: [],
    source: [],
    only_following: false,
    only_new: false,
    without_delivery: false,
    only_season: false,
    without_shop: false,
    with_shop: false,
    without_bank: false,
    with_bank: false,
    with_collected: false,
    without_collected: false,
    show_datepicker: false,
    attachments: "",

    products: [],
    categories: [],
    delivery_date: null,
    shipping_method: [],
    shipping_transporter: [],
    date_range: {
        start: null,
        end: null,
    },
});

order.activeFilters();

onMounted(() => {
    state.invoice_status = order.filters.invoice_status;
    state.status = order.filters.status;
    state.category = order.filters.category;
    state.source = order.filters.source;
    state.only_following = order.filters.only_following;
    state.only_new = order.filters.only_new;
    state.without_delivery = order.filters.without_delivery;

    state.only_season = order.filters.only_season;

    state.without_shop = order.filters.without_shop;
    state.with_shop = order.filters.with_shop;
    state.without_bank = order.filters.without_bank;
    state.with_bank = order.filters.with_bank;

    state.without_collected = order.filters.without_collected;
    state.with_collected = order.filters.with_collected;

    state.attachments = order.filters.attachments;
    state.show_datepicker = order.filters.show_datepicker;

    state.date_range = order.filters.date_range;

    state.products = order.filters.products;
    state.categories = order.filters.categories;
    state.delivery_date = order.filters.delivery_date;
    state.shipping_method = order.filters.shipping_method;
    state.shipping_transporter = order.filters.shipping_transporter;

    console.info("order.filters");
    console.info(order.filters);
});

enums.fill("order-invoice-status");
enums.fill("order-status");
enums.fill("order-source");
enums.fill("box-shipping-methods");
enums.fill("box-transporters");
enums.fill("order-categories");

function saveFilters() {
    order.saveFilters(state);
    Eventing.fire("setFilters");
    Modals.close();
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
