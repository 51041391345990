import { useAuthStore } from "@/store/auth";
import { defineStore } from "pinia";

export const useProductStore = defineStore({
    id: "products",
    state: () => ({
        errors: {
            message: null,
            errors: [],
        },
        products: [],
        categories: [],
        activityList: [],
    }),
    actions: {
        api() {
            return "/api/products/";
        },
        auth() {
            const user = useAuthStore();
            return { headers: { Authorization: "Bearer " + user.token() } };
        },
        async fillCategories() {
            try {
                let result = await axios.get("/api/categories", this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.$patch({
                        categories: result.data.data,
                    });
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async fillCategoriesMaatwerk() {
            try {
                let result = await axios.get(
                    "/api/categories?only=maatwerk",
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.$patch({
                        categories: result.data.data,
                    });
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async fillCategoriesMaatwerkProducts() {
            try {
                let result = await axios.get(
                    "/api/categories?only=maatwerk-products",
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    return result.data.data;
                }
            } catch (error) {
                this.fail(error);
                return error;
            }
        },
        async fillCategoriesList() {
            try {
                let result = await axios.get(
                    "/api/categories?list=true",
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async storeCategory(form) {
            try {
                let result = await axios.post(
                    "/api/categories",
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.added(result);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async showCategory(path) {
            try {
                let result = await axios.get(
                    "/api/categories/" + path,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillForm", result.data);
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
                return error;
            }
        },
        async updateCategory(path, form) {
            try {
                let result = await axios.put(
                    "/api/categories/" + path,
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.updated(result);
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
                return error;
            }
        },
        async destroyCategory(path) {
            try {
                let result = await axios.delete(
                    "/api/categories/" + path,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.deleted();
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async show(path) {
            try {
                let result = await axios.get(this.api() + path, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillForm", result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async fillWithBox(url, box) {
            if (typeof url === "undefined") {
                url = this.api();
            }
            try {
                let result = await axios.get(url + "?&box=" + box, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.success(result);
                    Eventing.fire("isLoaded");
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async fill(url) {
            if (typeof url === "undefined") {
                url = this.api();
            }
            try {
                let result = await axios.get(url, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.success(result);
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async search(search) {
            if (search) {
                let url = this.api() + "?search=" + search;
                try {
                    let result = await axios.get(url, this.auth());
                    if ([200, 201, 204].includes(result.status)) {
                        this.success(result);
                    }
                } catch (error) {
                    this.fail(error);
                }
            }
        },
        async searchWithinBox(search, box) {
            if (search) {
                let url = this.api() + "?search=" + search + "&box=" + box;
                try {
                    let result = await axios.get(url, this.auth());
                    if ([200, 201, 204].includes(result.status)) {
                        this.success(result);
                        Eventing.fire("isLoaded");
                    }
                } catch (error) {
                    this.fail(error);
                }
            }
        },
        async store(form) {
            try {
                let result = await axios.post(this.api(), form, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.added(result);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async update(path, form) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.updated(result);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async destroy(path) {
            try {
                let result = await axios.delete(this.api() + path, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.deleted();
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async activities() {
            try {
                let result = await axios.get(
                    "/api/stock-activities",
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.$patch({
                        activityList: result.data.data,
                    });
                }
            } catch (error) {
                this.fail(error);
            }
        },
        deleted() {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Verwijderd", "warning");
            this.fill();
        },
        updated(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Aangepast: " + result.data.data.name);
            this.fill();
        },
        added(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Toegevoegd: " + result.data.data.name);
            this.fill();
        },
        success(result) {
            this.$patch({
                products: result.data,
                activityList: [],
            });
            this.checkResults(result.data);
        },
        fail(error) {
            console.error(error);
            this.$patch({
                errors: error.response.data,
            });
            Eventing.fire("error", error.response);
        },
        checkResults(result) {
            try {
                if (result.data && Object.keys(result.data).length <= 0) {
                    Eventing.fire(
                        "toasting",
                        "Geen resultaten gevonden",
                        "info"
                    );
                }
            } catch (error) {}
        },
    },
});
