import { useAuthStore } from "@/store/auth";
import { defineStore } from "pinia";

export const useDashboardShopmanagerStore = defineStore({
    id: "dashboard-shopmanager",
    state: () => ({
        errors: {
            message: null,
            errors: [],
        },
        orders: [],
        filters: {},
        nested: {},
        activityList: [],
    }),
    actions: {
        api() {
            return "/api/dashboards/shopmanager/orders/";
        },
        auth() {
            const user = useAuthStore();
            return {
                headers: {
                    Authorization: "Bearer " + user.token(),
                    // Filters: JSON.stringify(this.getActiveFilters()),
                },
            };
        },

        async fill(url, shop, bank, collected, delivery_date) {
            if (typeof url === "undefined") {
                url = this.api();
            }
            try {
                let result = await axios.get(
                    url +
                        "&shop=" +
                        shop +
                        "&bank=" +
                        bank +
                        "&collected=" +
                        collected +
                        "&delivery_date=" +
                        delivery_date,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    console.info("fill", result.data);
                    return result.data;
                } else {
                    return response.status;
                }
            } catch (error) {
                this.fail(error);
                return error.response.status;
            }
        },

        async show(path) {
            try {
                let result = await axios.get(this.api() + path, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
                return error.response.status;
            }
        },

        async cart(path) {
            try {
                let result = await axios.get(
                    "/api/dashboards/shopmanager/orders/" + path + "/boxes",
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async saveStatus(items) {
            try {
                let result = await axios.post(this.api(), items, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },

        deleted() {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Verwijderd", "warning");
            this.fill();
        },
        updated(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Aangepast: " + result.data.data.hash);
            this.fill();
        },
        added(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Toegevoegd: " + result.data.data.hash);
            this.fill();
        },
        success(result) {
            this.$patch({
                orders: result.data,
                activityList: [],
            });
            this.checkResults(result.data);
        },
        fail(error) {
            console.error(error);
            this.$patch({
                errors: error.response.data,
            });
            Eventing.fire("error", error.response);
        },
        checkResults(result) {
            try {
                if (result.data && Object.keys(result.data).length <= 0) {
                    Eventing.fire(
                        "toasting",
                        "Geen resultaten gevonden",
                        "info"
                    );
                }
            } catch (error) {}
        },
    },
});
