<template>
    <div class="c-side-overlay" v-if="state.order">
        <app-dashboards-side-overlay-header :order="state.order" />

        <div class="c-side-overlay__content">
            <div
                class="c-side-overlay__content-section"
                v-if="state.order.is_splitted"
            >
                <div class="e-note">
                    <SvgInfo />
                    <div
                        class="e-note__text"
                        v-if="state.order.origin_reference"
                    >
                        <p>
                            Deze bestelling is een splitsing. Ga naar de
                            hoofdbestelling

                            <router-link
                                :to="{
                                    name: 'app.order.order',
                                    params: {
                                        hash: state.order.origin_hash,
                                    },
                                }"
                                class="c-order__order-link"
                                >{{ state.order.origin_reference }}
                            </router-link>
                            om de facturering te beheren.
                        </p>
                    </div>
                    <div
                        class="e-note__text"
                        v-if="!state.order.origin_reference"
                    >
                        <p>
                            Deze bestelling bestaat uit meerdere splitsingen.
                            Alle details uit die bestellingen worden in deze
                            hoofdbestelling verzameld onder 1 factuur.<br />
                            Wanneer je de Facturatiestatus aanpast, wordt deze
                            ook toegepast op de onderliggende splitsingen.
                        </p>
                    </div>
                </div>
            </div>

            <div
                class="c-side-overlay__content-section"
                :class="{
                    'is-hidden':
                        state.order.is_splitted && state.order.origin_reference,
                }"
            >
                <div class="e-form">
                    <div class="e-form__field-wrap">
                        <div class="e-form__label">
                            <p>Facturatiestatus</p>
                        </div>
                        <div class="e-form__select-wrap">
                            <select
                                class="e-form__select"
                                v-model="state.order.invoice_status"
                                @change="state.isChanged = true"
                            >
                                <option
                                    v-for="(
                                        option, value
                                    ) in state.invoice_statusses"
                                    v-bind:value="option"
                                    :key="value"
                                    :disabled="
                                        (state.order.exact &&
                                            option == 'Niet gefactureerd') ||
                                        (state.order.exact &&
                                            option ==
                                                'Staat klaar voor facturatie') ||
                                        (state.order.exact &&
                                            !option.includes('actureerd')) ||
                                        state.order.exact == 'busy'
                                    "
                                >
                                    {{ option }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="c-side-overlay__content-section"
                v-if="
                    state.order.notes &&
                    Object.values(state.order.notes).length > 0
                "
            >
                <h3 class="c-side-overlay__content-section-title">
                    Notities voor Administratie
                </h3>
                <div class="c-chat__messages">
                    <div
                        class="c-chat__message c-chat__message--note"
                        v-for="note in state.order.notes"
                        :key="note.id"
                    >
                        <div class="c-chat__message-inner-wrap">
                            <div class="c-chat__message-head">
                                <div
                                    class="c-chat__message-name"
                                    v-if="note.sender"
                                >
                                    <img
                                        class="c-chat__message-user-image"
                                        :src="note.sender.avatar"
                                    />

                                    {{ note.sender.name }}

                                    <span class="c-chat__message-source">
                                        {{ note.sender.role }}
                                    </span>
                                </div>
                                <div class="c-chat__message-date">
                                    {{ note.created_at }}
                                </div>
                            </div>
                            <div class="c-chat__message-text">
                                <p v-html="note.message"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="c-side-overlay__content-section" v-if="state.invoice">
                <div class="c-side-overlay__content-section-table">
                    <div class="c-table-default mt-min-10">
                        <div class="c-table-default__inner-wrap">
                            <div class="c-table-default__table">
                                <div class="c-table-default__head">
                                    <div class="c-table-default__tr">
                                        <div class="c-table-default__td">
                                            Product
                                        </div>
                                        <div class="c-table-default__td">
                                            Totaal
                                        </div>
                                    </div>
                                </div>
                                <div class="c-table-default__body">
                                    <invoice-row-small
                                        v-for="(row, index) in state.invoice
                                            .data"
                                        :key="index"
                                        :item="row"
                                        :category="true"
                                    >
                                    </invoice-row-small>
                                </div>
                                <div
                                    class="c-table-default__footer"
                                    v-if="state.invoice.totals"
                                >
                                    <div class="c-table-default__tr">
                                        <div
                                            class="c-table-default__td c-table-default__td--bold"
                                        >
                                            Totaal
                                        </div>
                                        <div
                                            class="c-table-default__td c-table-default__td--bold"
                                        >
                                            {{
                                                state.invoice.totals[
                                                    "subtotaal"
                                                ]
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="c-side-overlay__footer">
            <div class="c-side-overlay__footer-content">
                <button
                    class="e-button e-button--green e-button--full-width e-button--big"
                    v-if="
                        state.order.exact != 'busy' && state.isChanged != false
                    "
                    :class="{
                        'is-hidden':
                            state.order.is_splitted &&
                            state.order.origin_reference,
                    }"
                    @click="switchBillingStatus()"
                >
                    Opslaan
                </button>

                <!-- Bij het klikken op deze knop moet de SideOVerlay sluiten -->
                <button
                    class="e-button e-button--green e-button--full-width e-button--big"
                    v-if="state.isChanged == false"
                    @click="state.order = null"
                >
                    Sluiten
                </button>

                <button
                    class="e-button e-button--green e-button--full-width e-button--big disabled"
                    v-if="state.order.exact == 'busy'"
                >
                    Wordt naar Exact gestuurd&hellip;
                </button>

                <router-link
                    :to="{
                        name: 'app.order',
                        params: { hash: state.order.hash },
                    }"
                    class="e-button e-button--icon-right e-button--big"
                    >Naar bestelling <SvgArrowRight />
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useDashboardFinanceStore } from "@/store/dashboard/finance";
import SvgArrowRight from "@/svg/arrow-right.svg";
import SvgInfo from "@/svg/info.svg";
import { onMounted, reactive } from "vue";

const state = reactive({
    order: null,
    invoice: {
        data: [],
        totals: null,
    },
    isChanged: false,
    invoice_statusses: [],
});

const finance = useDashboardFinanceStore();

onMounted(() => {
    Eventing.listen("clearSideOverlay", () => {
        state.order = null;
        state.invoice = null;
    });

    Eventing.listen("invoiceStatusses", (data) => {
        state.invoice_statusses = data;
    });

    Eventing.listen("fillSideOverlay", (data) => {
        state.order = data.data;
        state.invoice = null;
        finance.invoice(data.data.hash);
    });

    Eventing.listen("fillInvoice", (data) => {
        state.invoice = data;
    });
});

function switchBillingStatus() {
    if (
        state.order.status.label == "Concept" ||
        state.order.status.label == "XXXX-In ontvangst"
    ) {
        Eventing.fire(
            "toasting",
            "Facturatiestatus kan pas worden aangepast wanneer order een status heeft",
            // "Facturatiestatus kan pas worden aangepast wanneer order minimaal de KIP-status heeft",
            "error"
        );
    } else {
        finance.switchBillingState(
            state.order.hash,
            state.order.invoice_status
        );
        if (state.order.invoice_status == "Factuur naar Exact sturen") {
            state.order.exact = "busy";
        }
    }
}
</script>
