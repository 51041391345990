import { defineStore } from "pinia";
import { useAuthStore } from "@/store/auth";
import SecureLS from "secure-ls";
import Swal from "sweetalert2";

export const useOrderStore = defineStore({
    id: "orders",
    state: () => ({
        errors: {
            message: null,
            errors: [],
        },
        orders: [],
        filters: {},
        nested: {},
    }),
    actions: {
        api() {
            return "/api/production/orders/";
        },
        auth() {
            const user = useAuthStore();
            return {
                headers: {
                    Authorization: "Bearer " + user.token(),
                },
            };
        },
        async show(path) {
            try {
                let result = await axios.get(this.api() + path, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillOrder", result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },

        handleQR(result) {
            try {
                let qrCode = result.split("_");
                this.$router.push({
                    name: "production.order",
                    hash: qrCode[0],
                    box_hash: qrCode[1],
                    params: {
                        qr: result,
                        hash: qrCode[0],
                        box_hash: qrCode[1],
                    },
                });
            } catch (error) {
                console.warn(error);
                Eventing.fire("toasting", "QR-code niet correct", "error");
            }
        },

        async fill(url) {
            if (typeof url === "undefined") {
                url = this.api();
            }
            try {
                let result = await axios.get(url, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.success(result);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async store(form) {
            try {
                let result = await axios.post(this.api(), form, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.added(result);
                    if (result.data.data.hash) {
                        //
                    }
                }
            } catch (error) {
                console.error(error);
                this.fail(error);
            }
        },
        async update(path, form) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    // this.updated(result);

                    if (typeof result.data.hash === "undefined") {
                    } else {
                        Eventing.fire("fillOrder", result.data);
                    }

                    try {
                        if (result.data.download) {
                            const url = result.data.download;
                            const link = document.createElement("a");
                            link.href = url;
                            // link.setAttribute("download", "Stickers");
                            document.body.appendChild(link);
                            link.click();
                            Eventing.fire(
                                "toasting",
                                "Labels worden gedownload",
                                "info"
                            );
                        }
                    } catch (error) {
                        console.warn(error);
                    }
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async printStickers(path, options) {
            Swal.fire({
                title: "Stickers printen",
                text: "Voor welke samenstelling wil je de printen?",
                confirmButtonText: "Print",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonText: "Annuleren",
                icon: "info",
                input: "select",
                inputOptions: options,
            }).then((result) => {
                if (result.isConfirmed) {
                    if (options[result.value].includes("adressen missen")) {
                        Eventing.fire(
                            "toasting",
                            "Geen adressen bekend, dus valt niets te printen",
                            "error"
                        );
                    } else if (options[result.value].includes("ELS")) {
                        Swal.fire({
                            text: "Hoeveel stickers/dozen?",
                            confirmButtonText: "Opslaan",
                            showCloseButton: true,
                            showCancelButton: true,
                            cancelButtonText: "Annuleren",
                            icon: "question",
                            input: "number",
                        }).then((resultAmount) => {
                            if (resultAmount.isConfirmed) {
                                this.update(path, {
                                    stickers: result.value,
                                    amount: resultAmount.value,
                                });
                            }
                        });
                    } else {
                        this.update(path, {
                            stickers: result.value,
                            amount: 1,
                        });
                    }
                }
            });
        },
        async setColli(path) {
            Swal.fire({
                title: "Colli",
                text: "Hoeveel colli heeft deze Order voor ELS?",
                confirmButtonText: "Opslaan",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonText: "Annuleren",
                icon: "info",
                input: "number",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.update(path, {
                        colli: result.value,
                    });
                    Eventing.fire("collieKnown");
                }
            });
        },

        async printCrates(path) {
            Swal.fire({
                title: "Krat labels",
                text: "Hoeveel kratten heeft deze Order?",
                confirmButtonText: "Opslaan en print",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonText: "Annuleren",
                icon: "info",
                input: "number",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.update(path, {
                        crates: result.value,
                    });
                }
            });
        },

        async updateStatus(path, form) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillOrder", result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async destroy(path) {
            try {
                let result = await axios.delete(this.api() + path, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.deleted();
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async search(search) {
            if (search) {
                let url = this.api() + "?search=" + search;
                try {
                    let result = await axios.get(url, this.auth());
                    if ([200, 201, 204].includes(result.status)) {
                        this.success(result);
                    }
                } catch (error) {
                    this.fail(error);
                }
            }
        },
        deleted() {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Verwijderd", "warning");
            this.fill();
        },
        updated(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Aangepast: " + result.data.data.hash);
            this.fill();
        },
        added(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Toegevoegd: " + result.data.data.hash);
            this.fill();
        },
        success(result) {
            this.$patch({
                orders: result.data,
            });
            this.checkResults(result.data);
        },
        fail(error) {
            console.error(error);
            this.$patch({
                errors: error.response.data,
            });
            Eventing.fire("error", error.response);
        },

        checkResults(result) {
            try {
                if (result.data && Object.keys(result.data).length <= 0) {
                    Eventing.fire(
                        "toasting",
                        "Geen resultaten gevonden",
                        "info"
                    );
                }
            } catch (error) {}
        },
    },
});
