<template>
    <article>
        <div class="c-production-content">
            <div class="c-production-content__section">
                <div class="e-search-input">
                    <label
                        class="e-search-input__search-label"
                        for="table-search"
                    >
                        <SvgSearch />
                    </label>
                    <input
                        class="e-search-input__search-input e-form__input"
                        id="table-search"
                        type="search"
                        @input="debounceSearch"
                        autocomplete="false"
                        spellcheck="false"
                        placeholder="Zoeken door bestellingen..."
                    />
                </div>
            </div>

            <div class="c-table">
                <div class="c-table__head">
                    <div class="c-table-item c-table-item--head">
                        <div
                            class="c-table-item__col c-table-item__col--production-order"
                        >
                            Order
                        </div>
                        <div
                            class="c-table-item__col c-table-item__col--status"
                        >
                            Status
                        </div>
                        <div
                            class="c-table-item__col c-table-item__col--delivery"
                        >
                            Levering
                        </div>
                        <div class="c-table-item__col c-table-item__col--date">
                            Status gewijzigd op
                        </div>
                    </div>
                </div>
                <div class="c-table__body">
                    <router-link
                        class="c-table-item"
                        :to="{
                            name: 'production.order',
                            params: { hash: order.hash },
                        }"
                        v-for="order in order.orders.data"
                        :key="order.id"
                    >
                        <div
                            class="c-table-item__col c-table-item__col--production-order c-table-item__col--two-lines"
                        >
                            <span style="color: #78ceab">{{
                                order.reference
                            }}</span>
                            <span v-if="order.contact">
                                {{ order.contact.company }}
                            </span>
                        </div>

                        <div
                            class="c-table-item__col c-table-item__col--status"
                        >
                            <button-state
                                :label="order.status.label"
                                :buttonclass="order.status.class"
                            />
                        </div>

                        <div
                            class="c-table-item__col c-table-item__col--delivery"
                        >
                            <div class="c-table-item__col--icon">
                                <span v-if="order.is_urgent">
                                    <SvgSpeed />
                                </span>
                            </div>
                            <span
                                class="e-label e-label--small e-label--box-name"
                                v-if="order.delivery_weeks"
                                :class="{
                                    ' e-tooltip':
                                        order && !order.delivery_completed,
                                }"
                                data-tooltip="Leverdatums nog niet compleet"
                                >Wk
                                <span
                                    class="e-label e-label--small"
                                    :class="{
                                        'e-label--delivery-known':
                                            order.delivery_weeks,
                                        'e-label--delivery-unknown':
                                            !order.delivery_weeks,
                                    }"
                                >
                                    {{ order.delivery_weeks }}
                                    <span v-if="!order.delivery_completed"
                                        >🚨</span
                                    >
                                </span>
                            </span>
                        </div>

                        <div
                            class="c-table-item__col c-table-item__col--date c-table-item__col--two-lines"
                        >
                            <section v-if="order.in_production">
                                {{ order.in_production.at }}

                                <span style="color: #78ceab">{{
                                    order.in_production.by
                                }}</span>
                            </section>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="c-production-footer">
            <router-link
                class="e-button e-button--green e-button--full-width"
                :to="{
                    name: 'production.scan',
                }"
                >QR scannen</router-link
            >
        </div>
    </article>
</template>

<script setup>
import { useOrderStore } from "@/store/production/order";
import SvgSearch from "@/svg/search.svg";
import SvgSpeed from "@/svg/speed.svg";
import { inject, onMounted, reactive } from "vue";

const Modals = inject("Modals");
const order = useOrderStore();

const state = reactive({
    url: "/api/production/orders?q",
    orderBy: "desc",
    filter: "updated_at",
    orderFilter: "",
    search: null,
    debounce: 0,
});

onMounted(() => {
    Eventing.listen("paginate", (url) => {
        state.url = url;
        order.fill(url);
    });
});

order.fill();

function debounceSearch(event) {
    clearTimeout(state.debounce);
    state.debounce = setTimeout(() => {
        state.search = event.target.value;
        if (state.search) {
            order.search(state.search);
        } else {
            order.fill();
        }
    }, 600);
}

function menu(menu) {
    Eventing.fire("toggleMenu", menu);
}
</script>
