<template>
    <div class="c-page-content">
        <div
            class="c-page-content__container c-page-content__container--spacing-small c-page-content__container--no-pt e-load-items-animation"
        >
            <div
                class="c-page-filter mt-20 e-form--transparent js-sticky-element"
            >
                <div class="c-page-filter__inner-wrap">
                    <div
                        class="c-page-filter__options c-page-filter__options--space-between"
                    >
                        <production-products-picker
                            :products="state.products"
                        ></production-products-picker>

                        <div class="c-page-filter__options-col">
                            <button
                                v-show="state.addExtraWeeks > 8"
                                class="e-button e-button--iconx18 e-button--icon-reverse"
                                @click="goBackInWeeks(4)"
                            >
                                <SvgFuture />
                                4 weken eerder
                            </button>

                            <button
                                v-show="state.addExtraWeeks == 8"
                                class="e-button e-button--iconx18 e-button--icon-reverse"
                                onclick="window.location.reload()"
                            >
                                <SvgFuture />
                                Deze week
                            </button>

                            <button
                                class="e-button e-button--iconx18"
                                @click="loadNextWeeks()"
                            >
                                <SvgFuture />
                                {{ state.addExtraWeeks }} weken later
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="c-page-content__section l-grid l-grid/3 l-grid/t/2 l-grid/m/1 l-grid--gap-big"
            >
                <div
                    class="c-status-counter e-module-style e-module-style--green"
                    v-for="(result, value) in state.tomorrow"
                    :key="value"
                >
                    <div class="e-widget-heading">
                        <div class="e-widget-heading__left">
                            <h2 class="e-widget-heading__name">Morgen</h2>
                        </div>
                    </div>
                    <div class="c-status-counter__inner-wrap">
                        <div class="c-status-counter__row">
                            <div
                                class="c-status-counter__chart c-status-counter__chart--big"
                            >
                                <chart-bars
                                    :series="Array.from(result)"
                                    :labels="state.statuslist"
                                    module_theme="green"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="c-status-counter e-module-style e-module-style--green l-grid__col/2 l-grid__col/t/1"
                    v-if="state.totals"
                >
                    <div class="e-widget-heading">
                        <div class="e-widget-heading__left">
                            <h2 class="e-widget-heading__name">Totaal</h2>
                        </div>
                    </div>

                    <div class="c-status-counter__inner-wrap">
                        <div class="c-status-counter__row">
                            <div
                                class="c-status-counter__chart c-status-counter__chart--big e-chart e-chart--glow"
                            >
                                <chart-area
                                    :series="Array.from(state.totals.series)"
                                    :labels="state.labels"
                                    module_theme="green"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <production-product-stats
                v-for="(value, slug) in state.products"
                :key="value"
                :slug="slug"
                :product="value"
                :weeks="state.loadExtraWeeks"
            >
            </production-product-stats>

            <div
                class="c-page-content__section c-status-counter e-module-style"
                v-for="(result, value) in state.results"
                :key="value"
            >
                <div class="e-section-heading">
                    <div class="e-section-heading__left">
                        <h2 class="e-section-heading__name">
                            Totaal per status
                        </h2>
                    </div>
                </div>

                <div class="c-status-counter__inner-wrap">
                    <div class="c-status-counter__row">
                        <div class="c-status-counter__chart e-chart">
                            <chart-bars
                                :series="Array.from(result.series)"
                                :labels="Array.from(state.labels)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import SvgFuture from "@/svg/future.svg";

import { useDashboardProductionStore } from "@/store/dashboard/production";

import { onMounted, reactive } from "vue";

const production = useDashboardProductionStore();

const state = reactive({
    products: [],
    results: [],
    labels: [],
    tomorrow: [],
    statuslist: [],
    totals: null,
    addExtraWeeks: 4,
    loadExtraWeeks: 0,
    firstBack: true,
});

onMounted(() => {
    Eventing.fire("stickyElement");

    Eventing.listen("reloadResultsForDashboard", () => {
        state.loadExtraWeeks = 0;
        state.addExtraWeeks = 4;
        state.products = [];
        window.location.reload(true);
        loadingResults();
    });
});

async function loadNextWeeks() {
    let weeks = state.addExtraWeeks;
    state.loadExtraWeeks = weeks;
    state.addExtraWeeks = state.addExtraWeeks + 4;
    state.products = [];
    state.results = [];
    state.labels = [];
    state.totals = null;
    await loadingResults(weeks);
}

async function goBackInWeeks(_weeks) {
    var weeks = state.addExtraWeeks - _weeks;
    if (state.firstBack) {
        weeks = state.addExtraWeeks - _weeks;
        state.firstBack = false;
    }
    state.loadExtraWeeks = weeks;
    state.addExtraWeeks = weeks;
    state.products = [];
    state.results = [];
    state.labels = [];
    state.totals = null;
    await loadingResults(weeks);
}

async function loadingResults(weeks) {
    Eventing.fire("clearSideOverlay");
    state.order = null;
    let results = await production.fill(
        typeof weeks === "undefined" ? null : weeks
    );
    if (results) {
        state.products = results.products;
        state.results = results.stats;
        state.labels = results.labels;
        if (results.statuslist) {
            state.statuslist = results.statuslist;
        }
        if (results.tomorrow && state.loadExtraWeeks == 0) {
            state.tomorrow = {
                series: [
                    {
                        name: "Morgen",
                        data: results.tomorrow,
                    },
                ],
            };
        }
        if (results.totals) {
            state.totals = {
                series: [
                    {
                        name: "Totaal",
                        data: results.totals,
                    },
                ],
            };
        }
    }
}
loadingResults();
</script>
