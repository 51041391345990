<template>
    <article>
        <div class="c-topbar">
            <div class="c-topbar__left">
                <div
                    class="c-topbar__left-button e-menu-button js-toggle-mobile-menu"
                    @click="menu('mobile')"
                >
                    <div class="e-menu-button__bars-wrap">
                        <div class="e-menu-button__bars"></div>
                    </div>
                </div>

                <button
                    class="e-button e-button--icon-only e-button--transparent e-button--white-text e-button--iconx16 e-button--green-darker-hover e-button--has-popover"
                >
                    <SvgDashboard />
                    <ul class="e-popover">
                        <li class="e-popover__item">
                            <router-link
                                active-class="e-popover__link--active"
                                class="e-popover__link"
                                :to="{ name: 'app.dashboards.traffic.orders' }"
                            >
                                Traffic
                            </router-link>
                        </li>
                        <li class="e-popover__item">
                            <router-link
                                active-class="e-popover__link--active"
                                class="e-popover__link"
                                :to="{ name: 'app.dashboards.design' }"
                            >
                                Vormgever
                            </router-link>
                        </li>
                        <li class="e-popover__item">
                            <router-link
                                active-class="e-popover__link--active"
                                class="e-popover__link"
                                :to="{ name: 'app.dashboards.shopmanager' }"
                            >
                                Shop manager
                            </router-link>
                        </li>
                        <li class="e-popover__item">
                            <router-link
                                active-class="e-popover__link--active"
                                class="e-popover__link"
                                :to="{ name: 'app.dashboards.finance' }"
                            >
                                Administratie
                            </router-link>
                        </li>
                        <li class="e-popover__item">
                            <router-link
                                active-class="e-popover__link--active"
                                class="e-popover__link"
                                :to="{ name: 'app.dashboards.production' }"
                            >
                                Productie
                            </router-link>
                        </li>
                    </ul>
                </button>

                <h1 class="c-topbar__title">
                    Welkom <b>{{ auth.name() }}</b>
                </h1>
            </div>

            <div
                class="c-topbar__right-button e-menu-button js-toggle-topbar-menu"
                @click="menu('topbar')"
            >
                <div class="e-menu-button__bars-wrap">
                    <div class="e-menu-button__bars"></div>
                </div>
            </div>
            <div class="c-topbar__right" v-show="checkIfViewHasGraph()">
                <button
                    class="e-button e-button--green e-button--green-darker-hover e-button--icon-only e-tooltip e-tooltip--left js-toggle-widgets"
                    data-tooltip="Verberg grafieken"
                    v-if="state.graphs"
                    @click="hideGraphsNow()"
                >
                    <SvgGraph />
                </button>

                <button
                    class="e-button e-button--green e-button--green-darker-hover e-button--icon-only e-tooltip e-tooltip--left js-toggle-widgets"
                    data-tooltip="Toon grafieken"
                    v-if="!state.graphs"
                    @click="showGraphsNow()"
                >
                    <SvgGraph />
                </button>
            </div>
        </div>

        <router-view name="tab"></router-view>
    </article>
</template>

<script setup>
import { useAuthStore } from "@/store/auth";
import { useUserStore } from "@/store/user";
import SvgDashboard from "@/svg/dashboard.svg";
import SvgGraph from "@/svg/graph.svg";
import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";

const user = useUserStore();
const auth = useAuthStore();
const route = useRoute();
function menu(menu) {
    Eventing.fire("toggleMenu", menu);
}

const state = reactive({
    graphs: true,
});

onMounted(() => {
    Eventing.fire("toggleWidgets");

    Eventing.listen("statsAreLoaded", () => {
        state.graphs = true;
    });
});

function hideGraphsNow() {
    Eventing.fire("hideGraphs");
    state.graphs = false;
}
function showGraphsNow() {
    state.graphs = false;
    setTimeout(() => {
        Eventing.fire("showGraphs");
    }, 200);
}

function checkIfViewHasGraph() {
    // Bepaal of de Grafiek Toggler wel of niet moet worden getoond. Kan o.b.v. route.path toe te voegen. Zie console voor de naam van Current Route.
    return (
        (route.path == "/app/dashboards/administratie" ||
            route.path == "/app/dashboards/traffic" ||
            route.path == "/app/dashboards/traffic/administratie" ||
            route.path == "/app/dashboards/traffic/maatwerk-runs") &&
        route.path != "/app/dashboards/traffic/voorraad-standen"
    );
}
</script>
