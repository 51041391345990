<template>
    <article>
        <div
            class="e-modal e-modal--w600 e-modal--align-start"
            modal-id="add-email"
        >
            <div class="e-modal__wrap">
                <div class="e-modal__modal e-form e-form--labels-above">
                    <div class="e-modal__close js-close-modal">
                        <SvgCross @click="Modals.close()" />
                    </div>

                    <div class="e-modal__section">
                        <div class="e-modal__section-title">
                            <h2 class="e-modal__title">
                                E-mail <span>toevoegen</span>
                            </h2>
                        </div>
                    </div>
                    <!-- <validation-errors :errors="email.errors" /> -->
                    <form
                        method="POST"
                        @submit.prevent="onSubmit"
                        @keydown="email.errors.errors = {}"
                        novalidate
                    >
                        <div class="e-modal__section">
                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        email.errors.message &&
                                        email.errors.errors.subject,
                                }"
                                :invalid-message="email.errors.errors.subject"
                            >
                                <label class="e-form__label">Onderwerp</label>
                                <input
                                    class="e-form__input"
                                    type="text"
                                    v-model="state.form.subject"
                                />
                            </div>
                            <div class="e-form__field-wrap">
                                <label
                                    class="e-form__label e-form__label--has-select-inside"
                                >
                                    Inhoud
                                </label>
                                <v-md-editor
                                    v-model="state.form.content"
                                    :toolbar="toolbar.toolbar"
                                    left-toolbar="undo redo | mailtags | h bold italic strikethrough quote | ul ol table hr | link image"
                                    right-toolbar="preview fullscreen"
                                    height="400px"
                                    :mode="'edit'"
                                ></v-md-editor>
                            </div>
                        </div>

                        <div
                            class="e-modal__section e-modal__section--bordered"
                        >
                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        email.errors.message &&
                                        email.errors.errors.product_id,
                                }"
                                :invalid-message="
                                    email.errors.errors.product_id
                                "
                            >
                                <label class="e-form__label"
                                    >Versturen wanneer een bestelling de
                                    volgende producten bevat</label
                                >
                                <Multiselect
                                    v-model="state.form.products"
                                    mode="tags"
                                    :close-on-select="false"
                                    :searchable="true"
                                    :create-option="true"
                                    :options="options.options['products']"
                                    placeholder="Deze keuze is optioneel"
                                />
                            </div>

                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        email.errors.message &&
                                        email.errors.errors.when_incomplete,
                                }"
                                :invalid-message="
                                    email.errors.errors.when_incomplete
                                "
                            >
                                <div
                                    class="e-form__multiple-checkboxes e-form__multiple-checkboxes--list"
                                >
                                    <label class="e-form__checkbox-wrap">
                                        <input
                                            class="e-form__checkbox"
                                            type="checkbox"
                                            v-model="state.form.when_incomplete"
                                        />
                                        <span
                                            class="e-form__checkbox-label"
                                        ></span>
                                        <span class="e-form__checkbox-text"
                                            >Aleen versturen indien benodigde
                                            bestanden niet aangeleverd
                                            zijn</span
                                        >
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div
                            class="e-modal__section e-modal__section--bordered"
                        >
                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        email.errors.message &&
                                        email.errors.errors.sending_moment,
                                }"
                                :invalid-message="
                                    email.errors.errors.sending_moment
                                "
                            >
                                <label class="e-form__label"
                                    >Wanneer moet de mail verstuurd
                                    worden?</label
                                >
                                <div class="e-form__select-wrap">
                                    <select
                                        class="e-form__select"
                                        v-model="state.form.sending_moment"
                                    >
                                        <option
                                            v-for="(option, value) in enums
                                                .options[
                                                'email-sending-moment'
                                            ]"
                                            v-bind:value="option"
                                            :key="value"
                                        >
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        email.errors.message &&
                                        email.errors.errors.days,
                                }"
                                :invalid-message="email.errors.errors.days"
                            >
                                <label class="e-form__label"
                                    >Aantal dagen
                                    <span class="e-form__label-helper"
                                        >Indien 0 dagen wordt de mail direct
                                        verstuurd wanneer de actie
                                        plaatsvindt</span
                                    ></label
                                >
                                <input
                                    class="e-form__input"
                                    type="number"
                                    v-model="state.form.days"
                                    min="1"
                                />
                            </div>

                            <div
                                v-show="state.form.days > 0"
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        email.errors.message &&
                                        email.errors.errors.time,
                                }"
                                :invalid-message="email.errors.errors.time"
                            >
                                <label class="e-form__label"
                                    >Tijdstip
                                    <span class="e-form__label-helper"
                                        >Vul het tijdstip voor wanneer de mail
                                        de deur uit moet</span
                                    ></label
                                >
                                <input
                                    class="e-form__input"
                                    type="time"
                                    v-model="state.form.time"
                                />
                            </div>
                        </div>

                        <div
                            class="e-modal__section e-modal__section--center-text"
                        >
                            <button
                                class="e-button e-button--full-width e-button--green js-close-modal"
                            >
                                Toevoegen
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div
                class="e-modal__bg js-close-modal"
                @click="Modals.close()"
            ></div>
        </div>
    </article>
</template>

<script setup>
import SvgArrowLeft from "@/svg/arrow-left.svg";
import SvgCheck from "@/svg/check.svg";
import SvgCross from "@/svg/cross.svg";

import { onMounted, ref, inject, reactive } from "vue";
import { useEmailStore } from "@/store/email";
import Multiselect from "@vueform/multiselect";
import { useOptionStore } from "@/store/option";

import { useToolbarStore } from "@/store/toolbar";
import { useEnumStore } from "@/store/enum";

const Modals = inject("Modals");

const email = useEmailStore();

const enums = useEnumStore();

enums.fill("email-sending-moment");

const toolbar = useToolbarStore();
toolbar.email();

const options = useOptionStore();
options.fill("products");

const state = reactive({
    form: {
        products: [],
    },
});

onMounted(() => {
    email.errors.errors = { message: null, errors: [] };
});

function onSubmit() {
    email.store(state.form);
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
