import { useAuthStore } from "@/store/auth";
import { defineStore } from "pinia";
import SecureLS from "secure-ls";

export const useDashboardProductionStore = defineStore({
    id: "dashboard-production",
    state: () => ({
        errors: {
            message: null,
            errors: [],
        },
        orders: [],
        filters: {},
        nested: {},
        activityList: [],
    }),
    actions: {
        api() {
            return "/api/dashboards/production/stats/";
        },
        auth() {
            const user = useAuthStore();
            return {
                headers: {
                    Authorization: "Bearer " + user.token(),
                    Products: JSON.stringify(this.getActiveFilters()),
                },
            };
        },

        async fill(weeks) {
            var url = this.api();
            if (weeks != null) {
                url = this.api() + "?add-weeks=" + weeks;
            }

            try {
                let result = await axios.get(url, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    console.info("fill", result.data);
                    return result.data;
                } else {
                    return response.status;
                }
            } catch (error) {
                this.fail(error);
                return error.response.status;
            }
        },

        async show(path, weeks) {
            if (weeks != null) {
                path = path + "?add-weeks=" + weeks;
            }

            try {
                let result = await axios.get(this.api() + path, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
                return error.response.status;
            }
        },

        async products() {
            try {
                let result = await axios.get(
                    "/api/dashboards/production/products",
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    console.info("fill", result.data);
                    return result.data;
                } else {
                    return response.status;
                }
            } catch (error) {
                this.fail(error);
                return error.response.status;
            }
        },

        async saveFilters(filters) {
            const ls = new SecureLS();
            ls.set("production-products", filters);
            return filters;
        },
        clearFilters() {
            const ls = new SecureLS();
            ls.set("production-products", {});
        },

        activeFilters() {
            this.$patch({
                filters: this.getActiveFilters(),
            });
        },
        getActiveFilters() {
            const ls = new SecureLS();
            let filters = ls.get("production-products");
            if (!filters) {
                this.clearFilters();
            }
            return ls.get("production-products");
        },
        checkResults(result) {
            try {
                if (result.data && Object.keys(result.data).length <= 0) {
                    Eventing.fire(
                        "toasting",
                        "Geen resultaten gevonden",
                        "info"
                    );
                }
            } catch (error) {}
        },

        deleted() {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Verwijderd", "warning");
            this.fill();
        },
        updated(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Aangepast: " + result.data.data.hash);
            this.fill();
        },
        added(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Toegevoegd: " + result.data.data.hash);
            this.fill();
        },
        success(result) {
            this.$patch({
                orders: result.data,
                activityList: [],
            });
            this.checkResults(result.data);
        },
        fail(error) {
            console.error(error);
            this.$patch({
                errors: error.response.data,
            });
            Eventing.fire("error", error.response);
        },

        checkResults(result) {
            try {
                if (result.data && Object.keys(result.data).length <= 0) {
                    Eventing.fire(
                        "toasting",
                        "Geen resultaten gevonden",
                        "info"
                    );
                }
            } catch (error) {}
        },
    },
});
