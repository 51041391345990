<template>
    <section>
        <modal-app-contact-create v-if="state.modal == 'contact-create'" />
        <modal-app-contact-update
            v-if="state.modal == 'contact-update'"
            :path="state.path"
        />

        <modal-app-email-create v-if="state.modal == 'email-create'" />
        <modal-app-email-update
            v-if="state.modal == 'email-update'"
            :path="state.path"
        />

        <modal-app-product-create v-if="state.modal == 'product-create'" />
        <modal-app-product-update
            v-if="state.modal == 'product-update'"
            :path="state.path"
        />

        <modal-app-user-create v-if="state.modal == 'user-create'" />
        <modal-app-user-update
            v-if="state.modal == 'user-update'"
            :path="state.path"
        />

        <modal-app-delivery-area-create
            v-if="state.modal == 'delivery-area-create'"
        />
        <modal-app-delivery-area-update
            v-if="state.modal == 'delivery-area-update'"
            :path="state.path"
        />

        <modal-app-order-products
            v-if="state.modal == 'order-products'"
            :path="state.path"
        />

        <modal-dashboard-production-products
            v-if="state.modal == 'dashboard-production-products'"
        />

        <modal-app-product-category-create
            v-if="state.modal == 'product-category-create'"
        />
        <modal-app-product-category-update
            v-if="state.modal == 'product-category-update'"
            :path="state.path"
        />

        <modal-app-order-change-status
            v-if="state.modal == 'order-change-status'"
        />
        <modal-app-order-filter v-if="state.modal == 'order-filter'" />

        <modal-app-maatwerk-runs v-if="state.modal == 'manage-runs'" />
    </section>
</template>
<script setup>
import { inject, onMounted, reactive } from "vue";
const Modals = inject("Modals");

const state = reactive({
    modal: null,
    path: null,
});

onMounted(() => {
    Eventing.listen("modal", (screen, path) => openModal(screen, path));
    Eventing.listen("closeModal", () => closeModal());
});

function openModal(screen, path) {
    state.modal = screen;
    if (typeof path !== "undefined") {
        state.path = path;
    }
    Modals.open();
}

function closeModal() {
    state.modal = null;
    Modals.closing();
}
</script>
