function toggleClass(triggerClass, targetClass, className) {
	var elements = document.querySelectorAll(targetClass);
	var clickableItems = document.querySelectorAll(triggerClass);

	clickableItems.forEach(item => {
		item.onclick = function() {

			elements.forEach(element => {
				element.classList.toggle(className);
			});
		}
	});
}

toggleClass(".js-toggle-mobile-menu", "body", "body--mobile-menu-is-open");
toggleClass(".js-toggle-topbar-menu", "body", "body--topbar-menu-is-open");