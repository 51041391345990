<template>
    <article>
        <client-navigation />
        <div class="l-main__client">
            <router-view name="content"></router-view>
        </div>
        <!--  dit is weggehaald. Kan verwijderd worden
        <section style="margin: 0 auto 4rem; text-align: center; width: 100%">
            <a @click="logOff()" class="e-button e-button--grey-dark mb-80">
                Uitloggen
            </a>
        </section>-->
    </article>
</template>

<script>
import { useAuthClientStore } from "@/store/authClient";

export default {
    mounted() {
        document.body.className = "";
        document.body.classList.add("body--full-width", "body--client-login");
        if ("client.app" == this.$route.name) {
            this.$router.push({
                name: "client.order",
            });
        }
        const user = useAuthClientStore();
        Echo.join("orders." + user.order())
            .listen(".new-chat", (event) => {
                if (event.chat.type != "Notitie") {
                    Eventing.fire("newChat", event.chat);
                }
            })
            .listen(".new-address", (event) => {
                console.info(event.address);
                Eventing.fire(
                    "addAddress." + event.address.box.hash,
                    event.address
                );
            })
            .here((users) => {
                this.users = users;
            })
            .joining((user) => {
                this.users.push(user);
            })
            .leaving((user) => {
                this.users = this.users.filter((u) => u.id != user.id);
            });
    },
    methods: {
        logOff() {
            const user = useAuthClientStore();
            user.logout();
        },
    },
};
</script>
