<template>
    <article>
        <router-view name="content"></router-view>
    </article>
</template>

<script>
import { useAuthStore } from "@/store/auth";
import Swal from "sweetalert2";

export default {
    mounted() {
        const auth = useAuthStore();
        let hash = auth.hash();

        document.body.className = "";
        if ("app" == this.$route.name) {
            this.$router.replace({
                name: "app.orders",
            });
        }

        Echo.private(`users.` + hash).notification((notification) => {
            // Eventing.fire("fetchNotifications");
            if (notification.type.includes("NewMessage")) {
                //
            }
            if (notification.message) {
                // setTimeout(() => {
                Swal.fire({
                    toast: true,
                    text: notification.message
                        .replace("{", "")
                        .replace("}", "")
                        .replace("{", "")
                        .replace("}", "")
                        .replace("{", "")
                        .replace("}", ""),
                    icon: "info",
                    position: "bottom-right",
                    showConfirmButton: false,
                    timer: 5000,
                });
                // }, 3001);
            }
            try {
                if (notification.download) {
                    const url = notification.download;
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        "pakbonnen-" + new Date().toUTCString() + ".pdf"
                    );
                    document.body.appendChild(link);
                    link.click();
                }
            } catch (error) {}

            try {
                if (notification.reloadOrders) {
                    Eventing.fire("reloadOrders");
                }
            } catch (error) {}
        });

        Echo.join("app")
            .here((users) => {
                this.users = users;
                Eventing.fire("onlineUsers", this.users);
            })
            .joining((user) => {
                this.users.push(user);
                Eventing.fire("onlineUsers", this.users);
            })
            .leaving((user) => {
                this.users = this.users.filter((u) => u.id != user.id);
                Eventing.fire("onlineUsers", this.users);
            });
    },
    data() {
        return {
            users: [],
        };
    },
};
</script>
