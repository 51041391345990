<template>
    <article>
        <div class="e-modal e-modal--w600" modal-id="on-transport">
            <div class="e-modal__wrap">
                <div class="e-modal__modal">
                    <div class="e-modal__close js-close-modal">
                        <SvgCross @click="Modals.close()" />
                    </div>

                    <div class="e-modal__section">
                        <div class="e-modal__section-title">
                            <h2 class="e-modal__title">Verwerkt door</h2>
                        </div>
                    </div>

                    <div class="e-modal__section">
                        <div class="e-form">
                            <div
                                class="e-form__field-wrap e-form__field-wrap--align-center"
                            >
                                <input
                                    class="e-form__input e-form__input--align-center"
                                    placeholder=""
                                />
                            </div>
                            <a
                                href="/frontend/transport"
                                class="e-button e-button--green e-button--full-width"
                                >Afronden</a
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="e-modal__bg js-close-modal"
                @click="Modals.close()"
            ></div>
        </div>
    </article>
</template>

<script setup>
import SvgInfo from "@/svg/info.svg";
import SvgEdit from "@/svg/edit.svg";
import SvgGift from "@/svg/gift.svg";
import SvgPlus from "@/svg/plus.svg";
import SvgCross from "@/svg/cross.svg";
import SvgCheck from "@/svg/check.svg";
import SvgDelete from "@/svg/delete.svg";

import { onMounted, ref, inject } from "vue";
const Modals = inject("Modals");
</script>
