<template>
    <article>
        <form
            method="POST"
            @submit.prevent="onSubmit"
            @keydown="user.errors.errors = {}"
            novalidate
            v-if="state.form"
        >
            <div class="c-topbar">
                <div class="c-topbar__left">
                    <div
                        class="c-topbar__left-button e-menu-button js-toggle-mobile-menu"
                        @click="menu('mobile')"
                    >
                        <div class="e-menu-button__bars-wrap">
                            <div class="e-menu-button__bars"></div>
                        </div>
                    </div>
                    <ul class="c-breadcrumbs">
                        <li class="c-breadcrumbs__item">
                            <p class="c-breadcrumbs__text">
                                Profiel instellingen
                            </p>
                        </li>
                    </ul>
                </div>
                <div
                    class="c-topbar__right-button e-menu-button js-toggle-topbar-menu"
                    @click="menu('topbar')"
                >
                    <div class="e-menu-button__bars-wrap">
                        <div class="e-menu-button__bars"></div>
                    </div>
                </div>
                <div class="c-topbar__right">
                    <a
                        class="e-button e-button--green"
                        v-if="state.form.role == 'Administratie' || true"
                        @click="makeConnectionToExact()"
                    >
                        Exact connectie
                    </a>
                </div>
            </div>

            <div class="c-page-content">
                <div
                    class="c-page-content__container c-page-content__container--spacing c-page-content__container--two-columns"
                >
                    <div class="c-page-content__col">
                        <section class="c-page-content__section e-module-style">
                            <div class="e-section-heading">
                                <div class="e-section-heading__left">
                                    <h2 class="e-section-heading__name">
                                        Gegevens
                                    </h2>
                                </div>
                            </div>
                            <div class="e-form">
                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            user.errors.message &&
                                            user.errors.errors.name,
                                    }"
                                    :invalid-message="user.errors.errors.name"
                                >
                                    <label class="e-form__label">Naam</label>
                                    <input
                                        class="e-form__input"
                                        type="text"
                                        v-model="state.form.name"
                                    />
                                </div>
                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            user.errors.message &&
                                            user.errors.errors.email,
                                    }"
                                    :invalid-message="user.errors.errors.email"
                                >
                                    <label class="e-form__label">E-mail</label>
                                    <input
                                        class="e-form__input"
                                        type="text"
                                        v-model="state.form.email"
                                    />
                                </div>
                            </div>
                        </section>

                        <section class="c-page-content__section e-module-style">
                            <div class="e-section-heading">
                                <div class="e-section-heading__left">
                                    <h2 class="e-section-heading__name">
                                        Profielfoto
                                    </h2>
                                </div>
                            </div>
                            <div class="e-form">
                                <div class="e-form__upload-area">
                                    <input
                                        class="e-form__upload-area-input"
                                        type="file"
                                        ref="uploadFile"
                                        required
                                        accept="image/*"
                                        v-on:change="
                                            handleFileUpload('profile-picture')
                                        "
                                    />
                                    <div class="e-form__upload-area-text">
                                        <div
                                            class="e-form__upload-area-text-uploading"
                                        >
                                            Uploading...
                                        </div>
                                        <div
                                            class="e-form__upload-area-text-default"
                                        >
                                            Selecteer een afbeelding om te
                                            uploaden
                                        </div>
                                        <progress
                                            v-if="
                                                state.uploadProgress > 0 &&
                                                state.uploadProgress < 100
                                            "
                                            :value="state.uploadProgress"
                                            max="100"
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section class="c-page-content__section e-module-style">
                            <div class="e-section-heading">
                                <div class="e-section-heading__left">
                                    <div class="e-section-heading__icon">
                                        <SvgNotifications />
                                    </div>
                                    <h2 class="e-section-heading__name">
                                        Notificaties
                                    </h2>
                                </div>
                            </div>

                            <div
                                class="e-form__field-wrap e-form__field-wrap--checkboxes"
                            >
                                <label class="e-form__label e-form__label--big"
                                    >E-mail</label
                                >
                                <div
                                    class="e-form__multiple-checkboxes e-form__multiple-checkboxes--list"
                                >
                                    <label
                                        class="e-form__checkbox-wrap"
                                        v-for="(option, value) in enums.options[
                                            'user-notifications'
                                        ]"
                                        v-bind:value="option"
                                        :key="value"
                                    >
                                        <input
                                            class="e-form__checkbox"
                                            type="checkbox"
                                            name="notifications"
                                            :value="value"
                                            v-model="state.form.notifications"
                                        />
                                        <span
                                            class="e-form__checkbox-label"
                                        ></span>
                                        <span class="e-form__checkbox-text">
                                            {{ option }}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </section>

                        <section class="c-page-content__section">
                            <button class="e-button e-button--green">
                                Opslaan
                            </button>
                        </section>
                    </div>

                    <div class="c-page-content__col">
                        <section class="c-page-content__section e-module-style">
                            <div class="e-section-heading">
                                <div class="e-section-heading__left">
                                    <div class="e-section-heading__icon">
                                        <SvgPencil />
                                    </div>
                                    <h2 class="e-section-heading__name">
                                        E-mail handtekening Feelingz
                                    </h2>
                                </div>
                            </div>

                            <v-md-editor
                                v-model="state.form.signature.feelingz"
                                :toolbar="toolbar.toolbar"
                                @upload-image="
                                    toolbar.toolbar.handleUploadImage
                                "
                                :disabled-menus="[]"
                                left-toolbar="undo redo | h bold italic strikethrough | ul ol table | link image"
                                right-toolbar="preview fullscreen"
                                height="auto"
                            ></v-md-editor>
                        </section>

                        <section class="c-page-content__section e-module-style">
                            <div class="e-section-heading">
                                <div class="e-section-heading__left">
                                    <div class="e-section-heading__icon">
                                        <SvgPencil />
                                    </div>
                                    <h2 class="e-section-heading__name">
                                        E-mail handtekening Kerstcadeaukiezen.nl
                                    </h2>
                                </div>
                            </div>

                            <v-md-editor
                                v-model="state.form.signature.kerstcadeaukiezen"
                                :toolbar="toolbar.toolbar"
                                @upload-image="
                                    toolbar.toolbar.handleUploadImage
                                "
                                :disabled-menus="[]"
                                left-toolbar="undo redo | h bold italic strikethrough | ul ol table | link image"
                                right-toolbar="preview fullscreen"
                                height="auto"
                            ></v-md-editor>
                        </section>
                    </div>
                </div>
            </div>
        </form>
    </article>
</template>

<script setup>
import SvgDelete from "@/svg/delete.svg";
import SvgPlus from "@/svg/plus.svg";
import SvgCheck from "@/svg/check.svg";
import SvgFilter from "@/svg/filter.svg";
import SvgSearch from "@/svg/search.svg";
import SvgChat from "@/svg/chat.svg";
import SvgRefresh from "@/svg/refresh.svg";
import SvgPencil from "@/svg/pencil.svg";
import SvgNotifications from "@/svg/notifications.svg";

import { onMounted, reactive, ref, inject } from "vue";
import { useUserStore } from "@/store/user";
import { useEnumStore } from "@/store/enum";
import { useAuthStore } from "@/store/auth";
import { useUploadStore } from "@/store/upload";
import { useToolbarStore } from "@/store/toolbar";
import { useExactStore } from "@/store/exact";

import Vapor from "laravel-vapor";
const uploadFile = ref(null);

const user = useUserStore();
const auth = useAuthStore();
const upload = useUploadStore();
const toolbar = useToolbarStore();

const exact = useExactStore();

const enums = useEnumStore();

enums.fill("user-notifications");

const state = reactive({
    form: {
        signature: {},
        notifications: [],
    },
    uploadProgress: 0,
});

user.show(auth.auth().user.hash);
toolbar.email();

onMounted(() => {
    Eventing.listen("fillForm", (data) => {
        state.form = data.data;
    });
});

function onSubmit() {
    user.update(auth.auth().user.hash, state.form);
    Eventing.fire("userChanged", state.form);
}

function menu(menu) {
    Eventing.fire("toggleMenu", menu);
}

async function makeConnectionToExact() {
    await exact.init();
}

function handleFileUpload(folder) {
    let file = uploadFile.value.files[0];
    Vapor.store(file, {
        progress: (progress) => {
            state.uploadProgress = Math.round(progress * 100);
        },
        headers: { Authorization: "Bearer " + auth.token() },
    }).then((response) => {
        let form = {
            uuid: response.uuid,
            key: response.key,
            bucket: response.bucket,
            name: file.name,
            content_type: file.type,
            folder: folder,
        };
        upload.store("profile-picture", form);
        setTimeout(() => {
            Eventing.fire("userChanged", state.form);
        }, 1000);
    });
}
</script>
