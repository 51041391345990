<template>
    <div class="e-form__upload-area">
        <input
            class="e-form__upload-area-input"
            type="file"
            ref="uploadFile"
            required
            v-on:change="handleFileUpload(attachment)"
        />
        <div class="e-form__upload-area-text">
            <div class="e-form__upload-area-text-uploading">Uploading...</div>
            <div class="e-form__upload-area-text-default">
                Upload:
                {{ attachment.name }}
            </div>
            <progress
                v-if="state.uploadProgress > 0 && state.uploadProgress < 100"
                :value="state.uploadProgress"
                max="100"
            />
        </div>
    </div>
</template>
<script setup>
import { useAuthStore } from "@/store/auth";
import { useUploadStore } from "@/store/upload";
import Vapor from "laravel-vapor";
import { reactive, ref } from "vue";

const props = defineProps({
    attachment: Object,
    order: String,
    box: String,
    addition: Number,
});

const auth = useAuthStore();
const upload = useUploadStore();

const uploadFile = ref(null);

const state = reactive({
    uploadProgress: 0,
});

function handleFileUpload(attachment) {
    let folder = "orders/" + props.order + "/box-" + props.box;
    if (props.box == "undefined" || typeof props.box === "undefined") {
        folder = "orders/" + props.order + "/addition-" + props.addition;
    }
    let file = uploadFile.value.files[0];
    Vapor.store(file, {
        progress: (progress) => {
            state.uploadProgress = Math.round(progress * 100);
        },
        headers: { Authorization: "Bearer " + auth.token() },
    }).then(async (response) => {
        let form = {
            uuid: response.uuid,
            key: response.key,
            bucket: response.bucket,
            name: file.name,
            content_type: file.type,
            folder: folder,
            attachment_id: attachment.id,
        };
        let result = await upload.store("attachment-order", form);
        if (result) {
            setTimeout(() => {
                Eventing.fire("reloadOrder");
                Eventing.fire("reloadAttachments");
            }, 300);
        }
    });
}
</script>
