import { defineStore } from "pinia";
import { useAuthClientStore } from "@/store/authClient";

export const useUploadStore = defineStore({
    id: "uploads",
    state: () => ({
        errors: {
            message: null,
            errors: [],
        },
    }),
    actions: {
        api() {
            return "/api/clients/upload/";
        },
        auth() {
            const user = useAuthClientStore();
            return {
                headers: {
                    Authorization: "Bearer " + user.token(),
                    Order: user.order(),
                },
            };
        },
        async store(what, form) {
            try {
                let result = await axios.put(
                    this.api() + what,
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    // this.added(result);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        fail(error) {
            console.error(error);
            this.$patch({
                errors: error.response.data,
            });
            Eventing.fire("error", error.response);
        },
    },
});
