import { createRouter, createWebHashHistory } from "vue-router";

import AppApp from "@/screens/App/App.vue";
import AppContacts from "@/screens/App/Contacts.vue";
import AppDashboards from "@/screens/App/Dashboards.vue";
import AppDashboardsDesign from "@/screens/App/Dashboards/Design.vue";
import AppDashboardsFinance from "@/screens/App/Dashboards/Finance.vue";
import AppDashboardsProduction from "@/screens/App/Dashboards/Production.vue";
import AppDashboardsShopManager from "@/screens/App/Dashboards/ShopManager.vue";
import AppDashboardsTrafficFinance from "@/screens/App/Dashboards/Traffic/Finance.vue";
import AppDashboardsTrafficMaatwerkRuns from "@/screens/App/Dashboards/Traffic/MaatwerkRuns.vue";
import AppDashboardsTraffic from "@/screens/App/Dashboards/Traffic/Orders.vue";
import AppDashboardsTrafficStock from "@/screens/App/Dashboards/Traffic/Stock.vue";
import AppDeliveryAreas from "@/screens/App/DeliveryAreas.vue";
import AppEmails from "@/screens/App/Emails.vue";
import AppModals from "@/screens/App/Modals.vue";
import AppNotifications from "@/screens/App/Notifications.vue";
import AppOrder from "@/screens/App/Order.vue";
import AppOrderBilling from "@/screens/App/Order/Billing.vue";
import AppOrderContact from "@/screens/App/Order/Contact.vue";
import AppOrderDelivery from "@/screens/App/Order/Delivery.vue";
import AppOrderFiles from "@/screens/App/Order/Files.vue";
import AppOrderMaatwerk from "@/screens/App/Order/Maatwerk.vue";
import AppOrderOrder from "@/screens/App/Order/Order.vue";
import AppOrders from "@/screens/App/Orders.vue";
import AppProducts from "@/screens/App/Products.vue";
import AppProfileSettings from "@/screens/App/ProfileSettings.vue";
import AppSidebar from "@/screens/App/Sidebar.vue";
import AppStock from "@/screens/App/Stock.vue";
import AppUsers from "@/screens/App/Users.vue";

import ProductionApp from "@/screens/Production/App.vue";
import ProductionHeader from "@/screens/Production/Header.vue";
import ProductionModals from "@/screens/Production/Modals.vue";
import ProductionOrder from "@/screens/Production/Order.vue";
import ProductionOverview from "@/screens/Production/Overview.vue";
import ProductionScan from "@/screens/Production/Scan.vue";

import TransportApp from "@/screens/Transport/App.vue";
import TransportHeader from "@/screens/Transport/Header.vue";
import TransportModals from "@/screens/Transport/Modals.vue";
import TransportOrder from "@/screens/Transport/Order.vue";
import TransportOrders from "@/screens/Transport/Orders.vue";
import TransportReceiver from "@/screens/Transport/Receiver.vue";
import TransportScan from "@/screens/Transport/Scan.vue";

import ClientApp from "@/screens/Client/App.vue";
import ClientBilling from "@/screens/Client/Billing.vue";
import ClientContact from "@/screens/Client/Contact.vue";
import ClientDelivery from "@/screens/Client/Delivery.vue";
import ClientFiles from "@/screens/Client/Files.vue";
import ClientModals from "@/screens/Client/Modals.vue";
import ClientOrder from "@/screens/Client/Order.vue";

import AuthClientLogin from "@/screens/Auth/ClientLogin.vue";
import AuthLogin from "@/screens/Auth/Login.vue";

const routes = [
    {
        path: "/",
        name: "start",
        redirect: "/login",
    },
    {
        path: "/app",
        name: "app",
        components: {
            main: AppApp,
            sidebar: AppSidebar,
            modal: AppModals,
        },
        children: [
            {
                path: "dashboards",
                name: "app.dashboards",
                components: {
                    content: AppDashboards,
                },
                children: [
                    {
                        path: "production",
                        name: "app.dashboards.production",
                        components: {
                            tab: AppDashboardsProduction,
                        },
                    },
                    {
                        path: "design",
                        name: "app.dashboards.design",
                        components: {
                            tab: AppDashboardsDesign,
                        },
                    },
                    {
                        path: "shopmanager",
                        name: "app.dashboards.shopmanager",
                        components: {
                            tab: AppDashboardsShopManager,
                        },
                    },
                    {
                        path: "traffic",
                        name: "app.dashboards.traffic.orders",
                        components: {
                            tab: AppDashboardsTraffic,
                        },
                    },
                    {
                        path: "traffic/voorraad-standen",
                        name: "app.dashboards.traffic.stock",
                        components: {
                            tab: AppDashboardsTrafficStock,
                        },
                    },
                    {
                        path: "traffic/administratie",
                        name: "app.dashboards.traffic.finance",
                        components: {
                            tab: AppDashboardsTrafficFinance,
                        },
                    },
                    {
                        path: "traffic/maatwerk-runs",
                        name: "app.dashboards.traffic.maatwerk-runs",
                        components: {
                            tab: AppDashboardsTrafficMaatwerkRuns,
                        },
                    },
                    {
                        path: "administratie",
                        name: "app.dashboards.finance",
                        components: {
                            tab: AppDashboardsFinance,
                        },
                    },
                ],
            },
            {
                path: "orders",
                name: "app.orders",
                components: {
                    content: AppOrders,
                },
            },
            {
                path: "order/:hash",
                name: "app.order",
                components: {
                    content: AppOrder,
                },
                children: [
                    {
                        path: "bestelling",
                        name: "app.order.order",
                        components: {
                            tab: AppOrderOrder,
                        },
                    },
                    {
                        path: "bestanden",
                        name: "app.order.files",
                        components: {
                            tab: AppOrderFiles,
                        },
                    },
                    {
                        path: "bezorging",
                        name: "app.order.delivery",
                        components: {
                            tab: AppOrderDelivery,
                        },
                    },
                    {
                        path: "facturatie",
                        name: "app.order.billing",
                        components: {
                            tab: AppOrderBilling,
                        },
                    },
                    {
                        path: "communicatie",
                        name: "app.order.contact",
                        components: {
                            tab: AppOrderContact,
                        },
                    },
                    {
                        path: "maatwerk",
                        name: "app.order.maatwerk",
                        components: {
                            tab: AppOrderMaatwerk,
                        },
                    },
                ],
            },
            {
                path: "assortiment",
                name: "app.products",
                components: {
                    content: AppProducts,
                },
            },
            {
                path: "voorraad",
                name: "app.stock",
                components: {
                    content: AppStock,
                },
            },
            {
                path: "emails",
                name: "app.emails",
                components: {
                    content: AppEmails,
                },
            },
            {
                path: "gebruikers",
                name: "app.users",
                components: {
                    content: AppUsers,
                },
            },
            {
                path: "bezorggebieden",
                name: "app.delivery-areas",
                components: {
                    content: AppDeliveryAreas,
                },
            },
            {
                path: "notificaties",
                name: "app.notifications",
                components: {
                    content: AppNotifications,
                },
            },
            {
                path: "klantenbestand",
                name: "app.contacts",
                components: {
                    content: AppContacts,
                },
            },
            {
                path: "profiel-instellingen",
                name: "app.profile-settings",
                components: {
                    content: AppProfileSettings,
                },
            },
        ],
    },
    {
        path: "/productie",
        name: "production.app",
        components: {
            main: ProductionApp,
            header: ProductionHeader,
            modal: ProductionModals,
        },
        children: [
            {
                path: "scan",
                name: "production.scan",
                components: {
                    content: ProductionScan,
                },
            },
            {
                path: "order/:hash/:box_hash?",
                name: "production.order",
                components: {
                    content: ProductionOrder,
                },
            },
            {
                path: "overzicht",
                name: "production.overview",
                components: {
                    content: ProductionOverview,
                },
            },
        ],
    },
    {
        path: "/transport",
        name: "transport.app",
        components: {
            main: TransportApp,
            header: TransportHeader,
            modal: TransportModals,
        },
        children: [
            {
                path: "scan",
                name: "transport.scan",
                components: {
                    content: TransportScan,
                },
            },
            {
                path: "bestellingen",
                name: "transport.orders",
                components: {
                    content: TransportOrders,
                },
            },
            {
                path: "bestelling",
                name: "transport.order",
                components: {
                    content: TransportOrder,
                },
            },
            {
                path: "ontvanger/:order/:box/:address/:signature",
                name: "transport.receiver",
                components: {
                    content: TransportReceiver,
                },
            },
        ],
    },
    {
        path: "/klant",
        name: "client.app",
        components: {
            main: ClientApp,
            modal: ClientModals,
        },
        children: [
            {
                path: "bestelling",
                name: "client.order",
                components: {
                    content: ClientOrder,
                },
            },
            {
                path: "aangeleverd",
                name: "client.files",
                components: {
                    content: ClientFiles,
                },
            },
            {
                path: "bezorging",
                name: "client.delivery",
                components: {
                    content: ClientDelivery,
                },
            },
            {
                path: "facturatie",
                name: "client.billing",
                components: {
                    content: ClientBilling,
                },
            },
            {
                path: "communicatie",
                name: "client.contact",
                components: {
                    content: ClientContact,
                },
            },
        ],
    },
    {
        path: "/login",
        name: "auth.login",
        components: {
            main: AuthLogin,
        },
    },
    {
        path: "/klant-login/:token/:email/:order",
        name: "auth.client-login",
        components: {
            main: AuthClientLogin,
        },
    },
    {
        path: "/logoff",
        name: "auth.logoff",
        components: {
            main: AuthLogin,
        },
    },
];

export default createRouter({
    history: createWebHashHistory(),
    routes,
});
