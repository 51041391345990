<template>
    <article>
        <div class="c-topbar">
            <h1 class="c-topbar__title" v-if="state.notifications.season">
                Welkom <b>{{ auth.name() }}</b
                >, er zijn {{ state.notifications.unread }} ongelezen
                notificaties
            </h1>
            <h1 class="c-topbar__title" v-if="!state.notifications.season">
                Welkom <b>{{ auth.name() }}</b
                >, data wordt geladen&hellip;
            </h1>
            <div class="c-topbar__right">
                <a
                    class="e-button e-button--green e-button--white-hover"
                    @click="loadNotifications()"
                >
                    <SvgRefresh />
                    Vernieuwen</a
                >
            </div>
        </div>

        <div class="c-below-topbar">
            <div
                class="e-hide-scrollbar e-hide-scrollbar--x"
                v-if="state.notifications.filters"
            >
                <div class="e-hide-scrollbar__inner">
                    <ul class="c-below-topbar__list">
                        <li
                            class="c-below-topbar__item"
                            :class="{
                                'is-hidden': amountOf(value, false) <= 0,
                            }"
                            v-for="(item, value) in state.notifications.filters"
                            :key="value"
                        >
                            <a
                                class="c-below-topbar__link"
                                :class="{
                                    'c-below-topbar__link--active':
                                        state.filter == value,
                                }"
                                @click="setFilter(value)"
                            >
                                {{ item }}
                                <span
                                    class="e-amount-label"
                                    v-show="amountOf(value, true) > 0"
                                    >{{ amountOf(value, true) }}</span
                                >
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div
            class="c-page-content c-page-content--has-page-menu"
            v-if="state.showPopover"
        >
            <div class="c-page-content__left">
                <div
                    class="c-timeline c-timeline--right-line c-timeline--bg-white"
                >
                    <div
                        class="c-timeline__messages"
                        v-if="state.notifications.data.length > 0"
                    >
                        <div
                            class="c-timeline__message"
                            v-for="(item, index) in state.notifications.data"
                            :key="index"
                            v-show="showNotifcation(item)"
                        >
                            <a
                                class="c-timeline__message-content"
                                :href="item.data.url"
                                :class="{
                                    read: item.read_at,
                                    unread: !item.read_at,
                                }"
                            >
                                <!-- If spoed -->
                                <div class="c-timeline__message-icon">
                                    <SvgSpeed v-if="item.data.is_urgent" />
                                </div>
                                <div class="c-timeline__message-text">
                                    <h2 v-if="item.data.order">
                                        Bestelling
                                        <span v-html="item.data.order"></span>
                                        van
                                        <span v-html="item.data.contact"></span>
                                    </h2>
                                    <p v-html="item.data.message"></p>
                                </div>
                            </a>
                            <span class="c-timeline__message-options">
                                <p class="c-timeline__message-date">
                                    {{ item.created_at }}
                                </p>
                                <a
                                    class="c-timeline__message-read"
                                    @click="readNotification(item)"
                                    v-if="!item.read_at"
                                >
                                    Markeer als gelezen
                                </a>
                            </span>
                        </div>

                        <div class="c-timeline__message">
                            <span class="c-timeline__message-options">
                                <a
                                    class="c-timeline__message-read"
                                    @click="readNotification('all')"
                                >
                                    <span>Markeer alles als gelezen</span>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="c-page-content__sidebar">
                <div
                    class="c-page-content__sidebar-section"
                    v-if="
                        state.notifications.season && state.notifications.stats
                    "
                >
                    <h2
                        class="c-page-content__sidebar-section-title"
                        @click="chartBars()"
                    >
                        {{ state.notifications.season }}
                    </h2>

                    <div
                        class="c-chart-bars js-chart-bars mt-20"
                        :data-total="state.notifications.data.length"
                    >
                        <div
                            class="c-chart-bars__bar e-tooltip"
                            v-for="(value, status) in state.notifications.stats"
                            :key="status"
                            :data-total="value"
                            :data-tooltip="status + ': ' + value + '×'"
                        ></div>
                    </div>
                    <ul class="c-data-list c-data-list--table-style">
                        <li
                            class="c-data-list__item"
                            v-for="(value, status) in state.notifications.stats"
                            :key="status"
                        >
                            <p class="c-data-list__subject">{{ status }}</p>
                            <p class="c-data-list__data">
                                {{ value }}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </article>
</template>

<script setup>
import SvgRefresh from "@/svg/refresh.svg";
import SvgSpeed from "@/svg/speed.svg";
import { inject, onMounted, reactive } from "vue";

import { useAuthStore } from "@/store/auth";

const user = useAuthStore();
const Modals = inject("Modals");
const auth = useAuthStore();
onMounted(async () => {
    Eventing.listen("fetchNotifications", () => {
        setTimeout(() => {
            state.showPopover = true;
            chartBars();
        }, 1000);
    });

    Eventing.listen("setNotifications", (data) => {
        state.notifications = data;
        chartBars();
    });
    Eventing.listen("fetchNotifications", () => {
        loadNotifications();
        chartBars();
    });

    loadNotifications();
});

const state = reactive({
    showPopover: true,
    notifications: {
        data: [],
        unread: 0,
        season: "",
        filters: null,
    },
    filter: null,
    stats: null,
});

async function loadNotifications() {
    state.filter = null;
    let data = await user.notifications();
    if (data) {
        state.notifications = await data.data;
        setTimeout(() => {
            chartBars();
        }, 500);
    }
}

function chartBars() {
    setTimeout(() => {
        const charts = document.querySelectorAll(".js-chart-bars");

        charts.forEach((chart) => {
            const bars = chart.querySelectorAll(".c-chart-bars__bar");
            let highestTotal = 0;

            //Set highestTotal
            bars.forEach((bar) => {
                const barTotal = parseInt(bar.getAttribute("data-total"));
                highestTotal =
                    barTotal > highestTotal ? barTotal : highestTotal;

                chart.setAttribute("data-total", highestTotal);
            });

            //Change bars height
            bars.forEach((bar) => {
                const barTotal = parseInt(bar.getAttribute("data-total"));
                bar.style.height = (barTotal / highestTotal) * 100 + "%";
            });
        });
    }, 1000);
}

function amountOf(type, unreads) {
    if (type == "Spoed") {
        return state.notifications.data.filter((obj) => {
            if (obj.data.is_urgent) {
                if (unreads == true && obj.read_at) {
                    return false;
                }
                return true;
            }
            return false;
        }).length;
    } else if (type == "Ongelezen") {
        return state.notifications.data.filter((obj) => {
            if (!obj.read_at) {
                return true;
            }
            return false;
        }).length;
    } else {
        const count = state.notifications.data.filter((obj) => {
            if (obj.data.message.includes(type)) {
                if (unreads == true && obj.read_at) {
                    return false;
                }
                return true;
            }
            return false;
        }).length;

        return count;
    }
}

function showNotifcation(item) {
    if (state.filter == "Spoed") {
        return item.data.is_urgent;
    } else if (state.filter == "Ongelezen") {
        return !item.read_at;
    } else if (state.filter && item.data.message.includes(state.filter)) {
        return true;
    } else if (state.filter && !item.data.message.includes(state.filter)) {
        return false;
    } else {
        if (item.data.message) {
            return true;
        }
    }
}

function setFilter(filter) {
    if (filter == state.filter) {
        state.filter = null;
    } else {
        state.filter = filter;
    }
}

function readNotification(which) {
    if (which == "all") {
        user.notificationRead("all");
    } else {
        which.read_at = true;
        user.notificationRead(which.id);
    }
}
</script>
<style scoped>
.read {
    opacity: 0.9;
}
.unread {
    border-left: 3px solid #78ceab;
}
</style>
