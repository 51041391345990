<template>
    <article>
        <div class="c-production-content">
            <div class="c-table">
                <div class="c-table__head">
                    <div class="c-table__tr">
                        <div class="c-table__td c-table__td--checkbox">
                            <label class="e-form__checkbox-wrap">
                                <input
                                    class="e-form__checkbox"
                                    type="checkbox"
                                />
                                <span
                                    class="e-form__checkbox-label"
                                ></span>
                            </label>
                        </div>
                        <div class="c-table__td">Postcode</div>
                        <div class="c-table__td">Colli</div>
                        <div class="c-table__td">Pakketnr.</div>
                    </div>
                </div>
                <div class="c-table__body">
                    <!-- @foreach(range(1, 8) as $i) -->

                    <router-link
                        class="c-table__tr"
                        :to="{ name: 'transport.order' }"
                    >
                        <div class="c-table__td c-table__td--checkbox">
                            <label class="e-form__checkbox-wrap">
                                <input
                                    class="e-form__checkbox"
                                    type="checkbox"
                                />
                                <span
                                    class="e-form__checkbox-label"
                                ></span>
                            </label>
                        </div>
                        <div class="c-table__td c-table__td--name">
                            1122AB
                        </div>
                        <div class="c-table__td">4</div>
                        <div class="c-table__td">KF1230029</div>
                    </router-link>
                    <!-- @endforeach -->
                </div>
            </div>

            <div class="c-production-content__delivery-data">
                <h2 class="c-production-content__title">Totaal</h2>
                <ul
                    class="c-data-list c-data-list--table-style c-data-list--no-ellipsis"
                >
                    <li class="c-data-list__item">
                        <p class="c-data-list__subject">Gewicht</p>
                        <p class="c-data-list__data">3500 gr</p>
                    </li>
                    <li class="c-data-list__item">
                        <p class="c-data-list__subject">Aantal colli</p>
                        <p class="c-data-list__data">5</p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="c-production-footer">
            <a
                class="e-button e-button--green e-button--full-width"
                href="/501"
                open-modal-id="on-transport"
                @click="Modals.show('transport-on-transport')"
                >Bestellingen zijn op Transport</a
            >
        </div>
    </article>
</template>

<script setup>
import { onMounted, ref, inject } from "vue";
const Modals = inject("Modals");
</script>
