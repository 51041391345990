<template>
    <article>
        <div
            class="e-modal e-modal--align-start"
            modal-id="dashboard-production-products"
        >
            <div class="e-modal__wrap">
                <div class="e-modal__modal">
                    <div class="e-modal__close js-close-modal">
                        <SvgCross @click="Modals.close()" />
                    </div>

                    <div class="e-modal__section">
                        <div class="e-modal__section-title">
                            <h2 class="e-modal__title">
                                Selecteer producten -
                                <span
                                    >{{ state.selection.length }} van
                                    {{ state.maxLimit }}</span
                                >
                            </h2>
                            <p
                                class="e-modal__desc e-modal__desc--small-spacing"
                            >
                                Selecteer maximaal 10 producten.
                            </p>
                        </div>
                        <div class="e-search-input mt-20">
                            <label
                                class="e-search-input__search-label"
                                for="table-search"
                            >
                                <SvgSearch />
                            </label>
                            <input
                                class="e-search-input__search-input e-form__input"
                                id="table-search"
                                type="search"
                                v-model="state.search"
                                @input="debounceSearch"
                                autocomplete="false"
                                spellcheck="false"
                                placeholder="Zoeken door producten..."
                            />
                        </div>
                    </div>

                    <div class="e-modal__section e-modal__section--bordered">
                        <div class="e-form e-modal__scroll-area">
                            <div
                                class="e-form__columns e-form__columns--small-gap e-form__columns--three-columns"
                            >
                                <label
                                    class="e-form__checkbox-wrap"
                                    v-for="(value, slug, key) in state.results"
                                    :key="'PK' + key"
                                    :class="{
                                        'limit-reached':
                                            state.selection.length >=
                                                state.maxLimit &&
                                            !state.selection.includes(
                                                value.slug
                                            ),
                                    }"
                                    v-show="inSearch(value)"
                                >
                                    <input
                                        class="e-form__checkbox"
                                        type="checkbox"
                                        :id="value.slug"
                                        :value="value.slug"
                                        v-model="state.selection"
                                        :disabled="
                                            state.selection.length >=
                                                state.maxLimit &&
                                            !state.selection.includes(
                                                value.slug
                                            )
                                        "
                                    />
                                    <span class="e-form__checkbox-label"></span>
                                    <span class="e-form__checkbox-text">
                                        {{ value.name }}
                                        <span
                                            class="e-form__checkbox-text-note"
                                        >
                                            {{ value.article_nr }}

                                            <!-- @W: Category -->
                                            <span
                                                v-if="
                                                    state.search &&
                                                    value.category
                                                "
                                                >|
                                                <span style="color: #5fb090">{{
                                                    value.category
                                                }}</span>
                                            </span>
                                        </span>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="e-modal__section">
                        <button
                            class="e-modal-submit__button e-button--full-width e-button e-button--green e-button--green--3d e-button--iconx16 js-close-modal"
                            @click="saveSelectionOfProducts()"
                        >
                            Opslaan
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </article>
</template>

<style scoped>
.limit-reached {
    opacity: 0.5;
    cursor: not-allowed;
}
</style>
<script setup>
import { useDashboardProductionStore } from "@/store/dashboard/production";
import SvgCross from "@/svg/cross.svg";
import SvgSearch from "@/svg/search.svg";
import { inject, reactive } from "vue";
const Modals = inject("Modals");

const props = defineProps({
    products: Object,
});

const production = useDashboardProductionStore();

const state = reactive({
    isPicking: false,

    results: [],
    selection: [],
    maxLimit: 10,
    search: null,
    debounce: 0,
});

async function loadProducts() {
    state.isPicking = true;
    state.selection = [];
    let results = await production.products();
    if (results) {
        state.results = results.data;
        state.results.forEach((product) => {
            if (product.checked == true) {
                state.selection.push(product.slug);
            }
        });
    }
}
loadProducts();

async function saveSelectionOfProducts() {
    let save = await production.saveFilters(state.selection);
    if (save) {
        state.isPicking = false;
        Eventing.fire("reloadResultsForDashboard");
        setTimeout(() => {
            Modals.close();
        }, 100);
    }
}

function debounceSearch(event) {
    clearTimeout(state.debounce);
    state.debounce = setTimeout(() => {
        state.search = event.target.value;
    }, 600);
}

function inSearch(product) {
    if (state.search == null || state.search == "") {
        return true;
    }
    if (
        product.article_nr.toLowerCase().includes(state.search) ||
        product.name.toLowerCase().includes(state.search) ||
        (product.category &&
            product.category.toLowerCase().includes(state.search))
    ) {
        return true;
    } else {
        return false;
    }
}
</script>
