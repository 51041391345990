<template>
    <article>
        <div class="c-topbar">
            <div class="c-topbar__left">
                <div
                    class="c-topbar__left-button e-menu-button js-toggle-mobile-menu"
                    @click="menu('mobile')"
                >
                    <div class="e-menu-button__bars-wrap">
                        <div class="e-menu-button__bars"></div>
                    </div>
                </div>
                <div class="c-topbar__search">
                    <label class="c-topbar__search-label" for="table-search">
                        <SvgSearch />
                    </label>
                    <input
                        class="c-topbar__search-input"
                        id="table-search"
                        type="search"
                        @input="debounceSearch"
                        autocomplete="false"
                        spellcheck="false"
                        placeholder="Zoeken door e-mails..."
                    />
                </div>
            </div>
            <div
                class="c-topbar__right-button e-menu-button js-toggle-topbar-menu"
                @click="menu('topbar')"
            >
                <div class="e-menu-button__bars-wrap">
                    <div class="e-menu-button__bars"></div>
                </div>
            </div>
            <div class="c-topbar__right">
                <button
                    class="e-button e-button--grey-dark"
                    open-modal-id="add-email"
                    @click="Modals.show('email-create')"
                >
                    <SvgPlus /> E-mail toevoegen
                </button>
            </div>
        </div>

        <div class="c-page-content">
            <div class="c-page-content__container c-page-content__container--spacing-small">

                <div class="c-table js-overflow-arrow">
                    <div class="c-table__head js-sticky-element">
                        <div class="c-table-item c-table-item--head">
                            <div class="c-table-item__col c-table-item__col--icon">
                                <div class="c-table-item__icon">
                                    <SvgSort />
                                </div>
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--subject c-table-item__col--sortable"
                                :class="{
                                    'c-table-item__col--sorted':
                                        state.filter == 'subject',
                                    'c-table-item__col--sorted-desc':
                                        state.orderBy == 'desc' &&
                                        state.filter == 'subject',
                                }"
                                @click="setFilter('subject')"
                            >
                                Naam
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--sending-moment c-table-item__col--sortable"
                                :class="{
                                    'c-table-item__col--sorted':
                                        state.filter == 'sending_moment',
                                    'c-table-item__col--sorted-desc':
                                        state.orderBy == 'desc' &&
                                        state.filter == 'sending_moment',
                                }"
                                @click="setFilter('sending_moment')"
                            >
                                Verzendmoment
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--when c-table-item__col--sortable"
                                :class="{
                                    'c-table-item__col--sorted':
                                        state.filter == 'days',
                                    'c-table-item__col--sorted-desc':
                                        state.orderBy == 'desc' &&
                                        state.filter == 'days',
                                }"
                                @click="setFilter('days')"
                            >
                                Wanneer
                            </div>
                            <div class="c-table-item__col c-table-item__col--button"></div>
                        </div>
                    </div>
                    <div class="c-table__body">
                        <div
                            class="c-table-item c-table-item--product-row"
                            open-modal-id="add-email"
                            v-for="email in email.emails.data"
                            :key="email.id"
                            @click="Modals.show('email-update', email.slug)"
                        >
                            <div class="c-table-item__col c-table-item__col--icon">
                            </div>
                            <div class="c-table-item__col c-table-item__col--subject">
                                {{ email.subject }}
                            </div>
                            <div class="c-table-item__col c-table-item__col--sending-moment">
                                {{ email.sending_moment }}
                            </div>
                            <div class="c-table-item__col c-table-item__col--when">
                                {{ email.when }}
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--button c-table-item__col--align-right"
                                @click="deleteEmail(email)"
                            >
                                <a class="e-button e-button--icon-only">
                                    <SvgDelete />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <pagination
                    :items="email.emails"
                    :order="state.orderFilter"
                    v-show="!state.search"
                />
            </div>
        </div>
            
    </article>
</template>

<script setup>
import SvgDelete from "@/svg/delete.svg";
import SvgPlus from "@/svg/plus.svg";
import SvgSearch from "@/svg/search.svg";
import SvgSort from "@/svg/sort.svg";

import { onMounted, reactive, ref, inject } from "vue";
import { useEmailStore } from "@/store/email";
import Swal from "sweetalert2";

const email = useEmailStore();
const Modals = inject("Modals");

const state = reactive({
    url: "/api/emails?q",
    orderBy: "asc",
    filter: "subject",
    orderFilter: "",
    search: null,
    debounce: 0,
});

onMounted(() => {
    Eventing.listen("paginate", (url) => {
        state.url = url;
        email.fill(url);
    });
    Eventing.fire("stickyElement");
    Eventing.fire("addOverflowArrows");
});

email.fill();

function deleteEmail(item) {
    setTimeout(() => {
        Modals.close();
    }, 100);
    Swal.fire({
        text: 'Zeker weten dat je "' + item.subject + '" wilt verwijderen?',
        confirmButtonText: "Wissen",
        showCloseButton: true,
        icon: "warning",
    }).then((result) => {
        if (result.isConfirmed) {
            email.destroy(item.slug);
        }
    });
}

function debounceSearch(event) {
    clearTimeout(state.debounce);
    state.debounce = setTimeout(() => {
        state.search = event.target.value;
        if (state.search) {
            email.search(state.search);
        } else {
            email.fill();
        }
    }, 600);
}

function setFilter(filter) {
    let orderBy =
        state.orderBy == "asc"
            ? (state.orderBy = "desc")
            : (state.orderBy = "asc");
    state.filter = filter;
    state.orderFilter = "&order=" + state.filter + "&by=" + orderBy;

    email.fill(state.url + "&order=" + state.filter + "&by=" + orderBy);
}

function menu(menu) {
    Eventing.fire("toggleMenu", menu);
}
</script>
