<template>
    <section v-if="state.box" @click="checkIfMayAccess()">
        <h2
            class="c-page-content__sub-section-title"
            v-if="order.has_varying_deliveries"
        >
            <SvgGift /> {{ state.box.name }}

            <span
                class="c-page-content__sub-section-title-right c-page-content__sub-section-title-note"
                v-if="state.addresses && state.addresses.length > 1"
                ><span>{{ state.addresses.length }}</span> adressen
                toegevoegd</span
            >
            <span
                class="c-page-content__sub-section-title-right c-page-content__sub-section-title-note"
                v-if="state.addresses && state.addresses.length == 1"
                ><span>{{ state.addresses.length }}</span> adres</span
            >
        </h2>

        <h2
            class="c-page-content__sub-section-title"
            v-if="!order.has_varying_deliveries"
        >
            <span
                class="c-page-content__sub-section-title-right c-page-content__sub-section-title-note"
                v-if="state.addresses && state.addresses.length > 1"
                ><span>{{ state.addresses.length }}</span> adressen
                toegevoegd</span
            >
            <span
                class="c-page-content__sub-section-title-right c-page-content__sub-section-title-note"
                v-if="state.addresses && state.addresses.length == 1"
                ><span>{{ state.addresses.length }}</span> adres</span
            >
        </h2>

        <div
            class="c-table-simple mt-10"
            :class="{
                blur:
                    !state.mayAccess &&
                    state.box.shipping_method != 'Op locatie',
            }"
            v-if="
                state.addresses &&
                state.addresses.length >= 0 &&
                (order.status.label == 'In ontvangst' ||
                    order.status.label == 'Is mailing')
            "
        >   
                <div
                    class="c-table-simple__bottom-button e-button e-button--green"
                    v-if="state.box.shipping_method != 'Digitaal'"
                    @click="
                        newAdress(state.box.shipping_method)
                    "
                >
                    Opslaan
                </div>
            <div
                class="c-table-simple__inner-wrap"
                v-if="state.box.shipping_method != 'Digitaal'"
            >
                <div class="c-table-simple__table">
                    <div class="c-table-simple__head">
                        <div class="c-table-simple__tr">
                            <div
                                class="c-table-simple__td"
                                v-if="state.box.shipping_method == 'Op locatie'"
                            >
                                Bedrijfsnaam
                            </div>
                            <div
                                class="c-table-simple__td"
                                v-if="state.box.shipping_method == 'Op locatie'"
                            >
                                Contactpersoon
                            </div>
                            <div
                                class="c-table-simple__td"
                                v-if="
                                    state.box.shipping_method == 'Huis-aan-huis'
                                "
                            >
                                Geadresseerde
                            </div>
                            <div
                                class="c-table-simple__td"
                                v-if="
                                    state.box.shipping_method == 'Huis-aan-huis'
                                "
                            >
                                Achternaam
                            </div>
                            <div class="c-table-simple__td">Postcode</div>
                            <div class="c-table-simple__td">Huisnr.</div>
                            <div class="c-table-simple__td">Adres</div>
                            <div class="c-table-simple__td">Stad</div>
                            <div class="c-table-simple__td">Land</div>
                            <div class="c-table-simple__td">&nbsp;</div>
                        </div>
                    </div>
                    <div
                        class="c-table-simple__body"
                        @keydown="box.errors.errors = {}"
                    >
                        <article
                            class="c-table-simple__tr"
                            v-for="(item, index) in state.addresses"
                            :key="index"
                        >
                            <div class="c-table-simple__td">
                                {{ item.name }}
                            </div>
                            <div class="c-table-simple__td">
                                {{ item.lastname }}
                            </div>
                            <div class="c-table-simple__td">
                                {{ item.postcode }}
                            </div>
                            <div class="c-table-simple__td">
                                {{ item.housenumber }}
                            </div>
                            <div class="c-table-simple__td">
                                {{ item.address }}
                            </div>
                            <div class="c-table-simple__td">
                                {{ item.city }}
                            </div>

                            <div class="c-table-simple__td">
                                {{ item.country }}
                            </div>
                            <div class="c-table-simple__td">
                                <button
                                    class="c-order-item__delete"
                                    @click="deleteAddress(item)"
                                >
                                    <SvgDelete />
                                </button>
                            </div>
                        </article>
                        <form class="c-table-simple__tr" v-if="showFormOrNot()">
                            <div class="c-table-simple__td">
                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            box.errors.message &&
                                            box.errors.errors.name,
                                    }"
                                    :invalid-message="box.errors.errors.name"
                                >
                                    <input
                                        type="text"
                                        v-model="state.form.name"
                                        class="e-form__input"
                                        :placeholder="
                                            state.box.shipping_method ==
                                            'Huis-aan-huis'
                                                ? 'Geadresseerde'
                                                : 'Bedrijfsnaam'
                                        "
                                    />
                                </div>
                            </div>

                            <div class="c-table-simple__td">
                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            box.errors.message &&
                                            box.errors.errors.lastname,
                                    }"
                                    :invalid-message="
                                        box.errors.errors.lastname
                                    "
                                >
                                    <input
                                        type="text"
                                        v-model="state.form.lastname"
                                        class="e-form__input"
                                        :placeholder="
                                            state.box.shipping_method ==
                                            'Huis-aan-huis'
                                                ? 'Achternaam'
                                                : 'Contactpersoon'
                                        "
                                    />
                                </div>
                            </div>

                            <div class="c-table-simple__td">
                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            box.errors.message &&
                                            box.errors.errors.postcode,
                                    }"
                                    :invalid-message="
                                        box.errors.errors.postcode
                                    "
                                >
                                    <input
                                        type="text"
                                        v-model="state.form.postcode"
                                        placeholder="0000AA"
                                        class="e-form__input e-form__input--zipcode"
                                    />
                                </div>
                            </div>
                            <div class="c-table-simple__td">
                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            box.errors.message &&
                                            box.errors.errors.housenumber,
                                    }"
                                    :invalid-message="
                                        box.errors.errors.housenumber
                                    "
                                >
                                    <input
                                        type="text"
                                        v-model="state.form.housenumber"
                                        @keyup="searchAddressByPostcode()"
                                        class="e-form__input e-form__input--housenumber"
                                        placeholder="0"
                                    />
                                </div>
                            </div>
                            <div class="c-table-simple__td">
                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            box.errors.message &&
                                            box.errors.errors.address,
                                    }"
                                    :invalid-message="box.errors.errors.address"
                                >
                                    <input
                                        type="text"
                                        v-model="state.form.address"
                                        placeholder="Straatnaam"
                                        class="e-form__input"
                                    />
                                </div>
                            </div>
                            <div class="c-table-simple__td">
                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            box.errors.message &&
                                            box.errors.errors.city,
                                    }"
                                    :invalid-message="box.errors.errors.city"
                                >
                                    <input
                                        type="text"
                                        v-model="state.form.city"
                                        placeholder="Plaatsnaam"
                                        class="e-form__input"
                                    />
                                </div>
                            </div>
                            <div class="c-table-simple__td">
                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            box.errors.message &&
                                            box.errors.errors.country,
                                    }"
                                    :invalid-message="box.errors.errors.country"
                                >
                                    <input
                                        type="text"
                                        v-model="state.form.country"
                                        placeholder="Land"
                                        class="e-form__input"
                                    />
                                </div>
                            </div>                            
                        </form>
                    </div>
                </div>
            </div>

            <div
                class="c-table-simple__inner-wrap"
                v-if="state.box.shipping_method == 'Digitaal'"
            >
                <div class="c-table-simple__table">
                    <div class="c-table-simple__head">
                        <div class="c-table-simple__tr">
                            <div class="c-table-simple__td">Voornaam</div>
                            <div class="c-table-simple__td">Achternaam</div>
                            <div class="c-table-simple__td">E-mailadres</div>
                            <div class="c-table-simple__td">&nbsp;</div>
                        </div>
                    </div>
                    <div
                        class="c-table-simple__body"
                        @keydown="box.errors.errors = {}"
                    >
                        <article
                            class="c-table-simple__tr"
                            v-for="(item, index) in state.addresses"
                            :key="index"
                        >
                            <div class="c-table-simple__td">
                                {{ item.name }}
                            </div>
                            <div class="c-table-simple__td">
                                {{ item.lastname }}
                            </div>
                            <div class="c-table-simple__td">
                                {{ item.email }}
                            </div>

                            <div class="c-table-simple__td">
                                <button
                                    class="c-order-item__delete"
                                    @click="deleteAddress(item)"
                                >
                                    <SvgDelete />
                                </button>
                            </div>
                        </article>
                        <form class="c-table-simple__tr" v-if="showFormOrNot()">
                            <div class="c-table-simple__td">
                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            box.errors.message &&
                                            box.errors.errors.name,
                                    }"
                                    :invalid-message="box.errors.errors.name"
                                >
                                    <input
                                        type="text"
                                        v-model="state.form.name"
                                        class="e-form__input"
                                        placeholder="Voornaam"
                                    />
                                </div>
                            </div>

                            <div class="c-table-simple__td">
                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            box.errors.message &&
                                            box.errors.errors.lastname,
                                    }"
                                    :invalid-message="
                                        box.errors.errors.lastname
                                    "
                                >
                                    <input
                                        type="text"
                                        v-model="state.form.lastname"
                                        class="e-form__input"
                                        placeholder="Achternaam"
                                    />
                                </div>
                            </div>

                            <div class="c-table-simple__td">
                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            box.errors.message &&
                                            box.errors.errors.email,
                                    }"
                                    :invalid-message="box.errors.errors.email"
                                >
                                    <input
                                        type="email"
                                        v-model="state.form.email"
                                        placeholder="E-mailadres"
                                        class="e-form__input"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="e-note mt-10"
            v-if="
                (state.box.shipping_method == 'Huis-aan-huis' ||
                    state.box.shipping_method == 'Digitaal') &&
                state.uploadProgress < 100 &&
                (order.status.label == 'In ontvangst' ||
                    order.status.label == 'Is mailing')
            "
        >
            <SvgInfo />
            <div class="e-note__text">
                <p v-if="state.box.shipping_method == 'Digitaal'">
                    Upload een Excel-bestand met alle e-mailaddressen.
                    <b>Let op:&nbsp;</b>
                    <a
                        @click="
                            downloadExcel(
                                state.box.hash,
                                'Naam,Achternaam,Email',
                                'import-sheet-' + state.box.shipping_method
                            )
                        "
                        target="_blank"
                        >download het voorbeeld Excel-bestand</a
                    >
                    of gebruik de volgende structuur: &nbsp;
                    <kbd>Naam | Achternaam | Email</kbd>.
                </p>
                <p v-if="state.box.shipping_method == 'Huis-aan-huis'">
                    Upload een Excel-bestand met alle adressen.
                    <b
                        >Let op: alleen een Excel bestand kan ge-upload
                        worden.</b
                    >

                    <br />Wij verzoeken je vriendelijk de volgende structuur aan
                    te houden: &nbsp;
                    <!-- <kbd
                        >Naam | Achternaam | Adres | Huisnummer | Postcode |
                        Plaats | Land</kbd
                    >. -->
                    <br /><kbd
                        >Voornaam | Achternaam | Straat | huisnummer +
                        toevoeging | Postcode | Plaats | Land</kbd
                    >
                    <br />of download het
                    <a
                        @click="
                            downloadExcel(
                                state.box.hash,
                                'Naam,Achternaam,Adres,Huisnummer,Postcode,Plaats,Land',
                                'import-sheet-' + state.box.shipping_method
                            )
                        "
                        >voorbeeld Excel-bestand</a
                    >
                    en gebruik deze om direct de adressen toe te voegen.
                </p>
            </div>
        </div>

        <div
            class="c-files__files c-files__files--no-padding"
            v-if="
                state.box &&
                state.box.shipping_method != 'Op locatie' &&
                (order.status.label == 'In ontvangst' ||
                    order.status.label == 'Is mailing')
            "
        >
            <div class="c-files__file-upload" v-if="state.uploadProgress < 100">
                <div class="e-form__upload-area">
                    <input
                        class="e-form__upload-area-input"
                        v-if="state.mayAccess"
                        type="file"
                        ref="uploadFile"
                        required
                        accept=".xlsx, .xls"
                        v-on:change="
                            handleFileUpload(
                                'imports/' + state.box.hash,
                                state.box.hash
                            )
                        "
                    />
                    <div class="e-form__upload-area-text">
                        <div class="e-form__upload-area-text-uploading">
                            Uploading...
                        </div>
                        <div class="e-form__upload-area-text-default">
                            Selecteer bestanden om te uploaden
                        </div>
                        <progress
                            v-if="
                                state.uploadProgress > 0 &&
                                state.uploadProgress < 100
                            "
                            :value="state.uploadProgress"
                            max="100"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import SvgGift from "@/svg/gift.svg";
import SvgInfo from "@/svg/info.svg";

import SvgDelete from "@/svg/delete.svg";
import SecureLS from "secure-ls";

import { useBoxStore } from "@/store/client/box";
import Swal from "sweetalert2";

import { useAuthClientStore } from "@/store/authClient";

import { useOrderStore } from "@/store/client/order";
import { useUploadStore } from "@/store/client/upload";

import { onMounted, reactive, ref } from "vue";

import Vapor from "laravel-vapor";
const uploadFile = ref(null);

const box = useBoxStore();
const user = useAuthClientStore();
const _order = useOrderStore();
const upload = useUploadStore();

const props = defineProps({
    order: Object,
    box: Object,
    auth: { type: String, required: false, default: "User" },
});

box.fillAddresses(props.box.hash);

const state = reactive({
    form: {
        name: null,
        postcode: null,
        housenumber: null,
        address: null,
        city: null,
        email: null,
        shippingMethod: null,
    },
    order: {
        hash: null,
        has_varying_deliveries: false,
        contact: {
            address: {},
        },
        followers: {},
        boxes: null,
        shipping_method: null,
    },
    box: null,
    addresses: null,
    uploadProgress: 0,
    mayAccess: false,
});

onMounted(() => {
    state.order = props.order;
    state.box = props.box;

    Eventing.listen("fillAddress." + props.box.hash, (data) => {
        state.addresses = data.data;
        state.form = {
            name: null,
            postcode: null,
            housenumber: null,
            address: null,
            city: null,
            email: null,
            shippingMethod: null,
        };
        Eventing.fire("reloadDelivery");
    });

    Eventing.listen("addAddress." + props.box.hash, (address) => {
        state.addresses.push(address);
        Eventing.fire("reloadDelivery");
    });

    Eventing.listen("reloadDeliveryPage", () => {
        checkIfMayAccess();
    });
    checkAccessToday();
});

function showFormOrNot() {
    try {
        if (state.box.shipping_method == "Op locatie") {
            if (state.addresses.length >= 1) {
                return false;
            }
        }
    } catch (error) {}

    return true;
}

function deleteAddress(item) {
    Swal.fire({
        text: "Zeker weten dat dit adres wilt verwijderen?",
        confirmButtonText: "Wissen",
        showCloseButton: true,
        icon: "warning",
    }).then((result) => {
        if (result.isConfirmed) {
            box.destroyAddress(props.box.hash, item.id);
        }
    });
}

function newAdress(shippingMethod) {
    state.form.shippingMethod = shippingMethod;
    box.storeAddress(props.box.hash, state.form);
}

function searchAddressByPostcode() {
    axios
        .post("/api/external/postcode", state.form, box.auth())
        .then((response) => {
            console.warn("postcode");
            state.form.address = response.data.streetname;
            state.form.city = response.data.city;
            state.form.postcode = response.data.postcode;
            state.form.country = "NLD";
        })
        .catch((error) => console.error(error));
}

function downloadExcel(_box, fields, _filename) {
    // axios({
    //     url: "/api/boxes/" + _box + "/addresses/" + fields,
    //     method: "GET",
    //     responseType: "blob",
    //     withCredential: false,
    //     headers: {
    //         Authorization: "Bearer " + user.token(),
    //     },
    // })
    //     .then((response) => {
    //         FileDownload(response.data, _filename.toLowerCase() + ".xls");
    //         Eventing.fire(
    //             "toasting",
    //             "Download is gestart: '" + _filename.toLowerCase() + ".xls'",
    //             "info"
    //         );
    //     })
    //     .catch((error) => console.error(error));

    axios({
        url: "/api/clients/boxes/" + _box + "/addresses/" + fields,
        method: "GET",
        // responseType: "blob",
        // withCredential: false,
        headers: {
            Authorization: "Bearer " + user.token(),
            Order: user.order(),
        },
    })
        .then((response) => {
            let result = response;
            const url = result.data.url;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", result.data.filename);
            document.body.appendChild(link);
            link.click();
            // FileDownload(response.data, _filename.toLowerCase() + ".xls");
            Eventing.fire(
                "toasting",
                "Download is gestart: '" + _filename.toLowerCase() + ".xls'",
                "info"
            );
        })
        .catch((error) => console.error(error));
}

function handleFileUpload(folder, box) {
    let file = uploadFile.value.files[0];
    Vapor.store(file, {
        progress: (progress) => {
            state.uploadProgress = Math.round(progress * 100);
        },
        headers: {
            Authorization: "Bearer " + user.token(),
            Order: user.order(),
        },
    }).then((response) => {
        let form = {
            uuid: response.uuid,
            key: response.key,
            bucket: response.bucket,
            name: file.name,
            content_type: file.type,
            folder: folder,
            box: box,
        };
        upload.store("addresses-box", form);

        Eventing.fire(
            "toasting",
            "Het bestand met de adressen is succesvol ontvangen en wordt zo snel mogelijk verwerkt!"
        );
        setTimeout(() => {
            // Eventing.fire("userChanged", state.form);
        }, 1000);
    });
}

function setPin(code) {
    const t = new Date();
    let w = parseInt(t.getDay()) + 1;
    let d = parseInt(t.getDate());

    let pin = parseInt(code) / parseInt(w) / parseInt(d);
    state.pin = pin;

    const ls = new SecureLS();
    ls.set("pin", pin);
}

function mayAccessToday() {
    const t = new Date();
    let w = parseInt(t.getDay()) + 1;
    let d = parseInt(t.getDate());

    const ls = new SecureLS();
    ls.set("mayAccess", w + d);
}

function checkAccessToday() {
    const t = new Date();
    let w = parseInt(t.getDay()) + 1;
    let d = parseInt(t.getDate());

    const ls = new SecureLS();
    if (ls.get("mayAccess") == w + d) {
        state.mayAccess = true;
    }
}

async function checkIfMayAccess() {
    if (state.box.shipping_method == "Op locatie") {
        state.mayAccess = true;
    }
    checkAccessToday();

    if (state.mayAccess == false) {
        Swal.fire({
            title: "Extra verificatie stap",
            text: "Ter beveiliging van de gegevens die wij nodig hebben voor de verzending aan je collega’s, vragen we je om een extra verificatie. Met onderstaande knop ontvang je per e-mail een code die je dient in te voeren voordat je de gegevens kunt uploaden.",
            confirmButtonText: "Verstuur e-mail met code",
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonText: "Annuleren",
            icon: "warning",
        }).then(async (result) => {
            if (result.isConfirmed) {
                Eventing.fire(
                    "toasting",
                    "Check je mailbox voor de toegangscode om door te gaan"
                );
                let result = await _order.update(user.order(), {
                    sendPin: true,
                });

                if (result) {
                    setPin(result.data.pin);

                    const { value: check } = await Swal.fire({
                        title: "Voer code in",
                        text: "De ontvangen code is vandaag geldig en meerdere keren te gebruiken",
                        confirmButtonText: "Verkrijg toegang",
                        showCloseButton: true,
                        showCancelButton: true,
                        cancelButtonText: "Annuleren",
                        icon: "question",
                        input: "text",
                        inputValidator: (value) => {
                            return new Promise((resolve) => {
                                if (parseInt(value) === parseInt(state.pin)) {
                                    resolve();
                                    state.mayAccess = true;
                                    _order.delivery(user.order());
                                    Eventing.fire(
                                        "toasting",
                                        "Je kunt de bezorging nu beheren!"
                                    );
                                    mayAccessToday();
                                } else {
                                    resolve(
                                        "Dit is niet de juiste toegangscode"
                                    );
                                }
                            });
                        },
                    });
                }
            }
        });
    }
}
</script>

<style scoped>
.blur,
.blurry {
    transition: all 1s ease-in-out;
    filter: blur(2px);
    opacity: 0.9;
    cursor: not-allowed;
}
</style>
