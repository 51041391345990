<template>
    <article>
        <div class="c-production-content__delivery-data">
            <h2 class="c-production-content__title">Bezorging data</h2>
            <ul
                class="c-data-list c-data-list--table-style c-data-list--no-ellipsis"
            >
                <li class="c-data-list__item">
                    <p class="c-data-list__subject">Totaal gewicht</p>
                    <p class="c-data-list__data">3500 gr</p>
                </li>
                <li class="c-data-list__item">
                    <p class="c-data-list__subject">Aantal colli</p>
                    <p class="c-data-list__data">5</p>
                </li>
                <li class="c-data-list__item">
                    <p class="c-data-list__subject">Opmerking bezorging</p>
                    <p class="c-data-list__data">Geen opmerking</p>
                </li>
            </ul>
        </div>
    </article>
</template>
