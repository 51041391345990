<template>
    <div class="c-page-content">
        <div class="c-page-content__container c-page-content__container--spacing-small">

            <div class="c-table js-overflow-arrow" v-if="totals">
                <div class="c-table__head js-sticky-element">
                    <div class="c-table-item c-table-item--head">
                        <div class="c-table-item__col c-table-item__col--category-name">Categorie ↓ Status →</div>
                        <div
                            class="c-table-item__col c-table-item__col--total-production"
                            v-for="(total, index) in totals.totals"
                            :key="index"
                        >
                            {{ Object.keys(totals.totals[index])[0] }}
                        </div>
                    </div>
                </div>
                <section class="c-table__body">
                    <div
                        class="c-table-item"
                        v-for="(category, categoryindex) in totals.categories"
                        :key="categoryindex"
                    >
                        <div class="c-table-item__col c-table-item__col--category-name">
                            {{ category }}
                        </div>

                        <div
                            class="c-table-item__col c-table-item__col--total-production"
                            v-for="(total, index) in totals.totals"
                            :key="index"
                        >
                            {{
                                new Intl.NumberFormat("nl-NL", {}).format(
                                    total[totals.statusses[index]][category]
                                )
                            }}
                        </div>
                    </div>
                </section>
            </div>

        </div>
    </div>
</template>

<script setup>
import { onMounted, onUpdated, reactive, ref, inject } from "vue";
import Vapor from "laravel-vapor";
import { useAuthStore } from "@/store/auth";
import { useUploadStore } from "@/store/upload";

const props = defineProps({
    totals: Object,
});

onUpdated(() => {
    Eventing.fire("stickyElement");
    Eventing.fire("addOverflowArrows");
});

</script>

<style scoped>
.c-table-item__col {
    width: 17%;
}
</style>
