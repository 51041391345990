<template>

   <apexchart 
        width="100%" 
        height="100%" 
        :series="props.series"
        :options='chart.options'
    >
    </apexchart>

</template>

<script setup>
import { inject, onMounted, reactive } from "vue";

const props = defineProps({
    series: Array,
    labels: Array,
    tooltipXFormatter: {
        type: Object,
        default: undefined,
    },
    module_theme: "", //'green' for green styling
    colors: {
        type: Array,
        default: ['#78ceab', '#5fb090', '#499175'],
    },
});

//Original style
var themeOptions = {
    colors: props.colors,
    grid: {
        borderColor: 'rgba(0,0,0,0.05)',
    },
    fill: {
        type: 'gradient',
        gradient: {
              shade: 'darken',
              type: "vertical",
              shadeIntensity: 0,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 0.8,
              stops: [10, 100],
              colorStops: []
          },
    },
    dataLabels: {
        enabled: true,
        offsetY: -24,
        style: {},
        background: {},
    },
    xaxis: {
          labels: {},
          axisBorder: {},
          axisTicks: {},
          crosshairs: {},
      },
      yaxis: {
            show: false,
        },
      stroke: {
            show: true,
            width: 4,
            colors: ['transparent']
        },
};

//Green module style
if(props.module_theme == 'green') {

    themeOptions = {
        colors: ['rgb(73, 145, 117)'],
        grid: {
            borderColor: 'rgba(255,255,255,0.2)',
            padding: {
                top: 20,
            },
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff']
        },
        dataLabels: {
            enabled: true,
            offsetY: -40,
            style: {
                fontSize: '12px',
            },
            background: {
                foreColor: '#fff',
                borderWidth: 1.2,
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                  shade: 'darken',
                  type: "vertical",
                  shadeIntensity: 0,
                  gradientToColors: ['#fff', '#fff'],
                  inverseColors: false,
                  opacityFrom: 0.1,
                  opacityTo: 0.9,
                  stops: [0, 100],
                  colorStops: []
              },
        },
        xaxis: {
          labels: {
            style: {
                fontSize: '11px',
                  colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
                  fontWeight: 500,
              },
              rotate: -45,
              maxHeight: 80,
          },
          crosshairs: {
                  stroke: {
                      color: 'rgba(255,255,255,0.8)',
                      width: 1,
                  },
              },
          axisBorder: {
            color: '#fff',
          },
          axisTicks: {
              show: false
          },
      },
      yaxis: {
            show: true,
            labels: {
                style: {
                      colors: ['#fff', '#fff', '#fff', '#fff', '#fff','#fff', '#fff'],
                      fontWeight: 500,
                  },
              },
        },
    }
}

const chart = reactive({
      options: {
        colors: themeOptions.colors,
        fill: themeOptions.fill,
        grid: themeOptions.grid,
        stroke: themeOptions.stroke,
        chart: {
          type: 'bar',
          group: 'status',
          offsetY: -20,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '70%',
            endingShape: 'rounded',
            borderRadius: 4,
            borderRadiusApplication: 'end',
            dataLabels: {
              position: 'top',
            },      
          },
        },
        dataLabels: themeOptions.dataLabels,
        legend: {
            position: 'left',
        },
        xaxis: {
            show: true,
            categories: props.labels,
            labels: themeOptions.xaxis.labels,
            axisBorder: themeOptions.xaxis.axisBorder,
            axisTicks: themeOptions.xaxis.axisTicks,
            crosshairs: themeOptions.xaxis.crosshairs,
        },
        yaxis: themeOptions.yaxis,
        tooltip: {
            enabled: true,
            intersect: false,
            followCursor: true,
            shared: true,
            x: {
               formatter: props.tooltipXFormatter,
            },
            marker: {
                show: false,
            },
        }
      },
});

</script>