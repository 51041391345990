<template>
    
    <app-dashboards-finance-content />

    <app-dashboards-side-overlay-finance />

</template>

<script setup>

import Swal from "sweetalert2";
import { inject, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";

const Modals = inject("Modals");

onMounted(() => {

});

</script>