<template>
    <div class="c-side-overlay" v-if="state.order">
        <app-dashboards-side-overlay-header :order="state.order" />

        <div class="c-side-overlay__content">
            <div class="c-side-overlay__content-section" v-if="state.runs">
                <div class="e-form">
                    <div class="e-form__field-wrap">
                        <div class="e-form__label">
                            <p>Drukkerij</p>
                        </div>
                        <div class="e-form__select-wrap">
                            <select
                                class="e-form__select"
                                placeholder="Run"
                                @change="state.isChanged = true"
                                v-model="state.form.run_drukkerij"
                            >
                                <option value="">Onbekend</option>
                                <option
                                    v-for="run in state.runs[0].items"
                                    :key="run.id"
                                    :value="run.id"
                                >
                                    {{ run.name }} ({{ run.pretty_date }})
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="e-form__field-wrap">
                        <div class="e-form__label">
                            <p>Magazijn</p>
                        </div>
                        <div class="e-form__select-wrap">
                            <select
                                class="e-form__select"
                                placeholder="Run"
                                @change="state.isChanged = true"
                                v-model="state.form.run_magazijn"
                            >
                                <option value="">Onbekend</option>
                                <option
                                    v-for="run in state.runs[1].items"
                                    :key="run.id"
                                    :value="run.id"
                                >
                                    {{ run.name }} ({{ run.pretty_date }})
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="c-side-overlay__content-section" v-if="state.basket">
                <ul class="c-data-list c-data-list--stripped">
                    <li class="c-data-list__item">
                        <p class="c-data-list__subject">Samenstelling</p>
                        <p class="c-data-list__data">{{ state.basket.box }}</p>
                    </li>
                    <li class="c-data-list__item">
                        <p class="c-data-list__subject">Product</p>
                        <p class="c-data-list__data">
                            {{ state.basket.product }}
                        </p>
                    </li>
                    <li class="c-data-list__item">
                        <p class="c-data-list__subject">Aantal</p>
                        <p class="c-data-list__data">
                            {{ state.basket.amount }}
                        </p>
                    </li>
                    <li class="c-data-list__item">
                        <p class="c-data-list__subject">Status</p>
                        <p class="c-data-list__data">
                            {{ state.basket.status_designer }}
                        </p>
                    </li>
                    <!-- 
                    <li class="c-data-list__item">
                        <p class="c-data-list__subject">Drukkerij</p>
                        <p class="c-data-list__data">
                            {{ state.basket.run_drukkerij?.name ?? "-" }}
                            <span class="e-suffix-text"
                                >&nbsp;{{
                                    state.basket.run_drukkerij?.pretty_date
                                }}</span
                            >
                        </p>
                    </li>
                    <li class="c-data-list__item">
                        <p class="c-data-list__subject">Magazijn</p>
                        <p class="c-data-list__data">
                            {{ state.basket.run_magazijn?.name ?? "-" }}
                            <span class="e-suffix-text"
                                >&nbsp;{{
                                    state.basket.run_magazijn?.pretty_date
                                }}</span
                            >
                        </p>
                    </li> 
                    -->
                </ul>
            </div>

            <div
                class="c-side-overlay__content-section"
                v-if="
                    state.order.notes &&
                    Object.values(state.order.notes).length > 0
                "
            >
                <h3 class="c-side-overlay__content-section-title">
                    Notities voor de Vormgever
                </h3>
                <div class="c-chat__messages">
                    <div
                        class="c-chat__message c-chat__message--note"
                        v-for="note in state.order.notes"
                        :key="note.id"
                    >
                        <div class="c-chat__message-inner-wrap">
                            <div class="c-chat__message-head">
                                <div
                                    class="c-chat__message-name"
                                    v-if="note.sender"
                                >
                                    <img
                                        class="c-chat__message-user-image"
                                        :src="note.sender.avatar"
                                    />

                                    {{ note.sender.name }}

                                    <span class="c-chat__message-source">
                                        {{ note.sender.role }}
                                    </span>
                                </div>
                                <div class="c-chat__message-date">
                                    {{ note.created_at }}
                                </div>
                            </div>
                            <div class="c-chat__message-text">
                                <p v-html="note.message"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="c-side-overlay__content-section"
                v-if="
                    state.cart && state.cart.data && state.cart.data.length > 0
                "
            >
                <div
                    class="c-side-overlay__content-section-table"
                    v-for="(box, subindex) in state.cart.data"
                    :key="box.id + subindex"
                    v-show="box.baskets.length > 0"
                >
                    <h3 class="c-side-overlay__content-section-title">
                        <SvgGift />
                        {{ box.name }}
                    </h3>
                    <div class="c-table-default">
                        <div class="c-table-default__inner-wrap">
                            <div class="c-table-default__table">
                                <div class="c-table-default__head">
                                    <div class="c-table-default__tr">
                                        <div class="c-table-default__td">
                                            Product
                                        </div>
                                        <div class="c-table-default__td">
                                            Aantal
                                        </div>
                                        <div class="c-table-default__td">
                                            Drukkerij
                                        </div>
                                        <div class="c-table-default__td">
                                            Magazijn
                                        </div>
                                        <div
                                            class="c-table-default__td is-hidden"
                                        ></div>
                                    </div>
                                </div>
                                <div class="c-table-default__body">
                                    <div
                                        class="c-table-default__tr"
                                        v-for="(basket, subindex) in box.items"
                                        :key="basket.id + subindex"
                                    >
                                        <div class="c-table-default__td">
                                            <span v-if="basket.product">{{
                                                basket.product
                                            }}</span>
                                        </div>
                                        <div class="c-table-default__td">
                                            {{ basket.amount }}
                                        </div>
                                        <div class="c-table-default__td">
                                            <span v-if="basket.run_drukkerij">{{
                                                basket.run_drukkerij.name
                                            }}</span>
                                        </div>
                                        <div class="c-table-default__td">
                                            <span v-if="basket.run_magazijn">{{
                                                basket.run_magazijn.name
                                            }}</span>
                                        </div>
                                        <div
                                            class="c-table-default__td is-hidden"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="c-side-overlay__footer">
            <div class="c-side-overlay__footer-content">
                <button
                    class="e-button e-button--full-width e-button--big js-close-side-overlay"
                    @click="state.order = null"
                    v-show="!state.isChanged"
                >
                    Sluiten
                </button>

                <router-link
                    :to="{
                        name: 'app.order',
                        params: { hash: state.order.hash },
                    }"
                    class="e-button e-button--full-width e-button--big"
                    :class="{ ' e-button--green': state.isChanged == false }"
                    >Naar bestelling <SvgArrowRight />
                </router-link>
                <button
                    class="e-button e-button--full-width e-button--big e-button--green"
                    @click="saveBasket()"
                    v-show="state.isChanged"
                >
                    Opslaan
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useDashboardDesignStore } from "@/store/dashboard/design";
import { useOrderStore } from "@/store/order";
import SvgArrowRight from "@/svg/arrow-right.svg";
import SvgGift from "@/svg/gift.svg";
import { onMounted, reactive } from "vue";

const order = useOrderStore();
const state = reactive({
    order: null,
    basket: null,
    cart: {
        data: [],
    },
    isChanged: false,
    runs: null,
    form: {
        run_drukkerij: "",
        run_magazijn: "",
    },
});

const design = useDashboardDesignStore();

onMounted(() => {
    Eventing.listen("clearSideOverlay", () => {
        state.order = null;
        state.cart = {
            data: [],
        };
    });

    Eventing.listen("fillSideOverlay", async (data) => {
        state.order = data.data;
        state.cart = {
            data: [],
        };
        let cart = await design.cart(data.data.hash);
        if (cart) {
            state.cart = cart;
        }
    });

    Eventing.listen("fillRuns", (data) => {
        console.info("fillRuns", data);
        state.runs = data;
    });

    Eventing.listen("fillSideOverlayBasket", (data) => {
        console.info("fillSideOverlayBasket", data);
        state.basket = data.data;
        state.form.run_drukkerij = "";
        state.form.run_magazijn = "";
        if (state.basket.run_drukkerij) {
            state.form.run_drukkerij = state.basket.run_drukkerij.id;
        }
        if (state.basket.run_magazijn) {
            state.form.run_magazijn = state.basket.run_magazijn.id;
        }
    });
});

async function saveBasket() {
    let data = await order.deliveryDateMaatwerk(
        state.order.hash,
        state.basket.id,
        state.form
    );
    if (data) {
        // Eventing.fire("reloadItemsAndKeepSidebarOpen");
        Eventing.fire("reloadItems");
    }
}
</script>
