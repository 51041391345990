<template>
    <article>
        <div
            class="c-topbar"
            :class="
                state.order &&
                state.order.source &&
                state.order.source.toLowerCase()
            "
        >
            <div class="c-topbar__left">
                <div
                    class="c-topbar__left-button e-menu-button js-toggle-mobile-menu"
                    @click="menu('mobile')"
                >
                    <div class="e-menu-button__bars-wrap">
                        <div class="e-menu-button__bars"></div>
                    </div>
                </div>

                <ul class="c-breadcrumbs">
                    <li class="c-breadcrumbs__item c-breadcrumbs__item--prev">
                        <!-- <router-link
                            :to="{ name: 'app.orders' }"
                            class="c-breadcrumbs__link"
                        > -->
                        <a class="c-breadcrumbs__link" @click="goBack()">
                            <SvgArrowLeft />
                        </a>
                        <!-- </router-link> -->
                    </li>
                    <!-- If spoed -->
                    <li
                        class="c-breadcrumbs__item"
                        v-if="state.order.is_urgent"
                    >
                        <div class="c-breadcrumbs__item-icon">
                            <div class="e-label-speed">
                                <SvgSpeed />
                            </div>
                        </div>
                    </li>
                    <li class="c-breadcrumbs__item">
                        <a
                            class="c-breadcrumbs__text c-breadcrumbs__text--title"
                            @click="
                                Modals.show(
                                    'contact-update',
                                    state.order.contact.slug
                                )
                            "
                        >
                            {{ state.order.contact.company }}
                        </a>
                    </li>
                    <li
                        class="c-breadcrumbs__item e-tooltip"
                        v-if="
                            state.order &&
                            state.order.contact &&
                            state.order.client_login &&
                            state.order.status &&
                            state.order.status.label != 'Concept'
                        "
                        data-tooltip="Inloggen als klant"
                    >
                        <a
                            class="e-button e-button--icon-only e-button--green e-button--transparent e-button--no-spacing-sides e-tooltip"
                            :href="state.order.client_login"
                            target="_blank"
                        >
                            <SvgLogin />
                        </a>
                    </li>
                    <li
                        class="c-breadcrumbs__item c-breadcrumbs__item--order-number"
                    >
                        <div class="c-breadcrumbs__order-number">
                            <kbd class="c-breadcrumbs__order-number-kbd">{{
                                state.order.reference
                            }}</kbd>
                            <!-- Opties: 

                                <p>Duplicatie van  <a class="c-order__order-link" href="">FE230759000624333</a></p>
                                <p>Splitsing van  <a class="c-order__order-link" href="">FE230759000624333</a></p>
                                <p>Bevat een splitsing: <a class="c-order__order-link" href="">FE230759000624333</a></p>
                                <p>Bevat een duplicatie: <a class="c-order__order-link" href="">FE230759000624333</a></p>
                            -->
                            <p
                                class="c-breadcrumbs__order-number-parent"
                                v-if="state.order.origin_reference"
                            >
                                <span v-if="state.order.is_duplicate">
                                    Duplicatie van

                                    <router-link
                                        :to="{
                                            name: 'app.order.order',
                                            params: {
                                                hash: state.order.origin_hash,
                                            },
                                        }"
                                        class="c-breadcrumbs__order-number-link"
                                        >{{ state.order.origin_reference }}
                                    </router-link>
                                </span>
                                <span
                                    v-if="
                                        state.order.is_splitted &&
                                        state.order.origin_reference
                                    "
                                >
                                    Splitsing van
                                    <router-link
                                        :to="{
                                            name: 'app.order.order',
                                            params: {
                                                hash: state.order.origin_hash,
                                            },
                                        }"
                                        class="c-breadcrumbs__order-number-link"
                                        >{{ state.order.origin_reference }}
                                    </router-link>
                                </span>
                                <span
                                    v-if="
                                        state.order.is_splitted &&
                                        !state.order.origin_reference
                                    "
                                >
                                    Bevat splitsing(en)
                                </span>
                            </p>
                        </div>
                    </li>
                </ul>
            </div>

            <div
                class="c-topbar__right-button e-menu-button js-toggle-topbar-menu"
                @click="menu('topbar')"
            >
                <div class="e-menu-button__bars-wrap">
                    <div class="e-menu-button__bars"></div>
                </div>
            </div>
            <div
                class="c-topbar__right"
                v-if="Object.keys(state.order.auth).length > 0"
            >
                <div class="c-followers">
                    <div
                        v-for="(user, index) in users"
                        class="c-followers__user e-tooltip"
                        :class="{
                            'c-followers__user--is-online': isOnlineInApp(user),
                            'c-followers__user--on-current-page':
                                isOnlineHere(user),
                            // 'c-followers__user--on-current-page': Object.values(
                            //     users
                            // ).includes(user.name),
                        }"
                        :data-tooltip="
                            Object.values(users).includes(user.name)
                                ? user.name + ' is hier nu'
                                : user.name
                        "
                        :key="index"
                    >
                        <img
                            class="c-followers__user-image"
                            :src="user.avatar"
                        />
                        <div class="c-followers__user-status"></div>
                    </div>

                    <div
                        v-for="(user, index) in state.order.followers"
                        class="c-followers__user e-tooltip"
                        :class="{
                            'c-followers__user--is-online': isOnlineInApp(user),
                            'c-followers__user--on-current-page':
                                isOnlineHere(user),
                            // 'c-followers__user--on-current-page': Object.values(
                            //     users
                            // ).includes(user.name),
                        }"
                        :data-tooltip="
                            Object.values(users).includes(user.name)
                                ? user.name + ' is hier nu'
                                : user.name
                        "
                        :key="index"
                    >
                        <img
                            class="c-followers__user-image"
                            :src="user.avatar"
                        />
                        <div class="c-followers__user-status"></div>
                    </div>
                </div>

                <div
                    class="c-topbar__status-change e-tooltip"
                    data-tooltip="Wijzig status"
                    open-modal-id="change-status-modal"
                    @click="openChangeStatusModal()"
                    v-if="user.role() != 'MedewerkerMedewerker'"
                >
                    <span
                        class="e-label e-label--big"
                        :class="state.order.status.class"
                    >
                        {{ state.order.status.label }}
                    </span>
                </div>

                <button
                    class="e-button e-button--green e-button--green-darker-hover"
                    @click="toggleBookmark()"
                    v-if="
                        state.order.auth.following &&
                        user.role() != 'MedewerkerMedewerker'
                    "
                >
                    <SvgCheck />
                    Volgend
                </button>
                <button
                    class="e-button e-button--green e-button--green-darker-hover"
                    @click="toggleBookmark()"
                    v-if="
                        !state.order.auth.following &&
                        user.role() != 'MedewerkerMedewerker'
                    "
                >
                    Volgen
                </button>

                <button
                    class="e-button e-button--green e-button--green-darker-hover e-button--icon-only e-button--iconx18 e-tooltip"
                    data-tooltip="Pakbon"
                    @click="getPackingSlip()"
                    v-if="user.role() != 'MedewerkerMedewerker'"
                >
                    <SvgReceipt />
                </button>

                <button
                    v-if="state.order.can_duplicate"
                    class="e-button e-button--green e-button--green-darker-hover e-button--icon-only e-button--iconx18 e-tooltip"
                    data-tooltip="Dupliceren"
                    @click="duplicateOrder()"
                >
                    <SvgDuplicate />
                </button>

                <span
                    v-if="user.role() != 'MedewerkerMedewerker'"
                    class="e-button e-button--green e-button--iconx16 e-button--icon-only e-button--green-darker-hover e-button--has-popover"
                >
                    <SvgOptions />

                    <ul class="e-popover e-popover--left">
                        <li
                            class="e-popover__item e-popover__item--order-source e-tooltip"
                            data-tooltip="Categorie"
                        >
                            <div class="e-form__field-wrap">
                                <div class="e-form__select-wrap">
                                    <select
                                        class="e-form__select"
                                        v-model="state.order.category"
                                        placeholder="Categorie.."
                                    >
                                        <option
                                            readonly
                                            disabled
                                            selected
                                            placeholder="Categorie.."
                                            v-if="state.order.category == null"
                                        >
                                            Categorie:
                                        </option>
                                        <option
                                            v-for="(option, value) in enums
                                                .options['order-categories']"
                                            v-bind:value="option"
                                            :key="value"
                                        >
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                                <button
                                    class="e-button e-button--full-width e-button--green e-button--icon-only"
                                    @click="updateCategory()"
                                >
                                    <SvgCheck />
                                </button>
                            </div>
                        </li>

                        <li
                            class="e-popover__item e-popover__item--order-source e-tooltip"
                            data-tooltip="Bron"
                        >
                            <div class="e-form__field-wrap">
                                <div class="e-form__select-wrap">
                                    <select
                                        class="e-form__select"
                                        v-model="state.order.source"
                                        required
                                    >
                                        <option
                                            v-for="(option, value) in enums
                                                .options['order-source']"
                                            v-bind:value="option"
                                            :key="value"
                                        >
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                                <button
                                    class="e-button e-button--full-width e-button--green e-button--icon-only"
                                    @click="updateSource()"
                                >
                                    <SvgCheck />
                                </button>
                            </div>
                        </li>

                        <li
                            class="e-popover__item"
                            @click="toggleUrgentness()"
                            v-if="user.role() != 'Shopmanager'"
                        >
                            <a
                                class="e-popover__link e-popover__link--iconx12"
                                v-show="!state.order.is_urgent"
                            >
                                <SvgCross /> Spoed
                            </a>
                            <a
                                class="e-popover__link e-popover__link--iconx12 e-popover__link--green-icon"
                                v-show="state.order.is_urgent"
                            >
                                <SvgCheck /> Spoed
                            </a>
                        </li>

                        <li
                            v-if="
                                (state.order.has_shop &&
                                    user.role() == 'Shopmanager') ||
                                (state.order.has_shop &&
                                    user.role() == 'Beheerder')
                            "
                            class="e-popover__item"
                        >
                            <a
                                class="e-popover__link e-popover__link--iconx12 e-popover__link--green-icon"
                                @click="toggleShop()"
                            >
                                <SvgCheck /> Shop
                            </a>
                        </li>

                        <li
                            v-if="
                                (!state.order.has_shop &&
                                    user.role() == 'Shopmanager') ||
                                (!state.order.has_shop &&
                                    user.role() == 'Beheerder')
                            "
                            class="e-popover__item"
                        >
                            <a
                                class="e-popover__link e-popover__link--iconx12"
                                @click="toggleShop()"
                            >
                                <SvgCross /> Shop
                            </a>
                        </li>

                        <li
                            v-if="
                                (state.order.has_bank &&
                                    user.role() == 'Shopmanager') ||
                                (state.order.has_bank &&
                                    user.role() == 'Beheerder')
                            "
                            class="e-popover__item"
                        >
                            <a
                                class="e-popover__link e-popover__link--green-icon"
                                @click="toggleBank()"
                            >
                                <SvgCheck /> Bank
                            </a>
                        </li>

                        <li
                            v-if="
                                (!state.order.has_bank &&
                                    user.role() == 'Shopmanager') ||
                                (!state.order.has_bank &&
                                    user.role() == 'Beheerder')
                            "
                            class="e-popover__item"
                        >
                            <a class="e-popover__link" @click="toggleBank()">
                                <SvgCross /> Bank
                            </a>
                        </li>

                        <li
                            v-if="
                                (state.order.has_collected &&
                                    state.order.has_bank &&
                                    user.role() == 'Shopmanager') ||
                                (state.order.has_collected &&
                                    state.order.has_bank &&
                                    user.role() == 'Beheerder')
                            "
                            class="e-popover__item"
                        >
                            <a
                                class="e-popover__link e-popover__link--green-icon"
                                @click="toggleCollected()"
                            >
                                <SvgCheck /> Opgewaardeerd
                            </a>
                        </li>

                        <li
                            v-if="
                                (!state.order.has_collected &&
                                    state.order.has_bank &&
                                    user.role() == 'Shopmanager') ||
                                (!state.order.has_collected &&
                                    state.order.has_bank &&
                                    user.role() == 'Beheerder')
                            "
                            class="e-popover__item"
                        >
                            <a
                                class="e-popover__link"
                                @click="toggleCollected()"
                            >
                                <SvgCross /> Opgewaardeerd
                            </a>
                        </li>
                    </ul>
                </span>
            </div>
        </div>
    </article>
</template>

<script setup>
import SvgArrowLeft from "@/svg/arrow-left.svg";
import SvgCheck from "@/svg/check.svg";
import SvgCross from "@/svg/cross.svg";
import SvgDuplicate from "@/svg/duplicate.svg";
import SvgLogin from "@/svg/login.svg";
import SvgOptions from "@/svg/options.svg";
import SvgReceipt from "@/svg/receipt.svg";
import SvgSpeed from "@/svg/speed.svg";

import { useAuthStore } from "@/store/auth";
import { useEnumStore } from "@/store/enum";
import { useOrderStore } from "@/store/order";
import Swal from "sweetalert2";
import { inject, onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";

const props = defineProps({
    users: Object,
});
const router = useRouter();
const enums = useEnumStore();
enums.fill("order-source");
enums.fill("order-categories");

const Modals = inject("Modals");
const order = useOrderStore();

const user = useAuthStore();

order.show(useRoute().params.hash);

const route = useRoute();

const state = reactive({
    order: {
        auth: {},
        contact: {
            address: {},
        },
        followers: {},
        source: null,
        is_duplicate: false,
        is_splitted: false,
        is_urgent: false,
        can_duplicate: false,
    },
    now_online: [],
});

onMounted(() => {
    Eventing.listen("fillOrder", (data) => {
        state.order = data.data;
    });
    Eventing.listen("fillHeader", (data) => {
        state.order = data.data;
    });

    Eventing.listen("onlineUsers", (users) => {
        console.info(users);
        state.now_online = users;
    });
});

function toggleBookmark() {
    order.bookmark(state.order.hash);
}

function goBack() {
    try {
        router.go(-2);
    } catch (error) {
        router.push({
            name: "app.orders",
            query: { t: Math.random() },
        });
    }
}

async function toggleShop() {
    await order.shop(state.order.hash);
}

async function toggleUrgentness() {
    await order.urgent(state.order.hash);
}

async function toggleBank() {
    await order.bank(state.order.hash);
}

async function toggleCollected() {
    await order.collected(state.order.hash);
}

function menu(menu) {
    Eventing.fire("toggleMenu", menu);
}

function isOnlineHere(user) {
    const count = props.users.filter((obj) => {
        if (obj.name.includes(user.name)) {
            return true;
        }
        return false;
    }).length;
    if (count >= 1) {
        return true;
    }
    return Boolean(count);
}

function openChangeStatusModal() {
    Modals.show("order-change-status");
    setTimeout(() => {
        Eventing.fire("openStatusChanger", route.params.hash);
    }, 500);
}

function getPackingSlip() {
    return order.packingSlip(route.params.hash);
}

function isOnlineInApp(user) {
    const count = state.now_online.filter((obj) => {
        if (obj.name.includes(user.name)) {
            return true;
        }
        return false;
    }).length;
    if (count >= 1) {
        return true;
    }
    return Boolean(count);
}

function updateSource() {
    order.switchSource(state.order.hash, state.order.source);
}

function updateCategory() {
    order.switchCategory(state.order.hash, state.order.category);
}

function duplicateOrder() {
    setTimeout(() => {
        document
            .getElementById("duplicate")
            .addEventListener("click", duplicateNow);

        function duplicateNow(event) {
            Swal.fire({
                text:
                    "Zeker weten dat je '" +
                    state.order.reference +
                    "' wilt dupliceren?",
                confirmButtonText: "Dupliceer",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonText: "Annuleren",
                icon: "warning",
            }).then((result) => {
                if (result.isConfirmed) {
                    order.duplicate(state.order.hash);
                }
            });
        }
    }, 500);

    setTimeout(() => {
        document.getElementById("split").addEventListener("click", splitNow);

        function splitNow(event) {
            Swal.fire({
                text:
                    "Zeker weten dat je '" +
                    state.order.reference +
                    "' wilt splitsen?",
                confirmButtonText: "Splitsen",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonText: "Annuleren",
                icon: "warning",
            }).then((result) => {
                if (result.isConfirmed) {
                    order.split(state.order.hash);
                }
            });
        }
    }, 500);

    Swal.fire({
        title: "Hoe wil je de bestelling <span>dupliceren</span>?",
        width: 600,
        padding: "1.5rem 1.5rem 3rem",
        html:
            '<div class="swal-options">' +
            '<div class="swal-options__col" id="split">' +
            '<div class="swal-options__col-icon">' +
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 3v3a1 1 0 0 1-2 0V4H4v10h2a1 1 0 0 1 0 2H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1Zm-6 17v-2a1 1 0 0 0-2 0v3a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-3a1 1 0 0 0 0 2h2v10Zm-2-5V9a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1Zm2-1h4v-4h-4Z"/></svg>' +
            "</div>" +
            '<div class="swal-options__col-content">' +
            '<div class="swal-options__col-title">' +
            "Splitsen" +
            "</div>" +
            "De bestelling wordt gedupliceerd onder <b>dezelfde factuur</b>. " +
            "</div>" +
            "</div>" +
            '<div class="swal-options__col" id="duplicate">' +
            '<div class="swal-options__col-icon">' +
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" data-v-72ca8247=""><path d="M18 3H4a1 1 0 0 0-1 1v14a1 1 0 1 1-2 0V4a3 3 0 0 1 3-3h14a1 1 0 1 1 0 2ZM13 11a1 1 0 1 1 2 0v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 1 1 0-2h2v-2Z"></path><path fill-rule="evenodd" d="M20 5a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3h12Zm0 2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h12Z" clip-rule="evenodd"></path></svg>' +
            "</div>" +
            '<div class="swal-options__col-content">' +
            '<div class="swal-options__col-title">' +
            "Dupliceren" +
            "</div>" +
            "De bestelling wordt gedupliceerd met een <b>nieuwe factuur</b>." +
            "</div>" +
            "</div>" +
            "</div>",
        showDenyButton: false,
        showConfirmButton: false,
        showCloseButton: true,
    }).then((result) => {
        if (result.isConfirmed) {
            box.destroyAddress(props.box.hash, item.id);
        }
    });
}
</script>
<style scoped></style>
