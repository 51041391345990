import { useAuthClientStore } from "@/store/authClient";
import { defineStore } from "pinia";
import SecureLS from "secure-ls";
export const useBoxStore = defineStore({
    id: "boxes",
    state: () => ({
        errors: {
            message: null,
            errors: [],
        },
        boxes: [],
        order: null,
    }),
    actions: {
        api(order) {
            return "/api/clients/orders/" + order + "/boxes/";
        },
        auth() {
            const user = useAuthClientStore();
            const ls = new SecureLS();
            let pin = ls.get("pin");
            if (ls.get("mayAccess") == false) {
                pin = null;
            }
            return {
                headers: {
                    Authorization: "Bearer " + user.token(),
                    Order: user.order(),
                    Pin: pin,
                },
            };
        },
        async show(path) {
            try {
                let result = await axios.get(this.api() + path, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillBox", result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async getStartAmountBasket(path) {
            try {
                let url =
                    "/api/clients/products?q&box=" + path + "&amount=true";
                let result = await axios.get(url, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("setStartAmount", result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async fill(order) {
            this.$patch({
                order: order,
            });

            try {
                let result = await axios.get(
                    this.api(order) + "?fill=box",
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.success(result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async storeAddress(box, form) {
            try {
                let result = await axios.post(
                    "/api/clients/boxes/" + box + "/addresses/",
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("toasting", "Toegevoegd", "success");
                    this.fillAddresses(box);
                    Eventing.fire("reloadOrder");
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async updateBoxDelivery(order, form) {
            try {
                let result = await axios.post(
                    "/api/clients/orders/" + order + "/deliveries",
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("toasting", "Aangepast");
                    Eventing.fire("fillDelivery", result.data);
                    Eventing.fire("reloadOrder");
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async destroyAddress(box, path) {
            try {
                let result = await axios.delete(
                    "/api/clients/boxes/" + box + "/addresses/" + path,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("toasting", "Verwijderd", "warning");
                    this.fillAddresses(box);
                    Eventing.fire("reloadOrder");
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async fillAddresses(box) {
            try {
                let result = await axios.get(
                    "/api/clients/boxes/" + box + "/addresses/",
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillAddress." + box, result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async deleteBox(order, path) {
            try {
                let result = await axios.delete(
                    this.api(order) + path,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.deleted(result);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async newBox(order, form) {
            try {
                let result = await axios.post(
                    this.api(order),
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.added(result);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async storeBasket(box, form) {
            if (typeof box === "object" && box !== null) {
                box = box.hash;
            }
            try {
                let result = await axios.post(
                    "/api/clients/boxes/" + box + "/baskets/",
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.added(result);
                    Eventing.fire("productAddedToBasket");
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async updateBasket(box, path, form) {
            try {
                let result = await axios.put(
                    "/api/clients/boxes/" + box + "/baskets/" + path,
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.updated(result);
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async updateBoxName(order, path, form) {
            try {
                let result = await axios.put(
                    this.api(order) + path,
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.updated(result);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async destroyBasket(box, path) {
            try {
                let result = await axios.delete(
                    "/api/clients/boxes/" + box + "/baskets/" + path,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.deleted(result);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        deleted() {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Verwijderd", "warning");
            Eventing.fire("reloadOrder");
            this.fill(this.$state.order);
        },
        updated(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Aangepast"); //: " + result.data.data.amount);
            console.info(this);
            Eventing.fire("reloadOrder");
            this.fill(this.$state.order);
        },
        added(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Toegevoegd");
            Eventing.fire("reloadOrder");
            this.fill(this.$state.order);
        },
        success(result) {
            this.$patch({
                boxes: result.data,
            });
        },
        fail(error) {
            console.error(error);
            this.$patch({
                errors: error.response.data,
            });
            Eventing.fire("error", error.response);
        },
    },
});
