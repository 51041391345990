<template>

    <app-dashboards-traffic-navigation />

    <app-stock-content />

    <app-dashboards-side-overlay-traffic-stock />

</template>

<script setup>
import Swal from "sweetalert2";

import {onMounted, reactive, ref, inject } from "vue";

const Modals = inject("Modals");

onMounted(() => {
});


</script>