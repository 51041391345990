<template>
    <article>
        <div class="c-login-frame">
            <div class="c-login-frame__modal">
                <div class="c-login-frame__head">
                    <div class="c-login-frame__logo">
                        <SvgFeelingz />
                    </div>
                </div>
                <div class="c-login-frame__form e-form">
                    <div
                        class="e-note e-note--error"
                        v-if="state.errors.message"
                    >
                        <p class="e-note__text">
                            {{ state.errors.message }}
                        </p>
                    </div>

                    <div class="e-preloader" v-if="!state.isLoaded"></div>

                    <form
                        v-if="state.isLoaded"
                        method="POST"
                        @submit.prevent="onSubmit"
                        @keydown="state.errors = {}"
                        novalidate
                    >
                        <div
                            class="e-form__field-wrap"
                            :class="{
                                'e-form__field-wrap--invalid':
                                    state.errors.message &&
                                    state.errors.errors.email,
                            }"
                        >
                            <input
                                class="e-form__input"
                                type="email"
                                required
                                v-model="state.form.email"
                                placeholder="E-mail"
                            />
                        </div>
                        <div
                            class="e-form__field-wrap"
                            :class="{
                                'e-form__field-wrap--invalid':
                                    state.errors.message &&
                                    state.errors.errors.password,
                            }"
                        >
                            <input
                                class="e-form__input"
                                type="password"
                                v-model="state.form.password"
                                placeholder="Wachtwoord"
                                required
                            />
                        </div>
                        <div class="e-form__field-wrap">
                            <button
                                type="submit"
                                @click="onSubmit()"
                                class="e-button e-button--full-width e-button--green"
                            >
                                Inloggen
                            </button>
                        </div>
                    </form>
                </div>
                <section v-if="state.isLoaded">
                    <div class="c-login-frame__foot">
                        <label
                            class="e-form__checkbox-wrap"
                            style="visibility: hidden"
                        >
                            <input class="e-form__checkbox" type="checkbox" />
                            <span class="e-form__checkbox-label"></span>
                            <span class="e-form__checkbox-text"
                                >Blijf ingelogd</span
                            >
                        </label>

                        <a
                            class="c-login-frame__foot-link"
                            style="visibility: hidden"
                            >Wachtwoord vergeten</a
                        >
                    </div>
                    <div class="c-login-frame__after-modal">
                        <p>Ontwikkeld door Coding Agency & Wndworks</p>
                    </div>
                </section>
            </div>
        </div>
    </article>
</template>

<script setup>
import SvgFeelingz from "@/svg/feelingz.svg";
import Swal from "sweetalert2";

import { onMounted, ref, reactive, inject } from "vue";
import { useAuthStore } from "@/store/auth";
import { useRouter, useRoute } from "vue-router";
const route = useRoute();
const router = useRouter();
const user = useAuthStore();

const state = reactive({
    form: {
        email: null,
        password: null,
    },
    errors: {},
    isLoaded: false,
});

onMounted(() => {
    document.body.className = "";
    document.body.classList.add("body--full-width", "body--auth");
    let auth = user.auth();
    user.authenticate(auth ? auth : { token: "missing" });
    setTimeout(() => {
        state.isLoaded = true;
    }, 500);
});

function onSubmit() {
    axios.get("/sanctum/csrf-cookie").then(() => {
        axios
            .post("/api/authenticate", state.form)
            .then((response) => user.authenticate(response.data))
            .catch((error) => handleLoginError(error));
    });
}

function handleLoginError(error) {
    state.isLoaded = true;
    try {
        error.response && error.response.status == 422
            ? (state.errors = error.response.data)
            : alert(error.message ?? error.response.status);
    } catch (error) {
        console.warn(error);
    }
}
</script>
