<template>
    <article>
        <router-view name="modal" />
        <div class="l-app-container body--sidebar-is-collapsed">
            <router-view name="header" />
            <router-view name="sidebar" />
            <router-view name="main" class="l-main" />
        </div>
    </article>
</template>

<script setup>
import { inject, onMounted } from "vue";
import { useRouter } from "vue-router";
const Toast = inject("Toast");

const router = useRouter();

onMounted(() => {
    Eventing.listen("error", (error) => {
        let _error = "[" + error.status + "] " + error.statusText;
        if (error.data && error.data.message) {
            _error = error.data.message;
        }
        if (error.status == 401) {
            setTimeout(() => {
                Toast.fire({
                    title: _error,
                    icon: "error",
                    position: "top-right",
                });
            }, 1000);
            router.push({
                name: "auth.login",
            });
        } else if (error.status == 404) {
            // Eventing.fire(
            //     "toasting",
            //     "[404: Niet gevonden] " + _error,
            //     "error"
            // );
            console.warn("[404] " + _error);
        } else if (
            error.status >= 400 &&
            error.status != 422 &&
            error.status != 404
        ) {
            Eventing.fire("toasting", _error, "error");
        }
    });
    Eventing.listen("toasting", (message, icon) => {
        if (typeof icon === "undefined" || !icon) {
            icon = "success";
        }
        Toast.fire({
            icon: icon,
            title: message,
            position: "center",
        });
    });

    Eventing.listen("toggleMenu", (menu) => {
        if (["mobile", "topbar"].includes(menu)) {
            document.body.classList.toggle("body--" + menu + "-menu-is-open");
            if (menu == "mobile") {
                document.body.classList.remove("body--topbar-menu-is-open");
            }
        }
        if (["client"].includes(menu)) {
            document.body.classList.toggle("body--" + menu + "-menu-is-closed");
        }
    });

    Eventing.listen("stickyElement", () => {
        const elements = document.querySelectorAll(".js-sticky-element");

        if (elements.length > 0) {
            elements.forEach((element) => {
                //Store the heights of all the topbars visible on the page
                const topBar = document.querySelector(".c-topbar");
                const belowTopBar = document.querySelector(".c-below-topbar");
                const pageMenu = document.querySelector(".c-page-menu");
                var topHeight = 10; //Spacing in px

                if (topBar) {
                    topHeight += topBar.offsetHeight;
                }

                if (belowTopBar) {
                    topHeight += belowTopBar.offsetHeight;
                }

                if (pageMenu) {
                    topHeight += pageMenu.offsetHeight;
                }

                //Start on top of page to keep the right calculations of the element
                window.scrollTo(0, 0);

                //Get the current top position of the element
                var elementTop = element.getBoundingClientRect().top;

                //Variable to store the new top position of the element
                var newElementTop = elementTop;

                //Current page height
                var pageHeight = document.body.clientHeight;

                //Create observer to observe document height changes
                const resizeObserver = new ResizeObserver((entries) => {
                    //New page height
                    const newPageHeight = entries[0].target.clientHeight;

                    //Difference variable to store the change in height
                    var difference;

                    //If the height is smaller
                    if (newPageHeight < pageHeight) {
                        //Calculate the difference in height
                        difference = pageHeight - newPageHeight;

                        //Set new top value for the element
                        newElementTop = newElementTop - difference;

                        //If the height is bigger
                    } else if (newPageHeight > pageHeight) {
                        //Calculate the difference in height
                        difference = newPageHeight - pageHeight;

                        //Set new top value for the element
                        newElementTop = elementTop + difference;
                    } else {
                        newElementTop = elementTop;
                    }

                    makeElementStick();

                    //Stop observing the document height
                    resizeObserver.unobserve(document.body);
                });

                //Click elements that affect the height of the document to start observing the document height changes
                var clickElements =
                    document.querySelectorAll(".js-toggle-widgets");

                clickElements.forEach((element) => {
                    element.addEventListener("click", function () {
                        //Start observing the document height changes
                        resizeObserver.observe(document.body);
                    });
                });

                //The function to make the element sticky
                function makeElementStick() {
                    const elementPosition = newElementTop - topHeight;

                    //If the element reaches the position below the headers
                    if (window.scrollY > elementPosition) {
                        //Add top position in pixels based on scroll position
                        element.style.top =
                            window.scrollY - elementPosition + "px";
                    } else {
                        //Reset the top position
                        element.style.top = "0px";
                    }
                }

                //Run the functions
                makeElementStick();
                window.addEventListener("scroll", makeElementStick);
                window.addEventListener("resize", makeElementStick);
            });
        }
    });

    Eventing.listen("toggleSideOverlay", () => {
        var classNameActive;

        //Open side overlay on click
        const openTriggers = document.querySelectorAll(".js-open-side-overlay");

        if (openTriggers.length > 0) {
            const sideOverlay = document.querySelector(".c-side-overlay");

            if (sideOverlay) {
                openTriggers.forEach((trigger) => {
                    trigger.addEventListener("click", function (e) {
                        classNameActive = trigger.classList[0] + "--active";

                        if (trigger.classList.contains(classNameActive)) {
                            removeTileActiveClasses();
                            closeSideOverlay(trigger);
                        } else {
                            removeTileActiveClasses();
                            openSideOverlay(trigger);
                        }

                        e.stopImmediatePropagation();
                    });
                });
            }
        }

        //Close side overlay on click
        const closeTriggers = document.querySelectorAll(
            ".js-close-side-overlay"
        );

        if (closeTriggers.length > 0) {
            closeTriggers.forEach((trigger) => {
                trigger.addEventListener("click", function () {
                    closeSideOverlay(trigger);
                    removeTileActiveClasses();
                });
            });
        }

        function openSideOverlay(trigger) {
            if (!classNameActive) {
                classNameActive = trigger.classList[0] + "--active";
            }

            trigger.classList.add(classNameActive);
            document.body.classList.add("body--side-overlay-is-open");
        }

        function closeSideOverlay(trigger) {
            trigger.classList.remove(classNameActive);
            document.body.classList.remove("body--side-overlay-is-open");
        }

        function removeTileActiveClasses() {
            const tiles = document.querySelectorAll("." + classNameActive);

            tiles.forEach((tile) => {
                tile.classList.remove(classNameActive);
            });
        }
    });

    Eventing.listen("toggleWidgets", () => {
        const elements = document.querySelectorAll(".js-toggle-widgets");

        if (elements.length > 0) {
            elements.forEach((element) => {
                element.addEventListener("click", function () {
                    document.body.classList.toggle("body--widgets-are-closed");
                });
            });
        }
    });

    /**
     * Te gebruiken via: Eventing.fire('scrollContentIntoView');
     */
    Eventing.listen("scrollContentIntoView", () => {
        try {
            const contentDiv = document.querySelector(".c-page-content");
            contentDiv.scrollIntoView({ behavior: "smooth" });
        } catch (error) {}
    });

    //Add arrows to overflowing content
    Eventing.listen("addOverflowArrows", () => {
        //Add the arrow to areas
        function addArrowToAreas() {
            //Scroll areas
            const areas = document.querySelectorAll(".js-overflow-arrow");

            //If areas exist
            if (areas.length > 0) {
                //For each area
                areas.forEach((area) => {
                    //Select arrow
                    const arrow = document.querySelector(".e-overflow-arrow");

                    //If area has overflowing content
                    if (area.scrollWidth > area.offsetWidth) {
                        //If arrow does not exist
                        if (!arrow) {
                            //Add arrow
                            addArrow(area);
                        }

                        //If area doesn't have overflowing content
                    } else {
                        //If arrow exist
                        if (arrow) {
                            //Remove the arrow
                            arrow.remove();
                        }
                    }
                });
            }
        }

        //Add the arrow
        function addArrow(area) {
            //Create arrow div
            var arrow = document.createElement("div");

            //Add class
            arrow.classList.add("e-overflow-arrow");

            //Add the div to the area
            area.append(arrow);

            //Add click functionality
            arrow.addEventListener("click", function () {
                //Scroll the area to the right
                area.scrollTo({
                    left: area.scrollWidth,
                    behavior: "smooth",
                });
            });

            //Detect scroll on the area
            area.addEventListener("scroll", function () {
                //If arrow exist and the area is scrolled
                if (arrow && area.scrollLeft > 0) {
                    //Hide the arrow
                    arrow.classList.add("e-overflow-arrow--hidden");
                } else {
                    //Remove the arrow
                    arrow.classList.remove("e-overflow-arrow--hidden");
                }
            });
        }

        addArrowToAreas();
        window.addEventListener("resize", addArrowToAreas);
    });
});
</script>
