import { useAuthStore } from "@/store/auth";
import { defineStore } from "pinia";
import Swal from "sweetalert2";

export const useAdditionStore = defineStore({
    id: "additions",
    state: () => ({
        errors: {
            message: null,
            errors: [],
        },
        additions: [],
        order: null,
    }),
    actions: {
        api(order) {
            return "/api/orders/" + order + "/additions/";
        },
        auth() {
            const user = useAuthStore();
            return {
                headers: {
                    Authorization: "Bearer " + user.token(),
                },
            };
        },
        async show(path) {
            try {
                let result = await axios.get(this.api() + path, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillAddition", result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async fill(order) {
            this.$patch({
                order: order,
            });

            try {
                let result = await axios.get(this.api(order), this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.success(result.data);
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async store(order, form) {
            if (form.total != 0) {
                await Swal.fire({
                    title: "BTW-percentage",
                    confirmButtonText: "Doorgaan",
                    showCloseButton: true,
                    showCancelButton: true,
                    cancelButtonText: "Annuleren",
                    icon: "info",
                    input: "select",
                    inputOptions: {
                        0: "0%",
                        9: "9%",
                        21: "21%",
                    },
                }).then((result) => {
                    if (result.value) {
                        form.vat = result.value;
                    }
                });
            }
            try {
                let result = await axios.post(
                    this.api(order),
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.added(result);
                    if (result.data.data.box_id) {
                        Eventing.fire("reloadBox");
                        Eventing.fire("reloadOpenBox", result.data.data.box);
                    }
                    return result.status;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async update(order, path, form) {
            try {
                let result = await axios.put(
                    this.api(order) + path,
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.updated(result);
                    Eventing.fire("reloadOpenBox", result.data.data.box);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async destroy(order, path) {
            try {
                let result = await axios.delete(
                    this.api(order) + path,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.deleted(result);

                    Eventing.fire("reloadOpenBoxByHash", result.data.hash);
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        deleted() {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Verwijderd", "warning");
            Eventing.fire("reloadOrder");
            Eventing.fire("reloadBox");
            Eventing.fire("reloadAdditions");
            this.fill(this.$state.order);
        },
        updated(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Aangepast"); //: " + result.data.data.amount);
            Eventing.fire("reloadOrder");
            console.info(this);
            Eventing.fire("reloadBox");
            Eventing.fire("reloadAdditions");
            this.fill(this.$state.order);
        },
        added(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Toegevoegd");
            Eventing.fire("reloadOrder");
            Eventing.fire("reloadAdditions");
            this.fill(this.$state.order);
        },
        success(result) {
            this.$patch({
                additions: result.data,
            });
        },
        fail(error) {
            console.error(error);
            this.$patch({
                errors: error.response.data,
            });
            Eventing.fire("error", error.response);
        },
    },
});
