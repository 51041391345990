<template>
    <article>
        <div class="c-page-content">
            <div
                class="c-page-content__container c-page-content__container--spacing e-load-items-animation"
            >
                <section class="c-page-content__section e-module-style">
                    <div class="e-section-heading">
                        <div class="e-section-heading__left">
                            <h2 class="e-section-heading__name">
                                Aanleverdeadline
                            </h2>
                        </div>
                        <div class="e-section-heading__right"></div>
                    </div>
                    <div class="e-note mb-10" v-if="!state.form.deadline">
                        <SvgInfo />
                        <div class="e-note__text">
                            <p>
                                Geef de uiterlijke aanleverdatum op. Het systeem
                                zal o.b.v. deze datum automatische
                                herinneringsmails versturen. Indien er geen
                                datum is ingevuld worden de e-mails niet
                                verstuurd. Dit geldt voor zowel e-mails over het
                                herinneren voor het aanleveren van bestanden als
                                het definitief maken van een leverdatum.
                            </p>
                        </div>
                    </div>
                    <div class="e-form">
                        <div class="e-form__field-wrap">
                            <input
                                class="e-form__input"
                                type="date"
                                v-model="state.form.deadline"
                                :min="state.future_date"
                                required
                                foo-click="
                                    state.showDatepicker = !state.showDatepicker
                                "
                                v-show="!state.showDatepicker"
                            />
                            <button
                                class="e-button e-button--grey-dark"
                                open-modal-id="add-email"
                                @click="saveDeadline()"
                            >
                                Toevoegen
                            </button>
                        </div>
                    </div>
                </section>

                <div class="c-page-content__section e-module-style">
                    <div class="e-section-heading">
                        <div class="e-section-heading__left">
                            <h2 class="e-section-heading__name">
                                Aan te leveren bestanden

                                <span v-if="state.loaded"
                                    >{{ state.order.checks.done }}/{{
                                        state.order.checks.total
                                    }}</span
                                >
                                <span>
                                    <span
                                        v-if="
                                            !state.loaded ||
                                            state.order.checks?.total == null
                                        "
                                        class="e-preloader"
                                    ></span
                                ></span>
                            </h2>
                        </div>
                    </div>

                    <div v-if="!state.isLoaded" class="e-preloader"></div>

                    <div class="c-files" v-if="state.order.checks.total > 0">
                        <!-- @include('frontend.partials.order.tabs.files.options') -->
                        <!-- 
                            <section class="c-page-content__section">
                            <div
                                class="c-files__options e-form__multiple-checkboxes e-form__multiple-checkboxes--list"
                            >
                                <label class="e-form__checkbox-wrap">
                                    <input
                                        class="e-form__checkbox"
                                        type="checkbox"
                                        checked
                                    />
                                    <span class="e-form__checkbox-label"></span>
                                    <span class="e-form__checkbox-text"
                                        >Stuur automatisch herinnering e-mail bij
                                        ontbrekende bestanden</span
                                    >
                                </label>
                                <label class="e-form__checkbox-wrap">
                                    <input
                                        class="e-form__checkbox"
                                        type="checkbox"
                                        checked
                                    />
                                    <span class="e-form__checkbox-label"></span>
                                    <span class="e-form__checkbox-text"
                                        >Stuur automatisch herinnering SMS bericht
                                        bij ontbrekende bestanden</span
                                    >
                                </label>
                            </div>
                        </section>
                        -->

                        <!-- @include('frontend.partials.order.tabs.files.sections') -->
                        <section class="c-page-content__section">
                            <div
                                class="c-files__sections"
                                v-for="box in state.attachments.boxes"
                                :key="box.id"
                                v-show="checkIfHasFiles(box)"
                            >
                                <h2 class="c-files__file-sub-section-title">
                                    <SvgGift /> {{ box.name }}
                                </h2>

                                <div
                                    class="c-files__section"
                                    v-for="basket in box.baskets"
                                    :key="basket.id + box.id"
                                    :class="{
                                        'c-files__section--files-missing':
                                            checkIfCompleted(
                                                basket.attachments
                                            ),
                                    }"
                                    v-show="
                                        basket.attachments &&
                                        basket.attachments.length > 0
                                    "
                                >
                                    <div
                                        class="e-section-heading e-section-heading--flex-start e-section-heading--label-style"
                                        v-if="
                                            basket.attachments &&
                                            basket.attachments.length > 0
                                        "
                                    >
                                        <div class="e-section-heading__left">
                                            <div
                                                class="e-section-heading__icon"
                                            >
                                                <SvgCross
                                                    v-if="
                                                        checkIfCompleted(
                                                            basket.attachments
                                                        )
                                                    "
                                                />
                                                <SvgCheck
                                                    v-if="
                                                        !checkIfCompleted(
                                                            basket.attachments
                                                        )
                                                    "
                                                />
                                            </div>
                                            <p class="e-section-heading__name">
                                                {{ basket.product.name }}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        class="c-files__files"
                                        v-if="
                                            basket.attachments &&
                                            basket.attachments.length > 0
                                        "
                                    >
                                        <section
                                            v-for="attachment in basket.attachments"
                                            :key="attachment.id"
                                        >
                                            <div
                                                class="c-files__file"
                                                v-if="attachment.completed_at"
                                            >
                                                <p
                                                    class="c-files__file-name"
                                                    v-if="
                                                        !attachment.path &&
                                                        !attachment.rejected_at
                                                    "
                                                >
                                                    Gemarkeerd als aangeleverd
                                                    door
                                                    {{
                                                        attachment.completed_by
                                                    }}
                                                    op
                                                    {{
                                                        attachment.completed_at
                                                    }}
                                                </p>

                                                <p
                                                    class="c-files__file-name"
                                                    style="color: tomato"
                                                    v-if="
                                                        !attachment.path &&
                                                        attachment.rejected_at
                                                    "
                                                >
                                                    Gemarkeerd als afgewezen
                                                    door
                                                    {{
                                                        attachment.completed_by
                                                    }}
                                                    op
                                                    {{ attachment.rejected_at }}
                                                </p>

                                                <p
                                                    class="c-files__file-name"
                                                    v-if="attachment.path"
                                                >
                                                    {{ attachment.name }}
                                                    <span>{{
                                                        attachment.filename
                                                    }}</span>
                                                    <span
                                                        class="c-files__file-date"
                                                    >
                                                        Toegevoegd door
                                                        {{
                                                            attachment.completed_by
                                                        }}
                                                        op
                                                        {{
                                                            attachment.completed_at
                                                        }}
                                                    </span>
                                                </p>
                                                <div
                                                    class="c-files__file-right"
                                                    v-if="
                                                        attachment.completed_at
                                                    "
                                                >
                                                    <a
                                                        v-if="attachment.path"
                                                        class="e-button e-button--small"
                                                        @click="
                                                            downloadAttachment(
                                                                attachment
                                                            )
                                                        "
                                                        download
                                                        >Download</a
                                                    >
                                                    <a
                                                        class="c-files__file-delete"
                                                        @click="
                                                            resetAttachment(
                                                                attachment,
                                                                box
                                                            )
                                                        "
                                                    >
                                                        <SvgDelete />
                                                    </a>
                                                </div>
                                            </div>

                                            <div
                                                class="c-files__file-upload"
                                                v-if="!attachment.completed_at"
                                            >
                                                <file-uploader
                                                    :attachment="attachment"
                                                    :box="box.hash"
                                                    :order="state.order.hash"
                                                />
                                                <div
                                                    class="c-files__file-upload-mark"
                                                >
                                                    <label
                                                        @click="
                                                            markAsCompleted(
                                                                attachment,
                                                                box
                                                            )
                                                        "
                                                        class="e-form__checkbox-wrap"
                                                    >
                                                        <input
                                                            class="e-form__checkbox"
                                                            type="checkbox"
                                                        />
                                                        <span
                                                            class="e-form__checkbox-label"
                                                        ></span>
                                                        <span
                                                            class="e-form__checkbox-text"
                                                            >Markeer als
                                                            aangeleverd</span
                                                        >
                                                    </label>

                                                    <label
                                                        class="e-form__checkbox-wrap"
                                                        @click="
                                                            markAsRejected(
                                                                attachment,
                                                                attachment,
                                                                box
                                                            )
                                                        "
                                                    >
                                                        <input
                                                            class="e-form__checkbox"
                                                            type="checkbox"
                                                        />
                                                        <span
                                                            class="e-form__checkbox-label"
                                                        ></span>
                                                        <span
                                                            class="e-form__checkbox-text"
                                                            >De klant wil hier
                                                            geen gebruik van
                                                            maken</span
                                                        >
                                                    </label>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section class="c-page-content__section">
                            <h2
                                class="c-files__file-sub-section-title"
                                v-if="
                                    state.attachments &&
                                    state.attachments.additions &&
                                    state.attachments.additions.length > 0
                                "
                            >
                                Extra opties
                            </h2>

                            <div
                                class="c-files__sections"
                                v-for="addition in state.attachments.additions"
                                :key="addition.id"
                            >
                                <div
                                    class="c-files__section"
                                    :class="{
                                        'c-files__section--files-missing':
                                            checkIfCompleted(
                                                addition.attachments
                                            ),
                                    }"
                                    v-show="addition.attachments.length > 0"
                                >
                                    <div
                                        class="e-section-heading e-section-heading--label-style"
                                    >
                                        <div class="e-section-heading__left">
                                            <div
                                                class="e-section-heading__icon"
                                            >
                                                <SvgCross
                                                    v-if="
                                                        checkIfCompleted(
                                                            addition.attachments
                                                        )
                                                    "
                                                />
                                                <SvgCheck
                                                    v-if="
                                                        !checkIfCompleted(
                                                            addition.attachments
                                                        )
                                                    "
                                                />
                                            </div>
                                            <p class="e-section-heading__name">
                                                {{ addition.name }}
                                            </p>
                                        </div>
                                        <div
                                            class="e-section-heading__right"
                                        ></div>
                                    </div>
                                    <div class="c-files__files">
                                        <section
                                            v-for="attachment in addition.attachments"
                                            :key="attachment.id"
                                        >
                                            <div
                                                class="c-files__file"
                                                v-if="attachment.completed_at"
                                            >
                                                <p
                                                    class="c-files__file-name"
                                                    v-if="!attachment.path"
                                                >
                                                    Gemarkeerd als aangeleverd
                                                    door
                                                    {{
                                                        attachment.completed_by
                                                    }}
                                                    op
                                                    {{
                                                        attachment.completed_at
                                                    }}
                                                </p>

                                                <p
                                                    class="c-files__file-name"
                                                    v-if="attachment.path"
                                                >
                                                    {{ attachment.name }}
                                                    <span>{{
                                                        attachment.filename
                                                    }}</span>
                                                    <span
                                                        class="c-files__file-date"
                                                    >
                                                        Toegevoegd door
                                                        {{
                                                            attachment.completed_by
                                                        }}
                                                        op
                                                        {{
                                                            attachment.completed_at
                                                        }}
                                                    </span>
                                                </p>
                                                <div
                                                    class="c-files__file-right"
                                                    v-if="
                                                        attachment.completed_at
                                                    "
                                                >
                                                    <a
                                                        class="e-button e-button--small"
                                                        v-if="attachment.path"
                                                        @click="
                                                            downloadAttachment(
                                                                attachment
                                                            )
                                                        "
                                                        download
                                                        >Download</a
                                                    >
                                                    <a
                                                        class="c-files__file-delete"
                                                        @click="
                                                            resetAttachment(
                                                                attachment,
                                                                'none'
                                                            )
                                                        "
                                                    >
                                                        <SvgDelete />
                                                    </a>
                                                </div>
                                            </div>

                                            <div
                                                class="c-files__file-upload"
                                                v-if="!attachment.completed_at"
                                            >
                                                <file-uploader
                                                    :attachment="attachment"
                                                    :addition="addition.id"
                                                    :order="state.order.hash"
                                                />

                                                <div
                                                    class="c-files__file-upload-mark"
                                                    @click="
                                                        markAsCompleted(
                                                            attachment,
                                                            'none'
                                                        )
                                                    "
                                                >
                                                    <label
                                                        class="e-form__checkbox-wrap"
                                                    >
                                                        <input
                                                            class="e-form__checkbox"
                                                            type="checkbox"
                                                        />
                                                        <span
                                                            class="e-form__checkbox-label"
                                                        ></span>
                                                        <span
                                                            class="e-form__checkbox-text"
                                                            >Markeer als
                                                            aangeleverd</span
                                                        >
                                                    </label>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <!-- <section style="display: none!important">
                            <div class="c-files__sections">
                                <div
                                    onclick="alert('TODO')"
                                    class="c-files__section c-files__section--files-missing"
                                >
                                    <div
                                        class="e-section-heading e-section-heading--label-style"
                                    >
                                        <div class="e-section-heading__left">
                                            <div
                                                class="e-section-heading__icon"
                                            >
                                                <SvgCross />
                                            </div>
                                            <p class="e-section-heading__name">
                                                Afleveradressen
                                            </p>
                                        </div>
                                        <div
                                            class="e-section-heading__right"
                                        ></div>
                                    </div>
                                    <div class="e-note">
                                        <SvgInfo />
                                        <div class="e-note__text">
                                            <p>
                                                Upload een Excel-bestand met alle
                                                afleveradressen. <b>Let op:</b>
                                                <a href=""
                                                    >download het voorbeeld CSV
                                                    bestand</a
                                                >
                                                of gebruik de volgende
                                                structuur: Naam | Postcode |
                                                Adres | Huisnummer.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="c-files__files">
                                        <div class="c-files__file-sub-section">
                                            <label
                                                class="e-form__checkbox-wrap"
                                            >
                                                <input
                                                    class="e-form__checkbox"
                                                    type="checkbox"
                                                />
                                                <span
                                                    class="e-form__checkbox-label"
                                                ></span>
                                                <span
                                                    class="e-form__checkbox-text"
                                                    >Alle samenstellingen hebben
                                                    dezelfde
                                                    afleveradressen</span
                                                >
                                            </label>
                                        </div>
                                        <div class="c-files__file-sub-section">
                                            <h2
                                                class="c-files__file-sub-section-title"
                                            >
                                                <SvgGift /> Samenstelling 1
                                            </h2>
                                            <div class="c-files__file-upload">
                                                <div
                                                    class="e-form__upload-area"
                                                >
                                                    <input
                                                        class="e-form__upload-area-input"
                                                        type="file"
                                                        name="incoming-pdf"
                                                        id="incoming-pdf"
                                                        required="required"
                                                        multiple="multiple"
                                                    />
                                                    <div
                                                        class="e-form__upload-area-text"
                                                    >
                                                        <div
                                                            class="e-form__upload-area-text-uploading"
                                                        >
                                                            Uploading...
                                                        </div>
                                                        <div
                                                            class="e-form__upload-area-text-default"
                                                        >
                                                            Selecteer of sleep
                                                            bestanden om te
                                                            uploaden
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="c-files__file-sub-section">
                                            <h2
                                                class="c-files__file-sub-section-title"
                                            >
                                                <SvgGift /> Samenstelling 2
                                            </h2>
                                            <div class="c-files__file-upload">
                                                <div
                                                    class="e-form__upload-area"
                                                >
                                                    <input
                                                        class="e-form__upload-area-input"
                                                        type="file"
                                                        name="incoming-pdf"
                                                        id="incoming-pdf"
                                                        required="required"
                                                        multiple="multiple"
                                                    />
                                                    <div
                                                        class="e-form__upload-area-text"
                                                    >
                                                        <div
                                                            class="e-form__upload-area-text-uploading"
                                                        >
                                                            Uploading...
                                                        </div>
                                                        <div
                                                            class="e-form__upload-area-text-default"
                                                        >
                                                            Selecteer of sleep
                                                            bestanden om te
                                                            uploaden
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section> -->
                    </div>
                </div>
            </div>
        </div>
    </article>
</template>

<script setup>
import SvgCheck from "@/svg/check.svg";
import SvgCross from "@/svg/cross.svg";
import SvgDelete from "@/svg/delete.svg";
import SvgGift from "@/svg/gift.svg";
import SvgInfo from "@/svg/info.svg";

import { useAttachmentStore } from "@/store/attachment";
import { useOrderStore } from "@/store/order";
import Swal from "sweetalert2";
import { inject, nextTick, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";

import "v-calendar/dist/style.css";

const Modals = inject("Modals");
const order = useOrderStore();

const attachment = useAttachmentStore();

const route = useRoute();

const state = reactive({
    order: {
        contact: {
            address: {},
        },
        followers: {},
        checks: {
            done: 0,
            total: null,
            todo: 0,
            perc: 0,
        },
    },
    loaded: false,
    isLoaded: false,
    attachments: {
        boxes: {},
        additions: {},
    },
    form: {
        deadline: null,
    },
    future_date: new Date().toISOString().split("T")[0],
    showDatepicker: false,
});
onMounted(() => {
    loadAndShowOrder();

    Eventing.listen("fillOrder", (data) => {
        state.order = data.data;
        state.form.deadline = state.order.deadline;
    });

    Eventing.listen("dateIsPicked", () => {
        state.showDatepicker = false;
    });

    Eventing.listen("reloadOrder", () => {
        setTimeout(() => {
            order.show(route.params.hash);
            attachment.fill(route.params.hash);
        }, 1000);
    });

    Eventing.listen("reloadAttachments", () => {
        fillAttachments();
    });

    Eventing.listen("doFillExtraOptions", () => {
        fillAttachments();
    });
});

async function loadAndShowOrder() {
    let result = await order.show(route.params.hash);
    if (result) {
        console.info("loadAndShowOrder", result.data);
        state.order = result.data;
        state.loaded = true;
        fillAttachments();
    }
}

async function loadAttachmentsForBox(box) {
    let result = await attachment.fillBox(route.params.hash, box);
    if (result) {
        console.warn(result);

        state.attachments.boxes.forEach((b, k) => {
            if (b.hash == box.hash) {
                state.attachments.boxes[k] = result.data;
            }
        });
        return true;
    }
    return false;
}

async function fillAttachments() {
    let result = await attachment.fillWithoutFilling(route.params.hash);
    if (result) {
        console.info("filBoxes", result.data);
        state.attachments = result.data;

        state.isLoaded = false;

        loadAttachmentsForAllBoxes();

        await nextTick();

        state.isLoaded = true;
    }
}

function loadAttachmentsForAllBoxes() {
    state.isLoaded = false;
    state.attachments.boxes.forEach((b) => {
        state.isLoaded = false;
        var result = loadAttachmentsForBox(b);
        if (result) {
            state.isLoaded = true;
            return result;
        }
    });
}

function checkIfCompleted(attachments) {
    try {
        if (attachments.length <= 0) {
            return false;
        }
        const count = attachments.filter((obj) => {
            if (obj.completed_at) {
                return true;
            }
            return false;
        }).length;
        if (attachments.length != count) {
            return true;
        }
    } catch (error) {
        // console.error("checkIfCompleted", error);
    }
    return false;
}

function checkIfHasFiles(box) {
    let hasFilesInBox = false;
    try {
        box.baskets.forEach((basket) => {
            if (basket.attachments.length > 0) {
                hasFilesInBox = true;
            }
        });
    } catch (error) {}
    return hasFilesInBox;
}

function downloadAttachment(_attachment) {
    attachment.show(state.order.hash, _attachment.id);
}

function saveDeadline() {
    if (state.form.deadline) {
        order.setDeadline(state.order.hash, state.form.deadline);
    }
}

function resetAttachment(item, box) {
    setTimeout(() => {
        Modals.close();
    }, 100);
    Swal.fire({
        text:
            'Zeker weten dat je "' +
            item.name +
            '" wilt resetten zodat er een nieuw bestand kan worden geüpload?',
        confirmButtonText: "Reset",
        showCloseButton: true,
        icon: "warning",
    }).then(async (result) => {
        if (result.isConfirmed) {
            let result = await attachment.remove(state.order.hash, item.id);
            if (result) {
                if (box == "none") {
                    fillAttachments();
                } else {
                    try {
                        loadAttachmentsForBox(box);
                    } catch (error) {
                        Eventing.fire("doFillExtraOptions");
                    }
                }
            }
        }
    });
}

function markAsCompleted(item, box) {
    setTimeout(() => {
        Modals.close();
    }, 100);
    Swal.fire({
        text:
            'Zeker weten dat je "' +
            item.name +
            '" wilt markeren als aangeleverd?',
        confirmButtonText: "Ja",
        showCloseButton: true,
        icon: "warning",
    }).then(async (result) => {
        if (result.isConfirmed) {
            let result = await attachment.completed(state.order.hash, item.id);
            if (result) {
                if (box == "none") {
                    fillAttachments();
                } else {
                    try {
                        loadAttachmentsForBox(box);
                    } catch (error) {
                        Eventing.fire("doFillExtraOptions");
                    }
                }
            }
        }
    });
}

function markAsRejected(item, product, box) {
    setTimeout(() => {
        Modals.close();
    }, 100);
    Swal.fire({
        text:
            'Zeker weten dat je geen gebruikt wilt maken van "' +
            item.name +
            '" voor "' +
            product.name +
            '"?',
        confirmButtonText: "Ja",
        showCloseButton: true,
        icon: "warning",
    }).then(async (result) => {
        if (result.isConfirmed) {
            let result = await attachment.rejected(state.order.hash, item.id);
            if (result) {
                if (box == "none") {
                    fillAttachments();
                } else {
                    try {
                        loadAttachmentsForBox(box);
                    } catch (error) {
                        Eventing.fire("doFillExtraOptions");
                    }
                }
            }
        }
    });
}
</script>
