<template>

    <apexchart 
        width="100%" 
        height="100%" 
        :series="props.series"
        :options='chart.options'
    >
    </apexchart>

</template>

<script setup>
import { inject, onMounted, reactive } from "vue";

const props = defineProps({
    series: Array,
    labels: Array,
    colors: {
        type: Array,
        default: ['#78ceab', '#5fb090', '#499175'],
    },
    module_theme: "", //'green' for green styling
});

//Green module style
var themeOptions = {
    colors: props.colors,
    stroke: {},
    grid: {
        borderColor: {},
    },
    dataLabels: {
        style: {},
        background: {},
    },
    fill: {},
    xaxis: {
        crosshairs: {},
        labels: {
            style: {},
        },
        axisBorder: {},
          axisTicks: {},
    },
    yaxis: {
        labels: {
            style: {},
        },
    },
};

if(props.module_theme == 'green') {

    themeOptions = {
        colors: ['rgb(73, 145, 117)'],
        stroke: {
            width: 2,
            colors: ['#fff'],
        },
        grid: {
            borderColor: 'rgba(255,255,255,0.2)',
        },
        dataLabels: {
            style: {
                fontSize: '12px',
            },
            background: {
                foreColor: '#fff',
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                  shade: 'lighten',
                  type: "vertical",
                  shadeIntensity: 0,
                  gradientToColors: ['#fff', '#fff'],
                  inverseColors: false,
                  opacityFrom: 0.2,
                  opacityTo: 0.8,
                  stops: [10, 100],
                  colorStops: []
              },
          },
          xaxis: {
              crosshairs: {
                  stroke: {
                      color: 'rgba(255,255,255,0.8)',
                      width: 1,
                  },
              },
              labels: {
                style: {
                    fontSize: '14px',
                      colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
                      fontWeight: 500,
                  },
              },
              axisBorder: {
                color: '#fff',
              },
              axisTicks: {
                  show: false
              },
          },
          yaxis: {
                labels: {
                style: {
                      colors: ['#fff', '#fff', '#fff', '#fff', '#fff','#fff', '#fff'],
                      fontWeight: 500,
                  },
              },
          },
    }
}


const chart = reactive({

      options: {
        colors: themeOptions.colors,
        labels: props.labels,
        fill: themeOptions.fill,
        stroke: themeOptions.stroke,
        chart: {
          type: 'area',
          group: 'status',
          offsetY: 0,
        },
        grid: {
            borderColor: themeOptions.grid.borderColor,
            xaxis: {
                lines: {
                    show: false
                }
            },
            padding: {
                top: 0,
                bottom: 0,
            },
        },
        dataLabels: {
          enabled: true,
          style: themeOptions.dataLabels.style,
          background: themeOptions.dataLabels.background,
        },
        legend: {
            position: 'left',
        },
        xaxis: {
            show: true,
            crosshairs: themeOptions.xaxis.crosshairs,
            labels: {
                style: themeOptions.xaxis.labels.style,
            },
            axisBorder: themeOptions.xaxis.axisBorder,
            axisTicks: themeOptions.xaxis.axisTicks,
        },
        yaxis: {
            show: true,
            labels: {
                style: themeOptions.yaxis.labels.style,
              },

        },
        tooltip: {
            x: {
                show: false,
              },
            intersect: false,
            shared: true,
            marker: {
                show: false,
            },
        }
      },
});


</script>