<template>
    <div
        v-if="showcategory"
        v-show="isNewCategory"
        class="c-table-item c-table-item--is-category"
    >
        <div class="c-table-item__col c-table-item__col--name">
            {{ product.category.name }}
        </div>
        <div class="c-table-item__col c-table-item__col--article-nr"></div>
        <div class="c-table-item__col c-table-item__col--price"></div>
        <div class="c-table-item__col c-table-item__col--amount"></div>
        <div class="c-table-item__col c-table-item__col--button-big"></div>
    </div>

    <client-product-adding-row
        v-if="showcategory"
        :product="product"
        :box="box"
    >
    </client-product-adding-row>

    <div
        class="c-table-item"
        v-if="!showcategory"
        :class="{
            'c-table-item--is-inside-category': product.category,
        }"
    >
        <div class="c-table-item__col c-table-item__col--product-name">
            <div class="c-table-item__product-image" v-if="product.image">
                <img
                    class="c-table-item__product-image-thumb"
                    :src="product.image"
                />
            </div>
            {{ product.name }}
        </div>
        <div class="c-table-item__col c-table-item__col--article-nr">
            {{ product.article_nr }}
        </div>
        <div class="c-table-item__col c-table-item__col--price">
            €{{ product.euro }}
        </div>
        <div class="c-table-item__col c-table-item__col--amount">
            <div class="e-form__field-wrap">
                <input
                    class="e-form__input e-form__input--small e-form__input--amount"
                    type="number"
                    min="0"
                    steps="1"
                    v-model="state.form.amount"
                    :disabled="state.form.disabled"
                    v-if="!product.just_one"
                />
                <input
                    class="e-form__input e-form__input--small e-form__input--amount"
                    type="hidden"
                    v-model="state.form.amount"
                    :disabled="state.form.disabled"
                    v-if="product.just_one"
                />
            </div>
        </div>
        <div
            class="c-table-item__col c-table-item__col--button-big"
            @click="addProduct(product)"
        >
            <button :disabled="state.busy" class="e-button e-button--small">
                Toevoegen
            </button>
        </div>
    </div>
</template>
<script setup>
import { useBoxStore } from "@/store/client/box";
import { onMounted, reactive } from "vue";
const props = defineProps({
    product: Object,
    showcategory: Boolean,
    isNewCategory: Boolean,
    box: String,
});
const boxing = useBoxStore();
const state = reactive({
    form: {
        amount: 0,
        product_id: null,
        disabled: false,
    },
    busy: false,
});

onMounted(() => {
    // findImage(props.product);

    Eventing.listen("productAddedToBasket", () => {
        setTimeout(() => {
            state.busy = false;
        }, 500);
    });

    Eventing.listen("setStartAmount", (amount) => {
        state.form.amount = amount;
        if (amount > 0) {
            state.form.disabled = true;
        }
    });
});

async function addProduct(item) {
    if (state.busy == false) {
        state.busy = true;
        if (item.just_one) {
            state.form.amount = 1;
        }
        if (state.form.amount > 0) {
            state.form.product_id = item.id;

            // Eventing.fire("addProductToBox", props.box, state.form);
            try {
                await boxing.storeBasket(props.box, state.form);
            } catch (error) {
                Eventing.fire("addProductToBox", props.box, state.form);
            }
        }
    }
}

// function findImage(product) {
//     try {
//         axios
//             .get(product.image + "?url=true", { withCredentials: false })
//             .then(function (response) {
//                 console.warn(response.status);
//                 if (response.data) {
//                     if (response.data == "" || response.data == null) {
//                         product.image = null;
//                     }
//                 } else {
//                     product.image = null;
//                 }
//             });
//     } catch (error) {
//         product.image = null;
//     }
// }
</script>
