import { useAuthClientStore } from "@/store/authClient";
import { defineStore } from "pinia";
import SecureLS from "secure-ls";

export const useOrderStore = defineStore({
    id: "orders",
    state: () => ({
        errors: {
            message: null,
            errors: [],
        },
        orders: [],
        filters: {},
        nested: {},
    }),
    actions: {
        api() {
            return "/api/clients/orders/";
        },
        auth() {
            const user = useAuthClientStore();

            return {
                headers: {
                    Authorization: "Bearer " + user.token(),
                    Order: user.order(),
                },
            };
        },
        async show(path) {
            try {
                let result = await axios.get(this.api() + path, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillOrder", result.data);
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async switchVaryingDelivery(path, _bool) {
            try {
                let result = await axios.post(
                    this.api() + path + "/deliveries",
                    { has_varying_deliveries: _bool },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("toasting", "Aangepast");
                    Eventing.fire("fillDelivery", result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async fill(url) {
            if (typeof url === "undefined") {
                url = this.api();
            }
            try {
                let result = await axios.get(url, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.success(result);
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async delivery(path) {
            try {
                let result = await axios.get(
                    this.api() + path + "/deliveries",
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillDelivery", result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async store(form) {
            try {
                let result = await axios.post(this.api(), form, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.added(result);
                    if (result.data.data.hash) {
                        this.$router.push({
                            name: "app.order",
                            hash: result.data.data.hash,
                            params: { hash: result.data.data.hash },
                        });
                    }
                }
            } catch (error) {
                console.error(error);
                this.fail(error);
            }
        },
        async update(path, form) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillOrder", result.data);
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async fillNested(path, nested) {
            try {
                let result = await axios.get(
                    this.api() + path + "/" + nested,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillNested", result.data);
                    var nestedList = {
                        [nested]: result.data,
                    };
                    this.$patch({
                        nested: nestedList,
                    });
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async storeNested(path, nested, form) {
            try {
                let result = await axios.post(
                    this.api() + path + "/" + nested,
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("storedNested", result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async bookmark(path) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    { bookmark: "toggle" },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillHeader", result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async switchTrafficmanager(path, user) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    { trafficmanager: user },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("toasting", "Trafficmanager aangepast");
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async acknowledge(path, user, deadline) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    {
                        trafficmanager: user,
                        delivery_deadline: deadline,
                    },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("toasting", "Order is goedgekeurd");
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async invoice(path) {
            try {
                let result = await axios.get(
                    this.api() + path + "/invoices",
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillInvoice", result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async setDeadline(path, deadline) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    {
                        delivery_deadline: deadline,
                    },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("toasting", "Deadline is toegevoegd");
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async switchStatus(path, status) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    { state: status },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillHeader", result.data);
                    Eventing.fire("reloadOrder");
                    // Eventing.fire("toasting", "Order status aangepast");
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async switchBillingState(path, status) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    { billingstate: status },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("toasting", "Facturatiestatus aangepast");
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async destroy(path) {
            try {
                let result = await axios.delete(this.api() + path, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.deleted();
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async search(search) {
            if (search) {
                let url = this.api() + "?search=" + search;
                try {
                    let result = await axios.get(url, this.auth());
                    if ([200, 201, 204].includes(result.status)) {
                        this.success(result);
                    }
                } catch (error) {
                    this.fail(error);
                }
            }
        },
        deleted() {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Verwijderd", "warning");
            this.fill();
        },
        updated(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Aangepast: " + result.data.data.hash);
            this.fill();
        },
        added(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Toegevoegd: " + result.data.data.hash);
            this.fill();
        },
        success(result) {
            this.$patch({
                orders: result.data,
            });
            this.checkResults(result.data);
        },
        fail(error) {
            console.error(error);
            this.$patch({
                errors: error.response.data,
            });
            Eventing.fire("error", error.response);
        },
        saveFilters(filters) {
            const ls = new SecureLS();
            ls.set("order-filters", filters);
        },
        clearFilters() {
            const ls = new SecureLS();
            ls.set("order-filters", {
                invoice_status: [],
                status: [],
                source: [],
                only_following: false,
            });
        },
        activeFilters() {
            this.$patch({
                filters: this.getActiveFilters(),
            });
        },
        getActiveFilters() {
            const ls = new SecureLS();
            let filters = ls.get("order-filters");
            if (!filters) {
                this.clearFilters();
            }
            return ls.get("order-filters");
        },
        checkResults(result) {
            try {
                if (result.data && Object.keys(result.data).length <= 0) {
                    Eventing.fire(
                        "toasting",
                        "Geen resultaten gevonden",
                        "info"
                    );
                }
            } catch (error) {}
        },
    },
});
