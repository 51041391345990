<template>
    <div class="c-side-overlay" v-if="state.stock">
        <div class="c-side-overlay__header">
            <div class="c-side-overlay__header-content">
                <div class="c-side-overlay__header-title-wrap">
                    <h2 class="c-side-overlay__title">
                        {{ state.stock.name }}
                    </h2>
                    <p
                        class="c-side-overlay__below-title"
                        v-if="state.stock.article_nr"
                    >
                        {{ state.stock.article_nr }}
                    </p>
                </div>
            </div>
        </div>

        <div class="c-side-overlay__content">
            <div class="c-side-overlay__content-section" v-if="state.graph">
                <div
                    class="c-side-overlay__bar-single e-chart e-chart--bars-rounded"
                >
                    <chart-bar-single
                        :series="state.graph.series"
                        :colors="state.graph.colors"
                        :yaxisMax="state.graph.yaxisMax"
                        :tooltipYFormatter="state.graph.tooltipYFormatter"
                    />
                </div>
            </div>

            <div class="c-side-overlay__content-section">
                <div class="e-form">
                    <div class="e-form__field-wrap">
                        <label class="e-form__label">Voorraad</label>
                        <input
                            class="e-form__input"
                            v-model="state.stock.stock"
                            type="number"
                        />
                    </div>
                    <div class="e-form__field-wrap">
                        <label class="e-form__label">In aanlevering</label>
                        <input
                            class="e-form__input"
                            v-model="state.stock.future_stock"
                            type="number"
                        />
                    </div>
                    <div
                        v-show="state.stock.future_stock"
                        class="e-form__field-wrap"
                    >
                        <label class="e-form__label">Verwacht op</label>
                        <input
                            type="date"
                            class="e-form__input"
                            v-model="state.stock.future_stock_date"
                        />
                    </div>
                    <div
                        v-show="state.stock.future_stock"
                        class="e-form__field-wrap e-tooltip"
                        data-tooltip="Het aantal wordt bij de voorraad opgeteld en de datum wordt verwijderd."
                        @click="updateStockWithFuture(state.stock)"
                    >
                        <button
                            class="e-button e-button--full-width e-button--small"
                        >
                            Aanlevering is ontvangen
                        </button>
                    </div>
                </div>
            </div>

            <!-- @Jasper - Voorraad totalen
                - subtotaal, gereserveerd en totaal
                - subtotaal is dan voorraad + aanlevering, en totaal is dan subtotaal - gereserveerd
                - die bar moet dan denk ik per product het subtotaal als 100% gebruiken en dan daarin de gereserveerd en het totaal -->

            <div class="c-side-overlay__content-section">
                <div class="e-form">
                    <div class="e-form__field-wrap">
                        <label class="e-form__label">Subtotaal</label>
                        <input
                            class="e-form__input"
                            type="number"
                            :value="
                                parseInt(state.stock.stock) +
                                parseInt(state.stock.future_stock)
                            "
                            disabled
                        />
                    </div>
                    <div class="e-form__field-wrap">
                        <label class="e-form__label">Gereserveerd</label>
                        <input
                            type="text"
                            class="e-form__input"
                            v-model="state.stock.future_stock_reduction"
                            disabled
                        />
                    </div>
                    <div class="e-form__field-wrap">
                        <label class="e-form__label">Totaal</label>
                        <input
                            class="e-form__input"
                            type="number"
                            :value="
                                parseInt(state.stock.stock) +
                                parseInt(state.stock.future_stock) -
                                parseInt(state.stock.future_stock_reduction)
                            "
                            disabled
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="c-side-overlay__footer">
            <div class="c-side-overlay__footer-content">
                <a
                    class="e-button e-button--full-width e-button--big"
                    @click="close()"
                >
                    Sluiten
                </a>
                <button
                    class="e-button e-button--green e-button--full-width e-button--big"
                    @click="updateStockOfProduct(state.stock)"
                >
                    Opslaan
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useStockStore } from "@/store/stock";
import { onMounted, reactive } from "vue";

const state = reactive({
    stock: null,
    graph: null,
    isChanged: false,
});

onMounted(() => {
    Eventing.listen("clearSideOverlay", () => {
        state.stock = null;
    });

    Eventing.listen("fillSideOverlay", (data) => {
        state.stock = data.data;
        console.info(data.data);
        // state.stock.total =
        //     parseInt(state.stock.stock) + parseInt(state.stock.future_stock);
        // state.stock.subtotal =
        //     parseInt(state.stock.stock) +
        //     parseInt(state.stock.future_stock) -
        //     parseInt(state.stock.future_stock_reduction);

        state.graph = {
            series: [
                {
                    name: "Totaal",
                    data: [parseInt(state.stock.stock) + parseInt(state.stock.future_stock) - parseInt(state.stock.future_stock_reduction)],
                },
            ],

            colors: [
                function ({ value, seriesIndex, w }) {
                    if (value < state.stock.stock_alert_at / 2 || value < 0) {
                        return "#ec3737"; //Alert stock color
                    } else if (value < state.stock.stock_alert_at) {
                        return "#ff911d"; //Low stock color
                    } else {
                        return "#54cb8f"; //Enough stock color
                    }
                },
                "#e2e2e2",
            ], //'In aanlevering' color

            yaxisMax: parseInt(state.stock.stock) + parseInt(state.stock.future_stock),

            //Tooltip info
            tooltipYFormatter: {
                function(val) {
                    return (
                        val +
                        " <small>" +
                        state.stock.future_stock_date_nl +
                        "</small>"
                    );
                },
            },
        };
    });
});

function close() {
    Eventing.fire("clearSideOverlay");
    state.stock = null;
}

const stock = useStockStore();

async function updateStockOfProduct(product) {
    let result = await stock.update(product.slug, state.stock);
    if (result) {
        state.stock = result;
        Eventing.fire("fillSideOverlay", result);
    }
}

async function updateStockWithFuture(product) {
    let result = await stock.update(product.slug, { setFutureStock: true });
    if (result) {
        state.stock = result;
        Eventing.fire("fillSideOverlay", result);
    }
}
</script>
