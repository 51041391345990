<template>
    <article>
        <div
            class="e-modal e-modal--w600 e-modal--align-start"
            modal-id="add-new-product"
        >
            <div class="e-modal__wrap">
                <div class="e-modal__modal">
                    <div class="e-modal__close js-close-modal">
                        <SvgCross @click="Modals.close()" />
                    </div>

                    <div class="e-modal__section">
                        <div class="e-modal__section-title">
                            <h2 class="e-modal__title">
                                Product <span>aanpassen</span>
                            </h2>
                        </div>
                    </div>
                    <form
                        method="POST"
                        @submit.prevent="onSubmit"
                        @keydown="product.errors.errors = {}"
                        novalidate
                    >
                        <div class="e-modal__section">
                            <div class="e-form">
                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            product.errors.message &&
                                            product.errors.errors.name,
                                    }"
                                    :invalid-message="
                                        product.errors.errors.name
                                    "
                                >
                                    <label class="e-form__label"
                                        >Productnaam</label
                                    >
                                    <input
                                        class="e-form__input"
                                        type="text"
                                        v-model="state.form.name"
                                    />
                                </div>
                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            product.errors.message &&
                                            product.errors.errors.article_nr,
                                    }"
                                    :invalid-message="
                                        product.errors.errors.article_nr
                                    "
                                >
                                    <label class="e-form__label"
                                        >Artikelnummer</label
                                    >
                                    <input
                                        class="e-form__input"
                                        type="text"
                                        v-model="state.form.article_nr"
                                    />
                                </div>

                                <div class="e-form__field-wrap">
                                    <label class="e-form__label"
                                        >Categorie</label
                                    >
                                    <div class="e-form__select-wrap">
                                        <select
                                            class="e-form__select"
                                            v-model="
                                                state.form.product_category_id
                                            "
                                        >
                                            <option value="">Geen</option>
                                            <option
                                                v-for="(
                                                    category, index
                                                ) in product.categories"
                                                v-bind:value="category.id"
                                                :key="index"
                                            >
                                                {{ category.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            product.errors.message &&
                                            product.errors.errors.price,
                                    }"
                                    :invalid-message="
                                        product.errors.errors.price
                                    "
                                >
                                    <label class="e-form__label">Prijs</label>
                                    <div
                                        class="e-form__pre-symbol"
                                        pre-symbol="€"
                                    >
                                        <input
                                            class="e-form__input"
                                            type="number"
                                            min="0.00"
                                            step="0.01"
                                            v-model="state.form.price"
                                        />
                                    </div>
                                </div>

                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            product.errors.message &&
                                            product.errors.errors.vat,
                                    }"
                                    :invalid-message="product.errors.errors.vat"
                                >
                                    <label class="e-form__label">BTW</label>
                                    <div
                                        class="e-form__pre-symbol"
                                        pre-symbol="%"
                                    >
                                        <input
                                            class="e-form__input"
                                            type="number"
                                            min="0"
                                            step="1"
                                            v-model="state.form.vat"
                                        />
                                    </div>
                                </div>

                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            product.errors.message &&
                                            product.errors.errors
                                                .purchasing_costs,
                                    }"
                                    :invalid-message="
                                        product.errors.errors.purchasing_costs
                                    "
                                >
                                    <label class="e-form__label"
                                        >Inkoopkosten</label
                                    >
                                    <div
                                        class="e-form__pre-symbol"
                                        pre-symbol="€"
                                    >
                                        <input
                                            class="e-form__input"
                                            type="number"
                                            min="0.00"
                                            step="0.01"
                                            v-model="
                                                state.form.purchasing_costs
                                            "
                                        />
                                    </div>
                                </div>

                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            product.errors.message &&
                                            product.errors.errors
                                                .insertion_costs,
                                    }"
                                    :invalid-message="
                                        product.errors.errors.insertion_costs
                                    "
                                >
                                    <label class="e-form__label"
                                        >Insteekkosten</label
                                    >
                                    <div
                                        class="e-form__pre-symbol"
                                        pre-symbol="€"
                                    >
                                        <input
                                            class="e-form__input"
                                            type="number"
                                            min="0.00"
                                            step="0.01"
                                            v-model="state.form.insertion_costs"
                                        />
                                    </div>
                                </div>

                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            product.errors.message &&
                                            product.errors.errors
                                                .production_costs,
                                    }"
                                    :invalid-message="
                                        product.errors.errors.production_costs
                                    "
                                >
                                    <label class="e-form__label"
                                        >Productiekosten</label
                                    >

                                    <div
                                        class="e-form__pre-symbol"
                                        pre-symbol="€"
                                    >
                                        <input
                                            class="e-form__input"
                                            type="number"
                                            min="0.00"
                                            step="0.01"
                                            v-model="
                                                state.form.production_costs
                                            "
                                        />
                                    </div>
                                </div>

                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            product.errors.message &&
                                            product.errors.errors.weight,
                                    }"
                                    :invalid-message="
                                        product.errors.errors.weight
                                    "
                                >
                                    <label class="e-form__label"
                                        >Gewicht (gr.)</label
                                    >
                                    <input
                                        class="e-form__input"
                                        type="number"
                                        min="0"
                                        step="1"
                                        v-model="state.form.weight"
                                    />
                                </div>

                                <div
                                    class="e-form__field-wrap e-tooltip"
                                    data-tooltip="Na deze datum gaat het product op inactief (optioneel)"
                                >
                                    <label class="e-form__label"
                                        >Actief tot</label
                                    >
                                    <input
                                        class="e-form__input"
                                        v-model="state.form.expiration_date"
                                        type="date"
                                    />
                                </div>

                                <div class="e-form__field-wrap mt-20">
                                    <div
                                        class="e-form__multiple-checkboxes e-form__multiple-checkboxes--list"
                                    >
                                        <label class="e-form__checkbox-wrap">
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.form.is_sold_out"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Product is uitverkocht</span
                                            >
                                        </label>

                                        <label class="e-form__checkbox-wrap">
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.form.is_digital"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Product is digitaal</span
                                            >
                                        </label>

                                        <label
                                            class="e-form__checkbox-wrap"
                                            v-show="!state.form.is_digital"
                                        >
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.form.has_stock"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Product heeft voorraad</span
                                            >
                                        </label>

                                        <label
                                            class="e-form__checkbox-wrap"
                                            v-show="!state.form.is_digital"
                                        >
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="
                                                    state.form.has_shippingcosts
                                                "
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Product heeft
                                                verzendkosten</span
                                            >
                                        </label>

                                        <label class="e-form__checkbox-wrap">
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.form.is_maatwerk"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Maatwerk product</span
                                            >
                                        </label>

                                        <label class="e-form__checkbox-wrap">
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="
                                                    state.form.for_shopmanager
                                                "
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Shopmanager product</span
                                            >
                                        </label>

                                        <label class="e-form__checkbox-wrap">
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.form.is_mailing"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Mailing product</span
                                            >
                                        </label>

                                        <label class="e-form__checkbox-wrap">
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="
                                                    state.form.no_clientaccess
                                                "
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Product is niet te selecteren
                                                in de klantlogin</span
                                            >
                                        </label>

                                        <label class="e-form__checkbox-wrap">
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="
                                                    state.form.limited_selection
                                                "
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Product is beperkt te
                                                selecteren in de
                                                klantlogin</span
                                            >
                                        </label>
                                        <label class="e-form__checkbox-wrap">
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="
                                                    state.form.is_invisible
                                                "
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Onzichtbaar in de
                                                klantlogin</span
                                            >
                                        </label>
                                        <label class="e-form__checkbox-wrap">
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.form.needs_files"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Product heeft bestanden nodig
                                                voor productie</span
                                            >
                                        </label>

                                        <label class="e-form__checkbox-wrap">
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.form.is_inactive"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Product is niet meer in
                                                gebruik</span
                                            >
                                        </label>

                                        <label class="e-form__checkbox-wrap">
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="
                                                    state.form
                                                        .is_hidden_packingslip
                                                "
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Product verbergen op de
                                                pakbon</span
                                            >
                                        </label>

                                        <label class="e-form__checkbox-wrap">
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.form.needs_bank"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Product vereist BANK</span
                                            >
                                        </label>
                                        <label class="e-form__checkbox-wrap">
                                            <input
                                                class="e-form__checkbox"
                                                type="checkbox"
                                                v-model="state.form.just_one"
                                            />
                                            <span
                                                class="e-form__checkbox-label"
                                            ></span>
                                            <span class="e-form__checkbox-text"
                                                >Aantallen niet aanpasbaar</span
                                            >
                                        </label>
                                    </div>
                                </div>

                                <div
                                    v-show="state.form.has_stock"
                                    class="e-form__field-wrap mt-20"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            product.errors.message &&
                                            product.errors.errors
                                                .stock_alert_at,
                                    }"
                                    :invalid-message="
                                        product.errors.errors.stock_alert_at
                                    "
                                >
                                    <label class="e-form__label"
                                        >Voorraad alert bij aantal</label
                                    >
                                    <input
                                        class="e-form__input"
                                        type="number"
                                        placeholder="Optioneel"
                                        min="0"
                                        step="1"
                                        v-model="state.form.stock_alert_at"
                                    />
                                </div>
                            </div>
                        </div>

                        <section>
                            <div
                                class="e-modal__section e-modal__section--bordered"
                            >
                                <div class="e-modal__section-title">
                                    <h2 class="e-modal__title">
                                        Product
                                        <span>codes</span>
                                    </h2>
                                    <p
                                        class="e-modal__desc e-modal__desc--small-spacing"
                                    >
                                        Meerdere codes scheiden middels komma<br />
                                        Per artikel geldt een enkele waarde voor
                                        Kostenplaats
                                    </p>
                                </div>
                            </div>

                            <div class="e-modal__section">
                                <div class="e-form">
                                    <!-- <div
                                        class="e-form__field-wrap"
                                        :class="{
                                            'e-form__field-wrap--invalid':
                                                product.errors.message &&
                                                product.errors.errors
                                                    .perfectview_code,
                                        }"
                                        :invalid-message="
                                            product.errors.errors
                                                .perfectview_code
                                        "
                                    >
                                        <label class="e-form__label"
                                            >PerfectView</label
                                        >
                                        <input
                                            class="e-form__input"
                                            type="text"
                                            v-model="
                                                state.form.perfectview_code
                                            "
                                        />
                                    </div> -->

                                    <div
                                        class="e-form__field-wrap"
                                        :class="{
                                            'e-form__field-wrap--invalid':
                                                product.errors.message &&
                                                product.errors.errors
                                                    .exact_codes,
                                        }"
                                        :invalid-message="
                                            product.errors.errors.exact_codes
                                        "
                                    >
                                        <label class="e-form__label"
                                            >Exact</label
                                        >
                                        <input
                                            class="e-form__input"
                                            type="text"
                                            v-model="state.form.exact_codes"
                                        />
                                    </div>
                                    <div
                                        class="e-form__field-wrap"
                                        :class="{
                                            'e-form__field-wrap--invalid':
                                                product.errors.message &&
                                                product.errors.errors
                                                    .costcenter,
                                        }"
                                        :invalid-message="
                                            product.errors.errors.costcenter
                                        "
                                    >
                                        <label class="e-form__label"
                                            >Kostenplaats</label
                                        >
                                        <input
                                            class="e-form__input"
                                            type="text"
                                            no-placeholder="Optioneel"
                                            v-model="state.form.costcenter"
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section v-show="state.form.has_shippingcosts">
                            <div
                                class="e-modal__section e-modal__section--bordered"
                            >
                                <div class="e-modal__section-title">
                                    <h2 class="e-modal__title">
                                        Verzendkosten
                                    </h2>
                                </div>
                            </div>

                            <div
                                class="e-modal__section"
                                v-if="state.form.shippingcosts"
                            >
                                <div class="e-form">
                                    <div
                                        class="e-form__field-wrap"
                                        :class="{
                                            'e-form__field-wrap--invalid':
                                                product.errors.message &&
                                                product.errors.errors[
                                                    'shippingcosts.HuisAanHuis_Klein'
                                                ],
                                        }"
                                        :invalid-message="
                                            product.errors.errors[
                                                'shippingcosts.HuisAanHuis_Klein'
                                            ]
                                        "
                                    >
                                        <label class="e-form__label"
                                            >Huis-aan-huis klein</label
                                        >
                                        <div
                                            class="e-form__pre-symbol"
                                            pre-symbol="€"
                                        >
                                            <input
                                                class="e-form__input"
                                                type="number"
                                                min="0.00"
                                                step="0.01"
                                                v-model="
                                                    state.form.shippingcosts
                                                        .HuisAanHuis_Klein
                                                "
                                                placeholder="Prijs incl. btw"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="e-form__field-wrap"
                                        :class="{
                                            'e-form__field-wrap--invalid':
                                                product.errors.message &&
                                                product.errors.errors[
                                                    'shippingcosts.OpLocatie_Klein'
                                                ],
                                        }"
                                        :invalid-message="
                                            product.errors.errors[
                                                'shippingcosts.OpLocatie_Klein'
                                            ]
                                        "
                                    >
                                        <label class="e-form__label"
                                            >Op locatie klein</label
                                        >
                                        <div
                                            class="e-form__pre-symbol"
                                            pre-symbol="€"
                                        >
                                            <input
                                                class="e-form__input"
                                                type="number"
                                                min="0.00"
                                                step="0.01"
                                                v-model="
                                                    state.form.shippingcosts
                                                        .OpLocatie_Klein
                                                "
                                                placeholder="Prijs incl. btw"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="e-form__field-wrap"
                                        :class="{
                                            'e-form__field-wrap--invalid':
                                                product.errors.message &&
                                                product.errors.errors[
                                                    'shippingcosts.OpLocatie_Groot'
                                                ],
                                        }"
                                        :invalid-message="
                                            product.errors.errors[
                                                'shippingcosts.OpLocatie_Groot'
                                            ]
                                        "
                                    >
                                        <label class="e-form__label"
                                            >Op locatie groot</label
                                        >
                                        <div
                                            class="e-form__pre-symbol"
                                            pre-symbol="€"
                                        >
                                            <input
                                                class="e-form__input"
                                                type="number"
                                                min="0.00"
                                                step="0.01"
                                                v-model="
                                                    state.form.shippingcosts
                                                        .OpLocatie_Groot
                                                "
                                                placeholder="Prijs incl. btw"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="e-form__field-wrap"
                                        :class="{
                                            'e-form__field-wrap--invalid':
                                                product.errors.message &&
                                                product.errors.errors[
                                                    'shippingcosts.StaffelVoorGroot'
                                                ],
                                        }"
                                        :invalid-message="
                                            product.errors.errors[
                                                'shippingcosts.StaffelVoorGroot'
                                            ]
                                        "
                                    >
                                        <label class="e-form__label"
                                            >Staffel voor groot</label
                                        >

                                        <input
                                            class="e-form__input"
                                            type="number"
                                            min="0"
                                            step="1"
                                            v-model="
                                                state.form.shippingcosts
                                                    .StaffelVoorGroot
                                            "
                                            placeholder="Vanaf welk aantal geldt de grote staffel?"
                                        />
                                    </div>
                                    <div
                                        class="e-form__field-wrap"
                                        :class="{
                                            'e-form__field-wrap--invalid':
                                                product.errors.message &&
                                                product.errors.errors[
                                                    'shippingcosts.StaffelGratis'
                                                ],
                                        }"
                                        :invalid-message="
                                            product.errors.errors[
                                                'shippingcosts.StaffelGratis'
                                            ]
                                        "
                                    >
                                        <label class="e-form__label"
                                            >Staffel voor gratis</label
                                        >
                                        <div
                                            class="e-form__pre-symbol"
                                            pre-symbol="€"
                                        >
                                            <input
                                                class="e-form__input"
                                                type="number"
                                                min="0"
                                                step="1"
                                                v-model="
                                                    state.form.shippingcosts
                                                        .StaffelGratis
                                                "
                                                placeholder="Vanaf welk bedrag geldt gratis verzenden?"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section
                            old-show="
                                !state.form.no_clientaccess &&
                                !state.form.product_category_id
                            "
                            v-show="!state.form.no_clientaccess"
                        >
                            <div
                                class="e-modal__section e-modal__section--bordered"
                            >
                                <div class="e-modal__section-title">
                                    <h2 class="e-modal__title">
                                        Product completeren
                                        <span>klantlogin</span>
                                    </h2>
                                    <p
                                        class="e-modal__desc e-modal__desc--small-spacing"
                                    >
                                        Selecteer de categorieën waaruit de
                                        klant producten dient te selecteren om
                                        tot een complete bestelling te komen
                                        voor dit product.
                                    </p>
                                </div>
                            </div>

                            <div class="e-modal__section">
                                <div class="e-form">
                                    <div
                                        class="e-form__field-wrap e-form__field-wrap--checkboxes"
                                    >
                                        <div
                                            class="e-form__multiple-checkboxes"
                                        >
                                            <label
                                                v-for="(
                                                    category, index
                                                ) in product.categories"
                                                :key="index"
                                                v-show="category.is_fillable"
                                                class="e-form__checkbox-wrap"
                                            >
                                                <input
                                                    class="e-form__checkbox"
                                                    type="checkbox"
                                                    name="pickables"
                                                    v-model="
                                                        state.form.pickables
                                                    "
                                                    :value="category.id"
                                                />
                                                <span
                                                    class="e-form__checkbox-label"
                                                ></span>
                                                <span
                                                    class="e-form__checkbox-text"
                                                    >{{ category.name }}</span
                                                >
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section v-show="state.form.limited_selection">
                            <div
                                class="e-modal__section e-modal__section--bordered"
                            >
                                <div class="e-modal__section-title">
                                    <h2 class="e-modal__title">
                                        Product
                                        <span>beperkt zichtbaar&nbsp;</span> bij
                                        klantlogin
                                    </h2>
                                    <p
                                        class="e-modal__desc e-modal__desc--small-spacing"
                                    >
                                        Vink de bronnen aan waar dit product wel
                                        te selecteren is
                                    </p>
                                </div>
                            </div>

                            <div class="e-modal__section">
                                <div class="e-form">
                                    <div
                                        class="e-form__field-wrap e-form__field-wrap--checkboxes"
                                    >
                                        <div
                                            class="x-e-form__multiple-checkboxes"
                                        >
                                            <label
                                                v-for="(option, value) in enums
                                                    .options['order-source']"
                                                :key="value"
                                                v-show="
                                                    option != 'Klant-login' &&
                                                    option != 'Productiesysteem'
                                                "
                                                class="e-form__checkbox-wrap"
                                            >
                                                <input
                                                    class="e-form__checkbox"
                                                    type="checkbox"
                                                    name="limited_selection"
                                                    v-model="
                                                        state.form
                                                            .limited_to_sources
                                                    "
                                                    v-bind:value="option"
                                                />
                                                <span
                                                    class="e-form__checkbox-label"
                                                ></span>
                                                <span
                                                    class="e-form__checkbox-text"
                                                    >{{ option }}</span
                                                >
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div
                            class="e-modal__section e-modal__section--bordered"
                            v-show="state.form.needs_files"
                        >
                            <div class="e-modal__section-title">
                                <h2 class="e-modal__title">
                                    Benodigde <span>bestanden</span>
                                </h2>
                                <p
                                    class="e-modal__desc e-modal__desc--small-spacing"
                                >
                                    Geef aan welke bestanden er nodig zijn.
                                </p>
                            </div>
                        </div>

                        <div
                            class="e-modal__section"
                            v-show="state.form.needs_files"
                        >
                            <div class="e-form">
                                <ul class="e-form__files-list">
                                    <li
                                        class="e-form__files-item"
                                        v-for="(name, index) in state.form
                                            .attachments"
                                        :key="index"
                                    >
                                        {{ name }}
                                        <div class="e-form__files-item-delete">
                                            <SvgDelete
                                                @click="removeAttachment(index)"
                                            />
                                        </div>
                                    </li>
                                </ul>

                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            product.errors.message &&
                                            product.errors.errors.attachments,
                                    }"
                                    :invalid-message="
                                        product.errors.errors.attachments
                                    "
                                >
                                    <input
                                        type="text"
                                        class="e-form__input"
                                        v-model="state.new_attachment"
                                        placeholder="Voer naamgeving voor benodigde bestand in"
                                    />
                                    <div
                                        class="e-button e-button--big e-button--grey"
                                        @click="addAttachment()"
                                    >
                                        Toevoegen
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="e-modal__section e-modal__section--center-text"
                        >
                            <button
                                class="e-button e-button--full-width e-button--green js-close-modal"
                            >
                                Aanpassen
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div
                class="e-modal__bg js-close-modal"
                @click="Modals.close()"
            ></div>
        </div>
    </article>
</template>

<script setup>
import { useEnumStore } from "@/store/enum";
import SvgCross from "@/svg/cross.svg";
import SvgDelete from "@/svg/delete.svg";

import { useProductStore } from "@/store/product";
import { inject, onMounted, reactive } from "vue";

const enums = useEnumStore();

enums.fill("order-source");

const Modals = inject("Modals");

const props = defineProps({
    path: {
        type: String,
    },
});

const product = useProductStore();

product.fillCategories();

const state = reactive({
    form: {
        product_category_id: "",
        vat: 21,
        pickables: [],
        shippingcosts: {},
        attachments: [],
    },
    new_attachment: null,
});

onMounted(() => {
    state.form = product.show(props.path);

    Eventing.listen("fillForm", (data) => {
        state.form = data.data;
    });

    product.errors.errors = { message: null, errors: [] };
});

function onSubmit() {
    console.info(state.form, "onUpdate");
    product.update(props.path, state.form);
}

function addAttachment() {
    if (state.new_attachment) {
        state.form.attachments.push(state.new_attachment);
        state.new_attachment = null;
    }
}
function removeAttachment(index) {
    state.form.attachments.splice(index, 1);
}
</script>
