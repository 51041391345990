import { useAuthStore } from "@/store/auth";
import { defineStore } from "pinia";

export const useStockStore = defineStore({
    id: "stocks",
    state: () => ({
        errors: {
            message: null,
            errors: [],
        },
        products: [],
    }),
    actions: {
        api() {
            return "/api/stocks/";
        },
        auth() {
            const user = useAuthStore();
            return { headers: { Authorization: "Bearer " + user.token() } };
        },

        async fill(url) {
            if (typeof url === "undefined") {
                url = this.api();
            }
            Eventing.fire("loader", "no");
            try {
                let result = await axios.get(url, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.success(result);
                    return result.status;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async show(path) {
            try {
                let result = await axios.get(this.api() + path, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async search(search) {
            if (search) {
                let url = this.api() + "?search=" + search;
                try {
                    let result = await axios.get(url, this.auth());
                    if ([200, 201, 204].includes(result.status)) {
                        this.success(result);
                    }
                } catch (error) {
                    this.fail(error);
                }
            }
        },
        async update(path, form) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.updated(result);
                    Eventing.fire("reloadStock");
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },

        updated(result) {
            Eventing.fire("closeModal");
            Eventing.fire(
                "toasting",
                "Aangepaste voorraad: " + result.data.data.name
                // " naar " +
                // result.data.data.stock
            );

            this.fill();
        },
        success(result) {
            this.$patch({
                products: result.data,
            });
            this.checkResults(result.data);
        },
        fail(error) {
            console.error(error);
            this.$patch({
                errors: error.response.data,
            });
            Eventing.fire("error", error.response);
        },
        checkResults(result) {
            try {
                if (result.data && Object.keys(result.data).length <= 0) {
                    Eventing.fire(
                        "toasting",
                        "Geen resultaten gevonden",
                        "info"
                    );
                }
            } catch (error) {}
        },
    },
});
