<template>
    <app-dashboards-traffic-navigation />

    <div class="c-page-content">
        <div
            class="c-page-content__container c-page-content__container--spacing-small e-load-items-animation"
        >
            <div class="c-widgets">
                <div
                    class="e-module-style e-module-style--green e-module-style--always-active mb-20"
                >
                    <div class="e-widget-heading">
                        <div class="e-widget-heading__left">
                            <h2 class="e-widget-heading__name">
                                Runs

                                <button
                                    class="e-button e-button--green-darker e-button--small"
                                    @click="Modals.show('manage-runs')"
                                >
                                    Beheren
                                </button>
                            </h2>
                        </div>
                    </div>

                    <div class="e-chart e-chart--timeline" v-if="state.series">
                        <chart-range-bar
                            :series="state.series"
                            module_theme="green"
                        />
                    </div>
                </div>
            </div>

            <div class="c-page-filter e-form--transparent">
                <div class="c-page-filter__inner-wrap">
                    <div class="c-page-filter__button js-toggle-page-filter">
                        <SvgFilter />
                    </div>
                    <div class="c-page-filter__options">
                        <div class="e-form__field-wrap">
                            <div class="e-form__label">
                                <p>Levering in</p>
                            </div>
                            <div class="e-form__select-wrap">
                                <select
                                    class="e-form__select e-form__select--small"
                                    placeholder="Run"
                                    v-model="state.type"
                                >
                                    <option>Drukkerij</option>
                                    <option>Magazijn</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!state.isLoaded" class="e-preloader"></div>

            <div
                class="c-status-columns"
                v-for="(item, index) in state.runs"
                :key="index"
                v-show="item.type == state.type"
            >
                <div class="c-status-columns__columns">
                    <div
                        class="c-status-columns__column"
                        v-for="run in item.items"
                        :key="run.id"
                    >
                        <div
                            class="c-status-columns__column-heading c-status-columns__column-heading--grey c-status-columns__column-heading--no-arrow"
                        >
                            <h2 class="c-status-columns__column-title">
                                {{ run.name }}
                                <span class="e-suffix-text">{{
                                    run.pretty_date
                                }}</span>
                                <span class="e-amount-label">{{
                                    item.type == "Drukkerij"
                                        ? Object.values(run.drukkerij).length
                                        : Object.values(run.magazijn).length
                                }}</span>
                            </h2>
                        </div>

                        <div class="c-status-columns__items">
                            <div class="list-group">
                                <draggable
                                    class="list-group"
                                    :list="
                                        item.type == 'Drukkerij'
                                            ? run.drukkerij
                                            : run.magazijn
                                    "
                                    group="products"
                                    :sort="false"
                                    itemKey="name"
                                    :disabled="true"
                                    @start="drag = false"
                                    @end="drag = false"
                                    preventOnFilter="true"
                                >
                                    <template #item="{ element, index }">
                                        <div class="list-group-item">
                                            <maatwerk-tile
                                                @click="toggleOrder(element)"
                                                :index="index"
                                                :test="element.id.toString()"
                                                :item="element"
                                                :order="element"
                                                :status="run.status"
                                                :runs="state.runs"
                                            ></maatwerk-tile>
                                        </div>
                                    </template>
                                </draggable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-dashboards-side-overlay-traffic-maatwerk-runs />
</template>

<script setup>
import { useDashboardRunsStore } from "@/store/dashboard/runs";

import { useDashboardDesignStore } from "@/store/dashboard/design";

import SvgFilter from "@/svg/filter.svg";
import { inject, nextTick, onMounted, onUpdated, reactive } from "vue";
import draggable from "vuedraggable";
const Modals = inject("Modals");
const runs = useDashboardRunsStore();
const design = useDashboardDesignStore();

const state = reactive({
    form: {},
    runs: null,
    type: "Drukkerij",
    order: null,
    series: null,
    isLoaded: false,
});

async function loadRuns() {
    state.series = null;
    let fill = await runs.fillForTraffic();
    if (fill) {
        console.info("loadRuns", fill);
        state.runs = fill;
        state.series = fill[0].series;

        await nextTick();

        state.isLoaded = true;
    }
}
loadRuns();

async function toggleOrder(basket) {
    if (state.order && state.order.hash == basket.hash) {
        state.order = null;

        if (document.body.classList.contains("body--side-overlay-is-open")) {
            document.body.classList.remove("body--side-overlay-is-open");
        }
    } else {
        Eventing.fire("clearSideOverlay");

        let result = await design.show(basket.hash);
        if (result) {
            state.order = result;
            document.body.classList.add("body--side-overlay-is-open");
            Eventing.fire("fillSideOverlay", result);

            Eventing.fire("fillRuns", state.runs);
        }

        let resultBasket = await design.basket(basket.id);
        if (resultBasket) {
            console.info("resultBasket", resultBasket);
            Eventing.fire("fillSideOverlayBasket", resultBasket);
        }
    }
}

onUpdated(() => {
    Eventing.fire("stickyElement");
});

onMounted(() => {
    Eventing.fire("toggleSideOverlay");
    Eventing.fire("activateScrollOnBottomPage");

    Eventing.listen("reloadItemsAndKeepSidebarOpen", () => {
        loadRuns();
    });

    Eventing.listen("reloadItems", () => {
        if (document.body.classList.contains("body--side-overlay-is-open")) {
            document.body.classList.remove("body--side-overlay-is-open");
        }
        loadRuns();
    });
});
</script>
