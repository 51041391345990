import { defineStore } from "pinia";
import SecureLS from "secure-ls";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

const ls = new SecureLS({
    encodingType: "rc4",
    isCompression: false,
});

const store = new Vuex.Store({
    state: {
        user: {},
    },
    plugins: [
        createPersistedState({
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
        }),
    ],
    mutations: {
        user: (state, value) =>
            value ? (state.user = value) : (state.user = null),
    },
});

export const useAuthStore = defineStore({
    id: "auth",
    state: () => ({
        //
    }),
    actions: {
        secureLS() {
            return new SecureLS({
                encodingType: "rc4",
                isCompression: false,
            });
        },
        auth() {
            return store.state.user;
        },
        name() {
            try {
                return this.auth().user.name;
            } catch (error) {
                return this.logout();
            }
        },
        avatar() {
            try {
                return this.auth().user.profile_photo;
            } catch (error) {
                return null;
            }
        },
        hash() {
            try {
                return this.auth().user.hash;
            } catch (error) {
                return null;
            }
        },
        token() {
            try {
                return this.auth().token;
            } catch (error) {
                return this.logout();
            }
        },
        role() {
            try {
                return this.auth().user.role;
            } catch (error) {
                return this.logout();
            }
        },
        authenticate(user) {
            store.commit("user", user);
            axios
                .put("/api/check-auth", null, {
                    headers: { Authorization: "Bearer " + user.token },
                })
                .then(() => {
                    switch (user.user.role) {
                        case "Productie":
                            this.$router.push("/productie/scan");
                            break;

                        case "Trafficmanager":
                            this.$router.push("/app/dashboards/traffic");
                            break;

                        case "Vormgever":
                            this.$router.push("/app/dashboards/design");
                            break;

                        case "Administratie":
                            this.$router.push("/app/dashboards/administratie");
                            break;

                        case "Shopmanager":
                            this.$router.push("/app/dashboards/shopmanager");
                            break;

                        default:
                            if (user.user.email == "rens@feelingz.nl") {
                                this.$router.push("/app/dashboards/production");
                            }
                            this.$router.push("/app/orders");
                            break;
                    }
                    setTimeout(() => {
                        Eventing.fire("toasting", "Welkom " + user.user.name);
                    }, 500);
                });
        },
        notifications() {
            axios
                .get("/api/notifications", {
                    headers: { Authorization: "Bearer " + this.auth().token },
                })
                .then((result) => {
                    Eventing.fire("setNotifications", result.data);
                    Eventing.fire("setStockAlert", result.data.stock_alert);
                    return result.data;
                });
        },
        notificationRead(which) {
            axios
                .get("/api/notifications?markAsRead=" + which, {
                    headers: { Authorization: "Bearer " + this.auth().token },
                })
                .then((result) => {
                    Eventing.fire("fetchNotifications");
                });
        },
        logout() {
            axios
                .put("/api/logoff", null, {
                    headers: { Authorization: "Bearer " + this.auth().token },
                })
                .then(() => {
                    this.secureLS().removeAll();
                    store.commit("user", null);
                    setTimeout(() => {
                        Eventing.fire(
                            "toasting",
                            "Succesvol uitgelogd",
                            "info"
                        );
                    }, 1000);
                    this.$router.push("/");
                });
        },
    },
});
