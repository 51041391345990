<template>
    <nav
        class="c-page-menu"
        :class="
            state.order &&
            state.order.source &&
            state.order.source.toLowerCase()
        "
    >
        <ul class="c-page-menu__list">
            <li class="c-page-menu__item">
                <router-link
                    active-class="c-page-menu__item-link--current"
                    class="c-page-menu__item-link"
                    :to="{ name: 'app.order.order' }"
                >
                    Bestelling

                    <span
                        class="c-page-menu__item-alert"
                        v-if="state.order.incomplete"
                    >
                        <SvgInfo />
                    </span>
                </router-link>
            </li>
            <li
                class="c-page-menu__item"
                v-if="
                    state.order.category || state.order.category == 'Maatwerk'
                "
            >
                <router-link
                    active-class="c-page-menu__item-link--current"
                    class="c-page-menu__item-link"
                    :to="{ name: 'app.order.maatwerk' }"
                >
                    Maatwerk

                    <span
                        class="c-page-menu__item-alert"
                        v-if="
                            state.order.incomplete &&
                            state.order.category == 'Maatwerk'
                        "
                    >
                        <SvgInfo />
                    </span>
                </router-link>
            </li>
            <li class="c-page-menu__item">
                <router-link
                    active-class="c-page-menu__item-link--current"
                    class="c-page-menu__item-link"
                    :to="{ name: 'app.order.files' }"
                >
                    Aangeleverd

                    <span v-if="state.order.checks.total > 0"
                        >{{ state.order.checks.done }}/{{
                            state.order.checks.total
                        }}</span
                    >
                </router-link>
            </li>
            <li class="c-page-menu__item">
                <router-link
                    active-class="c-page-menu__item-link--current"
                    class="c-page-menu__item-link"
                    :to="{ name: 'app.order.delivery' }"
                >
                    Bezorging

                    <span
                        class="c-page-menu__item-alert"
                        v-if="!state.order.delivery_completed"
                    >
                        <SvgInfo />
                    </span>
                </router-link>
            </li>
            <li class="c-page-menu__item">
                <router-link
                    active-class="c-page-menu__item-link--current"
                    class="c-page-menu__item-link"
                    :to="{ name: 'app.order.billing' }"
                >
                    Facturatie
                </router-link>
            </li>
            <li class="c-page-menu__item">
                <router-link
                    active-class="c-page-menu__item-link--current"
                    class="c-page-menu__item-link"
                    :to="{ name: 'app.order.contact' }"
                >
                    Contact

                    <div class="e-chat-counter">
                        <SvgChat />
                        <!-- <p class="e-chat-counter__count">1</p> -->
                    </div>
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script setup>
import SvgChat from "@/svg/chat.svg";
import SvgInfo from "@/svg/info.svg";
import { onMounted, reactive } from "vue";

const state = reactive({
    order: {
        source: null,
        checks: {
            done: 0,
            total: 0,
            todo: 0,
            perc: 0,
        },
    },
});

onMounted(() => {
    Eventing.listen("fillOrder", (data) => {
        state.order = data.data;
    });
});
</script>
<style scoped>
/* .c-page-menu.kerstcadeaukiezen {
    background-color: #bb181f;
} */
</style>
