<template>
    <section>
        <div class="e-label samenstellen" :class="buttonclass">
            {{ label }}
        </div>
    </section>
</template>
<script>
export default {
    props: {
        buttonclass: {
            type: String,
            default: "e-label",
        },
        label: {
            type: String,
            default: "",
        },
    },
};
</script>
