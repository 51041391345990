import { defineStore } from "pinia";
import { useAuthStore } from "@/store/auth";

export const useOptionStore = defineStore({
    id: "options",
    state: () => ({
        options: {},
    }),
    actions: {
        auth() {
            const user = useAuthStore();
            return { headers: { Authorization: "Bearer " + user.token() } };
        },
        async fill(_option, _value) {
            if (typeof _value === "undefined" || !_value) {
                _value = null;
            }
            let url = "/api/options/" + _option + "/" + _value;
            try {
                let result = await axios.get(url, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    var optionList = {
                        [_option]: result.data,
                    };
                    this.$patch({
                        options: optionList,
                    });
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        fail(error) {
            console.error(error);
            Eventing.fire("error", error.response);
        },
    },
});
