<template>
    <div class="c-page-content">
        <div class="c-page-content__left">
            <div class="c-order e-load-items-animation">
                <!-- @include('frontend.partials.order.tabs.contents.packages')  -->
                <div
                    class="e-note e-note--regular"
                    v-if="
                        (state.order.status &&
                            state.order.status.label &&
                            !state.order.status.label.includes(
                                'In ontvangst'
                            ) &&
                            !state.order.status.label.includes('Concept')) ||
                        (state.order.invoice_status &&
                            state.order.invoice_status.label.includes(
                                'Gefactureerd'
                            ))
                    "
                >
                    <div class="e-note__icon">
                        <div class="e-note__icon-circle-animation">
                            <SvgInfo />
                        </div>
                    </div>
                    <div class="e-note__text">
                        <p
                            v-if="
                                !state.order.status.label.includes(
                                    'In ontvangst'
                                ) &&
                                !state.order.status.label.includes('Concept')
                            "
                        >
                            De bestelling kan door de status "<b>{{
                                state.order.status.label.toLowerCase()
                            }}</b
                            >" niet meer gewijzigd worden.
                        </p>

                        <p
                            v-if="
                                state.order.invoice_status.label.includes(
                                    'Gefactureerd'
                                )
                            "
                        >
                            De bestelling kan door de facturatiestatus "<b>{{
                                state.order.invoice_status.label.toLowerCase()
                            }}</b
                            >" niet worden uitgebreid met betaalde toevoegingen.
                        </p>

                        <p>Maak een nieuwe bestelling aan voor deze klant.</p>
                        <a
                            class="e-button e-button--green"
                            v-if="state.order.contact"
                            @click="
                                Modals.show(
                                    'contact-update',
                                    state.order.contact.slug
                                )
                            "
                            >Bestelling toevoegen</a
                        >
                    </div>
                </div>
                <div v-if="!state.loaded" class="e-preloader"></div>
                <transition name="fade">
                    <section
                        v-if="state.loaded"
                        class="c-page-content__section"
                        :class="{
                            'moved----c-page-content__section--disabled':
                                state.order.status &&
                                state.order.status.label &&
                                !state.order.status.label.includes(
                                    'In ontvangst'
                                ) &&
                                !state.order.status.label.includes('Concept'),
                        }"
                    >
                        <!--  DEV Note: if status 'Kan in productie', add class c-page-content__section--disabled -->

                        <div class="c-order__packages-header">
                            <div class="c-order-item__col">
                                <div class="c-order-item__stock">Voorraad</div>
                                <div class="c-order-item__amount">Aantal</div>
                                <button class="c-order-item__delete"></button>
                            </div>
                        </div>

                        <!--  ORDER PACKAGE -->
                        <div
                            class="c-order__package"
                            @click="openBox(box)"
                            :id="box.hash"
                            v-for="(box, index) in state.boxes"
                            :key="box.hash + index"
                        >
                            <div class="e-section-heading">
                                <div class="e-section-heading__left">
                                    <div class="e-section-heading__icon">
                                        <SvgGift />
                                    </div>

                                    <div class="e-edit-toggle">
                                        <div class="e-edit-toggle__output-wrap">
                                            <h2 class="e-section-heading__name">
                                                {{ box.name }}
                                            </h2>

                                            <span v-if="box.missing_count > 0">
                                                <!-- <span>{{box.missing_count}}</span> -->
                                                <!-- &nbsp;🚨 -->
                                            </span>

                                            <button
                                                class="e-edit-toggle__edit-icon js-edit-toggle"
                                            >
                                                <SvgEdit />
                                            </button>
                                        </div>
                                        <div
                                            class="e-edit-toggle__input-wrap e-edit-toggle__input-wrap--save-icon-right"
                                        >
                                            <div
                                                class="e-edit-toggle__input-wrap-row"
                                            >
                                                <input
                                                    type="text"
                                                    class="e-form__input e-edit-toggle__input"
                                                    v-model="box.name"
                                                />
                                                <button
                                                    class="e-edit-toggle__save-icon js-edit-toggle"
                                                    @click="changeBoxName(box)"
                                                >
                                                    <SvgCheck />
                                                </button>
                                                <button
                                                    class="e-section-heading__icon e-section-heading__icon--delete"
                                                    @click="deleteBox(box)"
                                                >
                                                    <SvgDelete />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="e-section-heading__right"
                                    v-if="box.is_loaded"
                                >
                                    <button
                                        class="e-button e-button--grey-dark e-button--small"
                                        open-modal-id="add-products"
                                        @click="
                                            Modals.show('order-products', box)
                                        "
                                        :class="{
                                            'is-hidden':
                                                state.order.status &&
                                                state.order.status.label &&
                                                !state.order.status.label.includes(
                                                    'In ontvangst'
                                                ) &&
                                                !state.order.status.label.includes(
                                                    'Concept'
                                                ),
                                        }"
                                    >
                                        Producten toevoegen
                                    </button>
                                </div>
                            </div>

                            <!-- @ Ik heb alle content van een samenstelling dat geladen moet worden in de onderstaande div c-order__package-contents-wrap gezet. Het meest logisch lijkt mij dat een samenstelling geladen wordt wanneer je op de parent div klikt 'c-order__package' -->
                            <div
                                class="c-order__package-contents-wrap"
                                v-if="box.is_loaded"
                                :class="{
                                    'c-page-content__section--disabled':
                                        state.order.status &&
                                        state.order.status.label &&
                                        !state.order.status.label.includes(
                                            'In ontvangst'
                                        ) &&
                                        !state.order.status.label.includes(
                                            'Concept'
                                        ),
                                }"
                            >
                                <div
                                    class="c-order__package-contents js-drag-area"
                                >
                                    <div
                                        class="c-order-item js-drag-handle js-draggable"
                                        v-for="(
                                            basket, subindex
                                        ) in box.baskets"
                                        :key="basket.id + subindex"
                                    >
                                        <basket-item
                                            :order="state.order.hash"
                                            :basket="basket"
                                            :box="box.hash"
                                            :product="basket.product"
                                            :first-of-category="
                                                basket.product &&
                                                basket.first_of_categories.includes(
                                                    basket.product.article_nr
                                                )
                                            "
                                        />
                                    </div>
                                </div>

                                <order-additions
                                    v-if="
                                        state.order &&
                                        state.order.status &&
                                        state.order.status.label
                                    "
                                    :class="{
                                        'c-page-content__section--disabled':
                                            state.order.status &&
                                            state.order.status.label &&
                                            !state.order.status.label.includes(
                                                'In ontvangst'
                                            ) &&
                                            !state.order.status.label.includes(
                                                'Concept'
                                            ),
                                    }"
                                    :order="state.order.hash"
                                    :box="box.id.toString()"
                                    :additions="box.additions"
                                    :orderstatus="state.order.status.label"
                                >
                                </order-additions>
                            </div>

                            <!-- dit alleen tonen wanneer de samenstelling niet geopend is -->
                            <div
                                class="c-order__package-load-contents"
                                v-if="!box.is_loaded"
                                @click="openBox(box)"
                            >
                                <div
                                    class="e-preloader"
                                    v-if="box.is_opening"
                                ></div>

                                <span v-if="!box.is_opening"
                                    >Samenstelling openen <SvgArrowDown
                                /></span>
                            </div>
                        </div>
                        <!--  END ORDER PACKAGE -->

                        <button
                            class="e-button e-button--grey-dark e-button--full-width"
                            @click="addNewBox()"
                        >
                            <SvgPlus /> Samenstelling toevoegen
                        </button>
                    </section>
                </transition>
                <transition name="fade">
                    <order-additions
                        v-if="
                            state.order &&
                            state.order.status &&
                            state.order.status.label
                        "
                        :class="{
                            'is-hidden':
                                state.order.is_splitted &&
                                state.order.origin_reference,
                        }"
                        :order="state.order.hash"
                        :additions="state.additions"
                        :orderstatus="state.order.status.label"
                    >
                    </order-additions>
                </transition>
                <section class="c-page-content__section e-module-style">
                    <form
                        method="POST"
                        @submit.prevent="onSubmit"
                        @keydown="order.errors.errors = {}"
                        novalidate
                        v-if="state.form"
                    >
                        <div class="e-section-heading">
                            <div class="e-section-heading__left">
                                <div class="e-section-heading__icon">
                                    <SvgPencil />
                                </div>
                                <h2 class="e-section-heading__name">
                                    Notities
                                </h2>
                            </div>
                        </div>

                        <!-- [FEEL-100] Heb het nu vanaf "Contact.vue" gehaald -->
                        <div
                            class="c-chat"
                            v-if="
                                order.nested['messages'] &&
                                order.nested['messages'].data.length > 0
                            "
                        >
                            <div
                                class="e-hide-scrollbar e-hide-scrollbar--top-shadow"
                            >
                                <div
                                    class="c-chat__scroll-area e-hide-scrollbar__inner"
                                    ref="container"
                                >
                                    <div
                                        class="c-chat__messages"
                                        v-if="order.nested['messages']"
                                    >
                                        <div
                                            class="c-chat__message"
                                            v-for="(message, index) in order
                                                .nested['messages'].data"
                                            :key="index"
                                            v-show="message.type == 'Notitie'"
                                            :class="{
                                                'c-chat__message--client':
                                                    message.sender.role ==
                                                    'contact',
                                                'c-chat__message--note':
                                                    message.type == 'Notitie',
                                            }"
                                        >
                                            <div
                                                class="c-chat__message-inner-wrap"
                                            >
                                                <div
                                                    class="c-chat__message-head"
                                                >
                                                    <div
                                                        class="c-chat__message-name"
                                                    >
                                                        <img
                                                            class="c-chat__message-user-image"
                                                            v-if="
                                                                message.sender
                                                                    .avatar
                                                            "
                                                            :src="
                                                                message.sender
                                                                    .avatar ??
                                                                '/svg/user-image.svg'
                                                            "
                                                        />
                                                        <SvgUserImage
                                                            class="c-chat__message-user-image"
                                                            v-if="
                                                                !message.sender
                                                                    .avatar
                                                            "
                                                        ></SvgUserImage>

                                                        {{
                                                            message.sender.name
                                                        }}

                                                        <span
                                                            class="c-chat__message-source"
                                                            v-if="
                                                                message.type ==
                                                                    'Notitie' &&
                                                                !message.receiver
                                                            "
                                                        >
                                                            Notitie
                                                        </span>
                                                        <span
                                                            class="c-chat__message-source"
                                                            v-if="
                                                                message.type ==
                                                                    'Notitie' &&
                                                                message.receiver
                                                            "
                                                        >
                                                            Notitie voor
                                                            {{
                                                                message.receiver
                                                            }}
                                                        </span>
                                                    </div>

                                                    <div
                                                        class="c-chat__message-date"
                                                    >
                                                        {{ message.created_at }}

                                                        <a
                                                            @click="
                                                                deleteMessage(
                                                                    message
                                                                )
                                                            "
                                                            class="c-order-item__delete"
                                                        >
                                                            <SvgDelete />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div
                                                    class="c-chat__message-text"
                                                >
                                                    <p
                                                        v-html="message.message"
                                                    ></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <textarea
                            class="e-form__textarea"
                            placeholder="Notitie..."
                            required
                            v-model="state.form.message"
                        ></textarea>
                        <div class="e-form__multiple-radios mt-10 mb-10">
                            <div
                                class="e-form__checkbox-wrap e-form__checkbox-wrap--text-only"
                            >
                                <p>Alleen voor:</p>
                            </div>

                            <label
                                class="e-form__checkbox-wrap"
                                v-for="(option, value) in enums.options[
                                    'user-roles'
                                ]"
                                :key="value"
                            >
                                <input
                                    class="e-form__checkbox"
                                    :value="option"
                                    type="checkbox"
                                    name="message-type"
                                    v-model="state.form.receiver"
                                />
                                <span class="e-form__checkbox-label"></span>
                                <span class="e-form__checkbox-text">{{
                                    option
                                }}</span>
                            </label>
                        </div>
                        <button class="e-button e-button--green mt-10">
                            Notitie opslaan
                        </button>
                    </form>
                </section>
            </div>
        </div>
        <div
            class="c-page-content__sidebar"
            v-if="state.order.hash && state.openTimeline"
        >
            <transition name="fade">
                <app-order-timeline :order="state.order.hash" />
            </transition>
        </div>
    </div>
</template>

<script setup>
import { useAdditionStore } from "@/store/addition";
import { useBoxStore } from "@/store/box";
import { useEnumStore } from "@/store/enum";
import { useOrderStore } from "@/store/order";
import SvgArrowDown from "@/svg/arrow-down.svg";
import SvgCheck from "@/svg/check.svg";
import SvgDelete from "@/svg/delete.svg";
import SvgEdit from "@/svg/edit.svg";
import SvgGift from "@/svg/gift.svg";
import SvgInfo from "@/svg/info.svg";
import SvgPencil from "@/svg/pencil.svg";
import SvgPlus from "@/svg/plus.svg";
import SvgUserImage from "@/svg/user-image.svg";
import Swal from "sweetalert2";
import { inject, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
const Modals = inject("Modals");
const order = useOrderStore();
const box = useBoxStore();

const addition = useAdditionStore();
const enums = useEnumStore();

const route = useRoute();

enums.fill("user-roles");

// box.fill(useRoute().params.hash);
// addition.fill(useRoute().params.hash);
// order.fillNested(useRoute().params.hash, "messages");

const state = reactive({
    order: {
        contact: {
            address: {},
        },
        followers: {},
        status: {
            label: null,
        },
    },
    loaded: false,
    boxes: null,
    additions: null,
    form: null,
    optionsForCreating: {
        null: "Blanco samenstelling toevoegen",
    },
    currentCategories: [],
    shownCategories: [],
    openTimeline: false,
});

onMounted(() => {
    state.order = {
        contact: {
            address: {},
        },
        followers: {},
    };

    state.currentCategories = [];
    state.shownCategories = [];

    loadAndShowOrder();

    fillNestedOrder();

    Eventing.listen("fillOrder", (data) => {
        // state.order = data.data;
        getBoxOptionsForCreating();
    });

    Eventing.listen("reloadBox", () => {
        setTimeout(() => {
            box.fill(route.params.hash);
        }, 500);
    });

    Eventing.listen("reloadOpenBox", (_box) => {
        console.info("reloadOpenBox", _box);
        document.getElementById(_box.hash).click();
    });

    Eventing.listen("reloadOpenBoxByHash", (_box) => {
        document.getElementById(_box).click();
    });
    Eventing.listen("reloadOrder", () => {
        setTimeout(() => {
            order.show(route.params.hash);
        }, 1500);
    });

    Eventing.listen("addProductToBox", (box, form) => {
        addToBasket(box, form);
    });

    Eventing.listen("reloadAdditions", () => {
        fillAdditions();
    });

    setTimeout(() => {
        Eventing.fire("reloadActivities");
    }, 1000);

    Eventing.listen("newChat", (data) => {
        order.nested["messages"].data.push(data);
    });

    getBoxOptionsForCreating();
});

async function openBox(_box) {
    console.warn("openBox", _box);
    _box.is_opening = true;
    let result = await box.single(route.params.hash, _box.hash);
    if (result) {
        _box.baskets = result.data.baskets;
        _box.additions = result.data.additions;
        _box.is_loaded = true;
        _box.is_opening = false;
    }
}

async function loadAndShowOrder() {
    let result = await order.show(route.params.hash);
    if (result) {
        console.info("loadAndShowOrder", result.data);
        state.order = result.data;
        state.loaded = true;

        fillBoxes();
        fillAdditions();

        setTimeout(() => {
            state.openTimeline = true;
        }, 1500);
    } else {
        setTimeout(() => {
            state.openTimeline = true;
        }, 500);
    }
}

async function fillNestedOrder() {
    let result = await order.fillNested(route.params.hash, "messages");
    if (result) {
        state.form = {
            message: null,
            type: "Notitie",
            via: "Order",
            receiver: [],
        };
    }
}
async function fillBoxes() {
    state.loaded = false;
    let result = await box.loadBoxesWithoutFilling(route.params.hash);
    if (result) {
        console.info("filBoxes", result.data);
        state.boxes = result.data;
        state.loaded = true;
        try {
            openBox(state.boxes[0]);
        } catch (error) {}

        setTimeout(() => {
            openAllBoxes();
        }, 1500);
    }
}

async function openAllBoxes() {
    try {
        for (const box of state.boxes) {
            if (box.is_loaded == true) {
                //
            } else {
                await openBox(box);
            }
        }
    } catch (error) {}
}

async function fillAdditions() {
    // let result = await box.fill(route.params.hash);
    let result = await addition.fill(route.params.hash);
    if (result) {
        state.additions = result.data;
    }
}

//
//
//

function changeBoxName(_box) {
    box.updateBoxName(route.params.hash, _box.hash, { name: _box.name });
}

async function onSubmit() {
    await order.storeNested(route.params.hash, "messages", state.form);
    state.form.message = null;
    (state.form.receiver = []),
        Eventing.fire("toasting", "Notitie is toegevoegd");
}

function addToBasket(_box, form) {
    box.storeBasket(_box, form);
}

function deleteMessage(message) {
    Swal.fire({
        text: "Zeker weten dat je deze notitie wilt verwijderen?",
        confirmButtonText: "Verwijderen",
        showCloseButton: true,
        icon: "error",
    }).then(async (result) => {
        if (result.isConfirmed) {
            let deleting = await order.storeNested(
                route.params.hash,
                "messages",
                {
                    message: "delete:" + message.id,
                }
            );
            if (deleting) {
                window.location.reload(true);
            }
        }
    });
}

function getBoxOptionsForCreating() {
    var obj = {};
    obj[null] = "Blanco samenstelling toevoegen";
    try {
        box.boxes.forEach((box) => {
            obj[box.id] = "Op basis van " + box.name;
        });
    } catch (error) {}
    state.optionsForCreating = obj;
    return obj;
}

function addNewBox() {
    let waiting = getBoxOptionsForCreating();
    if (waiting) {
        Swal.fire({
            text: "Zeker weten dat je nog een samenstelling wilt toevoegen? Hoe moet deze worden opgebouwd? Selecteer hieronder op basis waarvan je de samenstelling eventueel vooraf ingevuld wilt hebben.",
            confirmButtonText: "Toevoegen",
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonText: "Annuleren",
            icon: "question",
            input: "select",
            inputOptions: state.optionsForCreating,
        }).then(async (result) => {
            if (result.isConfirmed) {
                let go = await box.newBox(route.params.hash, {
                    box_id: result.value,
                });
                if (go) {
                    loadAndShowOrder();
                    setTimeout(() => {
                        Eventing.fire("reloadOpenBoxByHash", go.hash);
                        try {
                            document
                                .getElementById(go.hash)
                                .scrollIntoView({ behavior: "smooth" });
                        } catch (error) {}
                    }, 2500);
                }
            }
        });
    }
}

function deleteBox(_box) {
    Swal.fire({
        text: "Zeker weten dat je nog een samenstelling wilt verwijderen?",
        confirmButtonText: "Wissen",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "Annuleren",
        icon: "warning",
    }).then(async (result) => {
        if (result.isConfirmed) {
            let deleting = await box.deleteBox(route.params.hash, _box.hash);
            if (deleting) {
                window.location.reload(true);
            }
        }
    });
}
</script>
