import { useAuthStore } from "@/store/auth";
import { defineStore } from "pinia";
import SecureLS from "secure-ls";

export const useOrderStore = defineStore({
    id: "orders",
    state: () => ({
        errors: {
            message: null,
            errors: [],
        },
        orders: [],
        filters: {},
        nested: {},
        activityList: [],
    }),
    actions: {
        api() {
            return "/api/orders/";
        },
        auth() {
            const user = useAuthStore();
            return {
                headers: {
                    Authorization: "Bearer " + user.token(),
                    Filters: JSON.stringify(this.getActiveFilters()),
                },
            };
        },
        async show(path) {
            try {
                let result = await axios.get(this.api() + path, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    console.info("show@path", result.data);
                    Eventing.fire("fillOrder", result.data);
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async invoice(path) {
            try {
                let result = await axios.get(
                    this.api() + path + "/invoices",
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillInvoice", result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async updateMultipleState(state) {
            try {
                let result = await axios.post(
                    "/api/orders-state-changer",
                    { status: state },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire(
                        "toasting",
                        "De statussen worden aangepast naar '" +
                            result.data +
                            "'. Dit kan even duren."
                    );
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async packingSlip(path) {
            try {
                let result = await axios.get(
                    "/api/packing-slip/" + path,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    if (path == "products") {
                        Eventing.fire(
                            "toasting",
                            "Pakbonnen worden klaargemaakt van selectie&hellip;"
                        );
                    } else {
                        const url = result.data.url;
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", result.data.filename);
                        document.body.appendChild(link);
                        link.click();
                        Eventing.fire(
                            "toasting",
                            "Pakbon wordt gedownload&hellip;"
                        );
                    }
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async transportPrint(path) {
            try {
                let result = await axios.get(
                    "/api/transport-print/" + path,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    if (path == "products") {
                        Eventing.fire(
                            "toasting",
                            "Print wordt klaargemaakt van selectie&hellip;"
                        );
                    } else {
                        const url = result.data.url;
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", result.data.filename);
                        document.body.appendChild(link);
                        link.click();
                        Eventing.fire(
                            "toasting",
                            "Transport print wordt gedownload&hellip;"
                        );
                    }
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async totals(what) {
            try {
                let result = await axios.post(
                    "/api/order-totals/" + what,
                    {},
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async export(what) {
            try {
                let result = await axios.post(
                    "/api/order-export/" + what,
                    {},
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    const url = result.data.url;
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", result.data.filename);
                    document.body.appendChild(link);
                    link.click();
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async delivery(path) {
            try {
                let result = await axios.get(
                    this.api() + path + "/deliveries",
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillDelivery", result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async activities(path) {
            try {
                let result = await axios.get(
                    "/api/order-activities/" + path,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.$patch({
                        activityList: result.data.data,
                    });
                    return result.data.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async fill(url) {
            if (typeof url === "undefined") {
                url = this.api();
            }
            try {
                let result = await axios.get(url, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.success(result);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async refresh() {
            try {
                await axios.get(this.api() + "?refresh=true", this.auth());
            } catch (error) {}
        },
        async store(form) {
            try {
                let result = await axios.post(this.api(), form, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.added(result);

                    if (result.data.data.hash) {
                        return this.$router.push({
                            name: "app.order",
                            hash: result.data.data.hash,
                            params: { hash: result.data.data.hash },
                        });
                    }
                    return result.data.data;
                }
            } catch (error) {
                console.error(error);
                this.fail(error);
            }
        },
        async update(path, form) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.updated(result);
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async fillNested(path, nested) {
            try {
                let result = await axios.get(
                    this.api() + path + "/" + nested,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillNested", result.data);
                    var nestedList = {
                        [nested]: result.data,
                    };
                    this.$patch({
                        nested: nestedList,
                    });
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async storeNested(path, nested, form) {
            try {
                let result = await axios.post(
                    this.api() + path + "/" + nested,
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("storedNested", result.data);
                    return result.status;
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async bookmark(path) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    { bookmark: "toggle" },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillHeader", result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async duplicate(path) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    { duplicate: true },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    return this.$router.push({
                        name: "app.order.order",
                        hash: result.data.data.hash,
                        params: { hash: result.data.data.hash },
                    });
                    // Eventing.fire("fillHeader", result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async split(path) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    { split: true },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("fillHeader", result.data);
                    Eventing.fire("fillOrder", result.data);
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                }
            } catch (error) {
                if ([409].includes(error.response.status)) {
                    Eventing.fire(
                        "toasting",
                        "Mislukt. Zorg voor minimaal 2 samenstellingen, want elke Samenstelling wordt gezien als splitsing op de bestelling.",
                        "error"
                    );
                } else {
                    this.fail(error);
                }
            }
        },
        async shop(path) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    { shop: "toggle" },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    if (result.data.data.has_shop) {
                        Eventing.fire("toasting", "Shop aangemaakt", "success");
                    } else {
                        Eventing.fire(
                            "toasting",
                            "Shop (nog) niet aangemaakt",
                            "warning"
                        );
                    }
                    Eventing.fire("fillHeader", result.data);
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async urgent(path) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    { urgent: "toggle" },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    if (result.data.data.is_urgent) {
                        Eventing.fire(
                            "toasting",
                            "Spoed bestelling",
                            "success"
                        );
                    } else {
                        Eventing.fire(
                            "toasting",
                            "Geen spoed (meer)",
                            "warning"
                        );
                    }
                    Eventing.fire("fillHeader", result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async bank(path) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    { bank: "toggle" },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    if (result.data.data.has_bank) {
                        Eventing.fire("toasting", "Bank actief", "success");
                    } else {
                        Eventing.fire(
                            "toasting",
                            "Bank (nog) niet actief",
                            "warning"
                        );
                    }
                    Eventing.fire("fillHeader", result.data);
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },

        async collected(path) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    { collected: "toggle" },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    if (result.data.data.has_collected) {
                        Eventing.fire(
                            "toasting",
                            "Opgewaardeerd aangevinkt",
                            "success"
                        );
                    } else {
                        Eventing.fire(
                            "toasting",
                            "Opgewaardeerd uitgevinkt",
                            "warning"
                        );
                    }
                    Eventing.fire("fillHeader", result.data);
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async switchTrafficmanager(path, user) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    { trafficmanager: user },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("toasting", "Trafficmanager aangepast");
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async switchTrafficmanager(path, user) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    { trafficmanager: user },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("toasting", "Trafficmanager aangepast");
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async switchSource(path, db) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    { source: db },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("toasting", "Bron aangepast");
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async maatwerk(path) {
            try {
                let result = await axios.get(
                    "/api/order-maatwerk/" + path,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async saveMaatwerk(path, items) {
            try {
                let result = await axios.post(
                    "/api/order-maatwerk/" + path,
                    items,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async deliveryDateMaatwerk(path, basket, form) {
            try {
                let result = await axios.put(
                    "/api/order-maatwerk/" + path + "/" + basket,
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    return result.status;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async switchCategory(path, db) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    { category: db },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("toasting", "Categorie aangepast");
                    return result.status;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async sendManualEmail(path, email) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    { email: email },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("toasting", "E-mail is verstuurd");
                }
            } catch (error) {
                console.error(error);

                Eventing.fire(
                    "toasting",
                    "E-mail is niet verstuurd. Waarschijnlijk omdat deze niet aan de condities voldoet. [Foutcode: " +
                        error.response.status +
                        "]",
                    "error"
                );
            }
        },
        async switchVaryingDelivery(path, _bool) {
            try {
                let result = await axios.post(
                    this.api() + path + "/deliveries",
                    { has_varying_deliveries: _bool },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("toasting", "Aangepast");
                    Eventing.fire("fillDelivery", result.data);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async acknowledge(path, user, deadline) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    {
                        trafficmanager: user,
                        delivery_deadline: deadline,
                    },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("toasting", "Order is goedgekeurd");
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async setDeadline(path, deadline) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    {
                        delivery_deadline: deadline,
                    },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("toasting", "Deadline is toegevoegd");
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async switchStatus(path, status) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    { state: status },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    if (status == "delete") {
                        this.$router.replace({
                            name: "app.orders",
                        });
                        setTimeout(() => {
                            Eventing.fire(
                                "toasting",
                                "Bestelling is succesvol verwijderd",
                                "success"
                            );
                        }, 1000);
                    } else if (status == "reset") {
                        Eventing.fire(
                            "toasting",
                            "Status is gereset naar 'Concept'",
                            "success"
                        );
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    }

                    Eventing.fire("fillHeader", result.data);
                    Eventing.fire("reloadOrder");

                    // Eventing.fire("toasting", "Order status aangepast");
                    return result.status;
                }
            } catch (error) {
                if ([409].includes(error.response.status)) {
                    Eventing.fire(
                        "toasting",
                        "Mislukt! Er is nog geen BANK actief",
                        "error"
                    );
                } else {
                    this.fail(error);
                }
                return error.response.status;
            }
        },
        async switchBillingState(path, status) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    { billingstate: status },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    Eventing.fire("toasting", "Facturatiestatus aangepast");

                    if (status == "Factuur naar Exact sturen") {
                        setTimeout(() => {
                            this.invoice(path);
                        }, 5000);
                    }
                    return result.status;
                }
            } catch (error) {
                this.fail(error);
                return error;
            }
        },
        async resetBillingState(path, status, note) {
            try {
                let result = await axios.put(
                    this.api() + path,
                    { billingstate: status, note: note },
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    return result.status;
                }
            } catch (error) {
                this.fail(error);
                return error;
            }
        },
        async destroy(path) {
            try {
                let result = await axios.delete(this.api() + path, this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.deleted();
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async search(search) {
            if (search) {
                let url = this.api() + "?search=" + search;
                try {
                    let result = await axios.get(url, this.auth());
                    if ([200, 201, 204].includes(result.status)) {
                        this.success(result);
                    }
                } catch (error) {
                    this.fail(error);
                }
            }
        },
        deleted() {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Verwijderd", "warning");
            this.fill();
        },
        updated(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Aangepast: " + result.data.data.hash);
            this.fill();
        },
        added(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Toegevoegd: " + result.data.data.hash);
            this.fill();
        },
        success(result) {
            this.$patch({
                orders: result.data,
                activityList: [],
            });
            this.checkResults(result.data);
        },
        fail(error) {
            console.error(error);
            this.$patch({
                errors: error.response.data,
            });
            Eventing.fire("error", error.response);
        },
        saveFilters(filters) {
            const ls = new SecureLS();
            ls.set("order-filters", filters);
        },
        clearFilters() {
            const ls = new SecureLS();
            ls.set("order-filters", {
                invoice_status: [],
                category: [],
                status: [],
                source: [],
                only_following: false,

                products: [],
                categories: [],
                delivery_date: null,
                shipping_method: [],
                shipping_transporter: [],
                show_datepicker: false,
                date_range: {
                    start: null,
                    end: null,
                },
            });
        },

        activeFilters() {
            this.$patch({
                filters: this.getActiveFilters(),
            });
        },
        getActiveFilters() {
            const ls = new SecureLS();
            let filters = ls.get("order-filters");
            if (!filters) {
                this.clearFilters();
            }
            return ls.get("order-filters");
        },
        checkResults(result) {
            try {
                if (result.data && Object.keys(result.data).length <= 0) {
                    Eventing.fire(
                        "toasting",
                        "Geen resultaten gevonden",
                        "info"
                    );
                }
            } catch (error) {}
        },
    },
});
