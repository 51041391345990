<template>
    <div class="c-page-content__section c-status-counter e-module-style">
        <div class="e-section-heading">
            <div class="e-section-heading__left">
                <h2 class="e-section-heading__name">{{ props.product }}</h2>
            </div>
        </div>

        <div
            class="c-status-counter__inner-wrap"
            v-for="(result, value) in state.results"
            :key="value"
        >
            <div class="c-status-counter__row">
                <div class="c-status-counter__chart e-chart">
                    <chart-bars
                        :series="Array.from(result.series)"
                        :labels="Array.from(state.labels)"
                    />
                    <!-- :tooltipXFormatter="
                                    dataChartProductPerStatus.tooltipXFormatter
                        " -->
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useDashboardProductionStore } from "@/store/dashboard/production";

const props = defineProps({
    product: String,
    slug: String,
    weeks: Number,
});

import { reactive } from "vue";

const production = useDashboardProductionStore();

const state = reactive({
    results: [],
    labels: [],
});

async function loadingResultsOrProduct() {
    let results = await production.show(props.slug, props.weeks);
    if (results) {
        console.warn("loadingResultsOrProduct", results.stats);
        state.results = results.stats;
        state.labels = results.labels;
    }
}
loadingResultsOrProduct();
</script>
