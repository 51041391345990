<template>
    <section>
        <div
            v-if="!product.category"
            class="c-order-item__row c-order-item__row--is-draggable"
        >
            <div class="c-order-item__col">
                <div class="c-order-item__name-wrap">
                    <span class="c-order-item__name">
                        {{ product.name }}
                    </span>
                    <span class="c-order-item__number">
                        {{ product.article_nr }}
                    </span>
                </div>
            </div>
            <div class="c-order-item__col">
                <div class="c-order-item__stock">
                    <span
                        v-if="
                            product.has_stock &&
                            !product.is_digital &&
                            product.current_stock != null
                        "
                        >{{ product.current_stock }}</span
                    >
                    <span v-if="!product.has_stock">—</span>
                    <span
                        class="e-alert-stamp e-tooltip"
                        v-show="
                            product.has_stock &&
                            !product.is_digital &&
                            product.current_stock != null
                        "
                        data-tooltip="Niet voldoende voorraad!"
                        v-if="
                            product.current_stock < basket.amount &&
                            product.has_stock
                        "
                        >!</span
                    >
                </div>
                <div
                    class="c-order-item__amount e-edit-toggle"
                    :class="{
                        invisble: product.just_one && basket.amount == 1,
                    }"
                >
                    <div class="e-edit-toggle__output-wrap">
                        <button class="e-edit-toggle__edit-icon js-edit-toggle">
                            <SvgEdit />
                        </button>
                        {{ basket.amount }}
                    </div>
                    <div class="e-edit-toggle__input-wrap">
                        <div class="e-edit-toggle__input-wrap-row">
                            <button
                                class="e-edit-toggle__save-icon js-edit-toggle"
                                @click="updateAmountOfBasket(basket)"
                            >
                                <SvgCheck />
                            </button>
                            <input
                                class="c-order-item__amount-input e-form__input e-edit-toggle__input"
                                v-model="state.form.amount"
                                type="number"
                                min="1"
                                steps="1"
                            />
                        </div>
                    </div>
                </div>
                <button
                    class="c-order-item__delete"
                    @click="deleteBasket(basket, product)"
                >
                    <SvgDelete />
                </button>
            </div>
        </div>
        <MissingProducts :missing="basket.missing"></MissingProducts>

        <div
            v-if="product && product.category && firstOfCategory"
            class="c-order-item__row c-order-item__row--is-draggable"
            :class="{
                'c-order-item__row--is-category': product.category,
            }"
        >
            <div class="c-order-item__col">
                <div class="c-order-item__name-wrap">
                    <p class="c-order-item__name">
                        {{ product.category }}
                    </p>
                </div>
            </div>
        </div>

        <div class="c-order-item__sub-items" v-if="product && product.category">
            <div class="c-order-item__row">
                <div class="c-order-item__col">
                    <div class="c-order-item__name-wrap">
                        <span class="c-order-item__name">
                            {{ product.name }}
                        </span>
                        <span class="c-order-item__number">
                            {{ product.article_nr }}
                        </span>
                    </div>
                </div>
                <div class="c-order-item__col">
                    <div class="c-order-item__stock">
                        {{ product.current_stock }}
                        <span
                            class="e-alert-stamp e-tooltip"
                            data-tooltip="Niet voldoende voorraad!"
                            v-show="
                                product.has_stock &&
                                !product.is_digital &&
                                product.current_stock != null
                            "
                            v-if="product.current_stock < basket.amount"
                            >!</span
                        >
                    </div>
                    <div
                        class="c-order-item__amount e-edit-toggle"
                        :class="{
                            invisble: product.just_one && basket.amount == 1,
                        }"
                    >
                        <div class="e-edit-toggle__output-wrap">
                            <button
                                class="e-edit-toggle__edit-icon js-edit-toggle"
                            >
                                <SvgEdit />
                            </button>
                            {{ state.amount }}
                        </div>
                        <div class="e-edit-toggle__input-wrap">
                            <div class="e-edit-toggle__input-wrap-row">
                                <button
                                    class="e-edit-toggle__save-icon js-edit-toggle"
                                    @click="updateAmountOfBasket(basket)"
                                >
                                    <SvgCheck />
                                </button>
                                <input
                                    type="number"
                                    min="1"
                                    steps="1"
                                    class="c-order-item__amount-input e-form__input e-edit-toggle__input"
                                    v-model="state.form.amount"
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        class="c-order-item__delete"
                        @click="deleteBasket(basket, product)"
                    >
                        <SvgDelete />
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped>
.invisble {
    visibility: hidden;
}
</style>
<script setup>
import MissingProducts from "@/components/MissingProducts.vue";
import { useBoxStore } from "@/store/box";
import SvgCheck from "@/svg/check.svg";
import SvgDelete from "@/svg/delete.svg";
import SvgEdit from "@/svg/edit.svg";
import Swal from "sweetalert2";
import { onMounted, reactive } from "vue";

const props = defineProps({
    product: Object,
    basket: Object,
    order: String,
    box: String,
    firstOfCategory: Boolean,
});

const state = reactive({
    form: {
        amount: 0,
    },
    amount: 0,
});

onMounted(() => {
    state.form.amount = props.basket.amount;
    state.amount = props.basket.amount;
});

const box = useBoxStore();

async function updateAmountOfBasket(basket) {
    Eventing.fire("toasting", "Wordt opgeslagen…", "info");
    state.amount = "…";
    let updating = await box.updateBasket(props.box, basket.id, state.form);
    if (updating) {
        state.amount = updating.data.amount;

        setLocalStorageWithExpiry("startAmount" + props.box, state.amount, 5);
    }
}

function setLocalStorageWithExpiry(key, value, minutes) {
    const now = new Date();
    const expiryTime = now.getTime() + minutes * 60 * 1000; // minuten omzetten naar milliseconden
    const item = {
        value: value,
        expiry: expiryTime,
    };
    localStorage.setItem(key, JSON.stringify(item));
}

function deleteBasket(item, product) {
    setTimeout(() => {
        Modals.close();
    }, 100);
    Swal.fire({
        text: 'Zeker weten dat je "' + product.name + '" wilt verwijderen?',
        confirmButtonText: "Wissen",
        showCloseButton: true,
        icon: "warning",
    }).then((result) => {
        if (result.isConfirmed) {
            box.destroyBasket(props.box, item.id);
        }
    });
}
</script>
