<template>
    <article>
        <div
            v-if="!state.activities || state.loading"
            class="e-preloader"
        ></div>
        <div class="c-timeline" v-if="state.activities">
            <div class="c-timeline__messages">
                <div
                    class="c-timeline__message"
                    v-for="(activity, index) in state.activities"
                    :key="index"
                >
                    <div class="c-timeline__message-content">
                        <div class="c-timeline__message-text">
                            <p
                                class="c-timeline__message-text"
                                v-html="activity.text"
                            ></p>
                        </div>
                    </div>
                    <div class="c-timeline__message-options">
                        <p class="c-timeline__message-date">
                            {{ activity.timestamp }} {{ activity.causer }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </article>
</template>

<script setup>
import { useOrderStore } from "@/store/order";
import { onMounted, reactive } from "vue";

const props = defineProps({
    order: String,
});

const order = useOrderStore();
// order.activities(props.order);

const state = reactive({
    activities: null,
    loading: false,
});

onMounted(() => {
    Eventing.listen("reloadActivities", () => {
        state.loading = true;
        setTimeout(() => {
            setActivities();
        }, 100);
    });
    setActivities();
});

async function setActivities() {
    let result = await order.activities(props.order);
    if (result) {
        state.activities = order.activityList;
        state.loading = false;
    }
}
</script>
