<template>
    <section>
        <OpenAssembler
            @click="openAssemble(basket)"
            v-if="basket.assembling"
            :missing="basket.missing"
        ></OpenAssembler>

        <div
            v-if="!product.category"
            class="c-order-item__row c-order-item__row--is-draggable"
            :class="{ 'is-invisible': product.is_invisible }"
        >
            <div class="c-order-item__col" v-if="!product.is_invisible">
                <div class="c-order-item__product-image" v-if="product.image">
                    <img
                        class="e-form__select-item-image"
                        :src="product.image"
                    />
                </div>
                <div class="c-order-item__name-wrap">
                    <span class="c-order-item__name">
                        {{ product.name }}
                    </span>
                </div>
            </div>
            <div class="c-order-item__col" v-if="!product.is_invisible">
                <div
                    class="c-order-item__amount e-edit-toggle"
                    :class="{ invisble: product.just_one }"
                >
                    <div class="e-edit-toggle__output-wrap">
                        <button class="e-edit-toggle__edit-icon js-edit-toggle">
                            <SvgEdit />
                        </button>
                        {{ basket.amount }}
                    </div>
                    <div class="e-edit-toggle__input-wrap">
                        <div class="e-edit-toggle__input-wrap-row">
                            <button
                                class="e-edit-toggle__save-icon js-edit-toggle"
                                @click="updateAmountOfBasket(basket)"
                            >
                                <SvgCheck />
                            </button>
                            <input
                                class="c-order-item__amount-input e-form__input e-edit-toggle__input"
                                v-model="state.form.amount"
                                type="number"
                                min="1"
                                steps="1"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <MissingProducts
            v-if="!basket.assembling"
            :missing="basket.missing"
        ></MissingProducts> -->

        <div
            v-if="product && product.category && firstOfCategory"
            class="c-order-item__row c-order-item__row--is-draggable"
            :class="{
                'c-order-item__row--is-category': product.category,
                'is-invisible': product.is_invisible,
            }"
        >
            <div class="c-order-item__col">
                <p class="c-order-item__name">
                    {{ product.category }}
                </p>
            </div>
        </div>
        <div
            class="c-order-item__sub-items"
            v-if="product && product.category"
            :class="{ 'is-invisible': product.is_invisible }"
        >
            <div class="c-order-item__row" v-if="!product.is_invisible">
                <div class="c-order-item__col">
                    <div
                        class="c-order-item__product-image"
                        v-if="product.image"
                    >
                        <img
                            class="e-form__select-item-image"
                            :src="product.image"
                        />
                    </div>
                    <div class="c-order-item__name-wrap">
                        <span class="c-order-item__name">
                            {{ product.name }}
                        </span>
                        <!-- 
                        <span class="c-order-item__number">
                            {{ product.article_nr }}
                        </span> 
                        -->
                    </div>
                </div>
                <div class="c-order-item__col">
                    <div
                        class="c-order-item__amount e-edit-toggle"
                        :class="{ invisble: product.just_one }"
                    >
                        <div class="e-edit-toggle__output-wrap">
                            <button
                                class="e-edit-toggle__edit-icon js-edit-toggle"
                            >
                                <SvgEdit />
                            </button>
                            {{ state.amount }}
                        </div>
                        <div class="e-edit-toggle__input-wrap">
                            <div class="e-edit-toggle__input-wrap-row">
                                <button
                                    class="e-edit-toggle__save-icon js-edit-toggle"
                                    @click="updateAmountOfBasket(basket)"
                                >
                                    <SvgCheck />
                                </button>
                                <input
                                    type="number"
                                    min="1"
                                    steps="1"
                                    class="c-order-item__amount-input e-form__input e-edit-toggle__input"
                                    v-model="state.form.amount"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped>
.invisble {
    visibility: hidden;
}
.is-invisible {
    display: none !important;
}
</style>
<script setup>
import { useBoxStore } from "@/store/client/box";
import SvgCheck from "@/svg/check.svg";
import SvgEdit from "@/svg/edit.svg";
import Swal from "sweetalert2";
import { onMounted, reactive } from "vue";
import OpenAssembler from "../OpenAssembler.vue";

const props = defineProps({
    product: Object,
    basket: Object,
    order: String,
    box: String,
    missing: Array,
    firstOfCategory: Boolean,
});

const state = reactive({
    form: {
        amount: 0,
    },
    amount: 0,
});

onMounted(() => {
    findImage(props.product);
    state.form.amount = props.basket.amount;
    state.amount = props.basket.amount;
});

const box = useBoxStore();

async function updateAmountOfBasket(basket) {
    Eventing.fire("toasting", "Wordt opgeslagen…", "info");
    state.amount = "…";
    let updating = await box.updateBasket(props.box, basket.id, state.form);
    if (updating) {
        state.amount = updating.data.amount;
    }
}

function openAssemble(basket) {
    Eventing.fire("openAssembleModal", props.box, basket);
}

function findImage(product) {
    try {
        axios
            .get(product.image.replace("/link", "/api") + "?url=true", {
                withCredentials: false,
            })
            .then(function (response) {
                console.warn(response.status);
                if (response.data) {
                    if (response.data == "" || response.data == null) {
                        product.image = null;
                    }
                } else {
                    product.image = null;
                }
            });
    } catch (error) {
        product.image = null;
    }
}

function deleteBasket(item, product) {
    setTimeout(() => {
        Modals.close();
    }, 100);
    Swal.fire({
        text: 'Zeker weten dat je "' + product.name + '" wilt verwijderen?',
        confirmButtonText: "Wissen",
        showCloseButton: true,
        icon: "warning",
    }).then((result) => {
        if (result.isConfirmed) {
            box.destroyBasket(props.box, item.id);
        }
    });
}
</script>
