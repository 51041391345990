<template>
    <article>
        <div class="e-modal e-modal--w320" modal-id="change-status-modal">
            <div class="e-modal__wrap">
                <div class="e-modal__modal">
                    <div class="e-modal__close js-close-modal">
                        <SvgCross @click="Modals.close()" />
                    </div>

                    <div class="e-modal__section">
                        <div class="e-modal__section-title">
                            <h2 class="e-modal__title">
                                Wijzig <span>status</span>
                            </h2>
                        </div>
                    </div>

                    <div class="e-modal__section e-modal__section--bordered">
                        <div class="e-form">
                            <section>
                                <div
                                    class="e-form__multiple-radios e-form__multiple-radios--list"
                                    v-if="state.order.hash == null"
                                >
                                    <label
                                        class="e-form__radio-wrap e-form__radio-wrap--disabled"
                                    >
                                        <span
                                            class="e-form__radio-label"
                                        ></span>
                                        <span class="e-form__radio-text"
                                            >Wordt geladen&hellip;</span
                                        >
                                    </label>
                                </div>

                                <div
                                    class="mb-10"
                                    :class="{
                                        'is-hidden': state.has_category == true,
                                    }"
                                >
                                    <div class="e-modal__section-title">
                                        <h2 class="e-modal__title">
                                            Definieer <span>categorie</span>
                                        </h2>
                                    </div>
                                </div>
                                <div
                                    class="mb-20"
                                    :class="{
                                        'is-hidden': state.has_category == true,
                                    }"
                                >
                                    <div class="e-form__field-wrap">
                                        <div class="e-form__select-wrap">
                                            <select
                                                class="e-form__select"
                                                v-model="state.order.category"
                                                placeholder="Categorie..."
                                                required
                                                @change="updateCategory()"
                                            >
                                                <option
                                                    readonly
                                                    disabled
                                                    selected
                                                    v-if="
                                                        state.order.category ==
                                                        null
                                                    "
                                                >
                                                    Selecteer categorie:
                                                </option>
                                                <option
                                                    v-for="(
                                                        option, value
                                                    ) in enums.options[
                                                        'order-categories'
                                                    ]"
                                                    v-bind:value="option"
                                                    :key="value"
                                                >
                                                    {{ option }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <!-- / Einde -->
                                <div
                                    class="e-form__multiple-radios e-form__multiple-radios--list"
                                    v-if="state.order && state.order.hash"
                                    :class="{
                                        'niet-klikbaar':
                                            state.has_category == false,
                                    }"
                                >
                                    <label
                                        class="e-form__radio-wrap"
                                        v-for="(option, index) in enums.options[
                                            'order-status'
                                        ]"
                                        :key="index"
                                        :class="{
                                            ' e-form__radio-wrap--disabled':
                                                checkForDisabled(option) ||
                                                !state.canChange,
                                        }"
                                    >
                                        <input
                                            class="e-form__radio"
                                            type="radio"
                                            name="order-status"
                                            :value="option"
                                            :disabled="
                                                checkForDisabled(option) ||
                                                !state.canChange
                                            "
                                            v-model="state.status"
                                        />
                                        <span
                                            class="e-form__radio-label"
                                        ></span>
                                        <span class="e-form__radio-text">{{
                                            option
                                        }}</span>
                                    </label>

                                    <label class="e-form__radio-wrap">
                                        <input
                                            class="e-form__radio"
                                            type="radio"
                                            name="order-status"
                                            :value="'delete'"
                                            v-model="state.status"
                                            onclick="return confirm('Zeker weten dat je deze bestelling wilt verwijderen? Alleen gebruikers met de rol van Beheerder kunnen deze actie afronden.')"
                                        />
                                        <span
                                            class="e-form__radio-label"
                                        ></span>
                                        <span class="e-form__radio-text"
                                            >Verwijderen</span
                                        >
                                    </label>

                                    <label
                                        class="e-form__radio-wrap"
                                        v-if="state.status != 'Concept'"
                                    >
                                        <input
                                            class="e-form__radio"
                                            type="radio"
                                            name="order-status"
                                            :value="'reset'"
                                            v-model="state.status"
                                            onclick="return confirm('Zeker weten dat je van deze bestelling de status wilt resetten naar Concept? Alleen gebruikers met de rol van Beheerder kunnen deze actie afronden.')"
                                        />
                                        <span
                                            class="e-form__radio-label"
                                        ></span>
                                        <span class="e-form__radio-text"
                                            >Reset status
                                        </span>
                                    </label>
                                </div>

                                <button
                                    @click="switchOrderStatus()"
                                    class="e-form__submit e-button e-button--green e-button--full-width mt-10 js-close-modal"
                                    :class="{
                                        'niet-klikbaar':
                                            state.has_category == false,
                                    }"
                                >
                                    Opslaan
                                </button>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="e-modal__bg js-close-modal"
                @click="Modals.close()"
            ></div>
        </div>
    </article>
</template>

<style scoped>
.niet-klikbaar {
    pointer-events: none;
    filter: blur(2px);
}
</style>

<script setup>
import SvgCross from "@/svg/cross.svg";

import { useAuthStore } from "@/store/auth";
import { useOptionStore } from "@/store/option";
import { useOrderStore } from "@/store/order";
import { inject, onMounted, reactive } from "vue";

import { useEnumStore } from "@/store/enum";
const Modals = inject("Modals");
const order = useOrderStore();
const enums = useEnumStore();

const options = useOptionStore();
options.fill("user-trafficmanagers");

const auth = useAuthStore();

enums.fill("order-status");
// order.show(useRoute().params.hash);

const state = reactive({
    status: null,
    canChange: false,
    has_category: true,
    order: {
        category: null,
        hash: null,
        status: {
            label: null,
        },
        auth: {},
        contact: {
            address: {},
        },
        followers: {},
    },
});

onMounted(() => {
    Eventing.listen("openStatusChanger", (hash) => {
        console.info("openStatusChanger", hash);
        if (state.order.hash == null) {
            // state.order.hash = hash;
            setTimeout(async () => {
                let r = await order.show(hash);
                if (r) {
                    setTimeout(() => {
                        state.canChange = true;
                    }, 500);
                }
            }, 100);
        } else {
            setTimeout(() => {
                state.canChange = true;
            }, 1500);
        }
    });
});

function loadCategories() {
    enums.fill("order-categories");
}

function checkForDisabled(value) {
    if (state.order.status.label == "Concept") {
        if (value == "Kan in productie") {
            return false;
        }
    }
    if (state.order.status.label == "In productie") {
        if (value == "Klaar voor transport") {
            return false;
        }
    }
    if (state.order.status.label == "In ontvangst") {
        if (value == "Kan in productie") {
            return false;
        }
    }
    if (value) {
        // return false;
    }
    if (value == "Afgeleverd" || value == "Is mailing") {
        return false;
    }
    if (options.options && options.options["order-status"] && value) {
        return !options.options["order-status"].includes(value);
    }
}

async function updateCategory() {
    let result = await order.switchCategory(
        state.order.hash,
        state.order.category
    );
    if (result) {
        state.has_category = true;
    }
}

onMounted(() => {
    Eventing.listen("fillOrder", (data) => {
        state.order = data.data;
        state.status = data.data.status.label;

        if (state.status == "Concept") {
            state.has_category = false;
            if (state.order.category != null) {
                state.has_category = true;
            }
        }
        options.fill("order-status", state.status);
    });
    Eventing.listen("fillHeader", (data) => {
        state.order = data.data;
        state.status = data.data.status.label;
        if (state.status == "Concept") {
            state.has_category = false;
            if (state.order.category != null) {
                state.has_category = true;
            }
        }
        options.fill("order-status", state.status);
    });
    loadCategories();
});

async function switchOrderStatus() {
    let result = await order.switchStatus(state.order.hash, state.status);
    if (result) {
        Modals.close();
    }
}
</script>
