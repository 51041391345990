<template>
    <article>
        <!--  ORDER PACKAGE -->

        <div class="c-order__package-contents js-drag-area">
            <div class="c-order-item">
                <!--  DEV Note: if row has sub items, add class 'c-order-item__row--is-category'-->
                <div
                    v-if="product && product.category && firstOfCategory"
                    class="c-order-item__row c-order-item__row--is-draggable"
                    :class="{
                        'c-order-item__row--is-category': product.category,
                    }"
                >   
                    <p class="c-order-item__name">{{ product.category }}</p>
                </div>
                <!--  Sub items -->
                <section
                    no-class="c-order-item__sub-items"
                    v-if="product && product.category"
                >
                    <div class="c-order-item__row">
                        <div
                            class="c-order-item__col c-order-item__col--align-left"
                        >
                            <label
                                class="c-order-item__checkbox e-form__checkbox-wrap"
                                v-if="inProduction"
                            >
                                <input
                                    class="e-form__checkbox"
                                    type="checkbox"
                                    v-model="basket.is_picked"
                                    @click="
                                        togglePicker(basket, basket.is_picked)
                                    "
                                />
                                <span class="e-form__checkbox-label"></span>
                            </label>

                            <div class="c-order-item__name-wrap">
                                <span class="c-order-item__name">
                                    {{ product.name }}
                                </span>
                                <span class="c-order-item__number">
                                    {{ product.article_nr }}
                                </span>
                            </div>
                        </div>
                        <div class="c-order-item__col">
                            <div class="c-order-item__amount">
                                {{ basket.amount }}
                            </div>
                        </div>
                    </div>
                </section>

                <section v-if="product && !product.category">
                    <div class="c-order-item__row">
                        <div
                            class="c-order-item__col c-order-item__col--align-left"
                        >
                            <label
                                class="c-order-item__checkbox e-form__checkbox-wrap"
                                v-if="inProduction"
                            >
                                <input
                                    class="e-form__checkbox"
                                    type="checkbox"
                                    v-model="basket.is_picked"
                                    @click="
                                        togglePicker(basket, basket.is_picked)
                                    "
                                />
                                <span class="e-form__checkbox-label"></span>
                            </label>
                            <div class="c-order-item__name-wrap">
                                <span class="c-order-item__name">
                                    {{ product.name }}
                                </span>
                                <span class="c-order-item__number">
                                    {{ product.article_nr }}
                                </span>
                            </div>
                        </div>
                        <div class="c-order-item__col">
                            <div class="c-order-item__amount">
                                {{ basket.amount }}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </article>
</template>

<script setup>
import SvgGift from "@/svg/gift.svg";
import SvgDelete from "@/svg/delete.svg";
import SvgCheck from "@/svg/check.svg";
import SvgEdit from "@/svg/edit.svg";
import Swal from "sweetalert2";
import { inject, reactive, onMounted } from "vue";
import MissingProducts from "@/components/MissingProducts.vue";
import { useOrderStore } from "@/store/production/order";
const _order = useOrderStore();

const props = defineProps({
    product: Object,
    basket: Object,
    order: String,
    box: String,
    firstOfCategory: Boolean,
    inProduction: Boolean,
});

const state = reactive({
    form: {
        amount: 0,
    },
});

onMounted(() => {
    state.form.amount = props.basket.amount;
});

async function togglePicker(basket, is_picked) {
    let result = await _order.update(props.order, {
        model: "basket",
        id: basket.id,
        is_picked: !is_picked,
    });
}

function deleteBasket(item, product) {
    setTimeout(() => {
        Modals.close();
    }, 100);
    Swal.fire({
        text: 'Zeker weten dat je "' + product.name + '" wilt verwijderen?',
        confirmButtonText: "Wissen",
        showCloseButton: true,
        icon: "warning",
    }).then((result) => {
        if (result.isConfirmed) {
            box.destroyBasket(props.box, item.id);
        }
    });
}
</script>
