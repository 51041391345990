import { defineStore } from "pinia";
import SecureLS from "secure-ls";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

const ls = new SecureLS({
    encodingType: "rc4",
    isCompression: false,
});

const store = new Vuex.Store({
    state: {
        client: {},
    },
    plugins: [
        createPersistedState({
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
        }),
    ],
    mutations: {
        client: (state, value) =>
            value ? (state.client = value) : (state.client = null),
    },
});

export const useAuthClientStore = defineStore({
    id: "clientAuth",
    state: () => ({
        //
    }),
    actions: {
        secureLS() {
            return new SecureLS({
                encodingType: "rc4",
                isCompression: false,
            });
        },
        auth() {
            return store.state.client;
        },
        name() {
            try {
                return this.auth().client.contactperson;
            } catch (error) {
                return this.logout();
            }
        },
        company() {
            try {
                return this.auth().client.company;
            } catch (error) {
                return this.logout();
            }
        },
        token() {
            try {
                return this.auth().token;
            } catch (error) {
                return this.logout();
            }
        },
        order() {
            try {
                return this.auth().order;
            } catch (error) {
                return this.logout();
            }
        },
        authenticate(client) {
            store.commit("client", client);
            axios
                .put("/api/check-auth-client", null, {
                    headers: { Authorization: "Bearer " + client.token },
                })
                .then(() => {
                    this.$router.push("/klant/bestelling");
                    setTimeout(() => {}, 500);
                });
        },
        logout() {
            axios
                .put("/api/logoff", null, {
                    headers: { Authorization: "Bearer " + this.auth().token },
                })
                .then(() => {
                    this.secureLS().removeAll();
                    store.commit("client", null);
                    setTimeout(() => {
                        Eventing.fire(
                            "toasting",
                            "Succesvol uitgelogd",
                            "info"
                        );
                    }, 1000);
                    this.$router.push("/");
                });
        },
    },
});
