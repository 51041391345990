<template>
    <article>
        <div class="c-page-content c-page-content--has-page-menu">
            <div
                class="c-page-content__container c-page-content__container--spacing"
            >
                <!-- @include('frontend.partials.order.tabs.billing.invoice') -->

                <section class="c-page-content__section e-module-style">
                    <div class="e-section-heading e-section-heading--no-border">
                        <div class="e-section-heading__left">
                            <h2 class="e-section-heading__name">Factuur</h2>
                        </div>
                        <div class="e-section-heading__right"></div>
                    </div>

                    <div class="c-table-default mt-min-10">
                        <div class="c-table-default__inner-wrap">
                            <div class="c-table-default__table">
                                <div class="c-table-default__head">
                                    <div class="c-table-default__tr">
                                        <div class="c-table-default__td">
                                            Product
                                        </div>
                                        <div class="c-table-default__td">
                                            Prijs
                                        </div>
                                        <div class="c-table-default__td">
                                            Aantal
                                        </div>
                                        <div class="c-table-default__td">
                                            Totaal
                                        </div>
                                    </div>
                                </div>
                                <div class="c-table-default__body">
                                    <invoice-row
                                        v-for="(row, index) in state.invoice
                                            .data"
                                        :key="index"
                                        :item="row"
                                        :category="true"
                                    >
                                    </invoice-row>
                                </div>
                                <div
                                    class="c-table-default__footer"
                                    v-if="state.invoice.totals"
                                >
                                    <div
                                        class="c-table-default__tr"
                                        style="display: none"
                                    >
                                        <div class="c-table-default__td"></div>
                                        <div class="c-table-default__td">
                                            Subtotaal
                                        </div>
                                        <div class="c-table-default__td"></div>
                                        <div class="c-table-default__td">
                                            {{
                                                state.invoice.totals[
                                                    "subtotaal"
                                                ]
                                            }}
                                        </div>
                                    </div>

                                    <!-- 
                                    <div class="c-table-default__tr">
                                        <div class="c-table-default__td"></div>
                                        <div class="c-table-default__td">
                                            BTW
                                        </div>
                                        <div class="c-table-default__td"></div>
                                        <div class="c-table-default__td">
                                            {{ state.invoice.totals["btw"] }}
                                        </div>
                                    </div> 
                                    -->

                                    <!-- <div class="c-table-default__tr">
                                        <div class="c-table-default__td"></div>
                                        <div
                                            class="c-table-default__td c-table-default__td--bold"
                                        >
                                            Totaal
                                        </div>
                                        <div class="c-table-default__td"></div>
                                        <div
                                            class="c-table-default__td c-table-default__td--bold"
                                        >
                                            {{ state.invoice.totals["subtotaal"] }}
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </article>
</template>

<script setup>
import { useOrderStore } from "@/store/client/order";
import { onMounted, reactive } from "vue";

import { useAuthClientStore } from "@/store/authClient";

const order = useOrderStore();

const user = useAuthClientStore();



const state = reactive({
    invoice: {
        data: [],
        totals: null,
    },
});

onMounted(() => {

    order.invoice(user.order());
    
    Eventing.listen("fillInvoice", (data) => {
        console.info(data);
        state.invoice = data;
    });
});
</script>
