import { useAuthStore } from "@/store/auth";
import { defineStore } from "pinia";

export const useAttachmentStore = defineStore({
    id: "attachments",
    state: () => ({
        errors: {
            message: null,
            errors: [],
        },
        attachments: {
            boxes: {},
            additions: {},
        },
        order: null,
    }),
    actions: {
        api(order) {
            return "/api/orders/" + order + "/attachments/";
        },
        auth() {
            const user = useAuthStore();
            return {
                headers: {
                    Authorization: "Bearer " + user.token(),
                },
            };
        },
        async show(order, path) {
            try {
                let result = await axios.get(
                    this.api(order) + path,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    console.info(result.data);
                    const url = result.data.url;
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", result.data.filename);
                    document.body.appendChild(link);
                    link.click();
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async fill(order) {
            this.$patch({
                order: order,
            });

            try {
                let result = await axios.get(this.api(order), this.auth());
                if ([200, 201, 204].includes(result.status)) {
                    this.success(result.data);
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async fillWithoutFilling(order) {
            this.$patch({
                order: order,
            });

            try {
                let result = await axios.get(
                    this.api(order) + "?mains=box",
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async fillBox(order, box) {
            this.$patch({
                order: order,
            });

            try {
                let result = await axios.get(
                    this.api(order) + "?load=box&hash=" + box.hash,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    return result.data;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async store(order, form) {
            try {
                let result = await axios.post(
                    this.api(order),
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.added(result);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async update(order, path, form) {
            try {
                let result = await axios.put(
                    this.api(order) + path,
                    form,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.updated(result);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async destroy(order, path) {
            try {
                let result = await axios.delete(
                    this.api(order) + path,
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.deleted(result);
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async remove(order, path) {
            try {
                let result = await axios.delete(
                    this.api(order) + path + "?reset=true",
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.deleted(result);
                    return result.status;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async completed(order, path) {
            try {
                let result = await axios.delete(
                    this.api(order) + path + "?completed=true",
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.updated(result);
                    return result.status;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        async rejected(order, path) {
            try {
                let result = await axios.delete(
                    this.api(order) + path + "?rejected=true",
                    this.auth()
                );
                if ([200, 201, 204].includes(result.status)) {
                    this.updated(result);
                    return result.status;
                }
            } catch (error) {
                this.fail(error);
            }
        },
        deleted() {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Verwijderd", "warning");
            Eventing.fire("reloadOrder");
            this.fill(this.$state.order);
        },
        updated(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Aangepast"); //: " + result.data.data.amount);
            Eventing.fire("reloadOrder");
            console.info(this);

            this.fill(this.$state.order);
        },
        added(result) {
            Eventing.fire("closeModal");
            Eventing.fire("toasting", "Toegevoegd");
            Eventing.fire("reloadOrder");
            this.fill(this.$state.order);
        },
        success(result) {
            this.$patch({
                attachments: result.data,
            });
        },
        fail(error) {
            console.error(error);
            this.$patch({
                errors: error.response.data,
            });
            Eventing.fire("error", error.response);
        },
    },
});
