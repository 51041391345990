<template>
    <article>
        <div class="c-topbar">
            <div class="c-topbar__left">
                <div
                    class="c-topbar__left-button e-menu-button js-toggle-mobile-menu"
                    @click="menu('mobile')"
                >
                    <div class="e-menu-button__bars-wrap">
                        <div class="e-menu-button__bars"></div>
                    </div>
                </div>
                <div class="c-topbar__search">
                    <label class="c-topbar__search-label" for="table-search"
                        ><SvgSearch
                    /></label>
                    <input
                        class="c-topbar__search-input"
                        id="table-search"
                        type="search"
                        @input="debounceSearch"
                        autocomplete="false"
                        spellcheck="false"
                        placeholder="Zoeken door producten..."
                    />
                </div>
            </div>
            <div
                class="c-topbar__right-button e-menu-button js-toggle-topbar-menu"
                @click="menu('topbar')"
            >
                <div class="e-menu-button__bars-wrap">
                    <div class="e-menu-button__bars"></div>
                </div>
            </div>
            <div class="c-topbar__right">
                <button
                    class="e-button e-button--grey-dark"
                    open-modal-id="add-product-category"
                    @click="Modals.show('product-category-create')"
                >
                    <SvgPlus /> Categorie
                </button>

                <button
                    class="e-button e-button--grey-dark"
                    open-modal-id="add-new-product"
                    @click="Modals.show('product-create')"
                >
                    <SvgPlus /> Product
                </button>
            </div>
        </div>

        <div class="c-below-topbar">
        <products-category-filter
            v-if="product && product.products && product.products.data"
        ></products-category-filter>
    </div>

        <div class="c-page-content">
            <div class="c-page-content__container c-page-content__container--spacing-small">

                <div class="c-table js-overflow-arrow">
                    <div class="c-table__head js-sticky-element">
                        <div class="c-table-item c-table-item--head">
                            <div class="c-table-item__col c-table-item__col--icon">
                                <div class="c-table-item__icon">
                                    <SvgSort />
                                </div>
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--product-name c-table-item__col--sortable"
                                :class="{
                                    'c-table-item__col--sorted': state.filter == 'name',
                                    'c-table-item__col--sorted-desc':
                                        state.orderBy == 'desc' &&
                                        state.filter == 'name',
                                }"
                                @click="setFilter('name')"
                            >
                                Naam
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--article-nr c-table-item__col--sortable"
                                :class="{
                                    'c-table-item__col--sorted':
                                        state.filter == 'article_nr',
                                    'c-table-item__col--sorted-desc':
                                        state.orderBy == 'desc' &&
                                        state.filter == 'article_nr',
                                }"
                                @click="setFilter('article_nr')"
                            >
                                Nummer
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--price c-table-item__col--sortable"
                                noclass="{
                                    'c-table-item__col--sorted':
                                        state.filter == 'price',
                                    'c-table-item__col--sorted-desc':
                                        state.orderBy == 'desc' &&
                                        state.filter == 'price',
                                }"
                                @click="setFilter('price')"
                            >
                                Prijs
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--weight c-table-item__col--sortable"
                                noclass="{
                                    'c-table-item__col--sorted':
                                        state.filter == 'weight',
                                    'c-table-item__col--sorted-desc':
                                        state.orderBy == 'desc' &&
                                        state.filter == 'weight',
                                }"
                                @click="setFilter('weight')"
                            >
                                Gewicht
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--button"
                            ></div>
                        </div>
                    </div>
                    <div class="c-table__body">
                        <products-row
                            :product="product"
                            v-for="product in product.products.data"
                            :key="product.id"
                            :showcategory="Boolean(product.category)"
                            :is-new-category="currently(product.category, product.slug)"
                            :show-category-products="state.shownCategories"
                        ></products-row>
                    </div>
                </div>
                <pagination
                    :items="product.products"
                    v-show="!state.search"
                    :class="{ 'is-hidden': state.inCategory }"
                    :order="state.orderFilter"
                />
            </div>
        </div>
    </article>
</template>

<script setup>
import SvgPlus from "@/svg/plus.svg";
import SvgSearch from "@/svg/search.svg";
import SvgSort from "@/svg/sort.svg";

import { useProductStore } from "@/store/product";
import Swal from "sweetalert2";
import { inject, onUpdated, onMounted, onUnmounted, reactive } from "vue";

const product = useProductStore();
const Modals = inject("Modals");

const state = reactive({
    url: "/api/products?q",
    orderBy: "asc",
    filter: "name",
    orderFilter: "",
    search: null,
    debounce: 0,
    inCategory: false,
    currentCategories: [],
    shownCategories: [],
});

onUpdated(() => {
    Eventing.fire("stickyElement");
});

onMounted(() => {
    Eventing.fire("stickyElement");
    Eventing.listen("paginate", (url) => {
        state.currentCategories = [];
        state.shownCategories = [];
        state.url = url;
        product.fill(url);
    });
    Eventing.listen("deletingProduct", (item) => {
        deleteProduct(item);
    });
    Eventing.listen("deletingProductCategory", (item) => {
        deleteProductCategory(item);
    });

    Eventing.listen("showModal", (modal, value) => {
        Modals.show(modal, value);
    });
    
    product.fill();

    let category = window.location.href.split("?category=")[1];
    if (category) {
        product.fill("/api/products?category=" + category);
        state.inCategory = true;
    }

    Eventing.listen("searchFor", (value) => {
        state.search = value;
        product.search(state.search);
    });

    Eventing.fire("addOverflowArrows");
});

function deleteProduct(item) {
    setTimeout(() => {
        Modals.close();
    }, 100);
    Swal.fire({
        text: 'Zeker weten dat je "' + item.name + '" wilt verwijderen?',
        confirmButtonText: "Wissen",
        showCloseButton: true,
        icon: "warning",
    }).then((result) => {
        if (result.isConfirmed) {
            product.destroy(item.slug);
        }
    });
}

function currently(item, product_id) {
    if (item && item.slug) {
        if (state.currentCategories.indexOf(item.slug) !== -1) {
        } else {
            state.shownCategories.push(product_id);
        }

        if (state.currentCategories.includes(item.slug)) {
            return false;
        } else {
            state.currentCategories.push(item.slug);
            return true;
        }
    }
    return false;
}

function deleteProductCategory(item) {
    setTimeout(() => {
        Modals.close();
    }, 100);
    Swal.fire({
        text:
            'Zeker weten dat je "' +
            item.name +
            '" wilt verwijderen? De producten onder deze categorie verdwijnen dan ook!',
        confirmButtonText: "Wissen",
        showCloseButton: true,
        icon: "warning",
    }).then((result) => {
        if (result.isConfirmed) {
            product.destroyCategory(item.slug);
        }
    });
}

function debounceSearch(event) {
    clearTimeout(state.debounce);
    state.debounce = setTimeout(() => {
        state.search = event.target.value;
        if (state.search) {
            product.search(state.search);
        } else {
            product.fill();
        }
    }, 600);
}

function setFilter(filter) {
    let orderBy =
        state.orderBy == "asc"
            ? (state.orderBy = "desc")
            : (state.orderBy = "asc");
    state.filter = filter;
    state.orderFilter = "&order=" + state.filter + "&by=" + orderBy;

    product.fill(state.url + "&order=" + state.filter + "&by=" + orderBy);
}

function menu(menu) {
    Eventing.fire("toggleMenu", menu);
}
</script>
