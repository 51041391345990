<template>
    <section class="c-page-content__section e-module-style" v-if="state.order">
        <div class="e-section-heading">
            <div class="e-section-heading__left">
                <h2 class="e-section-heading__name">Afleveradressen</h2>
            </div>
            <!-- <div class="e-section-heading__right">
                <label class="e-form__checkbox-wrap">
                    <input
                        class="e-form__checkbox"
                        type="checkbox"
                        value="true"
                    />
                    <span class="e-form__checkbox-label"></span>
                    <span class="e-form__checkbox-text"
                        >Bevat buitenlandse adressen</span
                    >
                </label>
            </div> -->
        </div>

        <!-- IF NOT Verschilt per samenstelling -->
        <section v-if="!state.order.has_varying_deliveries">
            <section v-for="(box, index) in state.order.boxes" :key="box.id">
                <address-table
                    :box="box"
                    :order="state.order"
                    v-if="index == 0 && props.auth == 'User'"
                    :auth="props.auth"
                ></address-table>

                <address-table-client
                    :box="box"
                    :order="state.order"
                    v-if="index == 0 && props.auth == 'Contact'"
                    :auth="props.auth"
                ></address-table-client>
            </section>
        </section>

        <!-- IF Verschilt per samenstelling -->
        <div
            class="c-page-content__sub-sections"
            v-if="state.order.has_varying_deliveries"
        >
            <div
                class="c-page-content__sub-section"
                v-for="box in state.order.boxes"
                :key="box.id"
            >
                <address-table
                    :box="box"
                    :order="state.order"
                    :auth="props.auth"
                    v-if="props.auth == 'User'"
                ></address-table>
                <address-table-client
                    :box="box"
                    :order="state.order"
                    :auth="props.auth"
                    v-if="props.auth == 'Contact'"
                ></address-table-client>
            </div>
        </div>
    </section>
</template>

<script setup>
import { onMounted, reactive } from "vue";
// if (props.auth == "User") {
// }

const props = defineProps({
    order: Object,
    auth: { type: String, required: false, default: "User" },
});

const state = reactive({
    order: {
        hash: null,
        has_varying_deliveries: false,
        contact: {
            address: {},
        },
        followers: {},
        boxes: null,
    },
});

onMounted(() => {
    state.order = props.order;
});
</script>
