<template>
    <article>

        <div 
            class="c-client-toolbar"
        >
            <div class="c-client-toolbar__inner">
                <router-link
                    class="e-button e-button--grey-dark e-button--full-width"
                    :to="{ name: 'client.delivery' }"
                    @click="scrollToPageContent"
                >
                   Bestanden toegevoegd? Ga naar <span>stap 3</span> en vul de bezorging in.
                   <div class="e-button__arrow-right"></div>
                </router-link>
            </div>
        </div>

        <div class="c-client-header">
            <div class="c-client-header__content">
                <div class="c-client-header__container">
                    <div class="c-client-header__text">
                        <h1 class="c-client-header__title">
                            Bestanden #{{ state.order.reference }}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-page-content">
            <div
                class="c-page-content__container c-page-content__container--spacing"
            >
                <div class="c-page-content__section">
                    <div class="e-section-heading">
                        <div class="e-section-heading__left">
                            <h2 class="e-section-heading__name">
                                Aan te leveren bestanden
                                <span
                                    >{{ state.order.checks.done }}/{{
                                        state.order.checks.total
                                    }}</span
                                >
                            </h2>
                        </div>
                    </div>

                    <div class="c-files">
                        <section class="c-page-content__section e-module-style">
                            <div
                                class="c-files__sections"
                                v-for="box in attachment.attachments.boxes"
                                :key="box.id"
                                v-show="checkIfHasFiles(box)"
                            >
                                <h2 class="c-files__file-sub-section-title">
                                    <SvgGift /> {{ box.name }}
                                </h2>

                                <div
                                    class="c-files__section"
                                    v-for="basket in box.baskets"
                                    :key="basket.id + box.id"
                                    :class="{
                                        'c-files__section--files-missing':
                                            checkIfCompleted(
                                                basket.attachments
                                            ),
                                    }"
                                    v-show="basket.attachments.length > 0"
                                >
                                    <div
                                        class="e-section-heading e-section-heading--flex-start e-section-heading--label-style"
                                    >
                                        <div class="e-section-heading__left">
                                            <div
                                                class="e-section-heading__icon"
                                            >
                                                <SvgCross
                                                    v-if="
                                                        checkIfCompleted(
                                                            basket.attachments
                                                        )
                                                    "
                                                />
                                                <SvgCheck
                                                    v-if="
                                                        !checkIfCompleted(
                                                            basket.attachments
                                                        )
                                                    "
                                                />
                                            </div>
                                            <p class="e-section-heading__name">
                                                {{ basket.product.name }}
                                            </p>
                                        </div>
                                        <div class="e-section-heading__right">
                                            <!-- <label
                                                class="e-form__checkbox-wrap"
                                            >
                                                <input
                                                    class="e-form__checkbox"
                                                    type="checkbox"
                                                />
                                                <span
                                                    class="e-form__checkbox-label"
                                                ></span>
                                                <span
                                                    class="e-form__checkbox-text"
                                                    >Ik wil hier geen gebruik
                                                    van maken</span
                                                >
                                            </label> -->
                                        </div>
                                    </div>
                                    <div class="c-files__files">
                                        <section
                                            v-for="attachment in basket.attachments"
                                            :key="attachment.id"
                                        >
                                            <div
                                                class="c-files__file"
                                                v-if="attachment.completed_at"
                                            >
                                                <p
                                                    class="c-files__file-name"
                                                    v-if="
                                                        !attachment.path &&
                                                        !attachment.rejected_at
                                                    "
                                                >
                                                    Gemarkeerd als aangeleverd
                                                    door
                                                    {{
                                                        attachment.completed_by
                                                    }}
                                                    op
                                                    {{
                                                        attachment.completed_at
                                                    }}
                                                </p>

                                                <p
                                                    class="c-files__file-name"
                                                    style="color: tomato"
                                                    v-if="
                                                        !attachment.path &&
                                                        attachment.rejected_at
                                                    "
                                                >
                                                    Gemarkeerd als afgewezen
                                                    door
                                                    {{
                                                        attachment.completed_by
                                                    }}
                                                    op
                                                    {{ attachment.rejected_at }}
                                                </p>

                                                <p
                                                    class="c-files__file-name"
                                                    v-if="attachment.path"
                                                >
                                                    {{ attachment.name }}
                                                    <span>{{
                                                        attachment.filename
                                                    }}</span>
                                                    <span
                                                        class="c-files__file-date"
                                                    >
                                                        Toegevoegd door
                                                        {{
                                                            attachment.completed_by
                                                        }}
                                                        op
                                                        {{
                                                            attachment.completed_at
                                                        }}
                                                    </span>
                                                </p>
                                                <div
                                                    class="c-files__file-right"
                                                    v-if="attachment.path"
                                                >
                                                    <a
                                                        class="e-button e-button--small"
                                                        @click="
                                                            downloadAttachment(
                                                                attachment
                                                            )
                                                        "
                                                        download
                                                        >Download</a
                                                    >
                                                    <a
                                                        class="c-files__file-delete"
                                                        @click="
                                                            resetAttachment(
                                                                attachment
                                                            )
                                                        "
                                                    >
                                                        <SvgDelete />
                                                    </a>
                                                </div>
                                            </div>

                                            <div
                                                class="c-files__file-upload"
                                                v-if="!attachment.completed_at"
                                            >
                                                <client-file-uploader
                                                    :attachment="attachment"
                                                    :box="box.hash"
                                                    :order="state.order.hash"
                                                />

                                                <div
                                                    class="c-files__file-upload-mark"
                                                >
                                                    <label
                                                        class="e-form__checkbox-wrap"
                                                        @click="
                                                            markAsRejected(
                                                                attachment,
                                                                basket.product
                                                                    .name
                                                            )
                                                        "
                                                    >
                                                        <input
                                                            class="e-form__checkbox"
                                                            type="checkbox"
                                                        />
                                                        <span
                                                            class="e-form__checkbox-label"
                                                        ></span>
                                                        <span
                                                            class="e-form__checkbox-text"
                                                            >Ik wil hier geen
                                                            gebruik van
                                                            maken</span
                                                        >
                                                    </label>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section class="c-page-content__section e-module-style">
                            <h2
                                class="c-files__file-sub-section-title"
                                v-if="
                                    attachment.attachments &&
                                    attachment.attachments.additions &&
                                    attachment.attachments.additions.length > 0
                                "
                            >
                                Extra opties
                            </h2>

                            <div
                                class="c-files__sections"
                                v-for="addition in attachment.attachments
                                    .additions"
                                :key="addition.id"
                            >
                                <div
                                    class="c-files__section"
                                    :class="{
                                        'c-files__section--files-missing':
                                            checkIfCompleted(
                                                addition.attachments
                                            ),
                                    }"
                                    v-show="addition.attachments.length > 0"
                                >
                                    <div
                                        class="e-section-heading e-section-heading--label-style"
                                    >
                                        <div class="e-section-heading__left">
                                            <div
                                                class="e-section-heading__icon"
                                            >
                                                <SvgCross
                                                    v-if="
                                                        checkIfCompleted(
                                                            addition.attachments
                                                        )
                                                    "
                                                />
                                                <SvgCheck
                                                    v-if="
                                                        !checkIfCompleted(
                                                            addition.attachments
                                                        )
                                                    "
                                                />
                                            </div>
                                            <p class="e-section-heading__name">
                                                {{ addition.name }}
                                            </p>
                                        </div>
                                        <div
                                            class="e-section-heading__right"
                                        ></div>
                                    </div>
                                    <div class="c-files__files">
                                        <section
                                            v-for="attachment in addition.attachments"
                                            :key="attachment.id"
                                        >
                                            <div
                                                class="c-files__file"
                                                v-if="attachment.completed_at"
                                            >
                                                <p
                                                    class="c-files__file-name"
                                                    v-if="!attachment.path"
                                                >
                                                    Gemarkeerd als aangeleverd
                                                    door
                                                    {{
                                                        attachment.completed_by
                                                    }}
                                                    op
                                                    {{
                                                        attachment.completed_at
                                                    }}
                                                </p>

                                                <p
                                                    class="c-files__file-name"
                                                    v-if="attachment.path"
                                                >
                                                    {{ attachment.name }}
                                                    <span>{{
                                                        attachment.filename
                                                    }}</span>
                                                    <span
                                                        class="c-files__file-date"
                                                    >
                                                        Toegevoegd door
                                                        {{
                                                            attachment.completed_by
                                                        }}
                                                        op
                                                        {{
                                                            attachment.completed_at
                                                        }}
                                                    </span>
                                                </p>
                                                <div
                                                    class="c-files__file-right"
                                                    v-if="attachment.path"
                                                >
                                                    <a
                                                        class="e-button e-button--small"
                                                        @click="
                                                            downloadAttachment(
                                                                attachment
                                                            )
                                                        "
                                                        download
                                                        >Download</a
                                                    >
                                                    <a
                                                        class="c-files__file-delete"
                                                        @click="
                                                            resetAttachment(
                                                                attachment
                                                            )
                                                        "
                                                    >
                                                        <SvgDelete />
                                                    </a>
                                                </div>
                                            </div>

                                            <div
                                                class="c-files__file-upload"
                                                v-if="!attachment.completed_at"
                                            >
                                                <client-file-uploader
                                                    :attachment="attachment"
                                                    :addition="addition.id"
                                                    :order="state.order.hash"
                                                />
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <!-- <section style="display: none">
                            <div class="c-files__sections">
                                <div
                                    onclick="alert('TODO')"
                                    class="c-files__section c-files__section--files-missing"
                                >
                                    <div
                                        class="e-section-heading e-section-heading--label-style"
                                    >
                                        <div class="e-section-heading__left">
                                            <div
                                                class="e-section-heading__icon"
                                            >
                                                <SvgCross />
                                            </div>
                                            <p class="e-section-heading__name">
                                                Afleveradressen
                                            </p>
                                        </div>
                                        <div
                                            class="e-section-heading__right"
                                        ></div>
                                    </div>
                                    <div class="e-note">
                                        <SvgInfo />
                                        <div class="e-note__text">
                                            <p>
                                                Upload een Excel-bestand met alle
                                                afleveradressen. <b>Let op:</b>
                                                <a href=""
                                                    >download het voorbeeld CSV
                                                    bestand</a
                                                >
                                                of gebruik de volgende
                                                structuur: Naam | Postcode |
                                                Adres | Huisnummer.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="c-files__files">
                                        <div class="c-files__file-sub-section">
                                            <label
                                                class="e-form__checkbox-wrap"
                                            >
                                                <input
                                                    class="e-form__checkbox"
                                                    type="checkbox"
                                                />
                                                <span
                                                    class="e-form__checkbox-label"
                                                ></span>
                                                <span
                                                    class="e-form__checkbox-text"
                                                    >Alle samenstellingen hebben
                                                    dezelfde
                                                    afleveradressen</span
                                                >
                                            </label>
                                        </div>
                                        <div class="c-files__file-sub-section">
                                            <h2
                                                class="c-files__file-sub-section-title"
                                            >
                                                <SvgGift /> Samenstelling 1
                                            </h2>
                                            <div class="c-files__file-upload">
                                                <div
                                                    class="e-form__upload-area"
                                                >
                                                    <input
                                                        class="e-form__upload-area-input"
                                                        type="file"
                                                        name="incoming-pdf"
                                                        id="incoming-pdf"
                                                        required="required"
                                                        multiple="multiple"
                                                    />
                                                    <div
                                                        class="e-form__upload-area-text"
                                                    >
                                                        <div
                                                            class="e-form__upload-area-text-uploading"
                                                        >
                                                            Uploading...
                                                        </div>
                                                        <div
                                                            class="e-form__upload-area-text-default"
                                                        >
                                                            Selecteer of sleep
                                                            bestanden om te
                                                            uploaden
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="c-files__file-sub-section">
                                            <h2
                                                class="c-files__file-sub-section-title"
                                            >
                                                <SvgGift /> Samenstelling 2
                                            </h2>
                                            <div class="c-files__file-upload">
                                                <div
                                                    class="e-form__upload-area"
                                                >
                                                    <input
                                                        class="e-form__upload-area-input"
                                                        type="file"
                                                        name="incoming-pdf"
                                                        id="incoming-pdf"
                                                        required="required"
                                                        multiple="multiple"
                                                    />
                                                    <div
                                                        class="e-form__upload-area-text"
                                                    >
                                                        <div
                                                            class="e-form__upload-area-text-uploading"
                                                        >
                                                            Uploading...
                                                        </div>
                                                        <div
                                                            class="e-form__upload-area-text-default"
                                                        >
                                                            Selecteer of sleep
                                                            bestanden om te
                                                            uploaden
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section> -->
                    </div>
                </div>
            </div>
        </div>
    </article>
</template>

<script setup>
import SvgCheck from "@/svg/check.svg";
import SvgCross from "@/svg/cross.svg";
import SvgDelete from "@/svg/delete.svg";
import SvgGift from "@/svg/gift.svg";

import { useAuthClientStore } from "@/store/authClient";
import { useAttachmentStore } from "@/store/client/attachment";
import { useOrderStore } from "@/store/client/order";
import Swal from "sweetalert2";
import "v-calendar/dist/style.css";
import { inject, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";

const Modals = inject("Modals");
const order = useOrderStore();

const user = useAuthClientStore();

const attachment = useAttachmentStore();

const route = useRoute();
order.show(user.order());

attachment.fill(user.order());

const state = reactive({
    order: {
        contact: {
            address: {},
        },
        followers: {},
        checks: {
            done: 0,
            total: 0,
            todo: 0,
            perc: 0,
        },
    },
});
onMounted(() => {
    Eventing.listen("fillOrder", (data) => {
        state.order = data.data;
    });

    Eventing.listen("reloadOrder", () => {
        setTimeout(() => {
            order.show(user.order());
            attachment.fill(user.order());
        }, 1000);
    });
});

function checkIfCompleted(attachments) {
    try {
        if (attachments.length <= 0) {
            return false;
        }
        const count = attachments.filter((obj) => {
            if (obj.completed_at) {
                return true;
            }
            return false;
        }).length;
        if (attachments.length != count) {
            return true;
        }
    } catch (error) {
        console.error("checkIfCompleted", error);
    }
    return false;
}

function checkIfHasFiles(box) {
    let hasFilesInBox = false;
    try {
        box.baskets.forEach((basket) => {
            if (basket.attachments.length > 0) {
                hasFilesInBox = true;
            }
        });
    } catch (error) {}
    return hasFilesInBox;
}

function downloadAttachment(_attachment) {
    attachment.show(state.order.hash, _attachment.id);
}

function markAsRejected(item, product) {
    setTimeout(() => {
        Modals.close();
    }, 100);
    Swal.fire({
        text:
            'Zeker weten dat je geen gebruikt wilt maken van "' +
            item.name +
            '" voor "' +
            product +
            '"?',
        confirmButtonText: "Ja",
        showCloseButton: true,
        icon: "warning",
    }).then((result) => {
        if (result.isConfirmed) {
            attachment.rejected(state.order.hash, item.id);
        }
    });
}

function resetAttachment(item) {
    setTimeout(() => {
        Modals.close();
    }, 100);
    Swal.fire({
        text:
            'Zeker weten dat je "' +
            item.name +
            '" wilt resetten zodat er een nieuw bestand kan worden geüpload?',
        confirmButtonText: "Reset",
        showCloseButton: true,
        icon: "warning",
    }).then((result) => {
        if (result.isConfirmed) {
            attachment.remove(state.order.hash, item.id);
        }
    });
}
</script>
<style scoped>
.c-files__file-delete {
    display: none;
}
</style>
