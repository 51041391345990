<template>
    <section>
        <modal-transport-on-transport
            :path="state.path"
            v-if="state.modal == 'transport-on-transport'"
        />
        <modal-transport-received
            :path="state.path"
            v-if="state.modal == 'transport-received'"
        />
    </section>
</template>
<script setup>
import { onMounted, reactive, ref, inject } from "vue";
const Modals = inject("Modals");

const state = reactive({ modal: null, path: null });

onMounted(() => {
    Eventing.listen("modal", (screen, value) => openModal(screen, value));
    Eventing.listen("closeModal", () => closeModal());
});

function openModal(screen, path) {
    state.modal = screen;
    if (typeof path !== "undefined") {
        state.path = path;
    }
    Modals.open();
}

function closeModal() {
    state.modal = null;
    Modals.closing();
}
</script>
