<template>
    <div
        v-if="showcategory"
        v-show="isNewCategory"
        class="c-table-item c-table-item--is-category"
    >
        <div class="c-table-item__col c-table-item__col--category-name">
            {{ product.category.name }}
        </div>
        <div class="c-table-item__col c-table-item__col--article-nr"></div>
        <div class="c-table-item__col c-table-item__col--stock"></div>
        <div class="c-table-item__col c-table-item__col--amount"></div>
        <div class="c-table-item__col c-table-item__col--button-big"></div>
    </div>

    <product-adding-row
        v-if="showcategory && state.ready"
        :product="product"
        :start-amount="state.form.amount"
        :box="box"
    >
    </product-adding-row>

    <div
        class="c-table-item"
        v-if="!showcategory && state.ready"
        :class="{
            'c-table-item--is-inside-category': product.category,
        }"
    >
        <div class="c-table-item__col c-table-item__col--product-name">
            {{ product.name }}
        </div>
        <div class="c-table-item__col c-table-item__col--article-nr">
            {{ product.article_nr }}
        </div>
        <div class="c-table-item__col c-table-item__col--stock">
            <span v-if="product.has_stock">{{ product.stock }}</span>
            <span v-if="!product.has_stock">—</span>
        </div>
        <div class="c-table-item__col c-table-item__col--amount">
            <div
                class="e-form__field-wrap"
                :class="{
                    'e-form__field-wrap--invalid':
                        product.stock < state.form.amount && product.has_stock,
                }"
            >
                <input
                    class="e-form__input e-form__input--small e-form__input--amount"
                    type="number"
                    min="0"
                    steps="1"
                    v-model="state.form.amount"
                    not-v-max="product.stock"
                    v-if="!product.just_one"
                />
                <input
                    class="e-form__input e-form__input--small e-form__input--amount"
                    type="hidden"
                    v-model="state.form.amount"
                    v-if="product.just_one"
                />
            </div>
        </div>
        <div
            class="c-table-item__col c-table-item__col--button-big"
            @click="addProduct(product)"
        >
            <button :disabled="state.busy" class="e-button e-button--small">
                Toevoegen
            </button>
        </div>
    </div>
</template>
<script setup>
import { onMounted, reactive } from "vue";

import { useBoxStore } from "@/store/box";
const boxing = useBoxStore();

const props = defineProps({
    product: Object,
    showcategory: Boolean,
    isNewCategory: Boolean,
    box: String,
    startAmount: {
        type: Number,
        default: 0,
    },
});

const state = reactive({
    form: {
        amount: 0,
        product_id: null,
    },
    busy: false,
    ready: false,
});

onMounted(() => {
    Eventing.listen("productAddedToBasket", () => {
        setTimeout(() => {
            state.busy = false;
        }, 500);
    });

    setTimeout(() => {
        state.ready = true;
    }, 2000);

    startAmountSetter();
    // Eventing.listen("setStartAmount", (amount) => {
    //     state.ready = true;
    //     state.form.amount = amount;
    //     if (props.product.just_one) {
    //         state.form.amount = 1;
    //     }
    // });

    state.form.amount = props.startAmount;
    if (props.product.just_one) {
        state.form.amount = 1;
    }
    state.ready = true;
});

async function addProduct(item) {
    if (state.busy == false) {
        state.busy = true;
        if (state.form.amount > 0) {
            state.form.product_id = item.id;
            // Eventing.fire("addProductToBox", props.box, state.form);

            setLocalStorageWithExpiry(
                "startAmount" + props.box,
                state.form.amount,
                5
            );

            try {
                await boxing.storeBasket(props.box, state.form);
            } catch (error) {
                Eventing.fire("addProductToBox", props.box, state.form);
            }
        }
    }
}

async function startAmountSetter() {
    const waarde = getLocalStorageWithExpiry("startAmount" + props.box);
    if (!waarde) {
        try {
            let startAmount = await boxing.getStartAmountBasket(props.box);
            if (startAmount) {
                if (startAmount == 0) {
                    startAmount = 1;
                }

                setLocalStorageWithExpiry(
                    "startAmount" + props.box,
                    startAmount,
                    5
                );
            }
        } catch (error) {}
    }
}

function setLocalStorageWithExpiry(key, value, minutes) {
    const now = new Date();
    const expiryTime = now.getTime() + minutes * 60 * 1000; // minuten omzetten naar milliseconden
    const item = {
        value: value,
        expiry: expiryTime,
    };
    localStorage.setItem(key, JSON.stringify(item));
}

function getLocalStorageWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // Controleer of de tijd verstreken is
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}
</script>
