<template>
    <app-dashboards-traffic-navigation />

    <div class="c-page-content">
        <div
            class="c-page-content__container c-page-content__container--spacing-small c-page-content__container--no-pb e-load-items-animation"
        >
            <div class="c-widgets" v-if="state.graphs">
                <div
                    class="l-grid l-grid/3 l-grid/t/2 l-grid/m/1 mb-20 e-load-items-animation"
                >
                    <!--  voorraad aantallen -->
                    <router-link
                        class="e-module-style e-module-style--green e-module-style--clickable e-module-style--small-spacing e-module-style--always-active"
                        :to="{ name: 'app.dashboards.traffic.stock' }"
                        v-if="state.stock"
                    >
                        <div class="e-widget-heading">
                            <div class="e-widget-heading__left">
                                <h2 class="e-widget-heading__name">Voorraad</h2>
                            </div>
                        </div>

                        <div class="e-chart e-chart--glow e-chart--donut">
                            <chart-donut
                                :series="state.stock.series"
                                :labels="state.stock.labels"
                                :colors="state.stock.colors"
                                :legend="false"
                                module_theme="green"
                            />
                        </div>
                    </router-link>

                    <div
                        class="c-counter e-module-style e-module-style--green e-module-style--no-spacing e-module-style--always-active l-grid__col/t/1"
                        v-for="(option, value) in state.invoice_statusses"
                        v-bind:value="option"
                        :key="value"
                        :class="{ 'is-hidden': value == 'SEND_TO_EXACT' }"
                        @click="navigateToFinance(option)"
                    >
                        <div class="c-counter__inner-wrap">
                            <div
                                class="c-counter__row c-counter__row--clickable e-tooltip"
                                data-tooltip="Filter bestellingen"
                                @click="
                                    state.source = '';
                                    state.invoice_status = option;
                                "
                            >
                                <p
                                    class="c-counter__title e-widget-heading__name"
                                >
                                    {{ option }}
                                </p>
                            </div>

                            <div class="c-counter__row">
                                <div
                                    class="c-counter__col c-counter__col--clickable e-tooltip e-tooltip--right"
                                    data-tooltip="Filter bestellingen"
                                    @click="
                                        state.source = 'Feelingz';
                                        state.invoice_status = option;
                                    "
                                >
                                    <p class="c-counter__source">Feelingz</p>
                                    <p class="c-counter__number">
                                        <count-up
                                            :end-val="
                                                countOutput(
                                                    'Feelingz',
                                                    option,
                                                    'count'
                                                )
                                            "
                                            :options="{ separator: '.' }"
                                        ></count-up>
                                    </p>
                                    <p class="c-counter__number-small">
                                        €
                                        <count-up
                                            :end-val="
                                                countOutput(
                                                    'Feelingz',
                                                    option,
                                                    'total'
                                                )
                                            "
                                            :options="{ separator: '.' }"
                                        ></count-up>
                                    </p>
                                </div>
                                <div
                                    class="c-counter__col c-counter__col--clickable e-tooltip e-tooltip--left"
                                    data-tooltip="Filter bestellingen"
                                    @click="
                                        state.source = 'KCK';
                                        state.invoice_status = option;
                                    "
                                >
                                    <p class="c-counter__source">KCK</p>
                                    <p class="c-counter__number">
                                        <count-up
                                            :end-val="
                                                countOutput(
                                                    'KCK',
                                                    option,
                                                    'count'
                                                )
                                            "
                                            :options="{ separator: '.' }"
                                        ></count-up>
                                    </p>
                                    <p class="c-counter__number-small">
                                        €
                                        <count-up
                                            :end-val="
                                                countOutput(
                                                    'KCK',
                                                    option,
                                                    'total'
                                                )
                                            "
                                            :options="{ separator: '.' }"
                                        ></count-up>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="c-counter__chart e-chart">
                            <chart-area-simple
                                :series="state.graphs[option]"
                                :labels="state.graphLabels"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="c-page-filter e-form--transparent">
                <div class="c-page-filter__inner-wrap">
                    <div class="c-page-filter__button js-toggle-page-filter">
                        <SvgFilter />
                    </div>
                    <div class="c-page-filter__options">
                        <div class="e-form__field-wrap">
                            <div class="e-form__label">
                                <p>Leverdatum</p>
                            </div>
                            <input
                                type="date"
                                v-show="state.delivery_date"
                                v-model="state.delivery_date"
                                @change="loadingResults()"
                                class="e-form__input e-form__input--small"
                            />
                            <span
                                class="e-form__input e-form__input--small"
                                v-show="!state.delivery_date"
                                @click="
                                    state.delivery_date = new Date()
                                        .toJSON()
                                        .slice(0, 10)
                                "
                                >Selecteer datum</span
                            >

                            <span
                                class="e-form__suffix"
                                v-show="state.delivery_date"
                                @click="
                                    state.delivery_date = '';
                                    loadingResults();
                                "
                                ><SvgCross />Reset</span
                            >
                        </div>
                        <div class="e-form__field-wrap">
                            <div class="e-form__label">
                                <p>Aanlever deadline</p>
                            </div>
                            <input
                                type="date"
                                v-show="state.deadline_date"
                                v-model="state.deadline_date"
                                @change="loadingResults()"
                                class="e-form__input e-form__input--small"
                            />
                            <span
                                class="e-form__input e-form__input--small"
                                v-show="!state.deadline_date"
                                @click="
                                    state.deadline_date = new Date()
                                        .toJSON()
                                        .slice(0, 10)
                                "
                                >Selecteer datum</span
                            >

                            <span
                                class="e-form__suffix"
                                v-show="state.deadline_date"
                                @click="
                                    state.deadline_date = '';
                                    loadingResults();
                                "
                            >
                                <SvgCross />
                                Reset
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="c-status-columns" v-if="state.results">
                <div class="c-status-columns__columns">
                    <div
                        class="c-status-columns__column"
                        v-for="(result, value) in state.results"
                        :key="value"
                    >
                        <div class="c-status-columns__column-heading">
                            <h2>
                                {{ result.status }}
                                <span
                                    class="c-status-columns__column-count e-label e-label--medium e-label--green"
                                    >{{ result.items.length }}</span
                                >
                            </h2>
                        </div>

                        <div class="c-status-columns__items">
                            <draggable
                                class="list-group drop-area"
                                :list="result.items"
                                group="products"
                                :sort="false"
                                @end="saveSortings"
                                itemKey="name"
                                filter=".c-order-tile__icon--button, .e-button, .date-picker, input"
                                preventOnFilter="false"
                            >
                                <template #item="{ element, index }">
                                    <div class="list-group-item">
                                        <order-tile
                                            @click="toggleOrder(element)"
                                            :index="index"
                                            :test="element.id.toString()"
                                            :item="element"
                                            :order="element"
                                            :status="result.status"
                                        ></order-tile>
                                    </div>
                                </template>
                            </draggable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-dashboards-side-overlay-traffic-order />
</template>

<script setup>
import SvgCross from "@/svg/cross.svg";
import SvgFilter from "@/svg/filter.svg";
import { inject, onMounted, reactive } from "vue";
import CountUp from "vue-countup-v3";
import { useRouter } from "vue-router";
// import CountUp from "vue-countup-v3";
import { onUpdated } from "vue";

import { useDashboardFinanceStore } from "@/store/dashboard/finance";
import { useDashboardTrafficStore } from "@/store/dashboard/traffic";
import draggable from "vuedraggable";
const Modals = inject("Modals");

function log(evt) {
    window.console.log(evt);
}

const traffic = useDashboardTrafficStore();
const finance = useDashboardFinanceStore();

onUpdated(() => {
    Eventing.fire("stickyElement");
    Eventing.fire("toggleSideOverlay");
    Eventing.fire("addOverflowArrows");
});

const router = useRouter();

const state = reactive({
    url: "/api/dashboards/traffic/orders?q",
    orderBy: "desc",
    results: null,

    delivery_date: "",
    deadline_date: "",

    order: null,
    source: "",
    invoice_status: "",

    stats: {},
    graphs: null,
    graphLabels: [],
    stock: [],
    invoice_statusses: {
        NOT_INVOICED: "Niet gefactureerd",
        // "READY_FOR_INVOICING": "Staat klaar voor facturatie",
        // "SEND_TO_EXACT": "Factuur naar Exact sturen",
        PARTLY_INVOICED: "Deels gefactureerd",
        // "INVOICED": "Gefactureerd",
        // "INVOICED_IDEAL": "Gefactureerd via iDEAL/website"
    },
});

onMounted(() => {
    Eventing.listen("hideGraphs", () => {
        state.graphs = false;
    });

    Eventing.listen("showGraphs", () => {
        loadStats();
        document.body.classList.toggle("body--widgets-are-closed");
    });

    Eventing.listen("reloadItems", () => {
        if (document.body.classList.contains("body--side-overlay-is-open")) {
            document.body.classList.remove("body--side-overlay-is-open");
        }
        loadingResults();
        loadStats();
    });
});

async function loadingResults() {
    Eventing.fire("clearSideOverlay");
    state.url = "/api/dashboards/traffic/orders?q";
    state.order = null;
    let results = await traffic.fill(
        state.url,
        state.delivery_date,
        state.deadline_date
    );
    if (results) {
        state.results = results;
    }
}
loadingResults();

async function saveSortings() {
    let data = await traffic.saveStatus({
        statusses: state.results,
    });
    if (data) {
        loadingResults();
    }
}

function navigateToFinance(status) {
    state.invoice_status = status;

    router.push({
        name: "app.dashboards.traffic.finance",
        query: { _invoice_status: state.invoice_status, _source: state.source },
    });
}

async function toggleOrder(order) {
    if (state.order && state.order.hash == order.hash) {
        state.order = null;

        if (document.body.classList.contains("body--side-overlay-is-open")) {
            document.body.classList.remove("body--side-overlay-is-open");
        }
    } else {
        Eventing.fire("clearSideOverlay");
        let result = await traffic.show(order.hash);
        if (result) {
            state.order = result;
            document.body.classList.add("body--side-overlay-is-open");
            Eventing.fire("fillSideOverlay", result);
        }
    }
}

function countOutput(source, _enum, value) {
    if (source == "Feelingz") {
        return state.stats.Feelingz[_enum][value];
    } else {
        return state.stats.KCK[_enum][value];
    }
}

async function loadStats() {
    let result = await finance.stats();
    if (result) {
        console.info("stats", result);
        state.stats = result.totals;
        state.graphs = result.stats;
        state.graphLabels = result.labels;

        console.warn("graphs", state.graphs);

        try {
            state.stock.series = result.stock;
            state.stock.labels = [
                "Kritiek (" + result.stock[0] + ")",
                "In aanlevering (" + result.stock[1] + ")",
                "Voldoende (" + result.stock[2] + ")",
                "Onvoldoende (" + result.stock[3] + ")",
            ];
            state.stock.colors = ["#f96060", "#e2e2e2", "#54cb8f", "#ff911d"];
        } catch (error) {
            console.error(error);
        }

        Eventing.fire("statsAreLoaded");
    }
}
loadStats();
</script>
