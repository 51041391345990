<template>
    <div class="c-page-content">
        <div
            class="c-page-content__container c-page-content__container--spacing-small e-load-items-animation"
        >
            <div class="c-widgets">
                <div
                    class="l-grid l-grid/5 l-grid/d/3 l-grid/t/2 l-grid/m/1 mb-20 l-grid--gap-small e-load-items-animation"
                    v-if="enums.options && state.graphs"
                >
                    <div
                        class="c-counter e-module-style e-module-style--green e-module-style--no-spacing e-module-style--always-active"
                        v-for="(option, value) in enums.options[
                            'order-invoice-status'
                        ]"
                        v-bind:value="option"
                        :key="value"
                        :class="{ 'is-hidden': value == 'SEND_TO_EXACT' }"
                        @click="loadingResults()"
                    >
                        <div class="c-counter__inner-wrap">
                            <div
                                class="c-counter__row c-counter__row--clickable e-tooltip"
                                data-tooltip="Filter bestellingen"
                                @click="
                                    state.source = '';
                                    state.invoice_status = option;
                                "
                            >
                                <p
                                    class="c-counter__title e-widget-heading__name"
                                >
                                    {{ option }}
                                </p>
                            </div>

                            <div class="c-counter__row">
                                <div
                                    class="c-counter__col c-counter__col--clickable e-tooltip e-tooltip--right"
                                    data-tooltip="Filter bestellingen"
                                    @click="
                                        state.source = 'Feelingz';
                                        state.invoice_status = option;
                                    "
                                >
                                    <p class="c-counter__source">Feelingz</p>
                                    <p class="c-counter__number">
                                        <count-up
                                            :end-val="
                                                countOutput(
                                                    'Feelingz',
                                                    option,
                                                    'count'
                                                )
                                            "
                                            :options="{ separator: '.' }"
                                        ></count-up>
                                    </p>
                                    <p class="c-counter__number-small">
                                        €
                                        <count-up
                                            :end-val="
                                                countOutput(
                                                    'Feelingz',
                                                    option,
                                                    'total'
                                                )
                                            "
                                            :options="{ separator: '.' }"
                                        ></count-up>
                                    </p>
                                </div>
                                <div
                                    class="c-counter__col c-counter__col--clickable e-tooltip e-tooltip--left"
                                    data-tooltip="Filter bestellingen"
                                    @click="
                                        state.source = 'KCK';
                                        state.invoice_status = option;
                                    "
                                >
                                    <p class="c-counter__source">KCK</p>
                                    <p class="c-counter__number">
                                        <count-up
                                            :end-val="
                                                countOutput(
                                                    'KCK',
                                                    option,
                                                    'count'
                                                )
                                            "
                                            :options="{ separator: '.' }"
                                        ></count-up>
                                    </p>
                                    <p class="c-counter__number-small">
                                        €
                                        <count-up
                                            :end-val="
                                                countOutput(
                                                    'KCK',
                                                    option,
                                                    'total'
                                                )
                                            "
                                            :options="{ separator: '.' }"
                                        ></count-up>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="c-counter__chart e-chart">
                            <chart-area-simple
                                :series="state.graphs[option]"
                                :labels="state.graphLabels"
                                :colors="dataChartCounter.colors"
                                :module_theme="dataChartCounter.module_theme"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="c-page-filter e-form--transparent js-sticky-element">
                <div class="c-page-filter__inner-wrap">
                    <div class="c-page-filter__button js-toggle-page-filter">
                        <SvgFilter />
                    </div>
                    <div class="c-page-filter__options">
                        <div class="e-form__field-wrap">
                            <div class="e-form__label">
                                <p>Bron</p>
                            </div>
                            <div class="e-form__select-wrap">
                                <select
                                    class="e-form__select e-form__select--small"
                                    placeholder="Bron"
                                    v-model="state.source"
                                    @change="loadingResults()"
                                >
                                    <option placeholder="Alles" value="">
                                        Alles
                                    </option>
                                    <option value="Feelingz">Feelingz</option>
                                    <option value="KCK">KCK</option>
                                </select>
                            </div>
                        </div>
                        <div class="e-form__field-wrap">
                            <div class="e-form__label">
                                <p>Facturatiestatus</p>
                            </div>
                            <div class="e-form__select-wrap">
                                <select
                                    class="e-form__select e-form__select--small"
                                    placeholder="Facturatiestatus"
                                    v-model="state.invoice_status"
                                    @change="loadingResults()"
                                >
                                    <option placeholder="Alles" value="">
                                        Alles
                                    </option>
                                    <option
                                        v-for="(option, value) in enums.options[
                                            'order-invoice-status'
                                        ]"
                                        v-bind:value="option"
                                        :key="value"
                                    >
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="c-table" v-if="!state.totals && state.results">
                <div class="c-table__body">
                    <div
                        class="c-table-item"
                        @click="toggleOrder(order)"
                        v-for="order in state.results.data"
                        :key="order.id"
                        :class="{
                            'c-table-item--active':
                                state.order && state.order.id == order.id,
                        }"
                    >
                        <div
                            class="e-label-speed e-tooltip"
                            data-tooltip="Spoed"
                            v-if="order.is_urgent"
                        >
                            <SvgSpeed />
                        </div>
                        <div class="c-table-item__col c-table-item__col--icon">
                            <div
                                class="c-table-item__icon c-table-item__icon--iconx14 e-tooltip"
                                :class="{
                                    'c-table-item__icon--grey-super-light':
                                        !order.is_maatwerk,
                                    'e-icon--maatwerk': order.is_maatwerk,
                                }"
                                data-tooltip="Maatwerk"
                            >
                                <SvgMaatwerk />
                            </div>
                        </div>
                        <div
                            class="c-table-item__col c-table-item__col--contact"
                        >
                            <h2 class="c-table-item__name" v-if="order.contact">
                                {{ order.contact.company }}
                            </h2>

                            <p v-if="!order.origin_reference">
                                <router-link
                                    :to="{
                                        name: 'app.order',
                                        params: { hash: order.hash },
                                    }"
                                    class="c-table-item__link"
                                    >{{ order.reference }}
                                </router-link>
                            </p>

                            <p v-if="order.origin_reference">
                                <router-link
                                    :to="{
                                        name: 'app.order',
                                        params: { hash: order.hash },
                                    }"
                                    class="c-table-item__link"
                                    >{{ order.reference }}
                                </router-link>

                                <span v-if="order.is_duplicate">
                                    &bull; Duplicatie van

                                    <router-link
                                        :to="{
                                            name: 'app.order.order',
                                            params: {
                                                hash: order.origin_hash,
                                            },
                                        }"
                                        class="c-table-item__link"
                                        >{{ order.origin_reference }}
                                    </router-link>
                                </span>
                                <span
                                    v-if="
                                        order.is_splitted &&
                                        order.origin_reference
                                    "
                                >
                                    &bull; Splitsing van

                                    <router-link
                                        :to="{
                                            name: 'app.order.order',
                                            params: {
                                                hash: order.origin_hash,
                                            },
                                        }"
                                        class="c-table-item__link"
                                        >{{ order.origin_reference }}
                                    </router-link>
                                </span>
                                <span
                                    v-if="
                                        order.is_splitted &&
                                        !order.origin_reference
                                    "
                                >
                                    &bull; Bevat splitsing(en)
                                </span>
                            </p>
                        </div>

                        <div
                            class="c-table-item__col c-table-item__col--status"
                        >
                            <div
                                classs="e-tooltip"
                                :data-tooltip="order.status.label"
                            >
                                <button-state
                                    :label="order.invoice_status"
                                    :buttonclass="'e-label--invoice'"
                                />
                            </div>
                        </div>
                        <div class="c-table-item__col c-table-item__col--files">
                            <div v-if="order.totals">
                                {{ order.totals }}
                            </div>
                        </div>
                        <div
                            class="c-table-item__col c-table-item__col--activity"
                        >
                            {{ order.updated_at }}
                        </div>

                        <div
                            class="c-table-item__col c-table-item__col--source"
                        >
                            {{ order.source }}
                        </div>
                    </div>
                </div>
            </div>
            <pagination v-if="state.results" :items="state.results" />
        </div>
    </div>
</template>

<script setup>
import { useDashboardFinanceStore } from "@/store/dashboard/finance";
import { useEnumStore } from "@/store/enum";
import SvgFilter from "@/svg/filter.svg";
import SvgMaatwerk from "@/svg/maatwerk.svg";
import SvgSpeed from "@/svg/speed.svg";
import { inject, onMounted, onUpdated, reactive } from "vue";
import CountUp from "vue-countup-v3";
import { useRoute } from "vue-router";

const Modals = inject("Modals");

const finance = useDashboardFinanceStore();

const enums = useEnumStore();

onUpdated(() => {
    Eventing.fire("stickyElement");
});

const state = reactive({
    url: "/api/dashboards/finance/orders?q",
    orderBy: "desc",
    results: null,

    source: "",
    invoice_status: "",

    stats: {},
    graphs: null,
    graphLabels: [],

    order: null,

    totals: null,
});

onMounted(() => {
    Eventing.listen("paginate", async (url) => {
        state.url = url;
        let results = await finance.fill(
            state.url,
            state.source,
            state.invoice_status
        );
        if (results) {
            state.results = results;
        }
    });

    Eventing.listen("hideGraphs", () => {
        state.graphs = false;
    });

    Eventing.listen("showGraphs", () => {
        loadStats();
        document.body.classList.toggle("body--widgets-are-closed");
    });

    Eventing.listen("reloadItems", () => {
        if (document.body.classList.contains("body--side-overlay-is-open")) {
            document.body.classList.remove("body--side-overlay-is-open");
        }
        loadingResults();
        loadStats();
    });
});

function checkForParameters() {
    const route = useRoute();
    let isHere = false;
    try {
        if (route.query._source) {
            state.source = route.query._source;
            isHere = true;
        }
    } catch (error) {}
    try {
        if (route.query._invoice_status) {
            state.invoice_status = route.query._invoice_status;
            isHere = true;
        }
    } catch (error) {}

    if (isHere) {
        setTimeout(() => {
            // loadingResults();
            // loadStats();
        }, 1000);
    }
}
checkForParameters();

async function loadEnums() {
    let enumlist = await enums.fill("order-invoice-status");
    if (enumlist) {
        Eventing.fire("invoiceStatusses", enumlist);
        loadStats();
    }
}
loadEnums();

async function loadStats() {
    let result = await finance.stats();
    if (result) {
        console.info("stats", result);
        state.stats = result.totals;
        state.graphs = result.stats;
        state.graphLabels = result.labels;

        console.warn("graphs", state.graphs);

        Eventing.fire("statsAreLoaded");
    }
}
function countOutput(source, _enum, value) {
    if (source == "Feelingz") {
        return state.stats.Feelingz[_enum][value];
    } else {
        return state.stats.KCK[_enum][value];
    }
}

async function loadingResults() {
    state.url = "/api/dashboards/finance/orders?q";
    let results = await finance.fill(
        state.url,
        state.source,
        state.invoice_status
    );
    if (results) {
        state.results = results;
    }
}
loadingResults();

async function toggleOrder(order) {
    if (state.order && state.order.hash == order.hash) {
        state.order = null;

        if (document.body.classList.contains("body--side-overlay-is-open")) {
            document.body.classList.remove("body--side-overlay-is-open");
        }
    } else {
        Eventing.fire("clearSideOverlay");
        let result = await finance.show(order.hash);
        if (result) {
            state.order = result;
            document.body.classList.add("body--side-overlay-is-open");
            Eventing.fire("fillSideOverlay", result);
        }
    }
}

const dataChartCounter = reactive({
    colors: ["#499175", "#5fb090"],
    module_theme: "green",
});
</script>
