<template>
    <article>
        <div
            class="e-modal e-modal--w600 e-modal--align-start"
            modal-id="manage-maatwerk-runs"
        >
            <div class="e-modal__wrap">
                <div class="e-modal__modal" v-if="state.runs">
                    <div
                        class="e-modal__close js-close-modal"
                        @click="Modals.close()"
                    >
                        <SvgCross @click="Modals.close()" />
                    </div>

                    <div class="e-modal__section">
                        <div class="e-modal__section-title">
                            <p
                                class="e-modal__desc e-modal__desc--small-spacing"
                            >
                                Beheer hier alle maatwerk Runs.
                            </p>
                        </div>
                    </div>

                    <section v-for="(item, index) in state.runs" :key="index">
                        <div
                            class="e-modal__section e-modal__section--bordered"
                        >
                            <div class="e-modal__section-title">
                                <h2
                                    class="e-modal__title"
                                    v-if="item.type == 'Magazijn'"
                                >
                                    Levering in <span>Magazijn</span>
                                </h2>
                                <h2
                                    class="e-modal__title"
                                    v-if="item.type == 'Drukkerij'"
                                >
                                    Levering aan <span>Drukkerij</span>
                                </h2>
                            </div>
                        </div>

                        <div class="e-modal__section">
                            <div class="e-form">
                                <ul class="e-form__input-list">
                                    <li
                                        class="e-form__input-list-item"
                                        v-for="run in item.items"
                                        :key="run.id"
                                    >
                                        <div
                                            class="e-form__input-list-item-field"
                                        >
                                            <input
                                                class="e-form__input"
                                                type="text"
                                                v-model="run.name"
                                            />
                                        </div>

                                        <div
                                            class="e-form__input-list-item-field"
                                        >
                                            <input
                                                class="e-form__input"
                                                type="date"
                                                v-model="run.date"
                                            />
                                        </div>
                                        <div
                                            class="e-form__input-list-item-button"
                                        >
                                            <button
                                                class="e-button e-button--grey-dark e-tooltip"
                                                data-tooltip="De run wordt verwijderd."
                                                v-if="
                                                    run.date &&
                                                    run.date ==
                                                        run.origin_date &&
                                                    run.name == run.origin_name
                                                "
                                                @click="destroyRun(run)"
                                            >
                                                Afgerond
                                            </button>
                                            <button
                                                class="e-button e-button--green e-tooltip"
                                                data-tooltip="De datum aanpassen."
                                                v-if="
                                                    (run.date &&
                                                        run.date !=
                                                            run.origin_date) ||
                                                    run.name != run.origin_name
                                                "
                                                @click="updateRun(run)"
                                            >
                                                Aanpassen
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                                <div class="e-form__field-wrap">
                                    <button
                                        class="e-button e-button--small e-button--full-width"
                                        @click="createRun(item.type)"
                                    >
                                        Voeg een run toe
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div
                        class="e-modal__section e-modal__section--bordered e-modal__section--center-text"
                    >
                        <button
                            class="e-button e-button--full-width e-button--green js-close-modal"
                            @click="Modals.close()"
                        >
                            Sluiten
                        </button>
                    </div>
                </div>
            </div>

            <div
                class="e-modal__bg js-close-modal"
                @click="Modals.close()"
            ></div>
        </div>
    </article>
</template>

<script setup>
import { useDashboardRunsStore } from "@/store/dashboard/runs";
import SvgCross from "@/svg/cross.svg";
import Swal from "sweetalert2";
import { inject, onMounted, reactive } from "vue";

const runs = useDashboardRunsStore();

const Modals = inject("Modals");

const state = reactive({
    form: {},
    runs: null,
});

async function loadRuns() {
    let fill = await runs.fill();
    if (fill) {
        state.runs = fill;
    }
}
loadRuns();

onMounted(() => {
    runs.errors.errors = { message: null, errors: [] };
});

async function updateRun(run) {
    let result = await runs.update(run.id, run);
    if (result) {
        loadRuns();
    }
}

async function createRun(runType) {
    const { value: date } = await Swal.fire({
        title: "Run voor " + runType,
        text: "Wanneer vindt de levering plaats?",
        input: "date",
        confirmButtonText: "Run toevoegen",
        didOpen: () => {
            const today = new Date().toISOString();
            Swal.getInput().min = today.split("T")[0];
        },
    });
    if (date) {
        let result = await runs.store({
            type: runType,
            date: date,
        });
        if (result) {
            loadRuns();
        }
    }
}

async function destroyRun(run) {
    Swal.fire({
        text: "Zeker weten dat je deze run wilt afronden en daarmee wissen?",
        confirmButtonText: "Afronden en wissen",
        showCloseButton: true,
        icon: "warning",
    }).then(async (result) => {
        if (result.isConfirmed) {
            let result = await runs.destroy(run.id);
            if (result) {
                loadRuns();
            }
        }
    });
}
</script>
