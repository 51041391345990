<template>
    <article>
        <div class="c-topbar">
            <div class="c-topbar__left">
                <div
                    class="c-topbar__left-button e-menu-button js-toggle-mobile-menu"
                    @click="menu('mobile')"
                >
                    <div class="e-menu-button__bars-wrap">
                        <div class="e-menu-button__bars"></div>
                    </div>
                </div>
                <div class="c-topbar__search">
                    <label class="c-topbar__search-label" for="table-search">
                        <SvgSearch />
                    </label>
                    <input
                        class="c-topbar__search-input"
                        id="table-search"
                        type="search"
                        @input="debounceSearch"
                        autocomplete="false"
                        spellcheck="false"
                        placeholder="Zoeken door bestellingen..."
                    />
                </div>
                <div
                    class="c-topbar__filter"
                    :class="{
                        'c-topbar__filter--is-active': state.active_filters,
                    }"
                >
                    <div
                        class="c-topbar__filter-button"
                        open-modal-id="filter-modal"
                        @click="Modals.show('order-filter')"
                    >
                        <SvgFilter />
                        Filter
                        <SvgCheck />
                    </div>
                    <div
                        v-if="state.active_filters"
                        class="c-topbar__filter-delete"
                        @click="resetFilters()"
                    >
                        Wissen
                    </div>
                </div>
            </div>
            <div
                class="c-topbar__right-button e-menu-button js-toggle-topbar-menu"
                @click="menu('topbar')"
            >
                <div class="e-menu-button__bars-wrap">
                    <div class="e-menu-button__bars"></div>
                </div>
            </div>
            <div class="c-topbar__right">
                <a
                    class="e-button e-button--iconx16 e-button--green e-button--white-hover"
                    @click="getPackingSlips()"
                    v-if="!state.search && state.active_filters"
                >
                    <SvgReceipt />
                    Pakbonnen</a
                >
                <a
                    class="e-button e-button--green e-button--white-hover"
                    @click="getTransportPrint()"
                    v-if="
                        !state.search &&
                        state.active_filters &&
                        state.tab == 'transport'
                    "
                >
                    <svg
                        width="8px"
                        height="8px"
                        viewBox="0 0 8 8"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M2 0v2h4v-2h-4zm-1.91 3c-.06 0-.09.04-.09.09v2.81c0 .05.04.09.09.09h.91v-2h6v2h.91c.05 0 .09-.04.09-.09v-2.81c0-.06-.04-.09-.09-.09h-7.81zm1.91 2v3h4v-3h-4z"
                        />
                    </svg>
                    Transport print
                </a>

                <a
                    class="e-button e-button--iconx16 e-button--green e-button--white-hover"
                    @click="getDataExport()"
                    v-if="!state.search && state.active_filters"
                >
                    <SvgSave />
                    Excel Export
                </a>

                <a
                    class="e-button e-button--iconx16 e-button--green e-button--white-hover"
                    @click="getTotals()"
                    v-if="!state.search && state.active_filters"
                >
                    <SvgGraph />
                    Totalen
                </a>

                <a
                    class="e-button e-button--green e-button--white-hover"
                    @click="switchOrdersToState('Kan in productie')"
                    v-if="
                        !state.search &&
                        state.active_filters &&
                        state.showSwichStatesBtn == 'IO'
                    "
                >
                    IO → KIP
                </a>
                <a
                    class="e-button e-button--green e-button--white-hover"
                    @click="switchOrdersToState('In productie')"
                    v-if="
                        !state.search &&
                        state.active_filters &&
                        state.showSwichStatesBtn == 'KIP'
                    "
                >
                    KIP → IP
                </a>
                <!--
                <a
                    class="e-button e-button--green e-button--white-hover is-hidden"
                    @click="refreshItems()"
                >
                    <SvgRefresh />
                    Vernieuwen</a
                >-->
            </div>
        </div>

        <orders-category-filter v-if="!state.totals"></orders-category-filter>

        <production-totals
            :totals="state.totals"
            v-if="state.totals && state.tab == 'productie'"
        ></production-totals>

        <transport-totals
            :totals="state.totals"
            v-if="state.totals && state.tab == 'transport'"
        ></transport-totals>

        <finance-totals
            :totals="state.totals"
            v-if="state.totals && state.tab == 'finance'"
        ></finance-totals>

        <div class="c-page-content">
            <div
                class="c-page-content__container c-page-content__container--spacing-small"
            >
                <div class="c-table js-overflow-arrow" v-if="!state.totals">
                    <div class="c-table__head js-sticky-element">
                        <div class="c-table-item c-table-item--head">
                            <div
                                class="c-table-item__col c-table-item__col--icon"
                            >
                                <div class="c-table-item__icon">
                                    <SvgSort />
                                </div>
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--contact"
                            >
                                Klant
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--status"
                                v-if="state.search"
                            >
                                Categorie
                            </div>                            
                            <div
                                class="c-table-item__col c-table-item__col--status c-table-item__col--sortable"
                                :class="{
                                    'c-table-item__col--sorted':
                                        state.filter == 'status',
                                    'c-table-item__col--sorted-desc':
                                        state.orderBy == 'desc' &&
                                        state.filter == 'status',
                                }"
                                @click="setFilter('status')"
                            >
                                Status
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--files c-table-item__col--sortable"
                                :class="{
                                    'c-table-item__col--sorted':
                                        state.filter ==
                                        'delivered_attachments->percentage',
                                    'c-table-item__col--sorted-desc':
                                        state.orderBy == 'desc' &&
                                        state.filter ==
                                            'delivered_attachments->percentage',
                                }"
                                @click="
                                    setFilter(
                                        'delivered_attachments->percentage'
                                    )
                                "
                            >
                                Aangeleverd
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--activity c-table-item__col--sortable"
                                :class="{
                                    'c-table-item__col--sorted':
                                        state.filter == 'updated_at',
                                    'c-table-item__col--sorted-desc':
                                        state.orderBy == 'desc' &&
                                        state.filter == 'updated_at',
                                }"
                                @click="setFilter('updated_at')"
                            >
                                Activiteit
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--followers"
                            >
                                Volgers
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--delivery"
                                v-if="state.showDelivery || true"
                            >
                                Levering
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--source c-table-item__col--sortable"
                                :class="{
                                    'c-table-item__col--sorted':
                                        state.filter == 'source',
                                    'c-table-item__col--sorted-desc':
                                        state.orderBy == 'desc' &&
                                        state.filter == 'source',
                                }"
                                @click="setFilter('source')"
                            >
                                Bron
                            </div>
                        </div>
                    </div>
                    <div class="c-table__body">

                        <router-link
                            class="c-table-item"
                            :to="{
                                name: 'app.order',
                                params: { hash: order.hash },
                            }"
                            v-for="order in order.orders.data"
                            :key="order.id"
                        >
                            <div
                                class="e-label-speed e-tooltip"
                                data-tooltip="Spoed"
                                v-if="order.is_urgent"
                            >
                                <SvgSpeed />
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--icon"
                            >
                                <div
                                    class="c-table-item__icon c-table-item__icon--iconx14 e-tooltip"
                                    :class="{
                                        'e-icon--maatwerk': order.is_maatwerk,
                                        'c-table-item__icon--grey-super-light':
                                            !order.is_maatwerk,
                                    }"
                                    data-tooltip="Maatwerk"
                                >
                                    <SvgMaatwerk />
                                </div>
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--contact"
                            >
                                <h2
                                    class="c-table-item__name"
                                    v-if="order.contact"
                                >
                                    {{ order.contact.company }}
                                </h2>

                                <!-- Opties: 
                                    Duplicatie van X
                                    Splitsing van  X
                                -->

                                <p v-if="!order.origin_reference">
                                    <a class="c-table-item__link">{{
                                        order.reference
                                    }}</a>
                                </p>

                                <p v-if="order.origin_reference">
                                    <a class="c-table-item__link">{{
                                        order.reference
                                    }}</a>
                                    <span v-if="order.is_duplicate">
                                        &bull; Duplicatie van

                                        <router-link
                                            :to="{
                                                name: 'app.order.order',
                                                params: {
                                                    hash: order.origin_hash,
                                                },
                                            }"
                                            class="c-table-item__link"
                                            >{{ order.origin_reference }}
                                        </router-link>
                                    </span>
                                    <span
                                        v-if="
                                            order.is_splitted &&
                                            order.origin_reference
                                        "
                                    >
                                        &bull; Splitsing van

                                        <router-link
                                            :to="{
                                                name: 'app.order.order',
                                                params: {
                                                    hash: order.origin_hash,
                                                },
                                            }"
                                            class="c-table-item__link"
                                            >{{ order.origin_reference }}
                                        </router-link>
                                    </span>
                                    <span
                                        v-if="
                                            order.is_splitted &&
                                            !order.origin_reference
                                        "
                                    >
                                        &bull; Bevat splitsing(en)
                                    </span>
                                </p>
                                
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--status"
                                v-if="state.search && order.category"
                            >
                                <div class="e-label e-label--delivered">{{ order.category }}</div>
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--status"
                            >
                                <button-state
                                    :label="order.status.label"
                                    :buttonclass="order.status.class"
                                />
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--files"
                            >
                                <div
                                    class="e-tooltip"
                                    :data-tooltip="order.checks.perc + '%'"
                                >
                                    {{ order.checks.done }}/{{
                                        order.checks.total
                                    }}

                                    <SvgCheckCircle
                                        v-if="
                                            order.checks.done ==
                                                order.checks.total &&
                                            order.checks.total > 0
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--activity"
                            >
                                {{ order.updated_at }}
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--followers"
                            >
                                <div class="c-followers">
                                    <div
                                        v-for="(user, index) in order.followers"
                                        class="c-followers__user e-tooltip"
                                        :class="{
                                            'c-followers__user--is-online':
                                                isOnline(user),
                                        }"
                                        :data-tooltip="user.name"
                                        :key="index"
                                    >
                                        <img
                                            class="c-followers__user-image"
                                            :src="user.avatar"
                                        />
                                        <div
                                            class="c-followers__user-status"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--delivery"
                                v-if="state.showDelivery || true"
                            >
                                <span
                                    class="e-label e-label--small e-label--box-name"
                                    v-if="
                                        order.delivery_weeks &&
                                        order.delivery_details.length == 0
                                    "
                                    :class="{
                                        ' e-tooltip':
                                            order && !order.delivery_completed,
                                    }"
                                    data-tooltip="Leverdatums nog niet compleet"
                                    >Wk
                                    <span
                                        class="e-label e-label--small"
                                        :class="{
                                            'e-label--delivery-known':
                                                order.delivery_weeks,
                                            'e-label--delivery-unknown':
                                                !order.delivery_weeks,
                                        }"
                                    >
                                        {{ order.delivery_weeks }}
                                        <span v-if="!order.delivery_completed"
                                            >🚨</span
                                        >
                                    </span>
                                </span>

                                <section
                                    v-if="
                                        Object.values(order.delivery_details)
                                            .length > 0
                                    "
                                >
                                    <div
                                        v-for="(
                                            detail, index
                                        ) in order.delivery_details"
                                        :key="index"
                                        style="margin: 4px 0"
                                    >
                                        <span
                                            v-if="detail.box != 'missing'"
                                            class="e-label e-label--small e-label--box-name"
                                            >{{ detail.box }}
                                            <span
                                                class="e-label e-label--small"
                                                :class="{
                                                    'e-label--delivery-known':
                                                        detail.date,
                                                    'e-label--delivery-unknown':
                                                        !detail.date,
                                                }"
                                            >
                                                {{
                                                    detail.date
                                                        ? detail.date
                                                        : "Onbekend"
                                                }}
                                            </span>
                                        </span>
                                        <span
                                            v-if="detail.box == 'missing'"
                                            class="e-label e-label--small e-label--box-name e-label--boxes-unknown"
                                            >Geen samenstelling bekend
                                        </span>
                                    </div>
                                </section>
                            </div>

                            <div
                                class="c-table-item__col c-table-item__col--source"
                                v-if="order && order.source"
                            >
                                {{ order.source }}
                            </div>
                        </router-link>
                    </div>
                </div>
                <pagination
                    v-show="!state.search"
                    :items="order.orders"
                    :order="state.orderFilter"
                />
            </div>
        </div>
    </article>
</template>

<script setup>
import { useOrderStore } from "@/store/order";
import SvgCheckCircle from "@/svg/check-circle.svg";
import SvgCheck from "@/svg/check.svg";
import SvgFilter from "@/svg/filter.svg";
import SvgGraph from "@/svg/graph.svg";
import SvgMaatwerk from "@/svg/maatwerk.svg";
import SvgReceipt from "@/svg/receipt.svg";
import SvgSave from "@/svg/save.svg";
import SvgSearch from "@/svg/search.svg";
import SvgSort from "@/svg/sort.svg";
import SvgSpeed from "@/svg/speed.svg";
import Swal from "sweetalert2";
import { inject, onMounted, onUpdated, reactive } from "vue";
import { useRoute } from "vue-router";

const Modals = inject("Modals");
const order = useOrderStore();

const route = useRoute();

const state = reactive({
    url: "/api/orders?q",
    orderBy: "desc",
    filter: "updated_at",
    orderFilter: "",
    search: null,
    debounce: 0,
    active_filters: false,
    now_online: [],
    showDelivery: false,
    showSwichStatesBtn: false,
    totals: null,
    tab: null,
});

onUpdated(() => {
    Eventing.fire("stickyElement");
});

onMounted(() => {
    Eventing.listen("paginate", (url) => {
        state.url = url;
        order.fill(url);
    });

    Eventing.listen("setFilters", () => {
        order.fill();
        state.active_filters = true;
        checkIfStateButtonShouldAppear();
        state.totals = null;
        state.tab = null;
    });

    Eventing.listen("onlineUsers", (users) => {
        console.info(users);
        state.now_online = users;
    });

    Eventing.listen("reloadOrders", () => {
        resetFilters();
        refreshItems();
    });

    Eventing.listen("refreshOrders", () => {
        refreshItems();
    });

    Eventing.fire("addOverflowArrows");

    checkIfFiltersAreSet();
});

order.fill();

order.activeFilters();

function refreshItems() {
    order.refresh();

    if (route.query && route.query.category) {
        order.fill(state.url + "&category=" + route.query.category);
    } else {
        order.fill();
    }
}

function getPackingSlips() {
    Eventing.fire(
        "toasting",
        "Pakbonnen worden klaargemaakt van selectie: dit kan eventjes duren afhankelijk van de geselecteerde aantallen&hellip;"
    );
    return order.packingSlip("products");
}

function getTransportPrint() {
    Eventing.fire(
        "toasting",
        "Print voor Transport wordt klaargemaakt: dit kan eventjes duren afhankelijk van de geselecteerde aantallen&hellip;"
    );
    return order.transportPrint("products");
}

async function switchOrdersToState(state) {
    await Swal.fire({
        title: state.showSwichStatesBtn == "IO" ? "IO → KIP" : "KIP → IP",
        text: "Zeker weten? Kan niet ongedaan gemaakt worden voor de gehele selectie",
        confirmButtonText: "Doorgaan",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "Annuleren",
        icon: "warning",
    }).then(async (result) => {
        if (result.isConfirmed) {
            return order.updateMultipleState(state);
        }
    });
}

async function getTotals() {
    await Swal.fire({
        title: "Overzicht",
        confirmButtonText: "Genereer",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "Annuleren",
        icon: "question",
        input: "select",
        inputOptions: {
            productie: "Productie",
            transport: "Transport",
            finance: "Financiën",
            orders: "Bestellingen",
        },
    }).then(async (result) => {
        if (result.value) {
            state.tab = result.value;
            state.totals = null;
            if (result.value == "orders") {
                return true;
            }
            Eventing.fire(
                "toasting",
                "Bezig met genereren van totalen. Dit kan eventjes duren&hellip;",
                "info"
            );
            let totals = await order.totals(result.value);
            state.totals = totals;
        }
    });
}

async function getDataExport() {
    await Swal.fire({
        title: "Export genereren",
        text: "Op basis van huidige filtering",
        confirmButtonText: "Genereer",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "Annuleren",
        icon: "question",
        input: "select",
        inputOptions: {
            orders: "Bestellingen",
            finance: "Financiën",
        },
    }).then(async (result) => {
        if (result.value) {
            Eventing.fire(
                "toasting",
                "Bezig met genereren van datasheet. Dit kan eventjes duren&hellip;",
                "info"
            );
            let _export = await order.export(result.value);
            if (_export) {
                Eventing.fire(
                    "toasting",
                    "Export is klaar en download is gestart!",
                    "success"
                );
            }
        }
    });
}

function checkIfStateButtonShouldAppear() {
    let getActiveFilters = order.getActiveFilters();
    state.showSwichStatesBtn = false;
    if (
        getActiveFilters.status.length == 1 &&
        getActiveFilters.status[0] == "In ontvangst"
    ) {
        state.showSwichStatesBtn = "IO";
    }
    if (
        getActiveFilters.status.length == 1 &&
        getActiveFilters.status[0] == "Kan in productie"
    ) {
        state.showSwichStatesBtn = "KIP";
    }
}

function resetFilters() {
    order.clearFilters();
    state.active_filters = false;
    state.search = null;
    state.showDelivery = false;
    state.totals = null;
    state.tab = null;
    order.fill();
}

function checkIfFiltersAreSet() {
    try {
        if (order.filters.invoice_status.length > 0) {
            state.active_filters = true;
        }
        if (order.filters.category.length > 0) {
            state.active_filters = true;
        }
        if (order.filters.status.length > 0) {
            state.active_filters = true;
        }
        if (order.filters.source.length > 0) {
            state.active_filters = true;
        }
        if (order.filters.only_following == true) {
            state.active_filters = true;
        }
        if (order.filters.only_new == true) {
            state.active_filters = true;
        }
        if (order.filters.without_delivery == true) {
            state.active_filters = true;
        }
        if (order.filters.only_season == true) {
            state.active_filters = true;
        }
        if (order.filters.products.length > 0) {
            state.active_filters = true;
        }
        if (order.filters.categories.length > 0) {
            state.active_filters = true;
        }
        if (order.filters.delivery_date) {
            state.active_filters = true;
            state.showDelivery = true;
        }
        if (order.filters.shipping_method.length > 0) {
            state.active_filters = true;
        }
        if (order.filters.shipping_transporter.length > 0) {
            state.active_filters = true;
        }

        if (order.filters.without_shop) {
            state.active_filters = true;
        }
        if (order.filters.with_shop) {
            state.active_filters = true;
        }
        if (order.filters.without_bank) {
            state.active_filters = true;
        }
        if (order.filters.with_bank) {
            state.active_filters = true;
        }
        if (order.filters.with_collected) {
            state.active_filters = true;
        }
        if (order.filters.without_collected) {
            state.active_filters = true;
        }
    } catch (error) {}
    checkIfStateButtonShouldAppear();
}

function debounceSearch(event) {
    clearTimeout(state.debounce);
    state.debounce = setTimeout(() => {
        state.search = event.target.value;
        if (state.search) {
            order.search(state.search);
        } else {
            order.fill();
        }
    }, 600);
}

function setFilter(filter) {
    let orderBy =
        state.orderBy == "asc"
            ? (state.orderBy = "desc")
            : (state.orderBy = "asc");
    state.filter = filter;
    state.orderFilter = "&order=" + state.filter + "&by=" + orderBy;

    order.fill(state.url + "&order=" + state.filter + "&by=" + orderBy);
}

function menu(menu) {
    Eventing.fire("toggleMenu", menu);
}

function isOnline(user) {
    const count = state.now_online.filter((obj) => {
        if (obj.name.includes(user.name)) {
            return true;
        }
        return false;
    }).length;
    if (count >= 1) {
        return true;
    }
    return Boolean(count);
}
</script>
