<template>
    <div class="c-page-filter__options-col">
        <button
            class="e-button e-tooltip e-tooltip--right"
            :data-tooltip="'Selecteer producten'"
            @click="Modals.show('dashboard-production-products')"
        >
            <SvgFilter />
            <span> Selectie </span>
        </button>

        <div class="e-page-heading">
            <h3 class="e-page-heading__text">
                <span
                    v-for="(value, slug, key) in props.products"
                    :key="value"
                    :slug="slug"
                    :product="value"
                    ><span v-if="key > 0">&nbsp; - &nbsp;</span>
                    {{ value }}
                </span>
            </h3>
        </div>
    </div>
</template>
<script setup>
import SvgFilter from "@/svg/filter.svg";

import { inject } from "vue";
const Modals = inject("Modals");

const props = defineProps({
    products: Object,
});
</script>
