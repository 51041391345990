<template>
    <article>
        <div class="e-modal e-modal--align-start" modal-id="add-products">
            <div class="e-modal__wrap">
                <div class="e-modal__modal">
                    <div
                        class="e-modal__close e-modal__close--white-hover js-close-modal"
                    >
                        <SvgCross @click="Modals.close()" />
                    </div>

                    <div class="e-modal__section e-modal__section--green-style">
                        <div class="e-modal__section-title">
                            <h2 class="e-modal__title">
                                Producten toevoegen aan
                                <span>"{{ state.box.name }}"</span>
                            </h2>
                        </div>
                    </div>

                    <div
                        class="e-modal__section e-modal__section--green-style e-modal__section--spacing-bottom"
                    >
                        <div class="e-search-input">
                            <label
                                class="e-search-input__search-label"
                                for="table-search"
                            >
                                <SvgSearch />
                            </label>
                            <input
                                class="e-search-input__search-input e-form__input"
                                id="table-search"
                                type="search"
                                @input="debounceSearch"
                                autocomplete="false"
                                spellcheck="false"
                                placeholder="Zoeken door producten..."
                            />
                        </div>
                    </div>

                    <div
                        v-if="!state.isLoaded"
                        class="e-modal__section e-modal__section--no-spacing-sides e-modal__section--no-spacing-top"
                    >
                        <div class="e-preloader"></div>
                    </div>

                    <div
                        v-if="state.isLoaded"
                        class="e-modal__section e-modal__section--no-spacing-sides e-modal__section--no-spacing-top"
                    >
                        <div class="c-table js-overflow-arrow">
                            <div class="c-table__head">
                                <div class="c-table-item c-table-item--head">
                                    <div
                                        class="c-table-item__col c-table-item__col--product-name"
                                    >
                                        Product
                                    </div>
                                    <div
                                        class="c-table-item__col c-table-item__col--article-nr"
                                    >
                                        Artikelnr.
                                    </div>
                                    <div
                                        class="c-table-item__col c-table-item__col--stock"
                                    >
                                        Voorraad
                                    </div>
                                    <div
                                        class="c-table-item__col c-table-item__col--amount"
                                    >
                                        Aantal
                                    </div>
                                    <div
                                        class="c-table-item__col c-table-item__col--button-big"
                                    ></div>
                                </div>
                            </div>
                            <div class="c-table__body">
                                <products-adding-row
                                    v-for="product in product.products.data"
                                    :key="product.id"
                                    :product="product"
                                    :box="state.box.hash"
                                    :showcategory="Boolean(product.category)"
                                    :is-new-category="
                                        currently(product.category)
                                    "
                                ></products-adding-row>
                            </div>
                        </div>
                    </div>

                    <pagination
                        :items="product.products"
                        :extraQuery="'&box=' + state.box.hash"
                        v-show="!state.search"
                        class="e-modal__pagination"
                    />

                    <div class="e-modal__section e-modal__section--center-text">
                        <button
                            @click="Modals.close()"
                            class="e-button e-button--full-width e-button--green js-close-modal"
                        >
                            Sluiten
                        </button>
                    </div>
                </div>
            </div>

            <div
                class="e-modal__bg js-close-modal"
                @click="Modals.close()"
            ></div>
        </div>
    </article>
</template>

<script setup>
import { useProductStore } from "@/store/product";
import SvgCross from "@/svg/cross.svg";
import SvgSearch from "@/svg/search.svg";

import { inject, onMounted, onUpdated, reactive } from "vue";
const Modals = inject("Modals");

const product = useProductStore();

const props = defineProps({
    path: {
        type: Object,
    },
});

const state = reactive({
    url: "/api/products?q&box=" + props.path.hash + "&",
    search: null,
    debounce: 0,
    box: {},
    isLoaded: false,
});

let currentCategories = [];

onUpdated(() => {
    Eventing.fire("addOverflowArrows");
});

onMounted(() => {
    state.box = props.path;
    Eventing.listen("paginate", (url) => {
        state.url = url;
        product.fillWithBox(url, props.path.hash);
    });

    Eventing.listen("isLoaded", () => {
        state.isLoaded = true;
    });

    currentCategories = [];

    product.fillWithBox(
        "/api/products?q&box=" + props.path.hash + "&",
        props.path.hash
    );
});

function debounceSearch(event) {
    clearTimeout(state.debounce);
    state.debounce = setTimeout(() => {
        state.search = event.target.value;
        if (state.search) {
            state.isLoaded = false;
            currentCategories = [];
            product.searchWithinBox(state.search, props.path.hash);
        } else {
            product.fillWithBox(state.url, props.path.hash);
        }
    }, 600);
}

function currently(item) {
    if (item && item.slug) {
        if (currentCategories.includes(item.slug)) {
            return false;
        } else {
            currentCategories.push(item.slug);
            return true;
        }
    }
    return false;
}
</script>
