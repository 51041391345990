<template>
    <article v-if="errors.errors && Object.values(errors.errors).length > 0">
        <div
            class="e-note e-note--error"
            style="display: block; margin: 10px 40px -10px"
        >
            <p
                class="e-note__text"
                v-for="(index, error) in errors.errors"
                :key="index"
            >
                <span>{{ errors.errors[error].toString() }}</span>
            </p>
        </div>
    </article>
</template>
<script setup>
import { reactive, onMounted } from "vue";

const details = reactive({
    show: false,
});

defineProps({
    errors: Object,
});

onMounted(() => {
    setTimeout(() => {
        try {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        } catch (error) {}
    }, 1000);
});
</script>
