<template>
    <article>
        <router-view name="content"></router-view>
    </article>
</template>

<script>
export default {
    mounted() {
        document.body.className = "";
        document.body.classList.add(
            "body--full-width",
            "body--production-login"
        );
        if ("transport.app" == this.$route.name) {
            this.$router.push({
                name: "transport.scan",
            });
        }
    },
};
</script>
