<template>
    <article>
        <div
            class="e-modal e-modal--w600 e-modal--align-start"
            modal-id="add-contact"
        >
            <div class="e-modal__wrap">
                <div class="e-modal__modal e-form">
                    <div class="e-modal__close js-close-modal">
                        <SvgCross @click="Modals.close()" />
                    </div>

                    <div class="e-modal__section">
                        <div class="e-modal__section-title">
                            <h2 class="e-modal__title">
                                Contact <span>toevoegen</span>
                            </h2>
                        </div>
                    </div>

                    <!-- <validation-errors :errors="contact.errors" /> -->
                    <form
                        method="POST"
                        @submit.prevent="onSubmit"
                        @keydown="contact.errors.errors = {}"
                        novalidate
                    >
                        <div class="e-modal__section e-modal__section--bordered">
                            <div class="e-modal__section-subtitle">
                                <h2 class="e-modal__title">Bedrijfsgegevens</h2>
                            </div>
                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.company,
                                }"
                                :invalid-message="contact.errors.errors.company"
                            >
                                <label class="e-form__label"
                                    >Bedrijfsnaam</label
                                >
                                <input
                                    class="e-form__input"
                                    type="text"
                                    v-model="state.form.company"
                                />
                            </div>

                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors[
                                            'address.postcode'
                                        ],
                                }"
                                :invalid-message="
                                    contact.errors.errors['address.postcode']
                                "
                            >
                                <label class="e-form__label">Postcode</label>
                                <input
                                    class="e-form__input"
                                    type="text"
                                    v-model="state.form.address.postcode"
                                />
                            </div>
                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors[
                                            'address.houseNumber'
                                        ],
                                }"
                                :invalid-message="
                                    contact.errors.errors['address.houseNumber']
                                "
                            >
                                <label class="e-form__label">Huisnummer</label>
                                <input
                                    class="e-form__input"
                                    type="text"
                                    v-model="state.form.address.houseNumber"
                                />
                            </div>
                        </div>
                        <div
                            class="e-modal__section e-modal__section--bordered"
                        >
                            <div class="e-modal__section-subtitle">
                                <h2 class="e-modal__title">Contactpersoon</h2>
                            </div>
                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.contactperson,
                                }"
                                :invalid-message="
                                    contact.errors.errors.contactperson
                                "
                            >
                                <label class="e-form__label">Naam</label>
                                <input
                                    class="e-form__input"
                                    type="text"
                                    v-model="state.form.contactperson"
                                />
                            </div>

                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.lastname,
                                }"
                                :invalid-message="
                                    contact.errors.errors.lastname
                                "
                            >
                                <label class="e-form__label">Achternaam</label>
                                <input
                                    class="e-form__input"
                                    type="text"
                                    v-model="state.form.lastname"
                                />
                            </div>

                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.jobtitle,
                                }"
                                :invalid-message="
                                    contact.errors.errors.jobtitle
                                "
                            >
                                <label class="e-form__label">Functie</label>
                                <input
                                    class="e-form__input"
                                    type="text"
                                    v-model="state.form.jobtitle"
                                />
                            </div>
                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.email,
                                }"
                                :invalid-message="contact.errors.errors.email"
                            >
                                <label class="e-form__label">E-mailadres</label>
                                <input
                                    class="e-form__input"
                                    type="email"
                                    v-model="state.form.email"
                                />
                            </div>
                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.mobile,
                                }"
                                :invalid-message="contact.errors.errors.mobile"
                            >
                                <label class="e-form__label">Mobiel nr.</label>
                                <input
                                    class="e-form__input"
                                    type="tel"
                                    v-model="state.form.mobile"
                                />
                            </div>
                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.phone,
                                }"
                                :invalid-message="contact.errors.errors.phone"
                            >
                                <label class="e-form__label"
                                    >Telefoon nr.</label
                                >
                                <input
                                    class="e-form__input"
                                    type="tel"
                                    v-model="state.form.phone"
                                />
                            </div>
                        </div>
                        <div
                            class="e-modal__section e-modal__section--bordered"
                        >
                            <div class="e-modal__section-subtitle">
                                <h2 class="e-modal__title">Overig</h2>
                            </div>

                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.code,
                                }"
                                :invalid-message="contact.errors.errors.code"
                            >
                                <label class="e-form__label"
                                    >Exact relatienr.</label
                                >
                                <input
                                    class="e-form__input"
                                    type="number"
                                    v-model="state.form.code"
                                />
                            </div>

                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.kvk,
                                }"
                                :invalid-message="contact.errors.errors.kvk"
                            >
                                <label class="e-form__label">KVK</label>
                                <input
                                    class="e-form__input"
                                    type="text"
                                    v-model="state.form.kvk"
                                />
                            </div>
                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.btw_id,
                                }"
                                :invalid-message="contact.errors.errors.btw_id"
                            >
                                <label class="e-form__label">BTW-id</label>
                                <input
                                    class="e-form__input"
                                    type="text"
                                    v-model="state.form.btw_id"
                                />
                            </div>
                            <div
                                class="e-form__field-wrap"
                                :class="{
                                    'e-form__field-wrap--invalid':
                                        contact.errors.message &&
                                        contact.errors.errors.invoice_email,
                                }"
                                :invalid-message="
                                    contact.errors.errors.invoice_email
                                "
                            >
                                <label class="e-form__label"
                                    >Factuur e-mail</label
                                >
                                <input
                                    class="e-form__input"
                                    type="email"
                                    v-model="state.form.invoice_email"
                                />
                            </div>
                        </div>

                        <div
                            class="e-modal__section e-modal__section--center-text"
                        >
                            <button
                                type="submit"
                                class="e-button e-button--full-width e-button--green js-close-modal"
                            >
                                Toevoegen
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div
                class="e-modal__bg js-close-modal"
                @click="Modals.close()"
            ></div>
        </div>
    </article>
</template>

<script setup>
import SvgCheck from "@/svg/check.svg";
import SvgCross from "@/svg/cross.svg";

import { onMounted, ref, inject, reactive } from "vue";
import { useContactStore } from "@/store/contact";

const Modals = inject("Modals");

const contact = useContactStore();

const state = reactive({
    form: {
        address: {},
    },
});

onMounted(() => {
    setTimeout(() => {
        // state.form.website = Object.values(enums.options)[0];
    }, 500);

    contact.errors.errors = { message: null, errors: [] };
});

function onSubmit() {
    contact.store(state.form);
}
</script>
