<template>
    <article>
        <div class="c-topbar">
            <div class="c-topbar__left">
                <div
                    class="c-topbar__left-button e-menu-button js-toggle-mobile-menu"
                    @click="menu('mobile')"
                >
                    <div class="e-menu-button__bars-wrap">
                        <div class="e-menu-button__bars"></div>
                    </div>
                </div>
                <!--
                <div class="c-topbar__search">
                    <label class="c-topbar__search-label" for="table-search">
                        <SvgSearch />
                    </label>
                    <input
                        class="c-topbar__search-input"
                        id="table-search"
                        type="search"
                        @input="debounceSearch"
                        autocomplete="false"
                        spellcheck="false"
                        placeholder="Zoeken door gebruikers..."
                    />
                </div>
                -->
            </div>
            <div
                class="c-topbar__right-button e-menu-button js-toggle-topbar-menu"
                @click="menu('topbar')"
            >
                <div class="e-menu-button__bars-wrap">
                    <div class="e-menu-button__bars"></div>
                </div>
            </div>
            <div class="c-topbar__right">
                <button
                    class="e-button e-button--grey-dark"
                    open-modal-id="add-user"
                    @click="Modals.show('user-create')"
                >
                    <SvgPlus />
                    Gebruiker
                </button>
            </div>
        </div>

        <div class="c-page-content">
            <div class="c-page-content__container c-page-content__container--spacing-small">
                <div class="c-table js-overflow-arrow">
                    <div class="c-table__head js-sticky-element">
                        <div class="c-table-item c-table-item--head">
                            <div class="c-table-item__col c-table-item__col--icon">
                                <div class="c-table-item__icon">
                                    <SvgSort />
                                </div>
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--user-name c-table-item__col--sortable"
                                :class="{
                                    'c-table-item__col--sorted':
                                        state.filter == 'name',
                                    'c-table-item__col--sorted-desc':
                                        state.orderBy == 'desc' &&
                                        state.filter == 'name',
                                }"
                                @click="setFilter('name')"
                            >
                                Naam
                            </div>
                            <div class="c-table-item__col c-table-item__col--email">E-mail</div>
                            <div
                                class="c-table-item__col c-table-item__col--login-at c-table-item__col--sortable"
                                :class="{
                                    'c-table-item__col--sorted':
                                        state.filter == 'last_login_at',
                                    'c-table-item__col--sorted-desc':
                                        state.orderBy == 'desc' &&
                                        state.filter == 'last_login_at',
                                }"
                                @click="setFilter('last_login_at')"
                            >
                                Laatste login
                            </div>
                            <div
                                class="c-table-item__col c-table-item__col--role c-table-item__col--sortable"
                                :class="{
                                    'c-table-item__col--sorted':
                                        state.filter == 'role',
                                    'c-table-item__col--sorted-desc':
                                        state.orderBy == 'desc' &&
                                        state.filter == 'role',
                                }"
                                @click="setFilter('role')"
                            >
                                Rol
                            </div>
                            <div class="c-table-item__col c-table-item__col--button"></div>
                        </div>
                    </div>
                    <div class="c-table__body">
                        <div
                            class="c-table-item c-table-item--product-row"
                            open-modal-id="add-user"
                            v-for="user in user.users.data"
                            :key="user.id"
                            @click="Modals.show('user-update', user.hash)"
                        >
                            <div class="c-table-item__col c-table-item__col--icon"></div>
                            <div class="c-table-item__col c-table-item__col--user-name">
                                <!-- DEV Note: if online, add class e-user-avatar--is-online  -->
                                <div class="e-user-avatar e-tooltip">
                                    <img
                                        class="e-user-avatar__image"
                                        :src="user.profile_photo"
                                    />
                                    <div
                                        class="e-user-avatar__status"
                                    ></div>
                                </div>
                                {{ user.name }}
                            </div>
                            <div class="c-table-item__col c-table-item__col--email">{{ user.email }}</div>
                            <div class="c-table-item__col c-table-item__col--login-at">
                                <span
                                    class="e-tooltip"
                                    :data-tooltip="user.last_login_at_ts"
                                >
                                    {{ user.last_login_at }}
                                </span>
                            </div>
                            <div class="c-table-item__col c-table-item__col--role">{{ user.role }}</div>
                            <div
                                class="c-table-item__col c-table-item__col--button c-table-item__col--align-right"
                                @click="deleteUser(user)"
                            >
                                <button class="e-button e-button--icon-only">
                                    <SvgDelete />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <pagination
                    :items="user.users"
                    :order="state.orderFilter"
                    v-show="!state.search"
                />
            </div>
        </div>
    </article>
</template>

<script setup>
import SvgDelete from "@/svg/delete.svg";
import SvgPlus from "@/svg/plus.svg";
import SvgSearch from "@/svg/search.svg";
import SvgSort from "@/svg/sort.svg";

import { onMounted, reactive, ref, inject } from "vue";
import { useUserStore } from "@/store/user";
import Swal from "sweetalert2";

const user = useUserStore();
const Modals = inject("Modals");

const state = reactive({
    url: "/api/users?q",
    orderBy: "desc",
    filter: "last_login_at",
    orderFilter: "",
    search: null,
    debounce: 0,
});

onMounted(() => {
    Eventing.listen("paginate", (url) => {
        state.url = url;
        user.fill(url);
    });
    Eventing.fire("stickyElement");
    Eventing.fire("addOverflowArrows");
});

user.fill();

function deleteUser(item) {
    setTimeout(() => {
        Modals.close();
    }, 100);
    Swal.fire({
        text: 'Zeker weten dat je "' + item.name + '" wilt verwijderen?',
        confirmButtonText: "Wissen",
        showCloseButton: true,
        icon: "warning",
    }).then((result) => {
        if (result.isConfirmed) {
            user.destroy(item.hash);
        }
    });
}

function debounceSearch(event) {
    clearTimeout(state.debounce);
    state.debounce = setTimeout(() => {
        state.search = event.target.value;
        if (state.search) {
            user.search(state.search);
        } else {
            user.fill();
        }
    }, 600);
}

function setFilter(filter) {
    let orderBy =
        state.orderBy == "asc"
            ? (state.orderBy = "desc")
            : (state.orderBy = "asc");
    state.filter = filter;
    state.orderFilter = "&order=" + state.filter + "&by=" + orderBy;

    user.fill(state.url + "&order=" + state.filter + "&by=" + orderBy);
}

function menu(menu) {
    Eventing.fire("toggleMenu", menu);
}
</script>
