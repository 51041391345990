import { defineStore } from "pinia";
import { useAuthStore } from "@/store/auth";

export const useExactStore = defineStore({
    id: "exact",
    state: () => ({
        options: {},
    }),
    actions: {
        auth() {
            const user = useAuthStore();
            return { headers: { Authorization: "Bearer " + user.token() } };
        },

        api(path) {
            return "/api/exact/" + path;
        },
        auth() {
            const user = useAuthStore();
            return {
                headers: {
                    Authorization: "Bearer " + user.token(),
                },
            };
        },
        async init() {
            if (
                confirm(
                    "Zeker weten dat je de connectie wilt instellen voor Exact?"
                )
            ) {
                try {
                    let result = await axios.get(
                        this.api("start"),
                        this.auth()
                    );
                    if ([200, 201, 204].includes(result.status)) {
                        window.location.href = result.data.url;
                    }
                } catch (error) {
                    this.fail(error);
                }
            }
        },
        fail(error) {
            console.error(error);
            Eventing.fire("error", error.response);
        },
    },
});
