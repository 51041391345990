<template>
    <article>
        <div class="c-topbar">
            <div class="c-topbar__left">
                <div
                    class="c-topbar__left-button e-menu-button js-toggle-mobile-menu"
                    @click="menu('mobile')"
                >
                    <div class="e-menu-button__bars-wrap">
                        <div class="e-menu-button__bars"></div>
                    </div>
                </div>
            </div>
            <div
                class="c-topbar__right-button e-menu-button js-toggle-topbar-menu"
                @click="menu('topbar')"
            >
                <div class="e-menu-button__bars-wrap">
                    <div class="e-menu-button__bars"></div>
                </div>
            </div>
            <div class="c-topbar__right">
                <button class="e-button" @click="showELS()">ELS</button>

                <button
                    class="e-button e-button--grey-dark"
                    open-modal-id="add-user"
                    @click="Modals.show('delivery-area-create')"
                >
                    <SvgPlus />
                    Bezorging
                </button>
            </div>
        </div>

        <div class="c-page-content">
            <div class="c-page-content__container c-page-content__container--spacing-small">

                <section v-if="!state.showELS">
                    <div class="c-table js-overflow-arrow">
                        <div class="c-table__head js-sticky-element">
                            <div class="c-table-item c-table-item--head">
                                <div class="c-table-item__col c-table-item__col--icon">
                                    <div class="c-table-item__icon">
                                        <SvgSort />
                                    </div>
                                </div>
                                <div
                                    class="c-table-item__col c-table-item__col--postcode-from c-table-item__col--sortable"
                                    :class="{
                                        'c-table-item__col--sorted':
                                            state.filter == 'postcode_from',
                                        'c-table-item__col--sorted-desc':
                                            state.orderBy == 'desc' &&
                                            state.filter == 'postcode_from',
                                    }"
                                    @click="setFilter('postcode_from')"
                                >
                                    Postcode van
                                </div>
                                <div
                                    class="c-table-item__col c-table-item__col--postcode-to c-table-item__col--sortable"
                                    :class="{
                                        'c-table-item__col--sorted':
                                            state.filter == 'postcode_to',
                                        'c-table-item__col--sorted-desc':
                                            state.orderBy == 'desc' &&
                                            state.filter == 'postcode_to',
                                    }"
                                    @click="setFilter('postcode_to')"
                                >
                                    Postcode tot
                                </div>

                                <div
                                    class="c-table-item__col c-table-item__col--date c-table-item__col--sortable"
                                    :class="{
                                        'c-table-item__col--sorted':
                                            state.filter == 'date',
                                        'c-table-item__col--sorted-desc':
                                            state.orderBy == 'desc' &&
                                            state.filter == 'date',
                                    }"
                                    @click="setFilter('date')"
                                >
                                    Datum
                                </div>

                                <div class="c-table-item__col c-table-item__col--button"></div>
                            </div>
                        </div>
                        <div class="c-table__body">
                            <div
                                class="c-table-item c-table-item--product-row"
                                open-modal-id="add-user"
                                v-for="deliveryarea in deliveryarea.deliveryareas
                                    .data"
                                :key="deliveryarea.id"
                                @click="
                                    Modals.show(
                                        'delivery-area-update',
                                        deliveryarea.id
                                    )
                                "
                            >
                                <div class="c-table-item__col c-table-item__col--icon">
                                </div>
                                <div class="c-table-item__col c-table-item__col--postcode-from">
                                    {{ deliveryarea.postcode_from }}
                                </div>
                                <div class="c-table-item__col c-table-item__col--postcode-to">
                                    {{ deliveryarea.postcode_to }}
                                </div>
                                <div class="c-table-item__col c-table-item__col--date">
                                    {{ deliveryarea.date }}
                                </div>

                                <div
                                    class="c-table-item__col c-table-item__col--align-right"
                                    @click="deleteDeliveryArea(deliveryarea)"
                                >
                                    <button class="e-button e-button--icon-only">
                                        <SvgDelete />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <pagination
                        :items="deliveryarea.deliveryareas"
                        :order="state.orderFilter"
                        v-show="!state.search"
                    />
                </section>

                <section v-if="state.showELS">
                    <div class="c-table js-overflow-arrow">
                        <div class="c-table__head js-sticky-table-header">
                            <div class="c-table-item c-table-item--head">
                                <div class="c-table-item__col c-table-item__col--els-xml">XML</div>
                                <div class="c-table-item__col c-table-item__col--els-order">Order</div>

                                <div class="c-table-item__col c-table-item__col--els-output">Output</div>
                            </div>
                        </div>
                        <div class="c-table__body">
                            <div
                                class="c-table-item c-table-item--product-row"
                                v-for="els in deliveryarea.els.data"
                                :key="els.id"
                            >
                                <div class="c-table-item__col c-table-item__col--els-xml c-table-item__col--align-right">
                                    <a
                                        target="_blank"
                                        :href="els.xml"
                                        onclick="return confirm('Je kunt deze link doorsturen naar ELS. De link is 8 uur geldig.')"
                                        class="e-button e-button--icon-only"
                                    >
                                        XML
                                    </a>
                                </div>
                                <div class="c-table-item__col c-table-item__col--els-order">
                                    {{ els.order }}
                                    <p>
                                        <small>{{ els.time }}</small>
                                    </p>
                                </div>
                                <div class="c-table-item__col c-table-item__col--els-output">
                                    <pre
                                        style="
                                            word-break: break-spaces !important;
                                            font-size: 0.8em;
                                        "
                                        >{{ els.output }}</pre
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <pagination
                        :items="deliveryarea.els"
                        :order="state.orderFilter"
                        v-show="!state.search"
                    />
                </section>
            </div>
        </div>
            
    </article>
</template>

<script setup>
import SvgDelete from "@/svg/delete.svg";
import SvgPlus from "@/svg/plus.svg";
import SvgSearch from "@/svg/search.svg";
import SvgSort from "@/svg/sort.svg";

import { onMounted, reactive, ref, inject } from "vue";
import { useDeliveryAreaStore } from "@/store/delivery-areas";
import Swal from "sweetalert2";

const deliveryarea = useDeliveryAreaStore();
const Modals = inject("Modals");

const state = reactive({
    url: "/api/delivery-areas?q",
    orderBy: "asc",
    filter: "postcode_from",
    orderFilter: "",
    search: null,
    debounce: 0,
    showELS: false,
});

onMounted(() => {
    Eventing.listen("paginate", (url) => {
        state.url = url;
        deliveryarea.fill(url);
    });
    Eventing.fire("stickyElement");
    Eventing.fire("addOverflowArrows");
});

deliveryarea.fill();

function deleteDeliveryArea(item) {
    setTimeout(() => {
        Modals.close();
    }, 100);
    Swal.fire({
        text: "Zeker weten dat je dit wilt verwijderen?",
        confirmButtonText: "Wissen",
        showCloseButton: true,
        icon: "warning",
    }).then((result) => {
        if (result.isConfirmed) {
            deliveryarea.destroy(item.id);
        }
    });
}

function debounceSearch(event) {
    clearTimeout(state.debounce);
    state.debounce = setTimeout(() => {
        state.search = event.target.value;
        if (state.search) {
            deliveryarea.search(state.search);
        } else {
            deliveryarea.fill();
        }
    }, 600);
}

async function showELS() {
    deliveryarea.tranactionsELS();
    state.showELS = true;
}

function setFilter(filter) {
    let orderBy =
        state.orderBy == "asc"
            ? (state.orderBy = "desc")
            : (state.orderBy = "asc");
    state.filter = filter;
    state.orderFilter = "&order=" + state.filter + "&by=" + orderBy;

    deliveryarea.fill(state.url + "&order=" + state.filter + "&by=" + orderBy);
}

function menu(menu) {
    Eventing.fire("toggleMenu", menu);
}
</script>
