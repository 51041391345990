<template>
    <article>
        <div class="c-topbar">
            <div class="c-topbar__left">
                <div
                    class="c-topbar__left-button e-menu-button js-toggle-mobile-menu"
                    @click="menu('mobile')"
                >
                    <div class="e-menu-button__bars-wrap">
                        <div class="e-menu-button__bars"></div>
                    </div>
                </div>
                <div class="c-topbar__search">
                    <label class="c-topbar__search-label" for="table-search">
                        <SvgSearch />
                    </label>
                    <input
                        class="c-topbar__search-input"
                        id="table-search"
                        type="search"
                        @input="debounceSearch"
                        autocomplete="false"
                        spellcheck="false"
                        placeholder="Zoeken door producten..."
                    />
                </div>
            </div>
            <div class="c-topbar__right"></div>
        </div>

        <app-stock-content />
    </article>

    <app-dashboards-side-overlay-traffic-stock />
</template>

<script setup>
import { useStockStore } from "@/store/stock";
import SvgSearch from "@/svg/search.svg";
import { inject, onMounted, onUpdated, reactive } from "vue";
const stock = useStockStore();
const Modals = inject("Modals");

const state = reactive({
    url: "/api/stocks?q",
    orderBy: "asc",
    filter: "name",
    orderFilter: "",
    search: null,
    debounce: 0,
    search: null,
    currentCategories: [],
    shownCategories: [],
});

onUpdated(() => {
    Eventing.fire("stickyElement");
});

onMounted(() => {});

function debounceSearch(event) {
    clearTimeout(state.debounce);
    state.debounce = setTimeout(() => {
        state.search = event.target.value;
        if (state.search) {
            stock.search(state.search);
        } else {
            stock.fill();
        }
    }, 600);
}

function menu(menu) {
    Eventing.fire("toggleMenu", menu);
}
</script>
