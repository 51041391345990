<template>
    <article>
        <div
            class="e-modal e-modal--w600 e-modal--align-start"
            modal-id="add-product-category"
        >
            <div class="e-modal__wrap">
                <div class="e-modal__modal">
                    <div class="e-modal__close js-close-modal">
                        <SvgCross @click="Modals.close()" />
                    </div>

                    <div class="e-modal__section">
                        <div class="e-modal__section-title">
                            <h2 class="e-modal__title">
                                Productcategorie <span>toevoegen</span>
                            </h2>
                        </div>
                    </div>
                    <form
                        method="POST"
                        @submit.prevent="onSubmit"
                        @keydown="product.errors.errors = {}"
                        novalidate
                    >
                        <div class="e-modal__section">
                            <div class="e-form">
                                <div
                                    class="e-form__field-wrap"
                                    :class="{
                                        'e-form__field-wrap--invalid':
                                            product.errors.message &&
                                            product.errors.errors.name,
                                    }"
                                    :invalid-message="
                                        product.errors.errors.name
                                    "
                                >
                                    <label class="e-form__label"
                                        >Categorienaam</label
                                    >
                                    <input
                                        class="e-form__input"
                                        type="text"
                                        placeholder="Bijv. Geschenkbox"
                                        v-model="state.form.name"
                                    />
                                </div>
                                <div class="e-form__field-wrap">
                                    <label class="e-form__checkbox-wrap">
                                        <input
                                            class="e-form__checkbox"
                                            type="checkbox"
                                            v-model="state.form.is_fillable"
                                        />
                                        <span
                                            class="e-form__checkbox-label"
                                        ></span>
                                        <span class="e-form__checkbox-text"
                                            >De klant kan producten in deze
                                            categorie toevoegen aan de
                                            bestelling via de klantlogin.</span
                                        >
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div
                            class="e-modal__section e-modal__section--center-text"
                        >
                            <button
                                class="e-button e-button--full-width e-button--green js-close-modal"
                            >
                                Toevoegen
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div
                class="e-modal__bg js-close-modal"
                @click="Modals.close()"
            ></div>
        </div>
    </article>
</template>

<script setup>
import SvgArrowLeft from "@/svg/arrow-left.svg";
import SvgCheck from "@/svg/check.svg";
import SvgCross from "@/svg/cross.svg";

import { onMounted, ref, inject, reactive } from "vue";
import { useProductStore } from "@/store/product";

const Modals = inject("Modals");

const product = useProductStore();

const state = reactive({
    form: {},
});

onMounted(() => {
    product.errors.errors = { message: null, errors: [] };
});

function onSubmit() {
    product.storeCategory(state.form);
}
</script>
