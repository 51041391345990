<template>
    <section>
        <modal-client-products
            v-if="state.modal == 'client-products'"
            :path="state.path"
        />
    </section>
</template>
<script setup>
import { onMounted, reactive, ref, inject } from "vue";
const Modals = inject("Modals");

const state = reactive({
    modal: null,
    path: null,
});

onMounted(() => {
    Eventing.listen("modal", (screen, path) => openModal(screen, path));
    Eventing.listen("closeModal", () => closeModal());
});

function openModal(screen, path) {
    state.modal = screen;
    if (typeof path !== "undefined") {
        state.path = path;
    }
    Modals.open();
}

function closeModal() {
    state.modal = null;
    Modals.closing();
}
</script>
