<template>
    <!-- #F030 -->
    <div
        class="c-below-topbar e-hide-scrollbar e-hide-scrollbar--x"
        v-if="state.categories"
    >
        <div class="e-hide-scrollbar__inner">
            <ul class="c-below-topbar__list">
                <li class="c-below-topbar__item">
                    <router-link
                        :to="{
                            name: 'app.products',
                        }"
                        @click="refreshItemsBasedOnCategory('all')"
                        class="c-below-topbar__link"
                        :class="{
                            'c-below-topbar__link--active':
                                ($route.query && !$route.query.category) ||
                                !$route.query,
                        }"
                    >
                        Alles
                    </router-link>
                </li>
                <li
                    class="c-below-topbar__item"
                    v-for="(item, index) in state.categories"
                    :key="index"
                >
                    <router-link
                        :to="{
                            name: 'app.products',
                            query: { category: item.slug },
                        }"
                        @click="refreshItemsBasedOnCategory(item.name)"
                        class="c-below-topbar__link"
                        :class="{
                            'c-below-topbar__link--active':
                                $route.query &&
                                $route.query.category &&
                                $route.query.category == item.slug,
                            wip: item.total <= 0,
                        }"
                    >
                        {{ item.name }}

                        <span class="e-amount-label">{{ item.total }}</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { useOrderStore } from "@/store/order";
import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";

import { useProductStore } from "@/store/product";
const order = useOrderStore();

const product = useProductStore();

const route = useRoute();

const state = reactive({
    categories: null,
});

onMounted(() => {
    getTotalsAndCategories();
    // if (route.query && route.query.category) {
    //     setTimeout(() => {
    //         Eventing.fire("refreshOrders");
    //     }, 500);
    // }
});

function refreshItemsBasedOnCategory(what) {
    setTimeout(() => {
        if (what == "all") {
            window.location.reload();
        } else {
            Eventing.fire("searchFor", what);
        }
    }, 500);
}

async function getTotalsAndCategories() {
    let categories = await product.fillCategoriesList();
    if (categories) {
        console.info(categories);
        state.categories = categories;
    }
}
</script>
