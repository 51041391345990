<template>

    <apexchart 
        width="100%" 
        height="100%" 
        :series="props.series"
        :options='chart.options'
    >
    </apexchart>

</template>

<script setup>
import { inject, onMounted, reactive } from "vue";

const props = defineProps({
    series: Array,
    labels: Array,
    module_theme: "", //'green' for green styling
});

//Green module style
var themeOptions = {
    plotOptions: {},
    fill: {},
    stroke: {},
    xaxis: {
        labels: {
            style: {},
        },
    },
};

if(props.module_theme == 'green') {

    themeOptions = {
        plotOptions: {
            radar: {
                polygons: {
                    strokeColors: '#fff',
                    strokeWidth: 1,
                    connectorColors: 'transparent',
                    fill: {
                        colors: ['#acdcc9', '#c2e5d7'],
                    }
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                  shade: 'darken',
                  type: "vertical",
                  shadeIntensity: 0,
                  gradientToColors: ['#fff', '#fff'],
                  inverseColors: true,
                  opacityFrom: 0.8,
                  opacityTo: 0.7,
                  stops: [100, 100],
                  colorStops: []
              },
          },
          stroke: {
            width: 3,
            colors: ['#fff'],
        },
        xaxis: {
          labels: {
            style: {
                  colors: ['#2c624d', '#2c624d', '#2c624d', '#2c624d', '#2c624d', '#2c624d', '#2c624d'],
                  fontWeight: 500,
              },
          },
        },
    }
}

const chart = reactive({

    options: {
        stroke: themeOptions.stroke,
        fill: themeOptions.fill,
        chart: {
            type: 'radar',
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '13px',
            },
        },
        legend: {
            show: true,
            position: 'left',
        },
        plotOptions: themeOptions.plotOptions,
        xaxis: {
          categories: props.labels,
          labels: {
            hideOverlappingLabels: false,
            style: themeOptions.xaxis.labels.style,
          },
        },
        yaxis: {
            show: false,
        },
        tooltip: {
            marker: {
                show: false,
            },
        }
      },
});


</script>