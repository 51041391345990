<template>
    <div
        v-if="showcategory"
        v-show="isNewCategory"
        class="c-table-item c-table-item--product-row c-table-item--is-category"
        @click="showModal('product-category-update', product.category.slug)"
    >
        <div class="c-table-item__col c-table-item__col--icon"></div>
        <div class="c-table-item__col c-table-item__col--category-name">
            {{ product.category.name }}
        </div>
        <div class="c-table-item__col c-table-item__col--article-nr"></div>
        <div class="c-table-item__col c-table-item__col--price"></div>
        <div class="c-table-item__col c-table-item__col--weight"></div>
        <div
            class="c-table-item__col c-table-item__col--button c-table-item__col--align-right"
            @click="deleteProductCategory(product.category)"
        >
            <button class="e-button e-button--icon-only">
                <SvgDelete />
            </button>
        </div>
    </div>
    <product-row v-if="showcategory" :product="product"> </product-row>

    <div
        v-if="!showcategory"
        class="c-table-item c-table-item--product-row"
        :class="{
            'c-table-item--is-inside-category': product.category,
        }"
        @click="showModal('product-update', product.slug)"
    >
        <div class="c-table-item__col c-table-item__col--icon"></div>
        <div class="c-table-item__col c-table-item__col--product-name">
            {{ product.name }}
        </div>
        <div class="c-table-item__col c-table-item__col--article-nr">
            {{ product.article_nr }}
        </div>
        <div class="c-table-item__col c-table-item__col--price">
            €
            {{ product.euro }}
        </div>
        <div class="c-table-item__col c-table-item__col--weight">
            {{ product.weight }}
            <span v-if="product.weight">gr.</span>
        </div>
        <div
            class="c-table-item__col c-table-item__col--button c-table-item__col--align-right"
            @click="deleteProduct(product)"
        >
            <button class="e-button e-button--icon-only">
                <SvgDelete />
            </button>
        </div>
    </div>
</template>
<script setup>
import SvgDelete from "@/svg/delete.svg";
import { inject } from "vue";

defineProps({
    product: Object,
    showcategory: Boolean,
    isNewCategory: Boolean,
});

const Modals = inject("Modals");

function deleteProduct(item) {
    Eventing.fire("deletingProduct", item);
}

function deleteProductCategory(item) {
    Eventing.fire("deletingProductCategory", item);
}

function showModal(modal, value) {
    Eventing.fire("showModal", modal, value);
}
</script>
